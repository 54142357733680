import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import useDisplayFlashOnResponse from '../hooks/useDisplayFlashOnResponse';
import initTranslations from '../lib/initTranslations';
import { FieldErrors, formErrorsFromError } from '../redux/errors/helpers';
import { useUpdateTrainingPathSetMutation } from '../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import {
  TrainingPathSet,
  UpdatableTrainingPathSetData,
} from '../redux/services/resourceApis/trainingPaths/types';
import { useTrainingPathContext } from './TrainingPathContext';

type TrainingPathSetState = {
  userId: number;
  trainingPathSet: TrainingPathSet;
  delayEnabled: boolean;
  updateErrors: FieldErrors | undefined;
  updateTrainingPathSet: (value: Partial<UpdatableTrainingPathSetData>) => void;
};

const TrainingPathSetContext = createContext({} as TrainingPathSetState);
const useTrainingPathSetContext = () => useContext(TrainingPathSetContext);

type TrainingPathSetProps = {
  children: ReactNode;
  userId: number;
  trainingPathSet: TrainingPathSet;
};

const t = initTranslations('training_path.path_config.config_set');

const TrainingPathSetProvider = ({
  userId,
  trainingPathSet,
  children,
}: TrainingPathSetProps): ReactElement => {
  const { delayType, delayValue, forceOrder, position, title, trainingPathSetUuid, titleVisible } =
    trainingPathSet;
  const [update, result] = useUpdateTrainingPathSetMutation();
  const { updateInProgress, setUpdateInProgress } = useTrainingPathContext();

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('update_failed'),
    successFunction: () => {
      setUpdateInProgress(false);
      result.reset();
    },
    errorFunction: () => {
      setUpdateInProgress(false);
      result.reset();
    },
  });

  const updateTrainingPathSet = useCallback(
    (updatedValues: Partial<UpdatableTrainingPathSetData>) => {
      if (updateInProgress) return;
      const { resetDelay = false, ...values } = updatedValues;

      setUpdateInProgress(true);
      update({
        userId,
        trainingPathSetUuid,
        delayType,
        delayValue,
        forceOrder,
        position,
        title,
        resetDelay,
        titleVisible,
        ...values,
      });
    },
    [
      delayType,
      delayValue,
      forceOrder,
      position,
      setUpdateInProgress,
      title,
      trainingPathSetUuid,
      update,
      updateInProgress,
      userId,
      titleVisible,
    ]
  );

  const delayEnabled = useMemo(() => {
    return delayType !== null && delayValue !== null;
  }, [delayType, delayValue]);

  const updateErrors = useMemo(() => {
    return result.error ? formErrorsFromError(result.error) : undefined;
  }, [result.error]);

  return (
    <TrainingPathSetContext.Provider
      value={{
        userId,
        trainingPathSet,
        delayEnabled,
        updateErrors,
        updateTrainingPathSet,
      }}
    >
      {children}
    </TrainingPathSetContext.Provider>
  );
};

export { TrainingPathSetProvider, useTrainingPathSetContext };
