import { Form, Formik, FormikState } from 'formik';
import React, { useCallback } from 'react';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../contexts/EditorToolbarContext';
import { useLocalesContext } from '../../../../../contexts/LocalesContext';
import { ReactComponent as TrainualCaptureLogo } from '../../../../../images/trainual_capture_logo.svg';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import Flyout from '../../../../design_system/overlays/flyout';
import InputField from '../../../../design_system/Triage/InputField';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import { insertEditorContent } from '../../lib/insertEditorContent';
import { EditorInputContainer } from '../../shared/styles';
import { validateCaptureUrl } from '../../shared/validators';
import { EmbedExtensionName } from '../../tiptap_extensions/types';
import LabelToolbarButton from '../../toolbar/buttons/components/LabelToolbarButton';
import { EmbedlyFlyoutId } from '../Embedly/EmbedlyFlyout/EmbedlyFlyout';

const EMBED_MENU_ID: RegisteredMenuId = 'trainual-capture-flyout';

const t = initTranslations('editor.embed_trainual_capture');

type Values = {
  url: string;
};

const initialValues: Values = {
  url: '',
};

const EmbedCaptureFlyout = () => {
  const { isMenuOpen, setActiveMenuId, closeMenu } = useActiveMenuHandler({
    menuId: EMBED_MENU_ID,
  });
  const { selectedEmbedMenuItem, setSelectedEmbedMenuItem } = useEditorToolbarContext();
  const { editor } = useEditorContext();
  const { productTerm } = useAccountTerminology();
  const { nonEnglishLocale } = useLocalesContext();

  const insertContent = useCallback(
    (values: Values) => {
      if (values.url) {
        insertEditorContent({
          editor,
          content: {
            type: EmbedExtensionName.IFRAME,
            attrs: {
              contentType: 'media',
              isCapture: true,
              src: values.url,
              contentUrl: values.url,
              title: values.url,
              className: 'trainual-capture-iframe',
            },
          },
        });
        setSelectedEmbedMenuItem(undefined);
        setActiveMenuId(null);
      }
    },
    [editor, setActiveMenuId, setSelectedEmbedMenuItem]
  );

  const onClose = useCallback(
    (resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void) => {
      setSelectedEmbedMenuItem(undefined);
      resetForm();
    },
    [setSelectedEmbedMenuItem]
  );

  const navigateBackToEmbedlyMenu = useCallback(() => {
    setActiveMenuId(selectedEmbedMenuItem ? EmbedlyFlyoutId : null);
  }, [selectedEmbedMenuItem, setActiveMenuId]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        insertContent(values);
        closeMenu();
        resetForm();
      }}
      validationSchema={validateCaptureUrl(productTerm)}
    >
      {({ handleChange, values, handleSubmit, errors, resetForm, touched }) => (
        <Flyout
          className='trainual-capture'
          fullWidthButtons={nonEnglishLocale}
          id={EMBED_MENU_ID}
          loading={false}
          onClose={() => onClose(resetForm)}
          placement='bottom-start'
          primaryButtonTask={() => {
            handleSubmit();
            setSelectedEmbedMenuItem(undefined);
          }}
          primaryButtonText={t('primary_button_text')}
          secondaryButtonTask={() => {
            onClose(resetForm);
            navigateBackToEmbedlyMenu();
          }}
          secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('secondary_button_text')}
          title={t('title', { productTerm })}
          triggerButton={
            <LabelToolbarButton
              Svg={TrainualCaptureLogo}
              active={isMenuOpen}
              ariaLabel={t('aria_label_embed_button', { productTerm, skipNoTranslate: true })}
              className='flyout-trigger'
              iconType='svg'
              id='trainual-capture-embed-button'
              label={t('toolbar_button_label')}
              onClick={() => {
                resetForm();
              }}
            />
          }
        >
          <EditorInputContainer>
            <Form>
              <InputField
                autoFocus
                errorText={touched.url && errors.url}
                id='embed-trainual-capture-input'
                label={t('input_label', { productTerm })}
                name='url'
                onChange={handleChange}
                placeholder={t('placeholder', { productTerm, skipNoTranslate: true })}
                value={values.url}
              />
            </Form>
          </EditorInputContainer>
        </Flyout>
      )}
    </Formik>
  );
};

export default EmbedCaptureFlyout;
