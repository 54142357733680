import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { AdjacentCurriculumElementProps } from '../../../../types/Editor';
import { Emoji } from '../../../../types/Emoji';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import NextButton from '../../flowcharts/shared/BaseNavigationButtons/NextButton';
import PreviousButton from '../../flowcharts/shared/BaseNavigationButtons/PreviousButton';
import { routes } from '../../publicApplication/applicationRouter';
import BaseNavigationButtons from './BaseNavigationButtons';

const t = initTranslations('curriculums_view.flowchart.footer.navigation_buttons');

type BackToSubjectButtonProps = {
  curriculumId: number;
  emoji: Emoji;
};

const BackToSubjectButton = ({ curriculumId, emoji }: BackToSubjectButtonProps) => {
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();
  const { slug } = useCurrentAccount();

  const route = routes.curriculumEdit({ slug, id: curriculumId });

  return (
    <DefaultButton
      behavesAs='a'
      fullWidth
      id='next-content-button'
      text={`${emoji ? emoji + ' ' : ''}${t('back_to_subject', {
        subject: subjectTermSingular.toLowerCase(),
      })}`}
      {...route.link}
    />
  );
};

type Props = {
  previous: AdjacentCurriculumElementProps | null;
  next: AdjacentCurriculumElementProps | null;
  curriculumId: number;
  curriculumEmoji: Emoji;
};

const NavigationButtons = ({ previous, next, curriculumId, curriculumEmoji }: Props) => {
  let nextButton = next && <NextButton mode='edit' {...next} />;

  if (!(next && previous)) {
    nextButton = <BackToSubjectButton curriculumId={curriculumId} emoji={curriculumEmoji} />;
  }

  const previousButton = previous && <PreviousButton mode='edit' {...previous} />;

  return <BaseNavigationButtons nextButton={nextButton} previousButton={previousButton} />;
};

export default NavigationButtons;
