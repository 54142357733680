import React from 'react';

import { SkeletonLoader } from '../../../shared/SkeletonLoader/styles';
import { CONSUMPTION_TOOLBAR_HEIGHT } from '../../ConsumptionPage/ToolbarContainer';
import { ContentLayout, EditorContentWrapper } from '../../shared/styles';
import { CREATE_TOOLBAR_HEIGHT } from '../../toolbar/Toolbar';
import { StepContentSkeletonWrapper, StepContentWrapper, TopicTitleWrapper } from './styles';

type StepContentSkeletonProps = {
  editMode?: boolean;
};

const StepContentSkeleton = ({ editMode = false }: StepContentSkeletonProps) => {
  const toolbarAdjustment = editMode ? CREATE_TOOLBAR_HEIGHT : CONSUMPTION_TOOLBAR_HEIGHT;

  return (
    <StepContentSkeletonWrapper>
      <EditorContentWrapper toolbarAdjustment={toolbarAdjustment}>
        <ContentLayout>
          <TopicTitleWrapper>
            <SkeletonLoader height='1.25rem' width='40%' />
          </TopicTitleWrapper>
          <StepContentWrapper>
            <SkeletonLoader height='1.25rem' marginBottom='2.5rem' />
            <SkeletonLoader marginBottom='1rem' />
            <SkeletonLoader marginBottom='1rem' width='80%' />
            <SkeletonLoader width='90%' />
          </StepContentWrapper>
        </ContentLayout>
      </EditorContentWrapper>
    </StepContentSkeletonWrapper>
  );
};

export default StepContentSkeleton;
