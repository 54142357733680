import React, { useContext } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../../lib/initTranslations';
import { AccountTerminologyContext } from '../../../../../../../AccountTerminologyProvider';
import { fontSm5 } from '../../../../../../../styled/TypeSystem';
import StepTitle from '../StepTitle/StepTitle';

const t = initTranslations('curriculums.ai_outliner.step_table');

const StepContainer = styled.div`
  width: 100%;
  margin-bottom: 8rem;
`;

const StepCounter = styled.p`
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

type StepProps = {
  title: string;
  id: string;
  content: string;
};

export interface Props {
  steps: StepProps[];
}

const StepSuggestionsTable = ({ steps }: Props) => {
  const {
    step: { plural: stepTerm },
  } = useContext(AccountTerminologyContext);
  return (
    <>
      <StepCounter>{t('step_counter', { step: stepTerm, count: steps.length })}</StepCounter>
      <StepContainer>
        <div>
          {steps.map((step) => {
            const { title, id, content } = step;
            return <StepTitle id={id} key={id} subtext={content} title={title} />;
          })}
        </div>
      </StepContainer>
    </>
  );
};

export default StepSuggestionsTable;
