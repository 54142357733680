import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useEffect, useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../../hooks/useDisplayFlashOnResponse';
import { ThreeDotLoaderGif } from '../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../lib/initTranslations';
import scrollToElement from '../../../../../../../lib/scrollToElement';
import translationClassname from '../../../../../../../lib/translationClassname';
import { useUpdateSurveyMutation } from '../../../../../../../redux/services/resourceApis/surveys/surveysApi';
import { MetaUserAccess } from '../../../../../../../types/Curriculum';
import { SurveyCurriculumElement } from '../../../../../../../types/CurriculumElement';
import Badge from '../../../../../../design_system/display/badge';
import Icon from '../../../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import useActiveMenuHandler from '../../../../../../design_system/useActiveMenuHandler';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import EmojiSelect from '../../../../../ContentLibrary/components/SubjectEmoji/EmojiSelect';
import { routes, useRoute } from '../../../../../publicApplication/applicationRouter';
import routeTo from '../../../../../publicApplication/routeTo';
import StatusBadgeActions from '../../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import EditableCurriculumElementTitle from '../../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import CurriculumElementThreeDot from '../../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { getSurveyUrlFromAccess } from '../../../../../shared/helpers';
import {
  ArrowIconWrapper,
  CurriculumElementThreeDotWrapper,
  EditBadgeWrapper,
  EmojiWrapper,
  GripVerticalIconWrapper,
  OutlineCurriculumElementRowContainer,
  OutlineRowContainer,
} from '../../../../shared/CurriculumElementRowStyles';
import { RowActionsContainer, RowCardBody } from '../../../../shared/CurriculumRowStyles';

const TitleWrapper = styled.div`
  align-self: center;
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${TruncatedText({ noWrap: 'nowrap' })};
`;

const LoadingTitle = styled.span`
  user-select: none;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: default;

  ${TruncatedText({ noWrap: 'nowrap' })};
  ${fontSm5};
`;

const TestGeneratingLoadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingText = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm1};
  ${fontSm5};
`;

const LoadingImage = styled.img`
  width: 4rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: -${({ theme: { constants } }) => constants.spacerSm3};
`;

const StyledTestBadge = styled(Badge)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export type OutlineSurveyTableRowProps = {
  curriculumElement: SurveyCurriculumElement;
  isCurriculumLocked?: boolean;
  curriculumId: number;
  provided: DraggableProvided;
  blockEditPrivileges: boolean;
  userAccess?: MetaUserAccess;
};

const t = initTranslations('curriculums_view');

const OutlineSurveyTableRow = ({
  curriculumElement,
  isCurriculumLocked,
  curriculumId,
  provided,
  blockEditPrivileges,
  userAccess,
}: OutlineSurveyTableRowProps) => {
  const route = useRoute();
  const {
    params: { scrollToElementId },
  } = route as Route<typeof routes.curriculumEdit>;
  const { slug } = useCurrentAccount();
  const [updateSurvey, updateSurveyResult] = useUpdateSurveyMutation();
  const { isSuccess } = updateSurveyResult;
  const { elementId, element, disableTranslation } = curriculumElement;
  const { title, status, inProcessingQueue, emoji, questionCount } = element;
  const [isEditing, setIsEditing] = useState(false);
  const { isMenuOpen } = useActiveMenuHandler({
    menuId: `curriculum-element-three-dot-${curriculumElement.id}`,
  });
  const surveyUrl = useMemo(
    () => getSurveyUrlFromAccess(userAccess, curriculumId, slug, elementId),
    [curriculumId, elementId, slug, userAccess]
  );

  useDisplayFlashOnResponse({
    result: updateSurveyResult,
    errorMessage: t('update_title_error'),
  });

  useEffect(() => {
    if (inProcessingQueue && elementId === scrollToElementId) {
      scrollToElement(`curriculum-element-survey-${elementId}`);
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [inProcessingQueue, elementId, scrollToElementId]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='curriculum-element-item'
      id={`curriculum-element-survey-${elementId}`}
    >
      <OutlineCurriculumElementRowContainer className='outline-curriculum-element-container'>
        <GripVerticalIconWrapper remainVisible={isMenuOpen}>
          <Icon name='grip-vertical' weight='solid' />
        </GripVerticalIconWrapper>
        <OutlineRowContainer processing={inProcessingQueue}>
          <RowCardBody isLocked={isCurriculumLocked || blockEditPrivileges}>
            <EditBadgeWrapper>
              <StyledTestBadge text={t('outline.test')} type='info' />
            </EditBadgeWrapper>
            <EmojiWrapper>
              <EmojiSelect
                clearEmoji={() => {
                  updateSurvey({ id: elementId, emoji: null });
                }}
                emoji={emoji}
                noEmojiIconName='clipboard-check'
                onEmojiSelect={(emoji: BaseEmoji) => {
                  updateSurvey({ emoji: emoji.native, id: elementId });
                }}
                usePortal
              />
            </EmojiWrapper>
            {inProcessingQueue ? (
              <TitleWrapper className={translationClassname(disableTranslation)}>
                <LoadingTitle>{title}</LoadingTitle>
              </TitleWrapper>
            ) : (
              <EditableCurriculumElementTitle
                disableTranslation={disableTranslation}
                editable={!blockEditPrivileges}
                id={elementId}
                isEditing={isEditing}
                isSuccess={isSuccess}
                route={surveyUrl}
                setIsEditing={setIsEditing}
                title={title}
                updateElement={({ id, title }) => updateSurvey({ id, name: title })}
              />
            )}
            <RowActionsContainer>
              {inProcessingQueue ? (
                <TestGeneratingLoadingContainer>
                  <LoadingText>{t('generating_test')}</LoadingText>
                  <LoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
                </TestGeneratingLoadingContainer>
              ) : (
                <>
                  <StatusBadgeActions
                    curriculumElement={{ id: curriculumElement.id, status }}
                    curriculumId={curriculumId}
                    infoText={t('questions', { count: questionCount })}
                  />
                  <ArrowIconWrapper onClick={() => routeTo(surveyUrl)}>
                    <Icon name='arrow-right' />
                  </ArrowIconWrapper>
                </>
              )}
            </RowActionsContainer>
          </RowCardBody>
        </OutlineRowContainer>
        <CurriculumElementThreeDotWrapper remainVisible={isMenuOpen}>
          {!blockEditPrivileges && (
            <CurriculumElementThreeDot
              canBeModified
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              eSignatureDisplayedAndRequired={false}
              isCurriculumLocked={isCurriculumLocked}
              renameClickHandler={() => setIsEditing(true)}
            />
          )}
        </CurriculumElementThreeDotWrapper>
      </OutlineCurriculumElementRowContainer>
    </div>
  );
};

export default OutlineSurveyTableRow;
