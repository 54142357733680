import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.content_page');

const CompanySectorToggle = ({ isDisabled }: { isDisabled: boolean }) => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.companySector}
        className='company-sector'
        disabled={isDisabled}
        handleToggle={() => setFieldValue('companySector', !values.companySector)}
        id='enable-company-sector'
        name='companySector'
        tooltipId={isDisabled ? 'disabled-company-toggle' : ''}
        tooltipText={isDisabled ? t('sector_toggle_disabled') : ''}
      />
      <ToggleInfoSection>
        <ToggleInfoWrapper>
          <FormSectionTitle>{t('company_toggle_title')}</FormSectionTitle>
          <FormSectionDescription>{t('company_toggle_title')}</FormSectionDescription>
        </ToggleInfoWrapper>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default CompanySectorToggle;
