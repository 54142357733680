import styled, { css } from 'styled-components';

import Link from '../../../../design_system/Link';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { deprecated, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

const sharedHeaderStyles = css`
  background-color: transparent;
  border-collapse: collapse;
  padding: ${({ theme }) => theme.constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  text-align: left;
  width: 33.33%;
  ${fontSm4};
`;

const sharedCellStyles = css`
  padding: ${({ theme }) => theme.constants.spacerSm3};
  text-align: left;
  width: 33.33%;
  ${fontSm4};
`;

export const CompletionsWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 3.75rem 3.75rem 7.5rem;
  background-color: ${({ theme }) => theme.vars.foundationSurface1};
  overflow: hidden;
  overflow-y: auto;
`;

export const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.constants.borderWidthSm} solid
    ${({ theme }) => theme.vars.borderSurface2};
  border-radius: ${({ theme }) => theme.constants.borderRadiusLg};
  margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  padding: ${({ theme }) => theme.constants.spacerMd2};
  border-radius: ${({ theme }) => theme.constants.borderRadiusLg};
  overflow: hidden;
  ${fontSm5};
`;

export const HeaderRow = styled.thead`
  border-bottom: ${({ theme }) => theme.constants.borderWidthSm} solid
    ${({ theme }) => theme.vars.borderSurface2};
  background-color: ${({ theme }) => theme.vars.foundationBase1};
`;

export const StyledNameHeader = styled.th`
  display: flex;
  align-items: center;
  ${sharedHeaderStyles};
`;

export const TableHeader = styled.th`
  ${sharedHeaderStyles};
`;

export const TableRow = styled.tr`
  border-top: ${({ theme }) => theme.constants.borderWidthSm} solid
    ${({ theme }) => theme.vars.borderSurface2};
`;

export const CompletionCell = styled.td`
  ${sharedCellStyles};
`;

export const CompletionPercentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.constants.spacerMd3};
`;

export const CompletionPercent = styled.div`
  padding: ${({ theme }) => theme.constants.spacerSm3};
  width: ${({ theme }) => theme.constants.spacerLg3};
`;

export const StyledNameCell = styled.td`
  ${sharedCellStyles};
`;

export const StyledActionCell = styled.td`
  ${sharedCellStyles};
`;

export const NameCellWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-inline: 0 ${({ theme }) => theme.constants.spacerSm3};
`;

export const AvatarWrapper = styled.div`
  margin-left: ${({ theme }) => theme.constants.spacerSm3};
  padding-inline: ${({ theme }) => theme.constants.spacerSm3};
`;

export const NameTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameText = styled.div.attrs({ className: 'notranslate' })`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${fontSm5}
`;

export const TitleText = styled.div`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  ${deprecated.fontSm3}
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${({ theme }) => theme.constants.spacerMd2};
`;

export const CompletionsContentDiv = styled.div`
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 80rem;
  min-width: 60rem;
`;

export const BreadcrumbWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
`;

export const BreadcrumbLink = styled(Link)`
  display: flex;
  align-self: flex-start;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: pointer;
  width: 100%;
  max-width: 40rem;
`;

export const BreadcrumbAction = styled.span`
  ${fontSm4}
  ${TruncatedText({})};
`;
