import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.people_settings');

const DisplayEmailToggle = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.enableProfileEmail}
        className='enable-profile-email-setting'
        handleToggle={() => setFieldValue('enableProfileEmail', !values.enableProfileEmail)}
        id='enable-profile-email'
        name='enableProfileEmail'
      />
      <ToggleInfoWrapper>
        <FormSectionTitle>{t('enable_profile_email_title')}</FormSectionTitle>
        <FormSectionDescription>{t('enable_profile_email_description')}</FormSectionDescription>
      </ToggleInfoWrapper>
    </ToggleSettingContainer>
  );
};

export default DisplayEmailToggle;
