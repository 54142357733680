import styled from 'styled-components';

import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';

export const TitleWrapper = styled.div`
  align-self: center;
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${TruncatedText({ noWrap: 'nowrap' })};
`;

export const LoadingTitle = styled.span`
  user-select: none;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: default;

  ${TruncatedText({ noWrap: 'nowrap' })};
  ${fontSm5};
`;

export const TestGeneratingLoadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingText = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm1};
  ${fontSm5};
`;

export const LoadingImage = styled.img`
  width: 4rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: -${({ theme: { constants } }) => constants.spacerSm3};
`;
