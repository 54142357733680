import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { SectionDivider } from '../../../AdvancedSettings/styles';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.general_settings');

const BetaFeatures = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <>
      <SectionDivider />
      <ToggleSettingContainer id='beta-features-toggle'>
        <StyledToggle
          checked={values.betaFeatures}
          className='beta-features-setting'
          handleToggle={() => setFieldValue('betaFeatures', !values.betaFeatures)}
          id='beta-features'
          name='betaFeatures'
        />
        <ToggleInfoSection>
          <ToggleInfoWrapper>
            <FormSectionTitle>{t('beta_features_title')}</FormSectionTitle>
            <FormSectionDescription>{t('beta_features_description')}</FormSectionDescription>
          </ToggleInfoWrapper>
        </ToggleInfoSection>
      </ToggleSettingContainer>
    </>
  );
};

export default BetaFeatures;
