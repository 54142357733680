import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useCurriculumElementInfo } from '../../../../../hooks/curriculum/useCurriculumElementInfo';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useSlideout from '../../../../../hooks/useSlideout';
import initTranslations from '../../../../../lib/initTranslations';
import { setSelectedContentType } from '../../../../../redux/domains/curriculums/curriculumsSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useAdminUpdateCurriculumMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CurriculumEdit } from '../../../../../redux/services/resourceApis/curriculums/types';
import { Emoji } from '../../../../../types/Emoji';
import SplitButton from '../../../../design_system/buttons/SplitButton';
import { MenuOptionProps } from '../../../../design_system/core/MenuOption/MenuOption';
import Icon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { EmojiWrapper } from '../../../shared/CurriculumCard/CurriculumCardStyles';
import EmojiPicker from '../../../shared/EmojiPicker';
import { SettingsButton } from '../UniversalHeader/SettingsButton/SettingsButton';
import { GlowingIconButton } from './AIOutliner/GlowingIconButton/GlowingIconButton';
import Title from './Title';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* The Negative 0.5rem - border width margin is added to align inner text with the rest of the 
  TopSurface content rather than hidden input border */
  margin-left: ${({ theme: { constants } }) => `calc(-${constants.spacerSm3} + 1px)`};
`;

// DS Override: Pointer events none was required to get tooltip to show up
const StyledIcon = styled(Icon)`
  pointer-events: none;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AddContentButton = styled(SplitButton)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

type Props = {
  emoji: Emoji;
  title: string;
  id: number;
  isLocked?: boolean;
  containsSignature?: boolean;
  curriculum: CurriculumEdit;
};

const t = initTranslations('curriculums.edit');

const Header = ({ emoji, title, id, isLocked, containsSignature, curriculum }: Props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [update, result] = useAdminUpdateCurriculumMutation();
  const {
    advanced_settings: { disable_translation: disableTranslation },
  } = curriculum;

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('emoji_error'),
  });

  const emojiSelected = useCallback(
    (emoji: BaseEmoji) => {
      update({ emoji: emoji.native, id });
      setShowPicker(false);
    },
    [id, update]
  );

  const clearEmoji = useCallback(() => {
    update({ emoji: null, id });
  }, [id, update]);

  const { open, close, isOpen } = useSlideout('ai-outliner');

  const dispatch = useAppDispatch();

  const scrollToCreateTopicInput = () => {
    const inputElement = document.querySelector<HTMLInputElement>(
      'input[name="new-curriculum-element-title"]'
    );

    if (inputElement) {
      const scrollIntoViewPromise = new Promise<void>((resolve) => {
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            observer.disconnect();
            resolve();
          }
        });
        observer.observe(inputElement);
      });

      // Scroll the element into view
      inputElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // When the scroll is done, focus the input element
      scrollIntoViewPromise.then(() => {
        setTimeout(() => {
          inputElement.focus();
        }, 300);
      });
    }
  };

  const elements = useCurriculumElementInfo();
  const addContentMenuOptions: MenuOptionProps[] = elements.map((element, index) => {
    return {
      title: element.title,
      description: element.description,
      onClick: () => {
        scrollToCreateTopicInput();
        dispatch(setSelectedContentType(index));
      },
      iconName: element.iconName,
      id: `add-content-${element.kind}-dropdown`,
    };
  });

  const mainButtonOnClick = () => {
    scrollToCreateTopicInput();
    dispatch(setSelectedContentType(0));
  };

  return (
    <HeaderWrapper>
      <Tooltip id='update-emoji-tooltip' place='top' text={t('update_emoji')} />
      <EmojiPicker
        emojiPresent={emoji}
        isOpen={showPicker}
        onClick={() => setShowPicker(!showPicker)}
        onClickOutside={() => setShowPicker(false)}
        onEmojiSelect={emojiSelected}
        removeButtonAction={clearEmoji}
        trigger={
          <EmojiWrapper
            clickable
            data-for='update-emoji-tooltip'
            data-tip
            id='emoji-wrapper'
            onClick={() => setShowPicker(!showPicker)}
          >
            {emoji ? (
              emoji
            ) : (
              <StyledIcon className='default-emoji' name='file-alt' size='md' weight='regular' />
            )}
          </EmojiWrapper>
        }
      />
      <TitleWrapper>
        <Title
          containsSignature={containsSignature}
          disableTranslation={disableTranslation}
          id={id}
          isLocked={isLocked}
          title={title}
        />
        <GlowingIconButton iconWithLabel onClick={() => (isOpen ? close() : open())} />
        <AddContentButton
          mainButtonOnClick={mainButtonOnClick}
          mainMenuButtonId='subject-outline-add-content-button'
          menuId='subject-outline-add-content-menu'
          menuOptions={addContentMenuOptions}
          text={t('create')}
        />
        <SettingsButton />
      </TitleWrapper>
    </HeaderWrapper>
  );
};

export default Header;
