import React from 'react';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../../AccountTerminologyProvider';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../../../../styled/TypeSystem';
import { SkeletonLoader } from '../../../../../../shared/SkeletonLoader/styles';

const t = initTranslations('curriculums.ai_outliner.loading');

const LoadingText = styled.p`
  margin-block: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

const LoadingRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const SkeletonRowWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 100%;
  border: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDisabled};
`;

const SkeletonRow = () => {
  const {
    constants: { borderRadiusMd, heightXs },
  } = useTheme();

  return (
    <SkeletonRowWrapper>
      <IconWrapper>
        <Icon fixedWidth name='chevron-right' size='xs' />
      </IconWrapper>
      <SkeletonLoader borderRadius={borderRadiusMd} height={heightXs} />
    </SkeletonRowWrapper>
  );
};

const LOADING_ROWS_COUNT = 4;

const StepSuggestionsLoader = () => {
  const {
    step: { plural: stepPlural },
  } = useAccountTerminology();

  return (
    <>
      <LoadingText>{t('steps', { steps: stepPlural.toLocaleLowerCase() })}</LoadingText>
      <LoadingRows>
        {[...Array(LOADING_ROWS_COUNT)].map((_, index) => (
          <SkeletonRow key={`step-loader-row-${index}`} />
        ))}
      </LoadingRows>
    </>
  );
};

export default StepSuggestionsLoader;
