import { Editor } from '@tiptap/react';
import React from 'react';

import { AccountEditorProvider } from '../../../../contexts/AccountEditorContext';
import useContentStyles from '../../../../hooks/useContentStyles';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { ContentBlock, StepContentBody, StyledEditorContent } from '../shared/styles';
import { ContentWrapper } from './styles';

interface Props {
  editor: Editor | null;
}

const Content = ({ editor }: Props) => {
  const account = useCurrentAccount();
  const contentStyles = useContentStyles();

  return (
    <ContentWrapper>
      <ContentBlock hasPaddingBottom>
        <StepContentBody hasBottomBorderRadius hasTopBorderRadius>
          <AccountEditorProvider account={account}>
            <StyledEditorContent
              $contentStyles={contentStyles}
              className='editor-preview-content'
              editor={editor}
            />
          </AccountEditorProvider>
        </StepContentBody>
      </ContentBlock>
    </ContentWrapper>
  );
};

export default Content;
