import React from 'react';

import { AccountEditorProvider } from '../../../../contexts/AccountEditorContext';
import { EditorCourseProvider } from '../../../../contexts/EditorCourseContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetEditorTopicQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import EditModeSkeleton from './components/EditModeSkeleton/EditModeSkeleton';
import DocumentFactory from './DocumentFactory';
import OutlineContent from './OutlineContent/OutlineContent';

type Props = {
  stepData: GetStepResponse;
};

const EditorPageBase = ({ stepData }: Props) => {
  const { productTerm } = useAccountTerminology();
  const account = useCurrentAccount();

  const { courseId, id: stepId } = stepData;
  const { data, isLoading, error } = useGetEditorTopicQuery({ courseId });

  if (error) return <BasicErrorDisplay error={error} />;
  if (isLoading || !data) return <EditModeSkeleton />;

  const { curriculum, ...course } = data;

  return (
    <EditorCourseProvider
      course={course}
      curriculum={curriculum}
      productTerm={productTerm}
      stepId={stepId}
    >
      <OutlineContent />
      <AccountEditorProvider account={account}>
        <DocumentFactory key={stepId} stepData={stepData} />
      </AccountEditorProvider>
    </EditorCourseProvider>
  );
};

export default EditorPageBase;
