import React, { useMemo } from 'react';

import { usePlanTitle } from '../../../../../../hooks/billing/usePlanTitle';
import { AccountSettingsState } from '../../../../../../redux/services/resourceApis/accountSettings/types';
import { BillingPlanName } from '../../../../../../types/BillingPlanName';
import SignaturePermissionsForm from '../SignaturePermissionsForm';

type SignaturePermissionFormProps = {
  accountSettingsState: AccountSettingsState;
};

const SignaturePermissionForm = ({ accountSettingsState }: SignaturePermissionFormProps) => {
  const accountPlanName = useMemo(
    () => accountSettingsState?.advancedSettings?.accountPlanName,
    [accountSettingsState]
  );
  const planName = useMemo(() => accountPlanName?.toLowerCase() || 'scale', [accountPlanName]);
  const planTitle = usePlanTitle({ name: planName as BillingPlanName });
  const {
    advancedSettings: { signaturePermissions },
    userPermissions,
  } = accountSettingsState;

  return (
    <SignaturePermissionsForm
      accountPlanName={planTitle}
      signaturePermissions={signaturePermissions}
      userPermissions={userPermissions}
    />
  );
};

export default SignaturePermissionForm;
