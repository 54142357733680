import React, { useContext, useState } from 'react';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import { FavoriteEmptyStateImage } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { useGetFavoriteAssignmentsQuery } from '../../../../redux/services/resourceApis/home/homeApi';
import { AccountTerminologyContext } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { HomeBlockBody, HomeBlockHeader } from '../ProgressBlock/styles';
import { WidgetContainer } from '../shared/styles';
import FavoriteCurriculumItem from './FavoriteCurriculumItem';
import { FavoriteEmptyState, FavoriteEmptyStateText, ShowMoreWrapper } from './styles';

const t = initTranslations('home.favorite_curriculums');

const FavoriteCurriculums = () => {
  const accountTerminology = useContext(AccountTerminologyContext);
  const {
    curriculum: { plural: curriculumTerm },
  } = accountTerminology;
  const { id: userId } = useCurrentUser();

  const [curriculumsCountShowed, setCurriculumsCountShowed] = useState(6);

  const { data: favoriteAssignmentsData } = useGetFavoriteAssignmentsQuery({ userId });

  const curriculumsCount = favoriteAssignmentsData?.assignments.length || 0;

  const handleShowMore = () => {
    setCurriculumsCountShowed(curriculumsCount);
  };

  return (
    <WidgetContainer id='home-favorite-curriculums'>
      <HomeBlockHeader>{t('title', { curriculums: curriculumTerm.toLowerCase() })}</HomeBlockHeader>
      <HomeBlockBody>
        {curriculumsCount ? (
          favoriteAssignmentsData?.assignments
            .slice(0, curriculumsCountShowed)
            .map(({ curriculumId, title, emoji, id }) => (
              <FavoriteCurriculumItem
                curriculumId={curriculumId}
                emoji={emoji}
                id={id}
                key={curriculumId}
                title={title}
              />
            ))
        ) : (
          <FavoriteEmptyState id='favorite-empty-state'>
            <img alt={t('your_favorite_list_is_empty')} src={FavoriteEmptyStateImage} />
            <FavoriteEmptyStateText>{t('your_favorite_list_is_empty')}</FavoriteEmptyStateText>
          </FavoriteEmptyState>
        )}
        {curriculumsCountShowed === 6 && curriculumsCount > 6 && (
          <ShowMoreWrapper>
            <DefaultButton
              buttonType='tertiary'
              id='show-more-favorite-curriculums'
              onClick={handleShowMore}
              text={t('show_more', { curriculum: curriculumTerm.toLowerCase() })}
            />
          </ShowMoreWrapper>
        )}
      </HomeBlockBody>
    </WidgetContainer>
  );
};

export default FavoriteCurriculums;
