import React from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import initTranslations from '../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../lib/userNaming';
import { fontMd3, fontSm5 } from '../../../../styled/TypeSystem';
import CurriculumsAssignments from '../../../home/CurriculumsAssignments/CurriculumsAssignments';
import ProgressBlock from '../../../home/ProgressBlock/ProgressBlock';
import PagePreview from '../../../shared/PagePreview/PagePreview';
import ComingUpNext from '../../shared/ComingUpNext';

const PageContainer = styled.div(
  ({ theme: { vars } }) => css`
    display: flex;
    height: 100%;
    background-color: ${vars.foundationSurface1};
  `
);

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerLg2};
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
`;

const RightContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd3};
    min-width: 22.5rem;
    padding: ${constants.spacerMd3};
    background-color: ${vars.foundationBase2};
  `
);

const PreviewWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${constants.spacerMd3};
    margin-top: ${constants.spacerMd3};
  `
);

const PreviewHeading = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${constants.spacerSm3};
  `
);

const PreviewHeader = styled.div(
  ({ theme: { constants } }) => css`
    font-weight: ${constants.fontSemibold};
    ${fontMd3};
  `
);

const PreviewSubHeader = styled.div`
  ${fontSm5};
`;

const t = initTranslations('training_path.path_config.preview');

const HomePagePreview = () => {
  const {
    user: { name, id: userId },
    trainingPath: { isPublished },
  } = useTrainingPathContext();

  const trainingPathTag = isPublished ? 'published' : 'draft';

  return (
    <PreviewWrapper>
      <PreviewHeading>
        <PreviewHeader>{t('you_are_previewing', { name: userFirstName(name) })}</PreviewHeader>
        <PreviewSubHeader>{t('heres_what', { name: userFirstName(name) })}</PreviewSubHeader>
      </PreviewHeading>
      <PagePreview userId={userId}>
        <PageContainer>
          <LeftContainer>
            <BodyWrapper>
              <CurriculumsAssignments trainingPathTag={trainingPathTag} userId={userId} />
              <ComingUpNext trainingPathTag={trainingPathTag} userId={userId} />
            </BodyWrapper>
          </LeftContainer>
          <RightContainer>
            <ProgressBlock userId={userId} />
          </RightContainer>
        </PageContainer>
      </PagePreview>
    </PreviewWrapper>
  );
};

export default HomePagePreview;
