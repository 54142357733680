import React from 'react';

import { AccountEditorProvider } from '../../../../../contexts/AccountEditorContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useGetTemplatePreviewContentQuery } from '../../../../../redux/services/resourceApis/templates/templatesApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Loader from '../../../../design_system/Triage/Loader';
import TopicHeader from '../../../editor/shared/TopicHeader/TopicHeader';
import PreviewCurriculumElementHeader from '../../../shared/preview/PreviewCurriculumElementHeader/PreviewCurriculumElementHeader';
import PreviewSurveyStatistics from '../../../shared/preview/PreviewSurveyStatistics/PreviewSurveyStatistics';
import {
  TemplatePreviewContentContainer,
  TemplatePreviewContentWrapper,
  TemplatePreviewCurriculumElementWrapper,
} from './styles';
import TemplatePreviewQuestion from './TemplatePreviewQuestion/TemplatePreviewQuestion';
import TemplatePreviewStep from './TemplatePreviewStep/TemplatePreviewStep';

export type TemplateContentProps = {
  id: number;
  curriculumElementId?: number;
};

const TemplatePreviewContent = ({ id, curriculumElementId }: TemplateContentProps) => {
  const account = useCurrentAccount();
  const {
    topic: { singular: topicTerm },
  } = useAccountTerminology();

  const result = useGetTemplatePreviewContentQuery({
    templateId: id,
    curriculumElementId,
  });

  const { data, isFetching, error } = result;

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(error)?.join(', '),
  });

  if (isFetching)
    return (
      <TemplatePreviewContentWrapper>
        <Loader />
      </TemplatePreviewContentWrapper>
    );

  if (!data) return <></>;

  const { elementId: currentElementId, elementKind, disableTranslation } = data;
  const isSurvey = elementKind === 'survey';

  return (
    <AccountEditorProvider account={account}>
      <TemplatePreviewContentWrapper id='template-preview-content'>
        <TemplatePreviewContentContainer>
          <TemplatePreviewCurriculumElementWrapper>
            {isSurvey ? (
              <PreviewCurriculumElementHeader
                description={data.description}
                disableTranslation={disableTranslation}
                displayEmoji
                elementType={elementKind}
                emoji={data.emoji}
                id={currentElementId}
                title={data.name}
              >
                <PreviewSurveyStatistics
                  minimumScore={data.minimumScore}
                  questionsCount={data.questionsCount}
                />
              </PreviewCurriculumElementHeader>
            ) : (
              <TopicHeader
                cover={data.cover}
                emoji={data.emoji}
                title={data.title}
                topicTerm={topicTerm}
              />
            )}
          </TemplatePreviewCurriculumElementWrapper>
          <div id={`template-preview-${isSurvey ? 'questions' : 'steps'}`}>
            {isSurvey
              ? data.elements.map((element) => (
                  <TemplatePreviewQuestion
                    answerType={element.answerType}
                    id={element.id}
                    key={element.id}
                    options={element.options}
                    position={element.position}
                    surveyId={currentElementId}
                    text={element.text}
                    textJson={element.textJson}
                  />
                ))
              : data.elements.map((element, index) => (
                  <TemplatePreviewStep
                    content={element.content}
                    id={element.id}
                    key={element.id}
                    number={index + 1}
                    title={element.title}
                    totalCount={data.coursesCount}
                  />
                ))}
          </div>
        </TemplatePreviewContentContainer>
      </TemplatePreviewContentWrapper>
    </AccountEditorProvider>
  );
};

export default TemplatePreviewContent;
