import React from 'react';

import useWindowResize from '../../../../hooks/useWindowResize';
import { STEPS_SLIDEOUT } from '../../../helpers/slideOut';
import { SlideoutWrapper } from '../../surveys/SurveyEdit/styles';
import SlideoutPanel from './index';

interface Props {
  children: React.ReactElement;
}

const StepsSlideout = ({ children }: Props) => {
  const { isDesktop } = useWindowResize();

  return isDesktop ? (
    <SlideoutWrapper isOpen zIndex={51}>
      {children}
    </SlideoutWrapper>
  ) : (
    <SlideoutPanel
      displayInOverlay
      dock='left'
      showClose={false}
      slideoutType={STEPS_SLIDEOUT}
      slideoutWidth='20rem'
    >
      {children}
    </SlideoutPanel>
  );
};

export default StepsSlideout;
