import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import { AccountRoutesContext } from '../../../../contexts/AccountRoutesContext';
import { useCallableAjaxRead } from '../../../../hooks/useAjaxRead';
import useAjaxWrite, { RequestMethod } from '../../../../hooks/useAjaxWrite';
import { AsyncData } from '../../../../hooks/useAsyncData';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { NoUsersGraphic, NoUsersGraphicDark } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import Loader, { LoaderContainer } from '../../../design_system/Triage/Loader';
import NoResults from '../../../design_system/Triage/NoResults';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { fontMd1 } from '../../../styled/TypeSystem';
import OutOfSeatsConfirmationModal from '../OutOfSeatsConfirmationModal';
import ModalTableRow, { TableCol } from './ModalTableRow';
import SuccessModal from './SuccessModal';

const TableHead = styled.div`
  width: 100%;
  height: 3.5rem;
  display: flex;
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  border-top-left-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border-top-right-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  div {
    color: ${(props) => props.theme.vars.textDefault};
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    border-right: none;
    &:first-child {
      @media (min-width: ${mediaBreakpointPxSm}) {
        padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3}`};
      }
    }
  }
  ${fontMd1};
`;

interface Candidates {
  deleted_at: string | null;
  totalSlots: number;
  finch_id: string;
  id: number;
  name: string;
  payroll_integration_id: number;
  personal_emails: string[];
  title: string;
  user_id: number | null;
  work_emails: string[];
}

export interface UpdatedCandidates extends Candidates {
  selected: boolean;
  email: string;
  emails: string[];
  permission: string;
  id: number;
}

interface FetchData {
  candidates: Candidates[];
  source: string;
  unlimited_user_slots: boolean;
  used_slots: number;
  total_slots: number;
}

interface Data {
  name: string;
  title: string;
  email: string;
  permission: string;
}

interface SubmitData {
  data: Data[];
  invite_now: boolean;
}

const t = initTranslations('bulk_user_upload_payroll_modal');

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

const BulkUserUploadPayrollModal = ({ showModal, setShowModal }: Props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showUpdateSubtitle, setShowUpdateSubtitle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const [candidates, setCandidates] = useState<UpdatedCandidates[] | null>(null);
  const [data, setData] = useState<Data[]>([]);
  const { payrollIntegrationSource, unlimitedUserSlots, usedUserSlotsCount, totalUserSlotsCount } =
    useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  const routes = useContext(AccountRoutesContext);
  const modalRef = useRef(null);

  const fetchData = useCallableAjaxRead<FetchData>(routes.ajax.bulkUploadPayrollCandidates({}));

  const addCandidatesWithInvite = useAjaxWrite<SubmitData>({
    requestMethod: RequestMethod.Post,
    path: routes.ajax.bulkUploadPayroll({}),
    dataItem: {
      candidates: data,
      invite_now: true,
    },
  });

  const addCandidatesWithoutInvite = useAjaxWrite<SubmitData>({
    requestMethod: RequestMethod.Post,
    path: routes.ajax.bulkUploadPayroll({}),
    dataItem: {
      candidates: data,
      invite_now: false,
    },
  });

  const submitImport = (result: AsyncData<SubmitData>) => {
    setIsSaving(true);
    setShowModal(false);
    if (result.status === 'success') {
      setIsSaving(false);
      setShowSuccessModal(true);
    }
    if (result.status === 'error') {
      if (result.baseErrors[0] === 'out_of_seats') {
        setIsSaving(false);
        setShowConfirmationModal(true);
      }
    }
  };

  const submitImportWithInvite = async () => {
    const result = await addCandidatesWithInvite();
    submitImport(result);
  };

  const submitImportWithoutInvite = async () => {
    const result = await addCandidatesWithoutInvite();
    submitImport(result);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const displayModal = queryParams.get('open_react_finch_upload_modal') === 'true';
    const updateModal = queryParams.get('update_upload_modal') === 'true';

    if (displayModal) {
      setShowModal(true);
      const url = new URL(window.location.href);
      url.searchParams.delete('open_react_finch_upload_modal');
      window.history.replaceState(null, '', url);
    }

    if (updateModal) {
      setShowUpdateSubtitle(true);
      const url = new URL(window.location.href);
      url.searchParams.delete('update_upload_modal');
      window.history.replaceState(null, '', url);
    }
  }, [setShowModal]);

  useEffect(() => {
    showModal &&
      (async () => {
        const result = await fetchData();
        if (result.status === 'success') {
          setIsLoading(false);
          setCandidates(
            result.data.candidates.map((user) => ({
              ...user,
              selected: false,
              permission: 'General',
              emails: Array.from(new Set(user.personal_emails.concat(user.work_emails))),
              email: user.personal_emails[0] || user.work_emails[0],
            }))
          );
        }

        if (result.status === 'error') {
          console.log('error');
        }
      })();
  }, [fetchData, showModal]);

  useEffect(() => {
    if (candidates) {
      candidates.some((user) => {
        if (user.selected) {
          setIsSelectedUser(true);
          return true;
        } else {
          setIsSelectedUser(false);
        }
      });

      const users = candidates
        .filter((user) => user.selected)
        .map((user) => ({
          name: user.name,
          title: user.title,
          email: user.email,
          permission: user.permission,
        }));
      setData(users);
    }
  }, [candidates]);

  const slotsLeft = totalUserSlotsCount - usedUserSlotsCount;
  const badgeText = unlimitedUserSlots
    ? t('seats_left.unlimited')
    : t('seats_left', { count: slotsLeft });

  const translatedProvider = initTranslations('finch_connect.provider');
  const accountInfoSource = translatedProvider(`${payrollIntegrationSource}`);

  const subtitle = candidates?.length
    ? !showUpdateSubtitle
      ? t('subtitle', { source: accountInfoSource, productTerm })
      : t('update_people_subtitle', { source: accountInfoSource, productTerm })
    : '';

  const hasCandidates = candidates?.length;

  const taskModalArgs: TaskModalProps = {
    title: t('title', { source: accountInfoSource }),
    badgeText,
    badgeType: 'info',
    isDisabled: !isSelectedUser,
    subtitle,
    onCloseRequest: () => setShowModal(false),
    processing: isSaving,
    primaryButtonText: hasCandidates ? t('add_and_invite') : '',
    primaryButtonTask: submitImportWithInvite,
    secondaryButtonText: hasCandidates ? t('cancel') : t('close'),
    heapModalName: 'bulk-user-upload-payroll-modal',
    tertiaryButton: {
      text: hasCandidates ? t('just_add_invite_later') : '',
      task: submitImportWithoutInvite,
      isDisabled: !isSelectedUser,
    },
  };

  return (
    <div id='bulk-user-upload-payroll-modal-wrapper' ref={modalRef}>
      {showModal && (
        <TaskModal {...taskModalArgs} desktopSize='xl'>
          {isLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : hasCandidates ? (
            <div>
              <TableHead>
                <TableCol>{t('name_title')}</TableCol>
                <TableCol>{t('email')}</TableCol>
                <TableCol>{t('permissions')}</TableCol>
              </TableHead>
              <div>
                {candidates &&
                  candidates.map((user, index) => (
                    <ModalTableRow
                      candidates={candidates}
                      index={index}
                      key={user.id}
                      setCandidates={setCandidates}
                      user={user}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <NoResults
              darkImage={NoUsersGraphicDark}
              heading={t('no_result.title')}
              iconWidth='70%'
              lightImage={NoUsersGraphic}
              showBorder={false}
              subHeaderText={t('no_result.text')}
              width='28rem'
            />
          )}
        </TaskModal>
      )}
      <OutOfSeatsConfirmationModal
        setShowModal={setShowConfirmationModal}
        showModal={showConfirmationModal}
        usedUserSlots={usedUserSlotsCount}
        userSlots={totalUserSlotsCount}
      />
      <SuccessModal
        setShowModal={setShowSuccessModal}
        showModal={showSuccessModal}
        usersCount={data.length}
      />
    </div>
  );
};

export default BulkUserUploadPayrollModal;
