import React from 'react';
import styled from 'styled-components';

import getTemplatesViewByDropdownOptions from '../../../../../lib/templates/getTemplatesViewByDropdownOptions';
import { OutlineTemplatesViewByFilter } from '../../../../../redux/services/resourceApis/templates/types';
import DropdownMenu, {
  DropdownMenuOption,
} from '../../../../design_system/Triage/menus/DropdownMenu';
import { SearchAction } from '../TemplatesOutlineIndexPage/types';

type TemplatesOutlineViewByDropdownProps = {
  viewBy: OutlineTemplatesViewByFilter;
  dispatchSearchState: React.Dispatch<SearchAction>;
};

const WrappedDropdownMenu = styled(DropdownMenu)`
  z-index: 11;
`;

const TemplatesOutlineViewByDropdown = ({
  viewBy,
  dispatchSearchState,
}: TemplatesOutlineViewByDropdownProps) => {
  const { options } = getTemplatesViewByDropdownOptions();
  const selectedOption = options.find((option) => option.sortColumn === viewBy) || options[0];

  const setSelectedOption = (value: DropdownMenuOption) => {
    dispatchSearchState({
      type: 'changeViewBy',
      viewBy: value.sortColumn as OutlineTemplatesViewByFilter,
    });
  };

  return (
    <WrappedDropdownMenu
      className='templates-outline-view-by-dropdown-wrapper'
      id='templates-outline-view-by-dropdown'
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      size='max-content'
    />
  );
};

export default TemplatesOutlineViewByDropdown;
