import React, { useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import useCurrentAccount from '../../../../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { MetaUserAccess } from '../../../../../../../../types/Curriculum';
import { EditableCurriculumElement } from '../../../../../../../../types/CurriculumElement';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import useActiveMenuHandler from '../../../../../../../design_system/useActiveMenuHandler';
import { routes } from '../../../../../../publicApplication/applicationRouter';
import routeTo from '../../../../../../publicApplication/routeTo';
import StatusBadgeActions from '../../../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import CurriculumElementThreeDot from '../../../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { viewableUserAccess } from '../../../../../../shared/helpers';
import {
  ArrowIconWrapper,
  CurriculumElementThreeDotWrapper,
  GripVerticalIconWrapper,
  OutlineCurriculumElementRowContainer,
  OutlineRowContainer,
  StyledBadge,
  VideoBadgeWrapper,
} from '../../../../../shared/CurriculumElementRowStyles';
import { RowActionsContainer, RowCardBody } from '../../../../../shared/CurriculumRowStyles';
import ElementEmojiAndTitle from '../../../../../shared/ElementTableRow/ElementEmojiAndTitle';

const t = initTranslations('curriculums_view');

type Props = {
  isLocked?: boolean;
  blockEditPrivileges: boolean;
  curriculumElement: EditableCurriculumElement;
  curriculumId: number;
  userAccess?: MetaUserAccess;
  provided: DraggableProvided;
};

const OutlineElementTableRow = ({
  isLocked,
  blockEditPrivileges,
  curriculumElement,
  curriculumId,
  userAccess,
  provided,
}: Props) => {
  const { slug } = useCurrentAccount();
  const { element, elementKind, elementId } = curriculumElement;
  const { editRoute, showRoute } = useCurriculumElement()({
    elementId,
    elementKind,
  });
  const { status } = element;
  const [isEditing, setIsEditing] = useState(false);
  const { isMenuOpen } = useActiveMenuHandler({
    menuId: `curriculum-element-three-dot-${curriculumElement.id}`,
  });

  const url = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return showRoute.href;
    } else {
      return editRoute.href;
    }
  }, [editRoute.href, showRoute.href, curriculumId, slug, userAccess]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='curriculum-element-item'
      id={`curriculum-element-${elementKind}-${elementId}`}
    >
      <OutlineCurriculumElementRowContainer className='outline-curriculum-element-container'>
        <GripVerticalIconWrapper remainVisible={isMenuOpen}>
          <Icon name='grip-vertical' weight='solid' />
        </GripVerticalIconWrapper>
        <OutlineRowContainer>
          <RowCardBody isLocked={isLocked || blockEditPrivileges}>
            <VideoBadgeWrapper>
              <StyledBadge text={t(`${elementKind}.badge`)} type='info' />
            </VideoBadgeWrapper>
            <ElementEmojiAndTitle
              blockEditPrivileges={blockEditPrivileges}
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              userAccess={userAccess}
            />
            <RowActionsContainer>
              <StatusBadgeActions
                curriculumElement={{ id: curriculumElement.id, status }}
                curriculumId={curriculumId}
              />
              <ArrowIconWrapper onClick={() => routeTo(url)}>
                <Icon name='arrow-right' />
              </ArrowIconWrapper>
            </RowActionsContainer>
          </RowCardBody>
        </OutlineRowContainer>
        <CurriculumElementThreeDotWrapper remainVisible={isMenuOpen}>
          {!blockEditPrivileges && (
            <CurriculumElementThreeDot
              canBeModified
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              eSignatureDisplayedAndRequired={false}
              isCurriculumLocked={isLocked}
              renameClickHandler={() => setIsEditing(true)}
            />
          )}
        </CurriculumElementThreeDotWrapper>
      </OutlineCurriculumElementRowContainer>
    </div>
  );
};

export default OutlineElementTableRow;
