import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import { HeaderCell } from './CurriculumCompletions';

const NameCellWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: ${({ theme }) => theme.constants.spacerSm3};
`;

const t = initTranslations('curriculum_edit.manage_completions.table');

interface Props {
  handleBulkCheckboxChange: () => void;
  bulkChecked: boolean;
}

const NameHeader = ({ bulkChecked, handleBulkCheckboxChange }: Props) => {
  return (
    <HeaderCell className='manage-completions-name-header'>
      <NameCellWrapper>
        <Checkbox
          checkboxStyle='half'
          checked={bulkChecked}
          id='manage-completions-name-header-checkbox'
          name='name-header-checkbox'
          onCheck={handleBulkCheckboxChange}
        />
        {t('name_header')}
      </NameCellWrapper>
    </HeaderCell>
  );
};

export default NameHeader;
