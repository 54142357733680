import React from 'react';

import { getFormattedPrice } from '../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../types/BillingInterval';
import CheckoutAddonPrice from '../CheckoutAddonPrice/CheckoutAddonPrice';
import {
  CheckoutWithAddonsTotalSectionTitle,
  CheckoutWithAddonsTotalSectionWrapper,
} from './styles';

type CheckoutWithAddonsTotalSectionProps = {
  totalPrice: number;
  billingInterval: BillingInterval;
};

const t = initTranslations('checkout_overlay');

const CheckoutWithAddonsTotalSection = ({
  billingInterval,
  totalPrice,
}: CheckoutWithAddonsTotalSectionProps) => {
  const price = getFormattedPrice(totalPrice);

  return (
    <CheckoutWithAddonsTotalSectionWrapper>
      <CheckoutWithAddonsTotalSectionTitle text={t('total')} />
      <CheckoutAddonPrice billingInterval={billingInterval} isTotal price={price} />
    </CheckoutWithAddonsTotalSectionWrapper>
  );
};

export default CheckoutWithAddonsTotalSection;
