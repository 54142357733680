import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { ContentType } from '../../../../types/ContentType';
import Icon from '../../../design_system/display/icons/Icon';
import { routes } from '../../publicApplication/applicationRouter';
import { OptionType } from '../ActionDropdown/ActionDropdownMenuOption/ActionDropdownMenuOption';

// DS Override: Need to match design color
const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const t = initTranslations('view_mode_dropdown_options');

type RouteContentType = Exclude<ContentType, ContentType.Course>;
type RouteFunction = (slug: string, id: number) => void;

type OptionProps = {
  id: number;
  slug: string;
  contentType: RouteContentType;
};

type RouteActions = {
  [key in RouteContentType]: {
    editRoute: RouteFunction;
    showRoute: RouteFunction;
  };
};

const routeActions: RouteActions = {
  [ContentType.Curriculum]: {
    editRoute: (slug, id) => routes.curriculumEdit({ slug, id }).push(),
    showRoute: (slug, id) => routes.curriculumShow({ slug, id }).push(),
  },
  [ContentType.Step]: {
    editRoute: (slug, id) => routes.editor({ slug, id }).push(),
    showRoute: (slug, id) => routes.consume({ slug, id }).push(),
  },
  [ContentType.Survey]: {
    editRoute: (slug, id) => routes.surveyEditor({ slug, id }).push(),
    showRoute: (slug, id) => routes.surveyConsume({ slug, id }).push(),
  },
  [ContentType.Flowchart]: {
    editRoute: (slug, id) => routes.flowchartEditor({ slug, id }).push(),
    showRoute: (slug, id) => routes.flowchartConsume({ slug, id }).push(),
  },
  [ContentType.Video]: {
    editRoute: (slug, id) => routes.videoEdit({ slug, id }).push(),
    showRoute: (slug, id) => routes.videoConsume({ slug, id }).push(),
  },
};

export const viewOptions = ({ id, slug, contentType }: OptionProps) => {
  const { editRoute, showRoute } = routeActions[contentType];

  return [
    {
      icon: <StyledIcon name='pencil' size='xs' weight='regular' />,
      title: t('editing.title'),
      description: t('editing.description'),
      onClick: () => editRoute(slug, id),
      optionType: OptionType.Edit,
    },
    {
      icon: <StyledIcon name='eye' size='xs' weight='regular' />,
      title: t('viewing.title'),
      description: t('viewing.description'),
      onClick: () => showRoute(slug, id),
      optionType: OptionType.View,
    },
  ];
};
