import React from 'react';
import styled from 'styled-components';

import { useFlowchartEditorData } from '../../../../contexts/FlowchartEditorDataProvider';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../lib/initTranslations';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import BaseFooter, { StyledIconControlButton } from '../shared/BaseFooter';
import FooterLoader from '../shared/FooterLoader';
import NavigationButtons from './NavigationButtons/NavigationButtons';

const NavigationButtonsWrapper = styled.div`
  width: 22rem;
`;

const t = initTranslations('curriculums_view.flowchart.footer');

const FooterContent = () => {
  const { data, isLoading } = useFlowchartEditorData();

  if (isLoading) return <FooterLoader />;
  if (!data) return null;

  const {
    adjacentCurriculumElements,
    curriculum: { id: curriculumId, emoji: curriculumEmoji },
  } = data;

  return (
    <NavigationButtonsWrapper>
      <NavigationButtons
        curriculumEmoji={curriculumEmoji}
        curriculumId={curriculumId}
        {...adjacentCurriculumElements}
      />
    </NavigationButtonsWrapper>
  );
};

const KeyboardShortcutControl = () => {
  const { configs: publicConfigs } = usePublicConfigs();

  return (
    <>
      <Tooltip id='keyboard-shortcuts' place='top' text={t('keyboard_shortcuts')} />
      <StyledIconControlButton
        ariaLabel={t('keyboard_shortcuts')}
        behavesAs='a'
        data-for='keyboard-shortcuts'
        data-tip
        href={publicConfigs['FLOWCHART_KEYBOARD_SHORTCUTS_HELP_PAGE']}
        name='keyboard'
        tooltipId='keyboard-shortcuts'
      />
    </>
  );
};

const Footer = () => {
  const { isLoading } = useFlowchartEditorData();

  return (
    <BaseFooter controls={<KeyboardShortcutControl />} isLoading={isLoading}>
      <FooterContent />
    </BaseFooter>
  );
};

export default Footer;
