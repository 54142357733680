import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import { TrainualPlusWidgetBackground } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { Addon } from '../../../../types/Addon';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { routes } from '../../publicApplication/applicationRouter';
import useLocalStorageState from '../../publicApplication/utils/useLocalStorageState';
import {
  ActionsWrapper,
  BackgroundImage,
  BackgroundImageWrapper,
  FeatureDescription,
  FeatureIcon,
  FeatureRow,
  FeaturesList,
  StyledWidgetContainer,
  Title,
} from './styles';

const t = initTranslations('home.trainual_plus');

const TrainualPlusWidget = () => {
  const { configs: publicConfigs } = usePublicConfigs();
  const {
    id: accountId,
    hasTrainualPlusFeatures,
    status,
    splitFeatures: { trainualPlusAddonWidgetEnabled },
    slug,
  } = useCurrentAccount();
  const { permission } = useCurrentUser();
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const isBillingAdmin = permission === 'billing_admin';
  const isActive = status === 'active';
  const canSeeTrainualPlusWidget =
    trainualPlusAddonWidgetEnabled && isBillingAdmin && ['active', 'trialing'].includes(status);
  const [isTrainualPlusWidgetVisible, setIsTrainualPlusWidgetVisible] = useLocalStorageState({
    key: `isTrainualPlusWidget_${accountId}`,
    initialValue: canSeeTrainualPlusWidget,
  });
  const features = [
    t('maximize_productivity'),
    t('boost_accountability'),
    t('multi_language'),
    t('custom_domain'),
    t('optimize_roles', { responsibilities: responsibilityPlural.toLowerCase() }),
  ];
  const { onSmallPlan, onMediumPlan, onGrowthPlan } = useCurrentAccount();
  const showAddPlanButton = isActive && (onSmallPlan || onMediumPlan || onGrowthPlan);
  const skipWidget = hasTrainualPlusFeatures || !isTrainualPlusWidgetVisible;

  const handleDismiss = () => {
    setIsTrainualPlusWidgetVisible(false);
  };

  const navigateToTrainualPlusOverlay = () => {
    routes
      .addonOverlay({
        slug,
        name: Addon.TrainualPlus,
      })
      .push();
  };

  if (skipWidget) return null;

  return (
    <StyledWidgetContainer id='trainual-plus-widget'>
      <BackgroundImageWrapper>
        <BackgroundImage alt={t('alt_text')} src={TrainualPlusWidgetBackground} />
      </BackgroundImageWrapper>

      <Title>{t('title')}</Title>

      <FeaturesList>
        {features.map((description, index) => (
          <FeatureRow key={index}>
            <FeatureIcon name='circle-check' weight='regular' />
            <FeatureDescription dangerouslySetInnerHTML={{ __html: description }} />
          </FeatureRow>
        ))}
      </FeaturesList>

      <ActionsWrapper>
        <DefaultButton
          buttonType='tertiary'
          fullWidth
          id='dismiss-trainual-plus-widget-button'
          onClick={handleDismiss}
          text={t('dismiss')}
        />
        {showAddPlanButton ? (
          <DefaultButton
            buttonType='brand'
            fullWidth
            id='trainual-plus-add-to-plan-button'
            onClick={navigateToTrainualPlusOverlay}
            text={t('add_to_plan')}
          />
        ) : (
          <DefaultButton
            behavesAs='a'
            buttonType='brand'
            fullWidth
            href={publicConfigs['INTEREST_IN_TRAINUAL_PLUS_EMAIL_HREF']}
            id='trainual-plus-talk-to-us-button'
            target='_blank'
            text={t('talk_to_us')}
          />
        )}
      </ActionsWrapper>
    </StyledWidgetContainer>
  );
};

export default TrainualPlusWidget;
