import React from 'react';

import { useGetCurriculumForEditQuery } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import SlideoutPanel from '../../../../shared/slideout';
import ActionsMenu from './ActionsMenu';
import SubjectOverview from './SubjectOverview';

type Props = {
  curriculumId: number;
};

const SettingsSlideout = ({ curriculumId }: Props) => {
  const { data: curriculum } = useGetCurriculumForEditQuery(curriculumId);

  if (!curriculum) return null;

  const {
    owner,
    minutes_to_read_formatted: minutesToReadFormatted,
    minutes_to_read: minutesToRead,
    verify_content_status: verifyContentStatus,
    verify_content_frequency_in_days: verifyContentFrequencyInDays,
    verified_content_at: verifiedContentAt,
    next_verified_content_at: nextVerifiedContentAt,
    sector,
    published,
    title,
    completion_required: completionRequired,
    can_assign_users: canAssignUsers,
    user_can_delete_subject: userCanDeleteSubject,
    curriculum_can_be_modified: curriculumCanBeModified,
    archived,
  } = curriculum;

  return (
    <SlideoutPanel
      id='subject-edit-settings-slideout'
      scrollable={false}
      showClose={false}
      slideoutType='subject-edit-settings'
      slideoutWidth='20rem'
    >
      <>
        <SubjectOverview
          curriculumId={curriculumId}
          minutesToRead={minutesToRead}
          minutesToReadFormatted={minutesToReadFormatted}
          nextVerifiedContentAt={nextVerifiedContentAt}
          owner={owner}
          sector={sector}
          verifiedContentAt={verifiedContentAt}
          verifyContentFrequencyInDays={verifyContentFrequencyInDays}
          verifyContentStatus={verifyContentStatus}
        />
        <ActionsMenu
          archived={archived}
          canDeleteSubject={userCanDeleteSubject}
          completionRequired={completionRequired}
          containsSignature={!curriculumCanBeModified}
          curriculumId={curriculumId}
          curriculumTitle={title}
          published={published}
          reportsAccess={canAssignUsers}
          sector={sector}
        />
      </>
    </SlideoutPanel>
  );
};

export default SettingsSlideout;
