import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings');

const PublicShare = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.publicCurriculums}
        className='public-curriculums-setting'
        handleToggle={() => setFieldValue('publicCurriculums', !values.publicCurriculums)}
        id='public-curriculums-toggle'
        name='publicCurriculums'
      />
      <ToggleInfoSection>
        <ToggleInfoWrapper>
          <FormSectionTitle>{t('public_share_title')}</FormSectionTitle>
          <FormSectionDescription>{t('public_share_description')}</FormSectionDescription>
        </ToggleInfoWrapper>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default PublicShare;
