import { useContext } from 'react';

import { ActiveModalContext } from '../../contexts/ActiveModalContext';
import { RegisteredModalId } from '../../lib/idRegistry';

const useActiveModalHandler = (menuId?: RegisteredModalId) => {
  const { activeModal, setActiveModal } = useContext(ActiveModalContext);

  const isModalOpen = menuId === activeModal;
  const closeActiveModal = () => setActiveModal(null);

  return {
    activeModal,
    closeActiveModal,
    setActiveModal,
    isModalOpen,
  };
};

export default useActiveModalHandler;
