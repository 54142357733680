import React, { useCallback, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';

import useActionCableChannel from '../../../../../../../hooks/useActionCableChannel';
import { SignatureLoader } from '../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../lib/initTranslations';
import ProgressBar from '../../../../../../design_system/Triage/ProgressBar';
import { LoaderWrapper, LoadingStateWrapper, ProgressBarWrapper, Subtitle, Text } from './styles';

const t = initTranslations('e_signature.loader');

const ESignatureLoader = () => {
  const [progressPercentage, setProgressPercentage] = useState(0);

  const channelProps = useMemo(() => {
    return {
      channel: 'PandaDoc::UploadChannel',
    };
  }, []);

  const received = useCallback((data) => {
    setProgressPercentage(data.percentage || 0);
  }, []);

  useActionCableChannel<{ percentage: number }>(channelProps, received);

  return (
    <LoadingStateWrapper id='e-signature-loading-state-wrapper'>
      <LoaderWrapper>
        <SVG src={SignatureLoader} />
      </LoaderWrapper>
      <ProgressBarWrapper>
        <ProgressBar id='signature-progress-bar' percent={progressPercentage} />
      </ProgressBarWrapper>
      <Subtitle>{t('document_loading')}</Subtitle>
      <Text text={t('hold_tight')} />
    </LoadingStateWrapper>
  );
};

export default ESignatureLoader;
