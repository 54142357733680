import React from 'react';
import styled from 'styled-components';

import { useVideoData } from '../../../../contexts/VideoDataContext';
import FooterLoader from '../../flowcharts/shared/FooterLoader';
import EditFooterNavigationButtons from './EditFooterNavigationButtons';

const NavigationButtonsWrapper = styled.div`
  width: 100%;
`;

const EditVideoFooterContent = () => {
  const { data, isLoading } = useVideoData();

  if (isLoading) return <FooterLoader />;
  if (!data) return null;

  const {
    adjacentCurriculumElements,
    curriculum: { id: curriculumId, emoji: curriculumEmoji },
  } = data;

  return (
    <NavigationButtonsWrapper>
      <EditFooterNavigationButtons
        curriculumEmoji={curriculumEmoji}
        curriculumId={curriculumId}
        {...adjacentCurriculumElements}
      />
    </NavigationButtonsWrapper>
  );
};

export default EditVideoFooterContent;
