import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import ContentTabSection from './ContentTabSection';
import DelegationTabSection from './DelegationTabSection';
import GeneralTabSection from './GeneralTabSection';
import PeopleTabSection from './PeopleTabSection';

type Props = {
  activeTab?: string;
};

const ActiveSettingsTab = ({ activeTab }: Props) => {
  const { delegationPlannerEnabled } = useCurrentAccount();
  const { data } = useGetAccountSettingsQuery();

  switch (activeTab) {
    case 'general':
      return <GeneralTabSection />;
    case 'content':
      return <ContentTabSection />;
    case 'people':
      return <PeopleTabSection />;
    case 'delegation':
      if (!delegationPlannerEnabled || !data?.responsibilitySpecializations.length)
        return <GeneralTabSection />;

      return <DelegationTabSection />;
    default:
      return <GeneralTabSection />;
  }
};

export default ActiveSettingsTab;
