import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';

export const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: 0;

  @media (max-width: ${mediaBreakpointPxSm}) {
    flex-direction: column;
    gap: ${({ theme: { constants } }) => constants.spacerMd3};
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const TemplatesSearchAndFilterWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (max-width: ${mediaBreakpointPxSm}) {
    flex-direction: column;
  }
`;

export const TemplatesOutlineDropdownsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (max-width: ${mediaBreakpointPxSm}) {
    flex-direction: column;
  }
`;

export const TemplatesOutlineLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;
