import React, { useState } from 'react';
import styled from 'styled-components';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { useGetCurriculumQuery } from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useSendSignedDocumentsBySubjectMutation } from '../../../../redux/services/resourceApis/eSignatures/eSignatureApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import SplitButton from '../../../design_system/buttons/SplitButton';
import { MenuOptionProps } from '../../../design_system/core/MenuOption/MenuOption';
import ConfirmationModal from '../../../design_system/overlays/modals/ConfirmationModal';
import { SplitButtonMenuOptions } from '../../people/UsersOutline/Table/TableTypes';

const EmailCsvSplitButtonWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

type EmailCsvSplitButtonProps = {
  curriculumId: number;
  downloadCsvDocument: () => void;
};

const t = initTranslations('reports.email_csv_split_button');

const EmailCsvSplitButton = ({ downloadCsvDocument, curriculumId }: EmailCsvSplitButtonProps) => {
  const [showEmailCsvModal, setShowEmailCsvModal] = useState(false);
  const { email } = useCurrentUser();
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();
  const { data: curriculum } = useGetCurriculumQuery(curriculumId);
  const [sendSignedDocumentBySubject, resultSignedDocumentBySubject] =
    useSendSignedDocumentsBySubjectMutation();

  const options: SplitButtonMenuOptions[] = [
    {
      displayed: true,
      value: {
        description: t('email_csv.description'),
        iconName: 'table-cells',
        id: 'email-csv',
        onClick: () => setShowEmailCsvModal(true),
        title: t('email_csv.title'),
      },
    },
    {
      displayed: !!curriculum?.signable_documents,
      value: {
        description: t('email_e_sign_files.description', {
          subject: subjectTermSingular.toLowerCase(),
        }),
        iconName: 'signature',
        id: 'email-e-sign-files',
        onClick: () => sendSignedDocumentBySubject({ curriculumId }),
        title: t('email_e_sign_files.title'),
      },
    },
  ];

  const splitButtonMenuOptions: MenuOptionProps[] = options
    .filter(({ displayed }) => displayed)
    .map(({ value }) => value);

  const downloadCsv = () => {
    downloadCsvDocument();
    setShowEmailCsvModal(false);
  };

  useDisplayFlashOnResponse({
    result: resultSignedDocumentBySubject,
    successMessage: t('email_e_sign_files.success_message'),
    errorMessage: t('email_e_sign_files.error_message'),
  });

  return (
    <EmailCsvSplitButtonWrapper>
      <SplitButton
        mainButtonOnClick={() => setShowEmailCsvModal(true)}
        mainMenuButtonId='email-csv-button'
        menuId='email-csv-menu'
        menuOptions={splitButtonMenuOptions}
        text={t('email_csv.title')}
      />
      {showEmailCsvModal && (
        <ConfirmationModal
          actionFunction={downloadCsv}
          actionText={t('email_csv.send_email')}
          heapModalName='email-csv-modal'
          message={t('email_csv.modal_message', { email })}
          onCloseRequest={() => setShowEmailCsvModal(false)}
          processing={false}
          title={t('email_csv.modal_title')}
        />
      )}
    </EmailCsvSplitButtonWrapper>
  );
};

export default EmailCsvSplitButton;
