import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import { LoadingIcon, UpdatedAtText } from './styles';
import { AutoSaveStatus, AutoSaveStatusIndicatorProps } from './types';

const WrappeUpdatedAtText = styled.div`
  display: flex;

  @media (max-width: ${mediaBreakpointPxXl}) {
    display: none;
  }
`;

const t = initTranslations('autosave_status_indicator');

const AutoSaveStatusIndicator = ({
  autosaveStatus,
  lastEditedBy,
  lastEditedOn,
  isFetching,
  updatedAtInWords,
}: AutoSaveStatusIndicatorProps) => {
  const status = isFetching ? AutoSaveStatus.Saving : autosaveStatus;

  return (
    <>
      {status === AutoSaveStatus.Saving && (
        <UpdatedAtText>
          <>
            <LoadingIcon
              className='chromatic-ignore'
              fixedWidth
              name='rotate'
              spinSpeed='fast'
              weight='solid'
            />
            {t('saving')}
          </>
        </UpdatedAtText>
      )}
      {(status === AutoSaveStatus.Saved || status === AutoSaveStatus.NoChanges) && (
        <>
          <Tooltip
            id='auto-save-status'
            place='left'
            text={
              lastEditedBy
                ? t('last_edited_with_user', { user: lastEditedBy, date: lastEditedOn })
                : t('last_edited_without_user', { date: lastEditedOn })
            }
          />
          <WrappeUpdatedAtText className='updated-at-text' data-for='auto-save-status' data-tip>
            <UpdatedAtText>{t('updated_on', { date: updatedAtInWords })}</UpdatedAtText>
          </WrappeUpdatedAtText>
        </>
      )}
    </>
  );
};

export default AutoSaveStatusIndicator;
