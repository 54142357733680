import styled from 'styled-components';

import Badge from '../../../design_system/display/badge';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { RowContainer } from './CurriculumRowStyles';

export const EmojiWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledBadge = styled(Badge)`
  max-width: 100%;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  ${TruncatedText({})};
`;

export const ArrowIconWrapper = styled.div`
  cursor: pointer;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const OutlineCurriculumElementRowContainer = styled.div`
  display: flex;
`;

export const OutlineRowContainer = styled(RowContainer)`
  /* 
    3.5rem is to account for the grip icons 1rem min width + 0.5rem right margin
    + CurriculumThreeDotWrapper 2rem min width. Now that these are all outside 
    the row these need to be accounted in reducing the width of the row and 
    ensure these stay lined up and on screen. 
 */
  width: calc(100% - 3.5rem);
`;

export const CurriculumElementThreeDotWrapper = styled.div<{ remainVisible: boolean }>`
  opacity: ${({ remainVisible }) => (remainVisible ? 1 : 0)};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd1} 0`};
  min-width: 2rem;

  ${OutlineCurriculumElementRowContainer}:hover & {
    opacity: 1;
  }
`;

export const GripVerticalIconWrapper = styled.div<{ remainVisible: boolean }>`
  opacity: ${({ remainVisible }) => (remainVisible ? 1 : 0)};
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerSm3} ${constants.spacerMd2} 0`};
  min-width: 1rem;

  ${OutlineCurriculumElementRowContainer}:hover & {
    opacity: 1;
  }
`;

export const BadgeWrapper = styled.div`
  width: 4.5rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd1};
`;

// TODO: video - update this to use the BadgeWrapper when all elements use the OutlineElementTableRow
export const VideoBadgeWrapper = styled.div`
  width: 4.5rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const EditBadgeWrapper = styled.div<{ hasChevron?: boolean }>`
  width: 4.5rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd1};
  margin-left: ${({ theme: { constants }, hasChevron }) =>
    hasChevron ? constants.spacerMd2 : constants.spacerLg3};
`;
