import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { Route } from 'type-route';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import usePageTitle from '../../../../hooks/usePageTitle';
import { useIsAdmin } from '../../../../hooks/users/useIsPermission';
import { ViewByEmptyStateSvg } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { isSector } from '../../../../lib/isSector';
import useDebounce from '../../../../lib/useDebounce';
import {
  setSuperShareModalCurriculumId,
  setSuperShareModalVisible,
} from '../../../../redux/domains/contentLibrary/contentLibrarySlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { contentLibraryApi } from '../../../../redux/services/resourceApis/contentLibrary/contentLibraryApi';
import { GroupResponse } from '../../../../redux/services/resourceApis/groups/types';
import NoResults from '../../../design_system/Triage/NoResults';
import DocumentUploadProgress from '../../DocumentUploads/DocumentUploadProgress/DocumentUploadProgress';
import ExplainAndInspireVideoPreviewElement from '../../ExplainAndInspireVideos/ExplainAndInspireVideoPreviewElement/ExplainAndInspireVideoPreviewElement';
import SuperShareModal from '../../people/SuperShare/SuperShareModal/SuperShareModal';
import { routes } from '../../publicApplication/applicationRouter';
import PageContent from '../../shared/page_content';
import ViewBySection from '././ViewBySection/ViewBySection';
import FilterSection from './FilterSection/FilterSection';
import Header from './Header/Header';
import {
  GROUP_VIEW_BY_OPTIONS,
  GroupsState,
  initialTableState,
  libraryReducer,
} from './libraryReducer';
import LibraryTable from './LibraryTable/LibraryTable';
import SectorContentBannerContent from './SectorContentBannerContent';
import SectorFilters from './SectorFilters/SectorFilters';

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 45.5rem;
`;

export type Props = {
  route: Route<typeof routes.content>;
};

const t = initTranslations('curriculums.content');

const ContentLibrary = ({ route }: Props) => {
  const {
    params: { sector, curriculum_deleted },
  } = route;

  const {
    slug,
    splitFeatures: { explainAndInspireVideosEnabled },
  } = useCurrentAccount();
  const dispatch = useAppDispatch();
  const { data: accountSettings } = useGetAccountSettingsQuery();
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'content', productTerm });
  const ability = useCurrentUserAbilities();
  const canCreateCurriculum = ability.can('create', 'Curriculum');
  const admin = useIsAdmin();
  const [tableState, tableDispatch] = useReducer(libraryReducer, initialTableState);
  const debouncedSearchValue = useDebounce<string>(tableState.searchValue, 500);
  const [cookies] = useCookies();
  const inspireVideoDismissed = cookies['dismissed-inspire-library-video'];
  const isInspireVideoDisabled =
    !explainAndInspireVideosEnabled || (inspireVideoDismissed && inspireVideoDismissed.value);
  const displayEmptyState = isInspireVideoDisabled && !tableState.loading;
  const { superShareModalCurriculumId, superShareModalVisible } = useAppSelector(
    (state) => state.contentLibrary
  );
  const setShowSuperShareModal = useCallback(
    (visible: boolean) => {
      dispatch(setSuperShareModalCurriculumId(null));
      dispatch(setSuperShareModalVisible(visible));
    },
    [dispatch]
  );

  const filtersQuery = useMemo(() => {
    const params: string[] = [];
    if (sector) params.push(`sector=${sector}`);
    if (debouncedSearchValue) params.push(`search=${debouncedSearchValue}`);
    if (tableState.groupIds) tableState.groupIds.map((id) => params.push(`group_ids[]=${id}`));
    if (tableState.userId) params.push(`user_id=${tableState.userId}`);
    if (tableState.access) params.push(`content_access=${tableState.access}`);
    if (tableState.status) params.push(`status=${tableState.status}`);
    if (tableState.kind) params.push(`kind=${tableState.kind}`);
    if (tableState.generalAccess) params.push(`access_control=${tableState.generalAccess}`);
    if (tableState.sortBySelection) params.push(`sort_by=${tableState.sortBySelection.value}`);
    if (tableState.eSignature) params.push(`e_signature=${tableState.eSignature}`);

    return params.join('&');
  }, [
    sector,
    debouncedSearchValue,
    tableState.groupIds,
    tableState.userId,
    tableState.access,
    tableState.status,
    tableState.kind,
    tableState.generalAccess,
    tableState.sortBySelection,
    tableState.eSignature,
  ]);

  const [getGroups, groupsResult] = contentLibraryApi.useLazyGetGroupsForViewByQuery();
  const usersDefaultViewBy = useMemo(() => cookies['view-by-content-option'], [cookies]);

  useEffect(() => {
    const usersDefaultSortBy = cookies['sort-by-content-option'];

    if (!tableState.defaultsHaveBeenSet) {
      tableDispatch({ type: 'setDefaults' });
      if (usersDefaultSortBy) tableDispatch({ type: 'setSortBy', sortBy: usersDefaultSortBy });
      if (usersDefaultViewBy) {
        tableDispatch({ type: 'setViewBySelection', viewBy: usersDefaultViewBy });
      }
    }
  }, [cookies, slug, tableState.defaultsHaveBeenSet, usersDefaultViewBy]);

  useEffect(() => {
    if (usersDefaultViewBy || !accountSettings) return;
    const viewByOption = GROUP_VIEW_BY_OPTIONS.find(
      (option) => option.value == accountSettings.advancedSettings.contentPageDefaultViewBy
    );
    if (viewByOption) {
      tableDispatch({
        type: 'setViewBySelection',
        viewBy: viewByOption,
      });
    }
  }, [accountSettings, usersDefaultViewBy]);

  useEffect(() => {
    tableDispatch({ type: 'setLoading', loading: groupsResult.isFetching });
  }, [groupsResult.isFetching, tableDispatch]);

  useEffect(() => {
    if (tableState.viewBySelection.value === 'all_content') {
      tableDispatch({
        type: 'setGroups',
        groups: undefined,
      });
    } else if (tableState.viewBySelection.value === 'all_groups') {
      getGroups({});
    } else {
      const kind = tableState.viewBySelection.value;
      getGroups({ kind });
    }
  }, [getGroups, tableState.viewBySelection.value]);

  useEffect(() => {
    if (!groupsResult.data) return;

    const groups: GroupsState[] = groupsResult.data.map((group: GroupResponse) => {
      return {
        id: group.id,
        title: group.name,
        filterParam: `group_ids[]=${group.id}`,
        totalUserCount: group.user_count,
        totalCurriculumsCount: group.curriculum_count,
        users: group.users,
        type: 'group',
      };
    });

    tableDispatch({
      type: 'setGroups',
      groups: groups.sort((a, b) => a.title.localeCompare(b.title)),
    });
  }, [groupsResult.data, tableState.viewBySelection.value]);

  return (
    <PageContent>
      <TopSection>
        <Header />
        <SectorFilters sector={sector && isSector(sector) ? sector : 'all'} />
        <SectorContentBannerContent
          contentSector={sector && isSector(sector) ? sector : 'all-content'}
        />
        <FilterSection
          isLoading={false}
          searchValue={tableState.searchValue}
          sortBySelection={tableState.sortBySelection}
          tableDispatch={tableDispatch}
          viewBySelection={tableState.viewBySelection}
        />
      </TopSection>
      {canCreateCurriculum && <DocumentUploadProgress />}
      {tableState.viewBySelection.value !== 'all_content' ? (
        tableState.groups && tableState.groups.length > 0 ? (
          tableState.groups.map((group) => (
            <ViewBySection
              filtersQuery={[filtersQuery, group.filterParam].join('&')}
              key={`view-by-${group.id}`}
              tableDispatch={tableDispatch}
              {...group}
            />
          ))
        ) : (
          displayEmptyState && (
            <NoResults
              className='groups-no-results'
              darkImage={ViewByEmptyStateSvg}
              heading={t('view_by_empty_state.heading', {
                kind: tableState.viewBySelection.label.toLowerCase(),
              })}
              iconWidth='20%'
              lightImage={ViewByEmptyStateSvg}
              minHeight='unset'
              showBackground={false}
              showBorder={false}
              subHeaderCta={
                admin
                  ? {
                      action: () =>
                        routes
                          .groups({
                            slug,
                            show_create_group_kind_modal: true,
                            group_kind: tableState.viewBySelection.value,
                          })
                          .push(),
                      text: t('view_by_empty_state.action_link_text', {
                        kind: tableState.viewBySelection.label.toLowerCase(),
                      }),
                    }
                  : undefined
              }
            />
          )
        )
      ) : (
        <LibraryTable
          curriculumDeleted={curriculum_deleted}
          filtersQuery={filtersQuery}
          hideEmptyState={!displayEmptyState}
          isFiltering={
            !!filtersQuery.replace(/&*sector=.+?(?=&|$)/, '').replace(/&*sort_by=.+?(?=&|$)/, '')
          }
          tableDispatch={tableDispatch}
        />
      )}
      {explainAndInspireVideosEnabled && (
        <ExplainAndInspireVideoPreviewElement resource='library' />
      )}
      {superShareModalVisible && (
        <SuperShareModal
          curriculumId={superShareModalCurriculumId as number}
          setShowSuperShareModal={setShowSuperShareModal}
          showSuperShareModal={superShareModalVisible}
        />
      )}
    </PageContent>
  );
};

export default ContentLibrary;
