import React from 'react';

import { GoogleDriveLogo } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { sectorsArray } from '../../../../types/Sector';
import DocumentImportCard from './DocumentImportCard/DocumentImportCard';
import { GoogleDrivePickerButton } from './FileUploadForm/styles';
import { FooterActionButtonsWrapper, StyledAddMoreFilesButton } from './styles';
import { UseFileUploadProps } from './types';

const t = initTranslations('bulk_document_import');

const UploadedFilesAndActions = ({
  bulkDocumentImportData,
  dispatch,
  maxFilesReached,
  handleOpenDropZonePicker,
}: UseFileUploadProps) => {
  return (
    <>
      {bulkDocumentImportData?.map((document) => (
        <DocumentImportCard
          document={document}
          infoText='12 pages' // ToDo: Replace with actual data here
          key={document.id}
          removeDocument={() => dispatch({ type: 'removeImportFile', id: document.id })}
          setDocumentSector={(index) =>
            dispatch({
              type: 'editSector',
              id: document.id,
              newSectorValue: sectorsArray[index],
            })
          }
        />
      ))}
      <FooterActionButtonsWrapper>
        <StyledAddMoreFilesButton
          buttonType='tertiary'
          disabled={maxFilesReached}
          id='add-more-files-button'
          onClick={handleOpenDropZonePicker}
          size='lg'
          text={t('add_more_files')}
        />
        <GoogleDrivePickerButton
          buttonType='secondary'
          disabled={maxFilesReached}
          iconImage={GoogleDriveLogo}
          id='add-from-google-drive-button'
          size='lg'
          text={t('add_from_google_drive')}
        />
      </FooterActionButtonsWrapper>
    </>
  );
};

export default UploadedFilesAndActions;
