import React, { useEffect, useReducer, useState } from 'react';

import { useCheckSimplePricingOrSimplePricingV3Plan } from '../../../../hooks/billing/useCheckSimplePricingOrSimplePricingV3Plan';
import { useValidateUpdatePaymentMethodForm } from '../../../../hooks/billing/useValidateUpdatePaymentMethodForm/useValidateUpdatePaymentMethodForm';
import { useCheckoutCreateSubscription } from '../../../../hooks/checkout/useCheckoutCreateSubscription';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import { checkRequiredPaymentFields } from '../../../../lib/billing/checkRequiredPaymentFields';
import { getNewPlanTotalAmount } from '../../../../lib/billing/getNewPlanTotalAmount';
import { getImagesByPlanName } from '../../../../lib/checkout/getImagesByPlanName';
import initTranslations from '../../../../lib/initTranslations';
import { messageFromError } from '../../../../redux/errors/helpers';
import { useGetCheckoutDataQuery } from '../../../../redux/services/resourceApis/checkout/checkoutApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Loader from '../../../design_system/Triage/Loader';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import {
  MainSectionWrapper,
  OverlayContentHeaderTitle,
  OverlayContentWrapper,
} from '../../shared/FullScreenOverlay/styles';
import { initState, reducer } from '../../shared/UpdatePaymentMethodForm/reducer';
import Includes from '../Includes/Includes';
import CheckoutCustomLinkTimer from './CheckoutCustomLinkTimer/CheckoutCustomLinkTimer';
import CheckoutDiscountsSection from './CheckoutDiscountsSection/CheckoutDiscountsSection';
import CheckoutLogos from './CheckoutLogos/CheckoutLogos';
import CheckoutPaymentMethodSection from './CheckoutPaymentMethodSection/CheckoutPaymentMethodSection';
import CheckoutPricingInformationSectionFactory from './CheckoutPricingInformationSectionFactory/CheckoutPricingInformationSectionFactory';
import {
  CheckoutBillingDetailsWrapper,
  CheckoutContentWrapper,
  CheckoutPaymentMethodWrapper,
} from './styles';

const t = initTranslations('checkout_overlay');

const CheckoutFullScreenOverlay = () => {
  const { slug, tags } = useCurrentAccount();
  const checkoutResult = useGetCheckoutDataQuery();
  const { isSuccess, error, data } = checkoutResult;
  const { flash } = useFlashNotification();
  const isSimplePricingPlan = useCheckSimplePricingOrSimplePricingV3Plan();

  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState(false);
  const [formState, dispatch] = useReducer(reducer, initState);
  const { data: formData, isFormError, isStripeError, paymentDetailsChanged } = formState;
  const { formErrors, validateFields, validateField, clearFieldError } =
    useValidateUpdatePaymentMethodForm(dispatch);
  const {
    checkoutCreateSubscription,
    isLoadingCreateSubscription,
    isSuccessCreateSubscription: isRedirectInProgress,
  } = useCheckoutCreateSubscription();

  const isPaymentError = isFormError || isStripeError || !paymentDetailsChanged;
  const isDisabledSubmitButton = isLoadingSubmitButton || isPaymentError;
  const isPaymentInProgress = isLoadingCreateSubscription || isRedirectInProgress;
  const handleClickOnSubmitButton = () => {
    setIsLoadingSubmitButton(true);

    checkoutCreateSubscription({
      billingDetails: formData,
      totalAmount: getNewPlanTotalAmount(data?.upcomingInvoice?.newPlan),
      validRequiredFields: checkRequiredPaymentFields({ formData, validateFields }),
    }).then(() => setIsLoadingSubmitButton(false));
  };

  useEffect(() => {
    if (!isSimplePricingPlan) {
      flash('error', t('not_authorized_flash'));
      routeTo(routes.home({ slug }));
    }
  }, [flash, isSimplePricingPlan, slug]);

  useEffect(() => {
    setIsLoadingSubmitButton(isPaymentInProgress);
  }, [isLoadingCreateSubscription, isPaymentInProgress, isRedirectInProgress]);

  useDisplayFlashOnResponse({
    result: checkoutResult,
    errorMessage: messageFromError(error)?.join(', '),
  });

  if (!tags?.includes('checkout_page_required')) {
    routeTo(routes.home({ slug }));
  }

  return (
    <FullScreenOverlay id='checkout' withoutCloseIcon>
      <OverlayContentWrapper>
        <MainSectionWrapper>
          {isSuccess && data ? (
            <CheckoutContentWrapper withDiscountSection={!!data.upcomingInvoice.coupon}>
              <CheckoutLogos
                planName={data.upcomingInvoice.newPlan.name}
                trainualLogoSrc={
                  getImagesByPlanName(data.upcomingInvoice.newPlan.name).trainualLogoSrc
                }
              />
              <Includes features={data.includes} planName={data.upcomingInvoice.newPlan.name} />
              <CheckoutDiscountsSection
                coupon={data.upcomingInvoice.coupon}
                planName={data.upcomingInvoice.newPlan.name}
                priceArrowSrc={getImagesByPlanName(data.upcomingInvoice.newPlan.name).priceArrowSrc}
                withDiscountSection={!!data.upcomingInvoice.coupon}
              />
              <CheckoutPricingInformationSectionFactory data={data} />
              {data.showTimer && data.expiredAt && (
                <CheckoutCustomLinkTimer expiredAt={data.expiredAt} />
              )}
            </CheckoutContentWrapper>
          ) : (
            <Loader />
          )}
        </MainSectionWrapper>
        <CheckoutBillingDetailsWrapper>
          <CheckoutPaymentMethodWrapper>
            <OverlayContentHeaderTitle withBottomMargin>{t('title')}</OverlayContentHeaderTitle>
            <CheckoutPaymentMethodSection
              clearFieldError={clearFieldError}
              columnDirectionOnMd='column'
              dispatch={dispatch}
              formData={formData}
              isFormProcessing={isLoadingCreateSubscription}
              paymentMethodFormErrors={formErrors}
              validateField={validateField}
              validateFields={validateFields}
            />
          </CheckoutPaymentMethodWrapper>
          <DefaultButton
            buttonType='primary'
            disabled={isDisabledSubmitButton}
            id='checkout-pay-primary-button'
            loading={isLoadingSubmitButton}
            onClick={handleClickOnSubmitButton}
            text={t('pay')}
          />
        </CheckoutBillingDetailsWrapper>
      </OverlayContentWrapper>
    </FullScreenOverlay>
  );
};

export default CheckoutFullScreenOverlay;
