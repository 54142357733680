import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { useGetFiltersDataQuery } from '../../../../../../redux/services/resourceApis/users/usersApi';
import { groupsToOptions } from '../../../../../../types/Group';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { IconWrapper } from '../../../../../design_system/buttons/DefaultButton/ButtonStyles';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import Flyout from '../../../../../design_system/overlays/flyout/Flyout';
import MultiSelectField from '../../../../../design_system/Triage/fields/MultiSelectField';
import useActiveMenuHandler from '../../../../../design_system/useActiveMenuHandler';
import { FormGroup } from '../../../../shared/FormGroup';
import { getAppliedFiltersCount } from '../reducer';
import { TableFiltersProps, TriggerFlyoutButtonProps } from '../TableTypes';
import ShowHideColumns from './ShowHideTableColumns';

const t = initTranslations('users_table.filters');

const StyledDefaultButton = styled(DefaultButton)`
  // DS Override - No pointer events so event passes to the wrapper with className 'flyout-trigger' for Flyout handling
  ${IconWrapper} {
    pointer-events: none;
  }
`;

const TriggerFlyoutButton = ({ count }: TriggerFlyoutButtonProps) => {
  return (
    <StyledDefaultButton
      buttonType='secondary'
      className='flyout-trigger'
      iconName='sliders'
      iconWeight='solid'
      id='toggle-users-outline-table-filters-button'
      text={t('trigger_button_text', { count })}
    />
  );
};

const TableFilters = ({
  appliedFilters,
  currentFilterSelection,
  tableDispatch,
  sortColumn,
  toggledColumns,
  showShowHideColumns,
}: TableFiltersProps) => {
  const [selectedStatus, setSelectedStatus] = useState(currentFilterSelection.status);
  const [selectedGroups, setSelectedGroups] = useState(currentFilterSelection.groupIds);
  const [selectedRoles, setSelectedRoles] = useState(currentFilterSelection.roleIds);
  const [selectedTeams, setSelectedTeams] = useState(currentFilterSelection.teamIds);
  const [selectedPermissions, setSelectedPermissions] = useState(
    currentFilterSelection.permissions
  );
  const menuId = 'users-table-flyout';
  const { closeMenu } = useActiveMenuHandler({ menuId });
  const clearSelectedFiltersState = () => {
    setSelectedStatus([]);
    setSelectedPermissions([]);
    setSelectedGroups([]);
    setSelectedRoles([]);
    setSelectedTeams([]);
  };
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}`;
  const [, setCookie, removeCookie] = useCookies([
    'outline_users_hidden_columns',
    'outline_users_filters_selected_permissions',
    'outline_users_filters_selected_status',
    'outline_users_filters_selected_groups',
    'outline_users_filters_selected_roles',
    'outline_users_filters_selected_teams',
  ]);

  const { data } = useGetFiltersDataQuery();

  if (!data) return <></>;

  const { groups, statuses, permissions } = data;

  const roles = groups.filter(({ kind }) => kind === 'role');
  const teams = groups.filter(({ kind }) => kind === 'team');

  const appliedFiltersCount = getAppliedFiltersCount(appliedFilters);

  return (
    <Flyout
      className='user-outline-table-filters'
      id={menuId}
      placement='bottom-start'
      primaryButtonTask={() => {
        const hiddenColumns = toggledColumns
          .filter(({ display }) => display === 'hidden')
          .map(({ columnName }) => columnName);
        setCookie('outline_users_hidden_columns', JSON.stringify(hiddenColumns), {
          path: cookiePath,
        });
        setCookie(
          'outline_users_filters_selected_permissions',
          JSON.stringify(selectedPermissions),
          {
            path: cookiePath,
          }
        );
        setCookie('outline_users_filters_selected_groups', JSON.stringify(selectedGroups), {
          path: cookiePath,
        });
        setCookie('outline_users_filters_selected_roles', JSON.stringify(selectedRoles), {
          path: cookiePath,
        });
        setCookie('outline_users_filters_selected_teams', JSON.stringify(selectedTeams), {
          path: cookiePath,
        });
        setCookie('outline_users_filters_selected_status', JSON.stringify(selectedStatus), {
          path: cookiePath,
        });
        tableDispatch({
          type: 'applyFilters',
          groupIds: selectedGroups,
          roleIds: selectedRoles,
          teamIds: selectedTeams,
          status: selectedStatus,
          permissions: selectedPermissions,
        });
        if (hiddenColumns.includes(sortColumn)) {
          tableDispatch({
            type: 'resetTableState',
            sortColumn: 'name',
            sortDirection: 'asc',
          });
        }
        closeMenu();
      }}
      primaryButtonText={t('primary_button_text')}
      scrollable
      secondaryButtonTask={() => {
        removeCookie('outline_users_filters_selected_permissions', {
          path: cookiePath,
        });
        removeCookie('outline_users_filters_selected_groups', {
          path: cookiePath,
        });
        removeCookie('outline_users_filters_selected_status', {
          path: cookiePath,
        });
        removeCookie('outline_users_filters_selected_roles', {
          path: cookiePath,
        });
        removeCookie('outline_users_filters_selected_teams', {
          path: cookiePath,
        });
        tableDispatch({ type: 'clearFilters' });
        clearSelectedFiltersState();
        closeMenu();
      }}
      secondaryButtonText={t('secondary_button_text')}
      strategy='absolute'
      title={t('title')}
      triggerButton={<TriggerFlyoutButton count={appliedFiltersCount} />}
    >
      <FormGroup className='status-group'>
        <MultiSelectField
          className='analytics-users-outline-table-filters-status-select'
          closeMenuOnSelect
          defaultValue={selectedStatus}
          fieldLabelText={t('status')}
          name='status'
          onNonDefaultSelected={(value: string[]) => {
            setSelectedStatus(value);
          }}
          options={formatOptions(statuses)}
          placeholder={t('status_placeholder')}
          value={selectedStatus}
        />
        <AssistiveText
          id='analytics-users-outline-table-filters-status-select-assistive-text-help'
          text={t('status_assistive_text')}
          type='help'
        />
      </FormGroup>
      <FormGroup className='permissions-group'>
        <MultiSelectField
          className='analytics-users-outline-table-filters-permissions-select'
          closeMenuOnSelect
          defaultValue={selectedPermissions}
          fieldLabelText={t('permission')}
          name='permissions'
          onNonDefaultSelected={(value: string[]) => {
            setSelectedPermissions(value);
          }}
          options={formatOptions(permissions)}
          placeholder={t('permissions_placeholder')}
          value={selectedPermissions}
        />
      </FormGroup>
      <FormGroup className='groups-group'>
        <MultiSelectField
          className='analytics-users-outline-table-filters-groups-select'
          closeMenuOnSelect
          defaultValue={selectedGroups}
          fieldLabelText={t('group')}
          name='groups'
          onNonDefaultSelected={(value: string[]) => {
            setSelectedGroups(value);
            const teamIds = teams.map(({ id }) => `${id}`);
            const roleIds = roles.map(({ id }) => `${id}`);
            const newTeamIds = value.filter((id) => teamIds.includes(id));
            const newRoleIds = value.filter((id) => roleIds.includes(id));
            setSelectedTeams(newTeamIds);
            setSelectedRoles(newRoleIds);
          }}
          options={groupsToOptions(groups)}
          placeholder={t('groups_placeholder')}
          value={selectedGroups}
        />
      </FormGroup>
      {showShowHideColumns && (
        <ShowHideColumns tableDispatch={tableDispatch} toggledColumns={toggledColumns} />
      )}
    </Flyout>
  );
};

export default TableFilters;
