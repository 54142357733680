import React, { useCallback, useEffect } from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useSetupOnboardingMutation } from '../../../../../redux/services/resourceApis/onboarding/onboardingApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import {
  OnboardingStepBulletWrapper,
  OnboardingStepContainer,
  OnboardingStepInner,
  OnboardingStepWrapper,
} from '../OnboardingStep/styles';
import {
  ButtonsContainer,
  ChooseVariationBullet,
  ChooseVariationContainer,
  ChooseVariationHeader,
  ChooseVariationInfoWrapper,
  ChooseVariationSubtitle,
  ChooseVariationTitle,
  VariationCardsWrapper,
} from './styles';
import VariationCard from './VariationCard';

const t = initTranslations('home.onboarding.choose_onboarding_variation_step');

const ChooseOnboardingVariationStep = () => {
  const { sliderRef, formData, initialFormData } = useOnboarding();
  const { cdpOnboardingDataPrefilled } = useAnalyticsUserEvents();
  const [setupOnboarding, result] = useSetupOnboardingMutation();
  const { productTerm } = useAccountTerminology();
  const { isLoading, isSuccess, error } = result;
  const submitButtonDisabled = formData.onboarding_variation === 'explore_my_own';
  const handlePrevSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    sliderRef?.current?.slickPrev();
    e.currentTarget.blur();
  };

  const handleSegmentTrackEvent = useCallback(() => {
    const storedData = localStorage.getItem('clearbitData');

    if (!!storedData) {
      cdpOnboardingDataPrefilled({
        prefilled: {
          company_name: initialFormData.name,
          user_name: initialFormData.user_name,
          user_title: initialFormData.user_title,
        },
        submitted: {
          company_name: formData.name,
          user_name: formData.user_name,
          user_title: formData.user_title,
        },
      });
    }
  }, [cdpOnboardingDataPrefilled, formData, initialFormData]);

  // remove stored data set in `RegistrationForm.tsx`
  useEffect(() => {
    if (isSuccess && !isLoading) {
      handleSegmentTrackEvent();
      localStorage.removeItem('clearbitData');
    }
  }, [handleSegmentTrackEvent, isLoading, isSuccess]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: messageFromError(error)?.join(', '),
  });

  return (
    <OnboardingStepWrapper id='onboarding-variation' visible>
      <OnboardingStepContainer>
        <ChooseVariationHeader>
          <OnboardingStepBulletWrapper>
            <ChooseVariationBullet />
          </OnboardingStepBulletWrapper>
          {t('header')}
        </ChooseVariationHeader>
        <OnboardingStepInner>
          <ChooseVariationContainer>
            <ChooseVariationInfoWrapper>
              <ChooseVariationTitle>{t('title', { productTerm })}</ChooseVariationTitle>
              <ChooseVariationSubtitle>{t('subtitle')}</ChooseVariationSubtitle>
            </ChooseVariationInfoWrapper>
            <VariationCardsWrapper>
              <VariationCard
                description={t('create_training_description')}
                emoji='📚'
                title={t('create_training')}
                type='create_content'
              />
              <VariationCard
                description={t('organize_business_description')}
                emoji='💸'
                title={t('organize_business')}
                type='organize_business'
              />
            </VariationCardsWrapper>
            <ButtonsContainer>
              <DefaultButton
                buttonType='tertiary'
                id='onboarding-choose-variation-step-back'
                onClick={handlePrevSlide}
                size='md'
                text={t('back')}
              />
              <DefaultButton
                buttonType='primary'
                className='lets-go-button'
                disabled={submitButtonDisabled}
                id='onboarding-choose-variation-step-submit'
                loading={!submitButtonDisabled && isLoading}
                onClick={() => setupOnboarding(formData)}
                size='md'
                text={t('lets_go')}
              />
            </ButtonsContainer>
          </ChooseVariationContainer>
        </OnboardingStepInner>
      </OnboardingStepContainer>
    </OnboardingStepWrapper>
  );
};

export default ChooseOnboardingVariationStep;
