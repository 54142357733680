import styled, { css } from 'styled-components';

import { getAddonTrainualPlusFeatureIconStyles } from '../../../../../../../lib/billing/getAddonTrainualPlusFeatureIconStyles';
import { Addon } from '../../../../../../../types/Addon';
import P from '../../../../../../design_system/text/P';
import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../../../../styled/Breakpoint';
import { fontMd3, fontSm2 } from '../../../../../../styled/TypeSystem';

export const FeatureSectionWrapper = styled.div`
  ${({ theme: { constants, vars } }) => css`
    position: relative;
    max-width: 33.75rem;
    margin: 0 auto 6.25rem;
    padding: ${constants.spacerLg1} 3.75rem;

    &:nth-of-type(odd) {
      background-color: ${vars.foundationBase1};
      border-radius: ${constants.borderRadiusXl};
      z-index: 1;
    }

    //training-paths-feature
    &:nth-of-type(1) {
      .feature-video {
        right: -32px;
        bottom: -72px;

        @media (max-width: ${mediaBreakpointPxLg}) {
          right: -10px;
        }
      }
    }

    //delegation-planner-feature
    &:nth-of-type(3) {
      .feature-video {
        left: -16px;
        bottom: -80px;

        @media (max-width: ${mediaBreakpointPxLg}) {
          left: 16px;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: ${mediaBreakpointPxMd}) {
      max-width: 25rem;
      padding: ${constants.spacerLg1} 2.5rem;
    }
  `}
`;

export const FeatureIcon = styled.img<{ iconName: Addon }>`
  position: absolute;
  ${({ iconName }) => getAddonTrainualPlusFeatureIconStyles(iconName)};
`;

const eSignatureWaveUnderline = encodeURIComponent(`
  <svg width="257" height="10" viewBox="0 0 257 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector 570" d="M2.5 6.0004C6.5 3.0004 11 3.0004 14 5.5004C17 8.0004 20.5 8.50077 23.5 6.0004C26.5 3.50004 31.5 3.00004 33.5 5.00004C35.5 7.00004 41 8.00077 44 5.5004C47 3.00004 51 1.99968 56 4.00004C61 6.0004 65 6.50163 67.5 5.00004C70 3.49846 74.5 1.50163 79 4.00004C83.5 6.49846 90 5.99821 92.5 4.00004C95 2.00187 102.5 2.50187 104.5 4.50187C106.5 6.50187 113.5 7.00196 116.5 4.50187C119.5 2.00179 127 2.49902 130 5.5004C133 8.50179 140 7.99902 143 5.5004C146 3.00179 152.5 2.49902 156.5 5.5004C160.5 8.50179 168 8.99902 173 5.5004C178 2.00179 185 1.9983 188 5.00004C191 8.00179 198 8.9983 203.5 5.00004C209 1.00179 215.5 2.50196 218 4.50187C220.5 6.50179 226.5 8.00182 230.5 4.50187C234.5 1.00192 241.5 2.50182 244 4.50187C246.5 6.50192 252.5 7.50081 255 5.5004" stroke="#FCD573" stroke-width="4" stroke-linecap="round"/>
  </svg>
`);

export const delegationPlannerFeatureIconStyles = css`
  top: -36px;
  right: 10px;
`;

export const eSignatureFeatureIconStyles = css`
  top: 58px;
  right: -43px;
  z-index: -1;

  @media (max-width: ${mediaBreakpointPxLg}) {
    top: 138px;
    right: -16px;
  }
`;

export const trainingPathFeatureIconStyles = css`
  top: 24px;
  left: -32px;

  @media (max-width: ${mediaBreakpointPxLg}) {
    top: -25px;
    left: -17px;
  }
`;

export const multiLanguageFeatureIconStyles = css`
  top: 54px;
  left: -60px;
  z-index: -1;

  @media (max-width: ${mediaBreakpointPxMd}) {
    top: -10px;
    left: -28px;
  }
`;

export const customDomainFeatureIconStyles = css`
  top: -18px;
  left: -16px;

  @media (max-width: ${mediaBreakpointPxLg}) {
    top: -32px;
    left: 8px;
  }
`;

export const sharedInlineBlockStyles = css`
  display: inline-block;
  position: relative;
`;

export const sharedBeforeStyles = css`
  content: '';
  display: block;
  position: absolute;
`;

export const FeatureTitle = styled.h3`
  ${({ theme: { constants, vars } }) => css`
    margin-bottom: ${constants.spacerMd2};
    color: ${vars.trainualBrandBlueStrong};
    text-align: center;
    ${fontMd3};
    line-height: normal;

    .e-signature-feature-accent {
      ${sharedInlineBlockStyles};

      &::before {
        inset: 16px 0 -12px;
        z-index: -1;
        background: url('data:image/svg+xml;utf8,${eSignatureWaveUnderline}') no-repeat center;
        ${sharedBeforeStyles};
      }
    }

    .training-paths-feature-accent {
      ${sharedInlineBlockStyles};

      &::before {
        bottom: 3px;
        left: 3px;
        right: 0;
        height: 3px;
        z-index: -1;
        transform: rotate(-1deg);
        border-radius: ${constants.borderRadiusEndcap};
        background-color: ${vars.trainualBrandMagentaMedium};
        ${sharedBeforeStyles};
      }
    }

    .delegation-planner-feature-accent {
      color: ${vars.trainualBrandGreenMedium};
    }

    .multi-language-feature-accent {
      ${sharedInlineBlockStyles};

      &::before {
        top: 11px;
        left: 2px;
        right: -1px;
        height: 12px;
        z-index: -1;
        transform: rotate(-1.294deg);
        border-radius: ${constants.borderRadiusEndcap};
        background-color: ${vars.previewBlueSecondary};
        ${sharedBeforeStyles};
      }
    }

    .custom-domain-feature-accent {
      ${sharedInlineBlockStyles};

      &::before {
        top: 10px;
        left: -3px;
        right: -4px;
        height: 12px;
        z-index: -1;
        transform: rotate(1.294deg);
        border-radius: ${constants.borderRadiusEndcap};
        background-color: ${vars.previewPurpleSecondary};
        ${sharedBeforeStyles};
      }
    }
  `}
`;

export const FeatureDescription = styled(P)`
  text-align: center;
  margin: 0;
  ${fontSm2};
`;
