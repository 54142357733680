import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { VideoDataContextProvider } from '../../../contexts/VideoDataContext';
import useCurrentAccount from '../../../hooks/useCurrentAccount';
import initTranslations from '../../../lib/initTranslations';
import { useGetVideoQuery } from '../../../redux/services/resourceApis/videos/videosApi';
import Loader from '../../design_system/Triage/Loader';
import { ThreeDotMenuOptionProps } from '../../design_system/Triage/menus/ThreeDotMenu/types';
import Portal from '../Portal';
import VideoFooter from './Footer/VideoFooter';
import VideoContent from './VideoContent';
import VideoModal from './VideoModal/VideoModal';
import VideoSideWidget from './VideoSideWidget/VideoSideWidget';
import VideoTopNav from './VideoTopNav';

const VideoWrapper = styled.div`
  ${({ theme: { vars } }) => css`
    z-index: 1000;
    background-color: ${vars.foundationBase1};
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  `}
`;

const VideoLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const VideoInternalLayout = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    flex: 1;
    overflow: auto;
    gap: ${constants.spacerLg2};
    justify-content: center;

    margin: ${constants.spacerMd1} ${constants.spacerLg2} ${constants.spacerMd1}
      ${constants.spacerLg2};
  `}
`;

const StyledVideoContent = styled(VideoContent)`
  ${() => css`
    flex-direction: column;
    flex: 0 0 75%;
  `}
`;

const StyledVideoSideWidget = styled(VideoSideWidget)`
  ${() => css`
    flex-direction: column;
    flex: 0 0 25%;
  `}
`;
const t = initTranslations('video.video_top_nav');

type Props = {
  id: number;
  editing: boolean;
};

const VIDEO_TYPES = {
  external: 'external',
  hosted: 'hosted',
};

const VideoPage = ({ id, editing }: Props) => {
  const { data, isError, isLoading } = useGetVideoQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const {
    splitFeatures: { hostedVideoEnabled },
  } = useCurrentAccount();

  const [showModal, setShowModal] = useState(false);

  const menuOptions = useMemo(() => {
    const menuOptions: ThreeDotMenuOptionProps[] = [
      {
        id: 'td-curriculum-element-edit',
        title: t('edit'),
        iconName: 'pencil',
        onClick: () => {
          setShowModal(true);
        },
        visible: editing,
      },
    ];
    return menuOptions;
  }, [editing]);

  if (isLoading) return <Loader />;
  if (isError || !data) return <></>; // TODO: handle error state

  return (
    <VideoDataContextProvider data={data} isError={isError} isLoading={isLoading}>
      <Portal>
        <VideoWrapper>
          <VideoLayout>
            <VideoTopNav editing={editing} menuOptions={menuOptions} />
            <VideoInternalLayout>
              <StyledVideoContent />
              {hostedVideoEnabled && (
                <StyledVideoSideWidget
                  widgetType={data.videoType === VIDEO_TYPES.external ? 'upload' : 'transcript'}
                />
              )}
            </VideoInternalLayout>
            <VideoFooter editing={editing} />
          </VideoLayout>
        </VideoWrapper>
      </Portal>
      {showModal && (
        <VideoModal onClose={() => setShowModal(false)} onCreateVideo={() => alert('create')} />
      )}
    </VideoDataContextProvider>
  );
};

export default VideoPage;
