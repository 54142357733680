import React from 'react';
import styled from 'styled-components';

import hexToRGBA from '../../../../../lib/HexToRGBA';
import initTranslations from '../../../../../lib/initTranslations';
import translationClassname from '../../../../../lib/translationClassname';
import { fontSm4 } from '../../../../styled/TypeSystem';

const HeadingContainer = styled.div`
  align-items: center;
  background-color: ${({ theme: { vars } }) => hexToRGBA(vars.foundationSurface1, 0.8)};
  border-radius: ${({ theme: { constants } }) =>
    `${constants.borderRadiusXl} ${constants.borderRadiusXl} 0 0`};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg2}`};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const Range = styled.h3`
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  ${fontSm4};
`;

const t = initTranslations('editor');

export type Props = {
  children?: React.ReactNode;
  currentItem: number;
  rangeTitle: string;
  totalItems: number;
  disableTranslation?: boolean;
};

const StepHeading = ({
  children,
  currentItem,
  rangeTitle,
  totalItems,
  disableTranslation,
}: Props) => {
  return (
    <HeadingContainer className={translationClassname(disableTranslation)}>
      <Range>
        {t('label.step', {
          step: rangeTitle,
          index: currentItem,
          totalSteps: totalItems,
        })}
      </Range>
      {children}
    </HeadingContainer>
  );
};

export default StepHeading;
