import React from 'react';
import styled from 'styled-components';

import useSlideout from '../../../../../../hooks/useSlideout';
import { AIOutlinerSlideoutBanner } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import IconButton from '../../../../../design_system/buttons/IconButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import { fontMd3, fontSm5 } from '../../../../../styled/TypeSystem';
import { sharedAIIconButtonStyles } from '../GlowingIconButton/GlowingIconButton';

const t = initTranslations('curriculums.ai_outliner.slideout');

const TopSection = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerSm3};
  right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSurface};

  :hover,
  :focus {
    color: ${({ theme: { vars } }) => vars.textSurface};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const AIIcon = styled(Icon)`
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};

  ${sharedAIIconButtonStyles};
`;

const HeaderText = styled.h1`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd3};
`;

const Description = styled.p`
  margin-block: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
  /* Override type system line-height from fontSm5 to match designs */
  line-height: 1.313rem;
`;

interface SlideoutHeaderProps {
  type: 'topic' | 'custom';
}

const SlideoutHeader = ({ type }: SlideoutHeaderProps) => {
  const { close } = useSlideout('ai-outliner');
  const {
    topic: { singular: topicSingular, plural: topicPlural },
  } = useAccountTerminology();

  return (
    <>
      <img src={AIOutlinerSlideoutBanner} />
      <CloseButton ariaLabel={t('close')} name='close' onClick={close} />
      <TopSection>
        <Header>
          <AIIcon name='wand-magic-sparkles' />
          <HeaderText>{t(`${type}.header`)}</HeaderText>
        </Header>
        <Description>
          {t(`${type}.description`, {
            topic: topicSingular.toLowerCase(),
            topics: topicPlural.toLowerCase(),
          })}
        </Description>
      </TopSection>
    </>
  );
};

export default SlideoutHeader;
