import React, { useCallback, useMemo, useReducer } from 'react';
import styled, { css } from 'styled-components';
import { Sector } from 'types/Sector';

import { useCurriculumEdit } from '../../../../../../contexts/CurriculumEditContext';
import { TeammateModalProvider } from '../../../../../../contexts/TeammateContext';
import useCopyUrlLink from '../../../../../../hooks/useCopyUrlLink';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import { useGetCurriculumElementsForAdminQuery } from '../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useToggleArchivingMutation } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import MenuOption from '../../../../../design_system/core/MenuOption/MenuOption';
import { ThreeDotMenuOptionProps } from '../../../../../design_system/Triage/menus/ThreeDotMenu/types';
import { fontSm4 } from '../../../../../styled/TypeSystem';
import { routes } from '../../../../publicApplication/applicationRouter';
import ContentModal from '../../../modals/CurriculumModal/ContentModal';
import ExportPDFModal from '../../../modals/ExportPDFModal';
import ShareChangesModal from '../../../ShareChangesModal/ShareChangesModal';
import { initialState, menuReducer } from './menuReducer';

const ActionsWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const Header = styled.div`
  ${({ theme: { vars, constants } }) => css`
    color: ${vars.textSubdued};
    font-weight: ${constants.fontSemibold};
    margin-top: ${constants.spacerMd3};
    margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
    margin-left: ${({ theme: { constants } }) => constants.spacerMd2};

    ${fontSm4}
  `}
`;

const t = initTranslations('curriculums.edit.settings.actions');

type Props = {
  curriculumId: number;
  curriculumTitle: string;
  published: boolean;
  completionRequired: boolean | undefined;
  reportsAccess?: boolean;
  canDeleteSubject: boolean;
  sector: Sector;
  containsSignature?: boolean;
  archived: boolean;
};

const ActionsMenu = ({
  curriculumId,
  curriculumTitle,
  published,
  completionRequired,
  reportsAccess,
  canDeleteSubject,
  sector,
  containsSignature,
  archived,
}: Props) => {
  const { copyUrlLink } = useCopyUrlLink();
  const {
    paywalledFeatures,
    slug,
    splitFeatures: { curriculumPrintViewEnabled },
  } = useCurrentAccount();
  const [{ editModal, exportModal, shareModal }, dispatch] = useReducer(menuReducer, initialState);
  const {
    curriculum: { singular: curriculumTermSingular },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const { setShowDeleteCurriculumModal } = useCurriculumEdit();

  const [archive, archivedResult] = useToggleArchivingMutation();
  useDisplayFlashOnResponse({
    result: archivedResult,
    successMessage: t('archive_success'),
    errorMessage: t('archive_error', { subject: curriculumTermSingular }),
  });

  const [unarchive, unArchivedResult] = useToggleArchivingMutation();
  useDisplayFlashOnResponse({
    result: unArchivedResult,
    successMessage: t('unarchive_success'),
    errorMessage: t('unarchive_error', { subject: curriculumTermSingular }),
  });

  const { data } = useGetCurriculumElementsForAdminQuery(curriculumId);
  const elementFinished = useMemo(
    () => data?.curriculumElements?.some((ce) => ce.element.status === 'finished') || false,
    [data?.curriculumElements]
  );
  const notifyTeamToolTipText = useMemo(() => {
    if (!published) {
      return t('notify_team_unpublished_subject_tooltip', {
        subject: curriculumTermSingular,
      });
    } else if (!elementFinished) {
      return t('notify_team_no_finished_elements_tooltip', {
        topic: topicTermSingular.toLowerCase(),
      });
    } else {
      return '';
    }
  }, [elementFinished, published, curriculumTermSingular, topicTermSingular]);

  const reportsPaywalled = paywalledFeatures.includes('reports');
  const reportRoute = useMemo(
    () =>
      reportsPaywalled
        ? routes.reportsUpgrade({ slug })
        : routes.curriculumByUserReport({ slug, id: curriculumId }),
    [curriculumId, reportsPaywalled, slug]
  );

  const ability = useCurrentUserAbilities();
  const canExportPdf = useMemo(
    () => ability.can('create', `ExportCurriculum-${curriculumId}`),
    [ability, curriculumId]
  );
  const curriculumRestrictedToModify = useMemo(
    () => ability.can('read', `CurriculumRestrictedToModify-${curriculumId}`),
    [ability, curriculumId]
  );

  const navigateToPrintView = useCallback(
    () => window.open(routes.curriculumPrint({ slug, id: curriculumId }).href, '_blank'),
    [curriculumId, slug]
  );

  const copy = useCallback(() => {
    copyUrlLink({
      path: routes.curriculumShow({ slug, id: curriculumId }).href,
      flashText: t('copy_link_flash'),
    });
  }, [copyUrlLink, curriculumId, slug]);

  const options: ThreeDotMenuOptionProps[] = useMemo(
    () => [
      {
        title: t('edit_settings'),
        onClick: () => dispatch({ type: 'edit', showEdit: true }),
        id: 'td-curriculum-edit-edit',
        iconName: 'cog',
        iconWeight: 'regular',
        visible: ability.can('update', `EditCurriculum-${curriculumId}`),
      },
      {
        title: t('copy_link'),
        onClick: copy,
        id: 'td-curriculum-edit-copy-link',
        iconName: 'link',
        iconWeight: 'regular',
        visible: true,
      },
      {
        title: t('export_pdf'),
        onClick: navigateToPrintView,
        id: 'td-subject-show-print-pdf',
        iconName: 'file-export',
        iconWeight: 'regular',
        visible: Boolean(canExportPdf && curriculumPrintViewEnabled),
      },
      {
        title: t('email_pdf'),
        onClick: () => dispatch({ type: 'export', showExport: true }),
        id: 'td-subject-export-pdf',
        iconName: 'envelope',
        iconWeight: 'regular',
        visible: canExportPdf,
      },
      {
        title: t('share_changes'),
        onClick: () => dispatch({ type: 'share', showShare: true }),
        id: 'td-subject-notify-team',
        iconName: 'paper-plane',
        iconWeight: 'regular',
        visible: true,
        tooltipText: notifyTeamToolTipText,
        isDisabled: !published || !elementFinished,
      },
      {
        title: t('view_report'),
        onClick: () => reportRoute.push(),
        id: 'td-curriculum-edit-view-report',
        iconName: 'file-lines',
        iconWeight: 'regular',
        visible: !!reportsAccess && (completionRequired || reportsPaywalled),
      },
      {
        title: t('archive'),
        onClick: () => archive(curriculumId),
        iconName: 'archive',
        iconWeight: 'regular',
        id: 'td-curriculum-archive',
        isDisabled: curriculumRestrictedToModify,
        tooltipText: curriculumRestrictedToModify ? t('disabled_by_e_signature') : '',
        visible: ability.can('update', `ArchiveCurriculum-${curriculumId}`) && !archived,
      },
      {
        title: t('unarchive'),
        onClick: () => unarchive(curriculumId),
        iconName: 'box-open',
        iconWeight: 'regular',
        id: 'td-curriculum-unarchive',
        visible: ability.can('update', `UnarchiveCurriculum-${curriculumId}`) && archived,
      },
      {
        title: t('delete'),
        onClick: () => setShowDeleteCurriculumModal(true),
        id: 'td-curriculum-edit-delete',
        isDisabled: curriculumRestrictedToModify,
        iconName: 'trash-alt',
        iconWeight: 'regular',
        tooltipText: curriculumRestrictedToModify ? t('disabled_by_e_signature') : '',
        visible: canDeleteSubject,
      },
    ],
    [
      ability,
      archive,
      archived,
      canDeleteSubject,
      canExportPdf,
      completionRequired,
      copy,
      curriculumId,
      curriculumPrintViewEnabled,
      curriculumRestrictedToModify,
      elementFinished,
      navigateToPrintView,
      notifyTeamToolTipText,
      published,
      reportRoute,
      reportsAccess,
      reportsPaywalled,
      setShowDeleteCurriculumModal,
      unarchive,
    ]
  );

  return (
    <ActionsWrapper>
      <Header>{t('header')}</Header>
      {options
        .filter((option) => option.visible)
        .map((option, index) => {
          return (
            <MenuOption
              iconName={option.iconName}
              id={option.id}
              isDisabled={option.isDisabled}
              key={`settings-action-menu-option-${index}`}
              onClick={(e) => {
                e.preventDefault();
                option.onClick(e);
              }}
              title={option.title}
              tooltipText={option.tooltipText}
            />
          );
        })}
      {editModal && (
        <div data-disabled={containsSignature && completionRequired} id='curriculum-modal'>
          <TeammateModalProvider>
            <ContentModal
              closeRequest={() => dispatch({ type: 'edit', showEdit: false })}
              curriculumId={curriculumId}
              sector={sector}
            />
          </TeammateModalProvider>
        </div>
      )}
      {shareModal && (
        <ShareChangesModal
          closeRequest={() => dispatch({ type: 'share', showShare: false })}
          completionRequired={completionRequired}
          curriculumId={curriculumId}
          curriculumTitle={curriculumTitle}
          dataSource='curriculum'
        />
      )}
      {exportModal && (
        <ExportPDFModal
          closePdfModal={() => dispatch({ type: 'export', showExport: false })}
          id={curriculumId}
          title={curriculumTitle}
        />
      )}
    </ActionsWrapper>
  );
};

export default ActionsMenu;
