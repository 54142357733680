import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import { deprecated, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import FieldLabel from '../../core/FieldLabel';
import Icon from '../../display/icons/Icon';
import { CharacterCountInterface } from '../../input/fields/TextareaWithCharacterCount';

type NativeInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type InputProps = Omit<NativeInputProps, 'ref'> & {
  errorText?: string | false;
};

const ValidationText = styled.p`
  margin: ${({ theme: { constants } }) => constants.spacerSm2} 0 0 0;
  ${fontSm4};
`;

export const ErrorText = styled(ValidationText)`
  color: ${({ theme: { vars } }) => vars.stateError};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.stateError};
`;

export const Input = styled.input<{ error?: boolean; disabled?: boolean; fillColor: string }>`
  border: none;
  width: 100%;
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};
  background-color: ${({ theme: { vars }, error, fillColor }) =>
    error ? vars.stateBackgroundError : fillColor};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  -webkit-box-shadow: 0 0 0 50px
    ${({ theme: { vars }, error, fillColor }) => (error ? vars.stateBackgroundError : fillColor)}
    inset;
  caret-color: ${({ theme: { vars } }) => vars.textDefault};

  &:focus {
    outline: none;
    border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    color: ${({ theme: { vars } }) => vars.textDefault};
    caret-color: ${({ theme: { vars } }) => vars.textDefault};
  }
  border-color: ${({ error, theme: { vars } }) => (error ? vars.stateError : 'transparent')};

  ::-webkit-input-placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ::-moz-placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ::-ms-input-placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  ::placeholder {
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }
  ${fontSm5};
`;

const TextFieldWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const InputWrapper = styled.div<{ hasError: boolean; fillColor: string; borderColor: string }>`
  background-color: ${({ hasError, fillColor, theme: { vars } }) =>
    hasError ? vars.stateBackgroundError : fillColor};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars }, hasError, borderColor }) => (hasError ? vars.stateError : borderColor)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  display: flex;
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd2};
  margin: 0;
  position: relative;
  width: 100%;
  height: ${({ theme: { constants } }) => constants.heightMd};

  &:focus-within {
    border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
      ${({ theme: { vars }, hasError }) => (hasError ? vars.stateError : vars.accentPrimaryDefault)};
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: ${({ theme: { constants } }) =>
      `${constants.spacerSm1} ${constants.spacerMd2} ${constants.spacerSm1} ${constants.spacerSm1}`};
  }
`;

export const StyledCharacterCount = styled.span<{ hasError: boolean }>`
  align-self: center;
  white-space: nowrap;
  color: ${({ theme: { vars }, hasError }) => (hasError ? vars.stateError : vars.textSubdued)};
  ${deprecated.fontSm3};
`;

const InputCharacterCount = ({
  charactersUsed,
  maxCharacters,
  hasFieldError,
}: CharacterCountInterface) => {
  if (!maxCharacters) {
    return <></>;
  }

  return (
    <StyledCharacterCount hasError={charactersUsed > maxCharacters || !!hasFieldError}>
      {charactersUsed} / {maxCharacters}
    </StyledCharacterCount>
  );
};

const t = initTranslations('input_field');

export interface Props extends InputProps {
  label?: string;
  placeholder?: string;
  maxCharacters?: number;
  inputFor?: string;
  focusText?: boolean;
  value: string | undefined;
  fillColor?: string;
  borderColor?: string;
  onFocusAction?: () => void;
}

// If Design System approves, we'll make this the default InputField component (and change the name of this one from InputFieldWithCharacterCounter)
const InputFieldWithCharacterCounter = (Props: Props) => {
  const {
    vars: { foundationSurface1, borderSurface2 },
  } = useTheme();

  const {
    inputFor,
    errorText,
    label,
    placeholder,
    maxCharacters,
    required,
    focusText = false,
    value,
    borderColor = borderSurface2,
    fillColor = foundationSurface1,
    onFocusAction,
    ...standardProps
  } = Props;

  const name = standardProps.name || '';
  const id = standardProps.id || name;
  const [maxCharError, setMaxCharError] = useState<string | null>(null);

  useEffect(() => {
    if (value && maxCharacters && value.length > maxCharacters) {
      const errorMessage = t('error_message_character_length', {
        input_for: inputFor,
        maxCharacters,
      });

      setMaxCharError(errorMessage);
    } else {
      setMaxCharError(null);
    }
  }, [inputFor, maxCharacters, value]);

  return (
    <InputGroup>
      {label && <FieldLabel htmlFor={id} required={required} text={label} />}
      <InputWrapper
        borderColor={borderColor}
        fillColor={fillColor}
        hasError={!!errorText || !!maxCharError}
      >
        <TextFieldWrapper>
          <Input
            {...standardProps}
            autoComplete='off'
            error={!!errorText}
            fillColor={fillColor}
            onFocus={(e) => {
              focusText && e.target.setSelectionRange(0, e.target.value.length);
              e.target.scrollLeft = 0;
              e.target.select();
              onFocusAction && onFocusAction();
            }}
            placeholder={placeholder}
            type='text'
            value={value}
          />
        </TextFieldWrapper>
        {maxCharacters && (
          <InputCharacterCount
            anchoredRightCharacterCount={false}
            charactersUsed={value ? value.trim().length : 0}
            hasFieldError={!!errorText}
            maxCharacters={maxCharacters}
          />
        )}
      </InputWrapper>
      {(errorText || maxCharError) && (
        <ErrorWrapper>
          <Icon name='circle-x' size='2xs' weight='solid' />
          <ErrorText>{errorText || maxCharError}</ErrorText>
        </ErrorWrapper>
      )}
    </InputGroup>
  );
};

export default InputFieldWithCharacterCounter;
