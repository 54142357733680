import React from 'react';
import styled from 'styled-components';

import { useVideoData } from '../../../contexts/VideoDataContext';

const VideoDetailsContainer = styled.div`
  /* TODO: scroll behavior */
  margin-top: 40px;
  overflow: auto;
`;

const VideoDetails = () => {
  const { data } = useVideoData();

  if (!data) {
    return null;
  }

  const { description, title, emoji } = data;

  return (
    <VideoDetailsContainer>
      <span>{emoji}</span>
      <h2>{title}</h2>
      <p>{description}</p>
    </VideoDetailsContainer>
  );
};

export default VideoDetails;
