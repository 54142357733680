import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { StyledResponsibilityItem } from '../../shared/Responsibility/styles';

export const ResponsibilityListItemWrapper = styled(StyledResponsibilityItem)`
  display: flex;
  align-items: center;
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export const StyledP = styled.p`
  max-width: 90%;
`;

export const DragAndDropIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm1};
  margin-right: ${({ theme: { constants } }) => constants.spacerMd1};
  opacity: 0;
  pointer-events: auto;
  cursor: grab;
`;
