import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Icon from '../../../design_system/display/icons/Icon';
import { fontMd1 } from '../../../styled/TypeSystem';
import MockSideNav from './MockSideNav';
import MockTopNav from './MockTopNav';

const Container = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${constants.spacerMd2};
  `
);

const PreviewBadgeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewBadge = styled.div(
  ({ theme: { constants, vars } }) => css`
    position: absolute;
    top: -${constants.spacerMd2};
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${constants.spacerSm2};
    padding: ${constants.spacerSm2} ${constants.spacerMd1};
    border-radius: ${constants.borderRadiusLg};
    background: ${vars.accentPrimaryDefault};
    color: ${vars.textSurface};
    text-align: center;
    font-weight: ${constants.fontMedium};
    ${fontMd1};
  `
);

const IconWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${constants.spacerSm2};
  `
);

const Wrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: normal;
    width: 100%;
    border: ${constants.borderWidth2xl} solid ${vars.accentPrimaryDefault};
    border-radius: 1.5rem;
    box-shadow: ${({ theme: { vars } }) => vars.shadowTopLarge};
    overflow: hidden;
  `
);

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
`;

const StyledContainer = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: ${constants.borderWidthSm} solid ${vars.borderSurface1};
  `
);

const t = initTranslations('page_preview');

export type PagePreviewProps = {
  userId: number;
  children: React.ReactNode;
};

const PagePreview = ({ userId, children }: PagePreviewProps) => {
  return (
    <Container>
      <PreviewBadgeWrapper>
        <PreviewBadge>
          <IconWrapper>
            <Icon name='eye' size='xs' weight='regular' />
          </IconWrapper>
          {t('preview')}
        </PreviewBadge>
      </PreviewBadgeWrapper>
      <Wrapper>
        <MockSideNav />
        <StyledContainer>
          <MockTopNav userId={userId} />
          <BodyWrapper>{children}</BodyWrapper>
        </StyledContainer>
        <Overlay />
      </Wrapper>
    </Container>
  );
};

export default PagePreview;
