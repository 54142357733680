import React, { useRef } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useTruncatedText from '../../../../../../hooks/useTruncatedText';
import translationClassname from '../../../../../../lib/translationClassname';
import { ConsumptionCurriculum, EditorCurriculum } from '../../../../../../types/Editor';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { routes } from '../../../../publicApplication/applicationRouter';
import CurriculumTitle from '../../../components/CurriculumTitle/CurriculumTitle';
import { CurriculumTitleLink, EditorBreadcrumbs } from '../../../shared/styles';

type Props = {
  curriculum: EditorCurriculum | ConsumptionCurriculum;
  mode: 'view' | 'edit';
};

const CurriculumBreadcrumb = ({ curriculum, mode }: Props) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const showTooltip = useTruncatedText(textRef);

  const { slug } = useCurrentAccount();
  const route =
    mode === 'view'
      ? routes.curriculumShow({ slug, id: curriculum.id })
      : routes.curriculumEdit({ slug, id: curriculum.id });

  return (
    <EditorBreadcrumbs id='editor-breadcrumb'>
      {showTooltip && (
        <Tooltip id='curriculum-title-tooltip' place='bottom' text={curriculum.title} />
      )}
      <CurriculumTitleLink
        className={translationClassname(curriculum.disableTranslation)}
        dataFor='curriculum-title-tooltip'
        href={route.href}
        prefixIconName='arrow-left'
        text={<CurriculumTitle id='curriculum-title' title={curriculum.title} titleRef={textRef} />}
        underlineBehavior='dynamic'
      />
    </EditorBreadcrumbs>
  );
};

export default CurriculumBreadcrumb;
