import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import {
  resetSearchClear,
  selectAllUsers,
  setSearchValue,
} from '../../../../../redux/domains/curriculumCompletions/curriculumCompletionsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  useGetManageCurriculumQuery,
  useUpdateCompletionsMutation,
} from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CompletionAction } from '../../../../../redux/services/resourceApis/curriculums/types';
import TaskModal from '../../../../design_system/overlays/modals/TaskModal';
import SearchField from '../../../../design_system/Triage/fields/SearchField';
import BulkCompletionsActionsMenu from '../../CurriculumEdit2/CurriculumCompletionsModal/BulkCompletionsActionsMenu';
import CurriculumCompletions from './CurriculumCompletions';

export interface Props {
  curriculumId: number;
  onCloseRequest: () => void;
}

const t = initTranslations('curriculum_edit.manage_completions');

const SearchFieldWrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} auto ${constants.spacerMd2} 0`};
  width: 15rem;
`;

const CurriculumCompletionsModal = ({ curriculumId, onCloseRequest }: Props) => {
  const dispatch = useAppDispatch();
  const { searchValue, page, searchClear, allUsersSelected, selectedUsers } = useAppSelector(
    (state) => state.curriculumCompletions
  );

  const debouncedSearchValue = useDebounce<string>(searchValue.trim(), 500);

  useEffect(() => {
    if (!debouncedSearchValue && searchClear) {
      dispatch(resetSearchClear());
    }
  }, [searchValue, dispatch, searchClear, debouncedSearchValue]);

  const [updateCompletions, result] = useUpdateCompletionsMutation();
  const handleBulkActionSelection = useCallback(
    (action: CompletionAction) => {
      updateCompletions({
        id: curriculumId,
        userIds: selectedUsers,
        actionType: action,
        search: searchValue,
        page,
      });
    },
    [curriculumId, page, searchValue, selectedUsers, updateCompletions]
  );

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('table.error_message'),
    successFunction: () => {
      result.reset();
      dispatch(selectAllUsers(false));
    },
  });

  const { isFetching, data, isLoading } = useGetManageCurriculumQuery({
    id: curriculumId,
    search: debouncedSearchValue,
    page,
  });

  return (
    <TaskModal
      desktopSize='3xl'
      footerContent={
        <>
          {(selectedUsers.length || allUsersSelected) && (
            <BulkCompletionsActionsMenu
              handleBulkActionSelection={handleBulkActionSelection}
              onCloseRequest={() => dispatch(selectAllUsers(false))}
              selectedCount={selectedUsers.length}
            />
          )}
        </>
      }
      headerChildren={
        <SearchFieldWrapper>
          <SearchField
            dataLoading={isFetching}
            iconPosition='right'
            placeholder={t('search_placeholder')}
            searchValue={searchValue}
            setSearchValue={(value: string) => {
              dispatch(setSearchValue(value));
            }}
          />
        </SearchFieldWrapper>
      }
      heapModalName='curriculum-completions-modal'
      minMaxModalHeight={{ min: '1rem', max: '90vh' }}
      onCloseRequest={onCloseRequest}
      processing={isFetching}
      scrollInsideBody
      secondaryButtonText={t('close')}
      title={t('completions')}
    >
      <CurriculumCompletions curriculumId={curriculumId} data={data} isLoading={isLoading} />
    </TaskModal>
  );
};

export default CurriculumCompletionsModal;
