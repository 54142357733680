import { Edge, Node } from '@xyflow/react';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route } from 'type-route';

import { FlowchartControlProvider } from '../../../../contexts/FlowchartControlProvider';
import { useFlowchartEditorData } from '../../../../contexts/FlowchartEditorDataProvider';
import { useFlowchartHandlers } from '../../../../hooks/flowcharts/useFlowchartHandlers';
import useAutoSaving from '../../../../hooks/useAutoSaving';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { useUpdateFlowchartMutation } from '../../../../redux/services/resourceApis/flowcharts/flowchartsApi';
import {
  EditorFlowchartResponse,
  SerializedFlowchartJsonStorage,
} from '../../../../redux/services/resourceApis/flowcharts/types';
import { ContentType } from '../../../../types/ContentType';
import ElementTopNav from '../../curriculums/shared/ElementTopNav/ElementTopNav';
import { routes, useRoute } from '../../publicApplication/applicationRouter';
import CurriculumLockedOverlay from '../../shared/CurriculumLockedOverlay/CurriculumLockedOverlay';
import { serializeEdges, serializeNodes } from '../lib/serialize';
import { Canvas } from '../shared/BaseFlowchart/styles';
import Flowchart from '../shared/Flowchart';
import Footer from './Footer';

type Props = {
  data: EditorFlowchartResponse | undefined;
  isLoading: boolean;
};

const defaultReactFlowJson = {
  edges: [],
  nodes: [],
};

const Body = ({ data, isLoading: isDelayedLoading }: Props) => {
  const {
    params: { id: flowchartId },
  } = useRoute() as Route<typeof routes.flowchartEditor>;

  const autoSaveProps = useMemo(
    () => ({
      channel: 'FlowchartVersionsChannel',
      flowchart_id: String(flowchartId),
    }),
    [flowchartId]
  );

  const { showSaving } = useAutoSaving(autoSaveProps);

  const [updateFlowchart] = useUpdateFlowchartMutation();

  const updateTimeoutRef = useRef<number | null>(null);

  const [previousFlowchartData, setPreviousFlowchartData] =
    useState<SerializedFlowchartJsonStorage>(defaultReactFlowJson);
  const [currentFlowchartData, setCurrentFlowchartData] =
    useState<SerializedFlowchartJsonStorage>(defaultReactFlowJson);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isDataModified, setIsDataModified] = useState(false);

  const flowchartHandlers = useFlowchartHandlers();
  const { storeConnectionsData, edges, setEdges, nodes, setNodes } = flowchartHandlers;
  const { id: currentUserId } = useCurrentUser();

  const curriculum = data?.curriculum;
  const isLocked = curriculum?.locked || false;

  // Set initial data once when data is loaded
  useEffect(() => {
    if (data) {
      const { connections, content } = data;

      if (!isInitialized) setIsInitialized(true);
      if (!content) return;

      const { edges, nodes } = content;

      const serialized = {
        edges: serializeEdges(edges),
        nodes: serializeNodes(nodes),
      };

      setCurrentFlowchartData(serialized);
      setPreviousFlowchartData(serialized);
      setNodes(nodes);
      setEdges(edges);
      storeConnectionsData(connections);
    }
  }, [data, isInitialized, setEdges, setNodes, storeConnectionsData]);

  // Update the React Flow JSON object when nodes or edges change
  useEffect(() => {
    if (!isInitialized) return;

    setCurrentFlowchartData({
      edges: serializeEdges(edges),
      nodes: serializeNodes(nodes),
    });
  }, [edges, isInitialized, nodes]);

  // Check for modifications with the previous and current React Flow JSON objects
  useEffect(() => {
    const nodesDidChange = !isEqual(previousFlowchartData.nodes, currentFlowchartData.nodes);

    if (nodesDidChange) {
      setIsDataModified(true);
      return;
    }

    const edgesDidChange = !isEqual(previousFlowchartData.edges, currentFlowchartData.edges);

    setIsDataModified(edgesDidChange);
  }, [previousFlowchartData, currentFlowchartData]);

  const saveFlowchart = useCallback(
    async (flowchartData: SerializedFlowchartJsonStorage) => {
      if (!isInitialized) {
        return;
      }

      const { nodes, edges } = flowchartData;
      return updateFlowchart({
        id: flowchartId,
        content: {
          nodes: nodes as Node[],
          edges: edges as Edge[],
        },
        lastEditorId: currentUserId,
      });
    },
    [updateFlowchart, currentUserId, flowchartId, isInitialized]
  );

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    let didSave = false;
    const unloadHandler = () => {
      if (!didSave) {
        didSave = true;
        saveFlowchart(currentFlowchartData);
      }
    };

    const visibilityHandler = () => {
      if (document.visibilityState === 'hidden' && !didSave) {
        didSave = true;
        saveFlowchart(currentFlowchartData);
      }
    };

    // Force a save when the document is hidden or the window unloads
    // Depending on the platform/browser/scenario both or just one
    // will be triggered.
    document.addEventListener('visibilitychange', visibilityHandler);
    window.addEventListener('beforeunload', unloadHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityHandler);
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [saveFlowchart, currentFlowchartData, isInitialized]);

  const handleUpdate = useCallback(() => {
    // Clear any existing timeout to reset the timer
    if (updateTimeoutRef.current !== null) {
      clearTimeout(updateTimeoutRef.current);
    }

    // Set a new timeout to delay the update request
    updateTimeoutRef.current = setTimeout(async () => {
      if (currentFlowchartData) {
        await saveFlowchart(currentFlowchartData);

        // Update the previous data to the current data to ensure the next comparison is accurate
        setPreviousFlowchartData(currentFlowchartData);
        setIsDataModified(false);
      }
    }, 1500) as unknown as number;
  }, [currentFlowchartData, saveFlowchart]);

  useEffect(() => {
    if (isDataModified) handleUpdate();
  }, [isDataModified, handleUpdate]);

  const { isLoading, data: flowchartEditorData } = useFlowchartEditorData();

  return (
    <FlowchartControlProvider flowchartHandlers={flowchartHandlers} readonly={false}>
      <ElementTopNav
        contentType={ContentType.Flowchart}
        data={flowchartEditorData}
        editing
        isLoading={isLoading}
        showSaving={showSaving}
        threeDotMenuOptions={[]}
      />
      <Canvas>
        {isLocked && curriculum && (
          <CurriculumLockedOverlay curriculum={curriculum} elementType='Flowchart' />
        )}
        <Flowchart flowchartId={data?.id} isLoading={isDelayedLoading} />
      </Canvas>
      <Footer />
    </FlowchartControlProvider>
  );
};

export default Body;
