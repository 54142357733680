import React from 'react';
import styled, { css } from 'styled-components';

import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';

const StyledButton = styled.button(
  ({ theme: { constants, vars } }) => css`
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    flex-basis: calc(50% - 0.5rem);
    display: flex;
    align-items: center;
    gap: ${constants.spacerSm3};
    color: ${vars.textDefault};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    cursor: pointer;

    :hover,
    :active,
    :focus {
      background-color: ${vars.foundationBase2};
    }
  `
);

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Text = styled.span(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textDefault};
    font-weight: ${constants.fontMedium};
    ${fontMd1};
  `
);

const SubText = styled.span(
  ({ theme: { vars } }) => css`
    color: ${vars.textSubdued};
    ${fontSm4};
  `
);

const IconWrapper = styled.div`
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  text: string;
  subText?: string;
  onClick: () => void;
  id: string;
  icon: string;
};

const CreateContentButton = ({ icon, text, subText, onClick, id }: Props) => {
  return (
    <StyledButton id={`create-content-${id}`} onClick={onClick} type='button'>
      <IconWrapper>
        <img alt={text} src={icon} />
      </IconWrapper>
      <TextWrapper>
        <Text>{text}</Text>
        <SubText>{subText}</SubText>
      </TextWrapper>
    </StyledButton>
  );
};

export default CreateContentButton;
