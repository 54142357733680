import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import { TeammateModalProvider } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useJobsComplete, { JobCompleteStatus } from '../../../../../hooks/useJobsComplete';
import useWindowResize from '../../../../../hooks/useWindowResize';
import { SearchesEmptyState, SearchesEmptyStateDark } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { useGetUsersOutlineQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import { trainualApi } from '../../../../../redux/services/trainualService';
import { store } from '../../../../../redux/stores/store';
import Avatar from '../../../../design_system/display/avatar';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import { routes } from '../../../publicApplication/applicationRouter';
import { sortIcon } from '../../../reports/SortIcon';
import OutOfSeatsConfirmationModal from '../../OutOfSeatsConfirmationModal';
import ProfileRouterLink from '../../Profile/ProfileRouterLink';
import TableActionsHeader from './ActionsHeader/TableActionsHeader';
import Menu from './Body/Menu';
import TableBodyCell from './Body/TableBodyCell';
import BulkUserManagementMenu from './BulkUserManagementMenu/BulkUserManagementMenu';
import { columns } from './Column';
import TableHeader from './Header/TableHeader';
import LoadingTable from './LoadingTable';
import { getAppliedFiltersCount, reducer } from './reducer';
import {
  BodyRow,
  CheckboxWrapper,
  LeftAlignedDataStyled,
  NameCell,
  TableActionsWrapper,
  TableStyled,
  TableWrapper,
  UserNameWrapper,
} from './Styles';
import { Column, TableState } from './TableTypes';

const AvatarWrapper = styled(ProfileRouterLink)`
  padding-right: ${({ theme }) => theme.constants.spacerSm2};
`;

const t = initTranslations('users_table');

export const DEFAULT_STATUSES = [
  'active',
  'pending',
  'not invited',
  'invite sending',
  'temporary - archiving',
];

const generateEmptyStateText = (searchValue: string, appliedFiltersCount: number) => {
  const hasSearchValue = !!searchValue;
  const hasAppliedFilters = !!appliedFiltersCount;

  if (hasSearchValue && !hasAppliedFilters) {
    return {
      actionLinkText: t('empty_state.when_has_search_value.action_link_text'),
      actionText: t('empty_state.when_has_search_value.action_text'),
    };
  }

  if (hasAppliedFilters && !hasSearchValue) {
    return {
      actionLinkText: '',
      actionText: t('empty_state.when_applied_filters.action_text'),
    };
  }

  return {
    actionLinkText: '',
    actionText: t('empty_state.when_applied_filters_with_search_value.action_text'),
  };
};

const UsersTable = () => {
  const { isDesktop } = useWindowResize();
  const {
    accountPlan: plan,
    slug,
    totalUserSlotsCount,
    usedUserSlotsCount,
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const canReadBulkUserManagement = isDesktop && plan !== 'build';
  const cookiePath = `/${slug}`;
  const [cookies, setCookie] = useCookies([
    'outline_users_hidden_columns',
    'outline_users_selected_sort',
    'outline_users_filters_selected_permissions',
    'outline_users_filters_selected_groups',
    'outline_users_filters_selected_roles',
    'outline_users_filters_selected_teams',
    'outline_users_filters_selected_status',
  ]);

  const initialVisibleTableColumns = useMemo(() => {
    const columnsToRender = columns.map((column): Column => {
      if (
        cookies.outline_users_hidden_columns &&
        cookies.outline_users_hidden_columns.includes(column.columnName) &&
        column.hideable
      ) {
        return { ...column, display: 'hidden' };
      }
      if (
        cookies.outline_users_hidden_columns &&
        !cookies.outline_users_hidden_columns.includes(column.columnName) &&
        column.hideable &&
        column.display === 'hidden'
      ) {
        return { ...column, display: 'visible' };
      }
      return column;
    });

    return columnsToRender.filter(({ columnName }) => !['roles', 'teams'].includes(columnName));
  }, [cookies.outline_users_hidden_columns]);

  const cookieSelectedGroups = cookies.outline_users_filters_selected_groups || [];
  const cookieSelectedRoles = cookies.outline_users_filters_selected_roles || [];
  const cookieSelectedTeams = cookies.outline_users_filters_selected_teams || [];
  const cookieSelectedStatus = cookies.outline_users_filters_selected_status || [];
  const cookieSelectedPermissions = cookies.outline_users_filters_selected_permissions || [];

  const initialTableState: TableState = {
    appliedFilters: {
      permissions: cookieSelectedPermissions,
      groupIds: cookieSelectedGroups,
      roleIds: cookieSelectedRoles,
      status: !!cookieSelectedStatus.length ? cookieSelectedStatus : DEFAULT_STATUSES,
      teamIds: cookieSelectedTeams,
    },
    flyout: {
      currentFilterSelection: {
        permissions: cookieSelectedPermissions,
        groupIds: cookieSelectedGroups,
        roleIds: cookieSelectedRoles,
        teamIds: cookieSelectedTeams,
        status: cookieSelectedStatus,
      },
    },
    hiddenFields: [],
    page: 1,
    searchValue: '',
    selectedUsers: [],
    unselectedUsers: [],
    selectAllUsers: false,
    sortColumn: cookies.outline_users_selected_sort
      ? cookies.outline_users_selected_sort['column']
      : 'name',
    sortDirection: cookies.outline_users_selected_sort
      ? cookies.outline_users_selected_sort['dir']
      : 'asc',
    sortIcon: sortIcon(
      cookies.outline_users_selected_sort ? cookies.outline_users_selected_sort['dir'] : 'asc'
    ),
    tableColumns: initialVisibleTableColumns,
    toggledColumns: initialVisibleTableColumns,
  };

  const [importJobIds, setImportJobIds] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [tableState, tableDispatch] = useReducer(reducer, initialTableState);
  const debouncedSearchValue = useDebounce<string>(
    tableState.searchValue.length >= 2 ? tableState.searchValue : '',
    500
  );

  const appliedFiltersCount = getAppliedFiltersCount(tableState.appliedFilters);
  const emptyStateText = generateEmptyStateText(tableState.searchValue, appliedFiltersCount);

  // Uncheck if the screen size has changed
  useEffect(() => {
    if (!isDesktop) {
      tableDispatch({
        type: 'selectAllUsers',
        selectAllUsers: false,
      });
    }
  }, [isDesktop]);

  useEffect(() => {
    setCookie(
      'outline_users_selected_sort',
      JSON.stringify({ column: tableState.sortColumn, dir: tableState.sortDirection }),
      { path: cookiePath }
    );
  }, [setCookie, tableState.sortDirection, tableState.sortColumn, cookiePath]);

  const { data, isLoading, isFetching, isError, refetch } = useGetUsersOutlineQuery({
    sortColumn: tableState.sortColumn,
    sortDirection: tableState.sortDirection,
    searchValue: debouncedSearchValue,
    page: tableState.page,
    hiddenFields: tableState.tableColumns
      .filter(({ display }) => display === 'hidden')
      .map(({ columnName }) => columnName),
    appliedFilters: tableState.appliedFilters,
  });

  const { status: bulkUploadStatus } = useJobsComplete(importJobIds, refetch);

  useEffect(() => {
    if (
      bulkUploadStatus === JobCompleteStatus.error ||
      bulkUploadStatus === JobCompleteStatus.complete
    ) {
      // Even if we encounter an error while fetching the job status, we should still refetch the users
      // to provide the most updated information to the user in the table
      store.dispatch(
        trainualApi.util.invalidateTags([
          { type: 'User', id: 'CURRENT_USER' },
          { type: 'Account', id: 'CURRENT_ACCOUNT' },
          { type: 'User', id: 'LIST' },
          'User',
        ])
      );
    }
  }, [refetch, bulkUploadStatus, importJobIds]);

  if (isLoading) return <LoadingTable />;
  if (isError) return <div>{t('error')}</div>;
  if (!data) return <></>;

  const {
    users,
    all_user_ids: allUserIds,
    limit_value: limitValue,
    total_pages: totalPages,
    total_users_count: totalUsersCount,
  } = data;
  const userIdsWithoutUnselectedUsers = allUserIds.filter(
    (id) => !tableState.unselectedUsers.includes(id)
  );

  return (
    <TableActionsWrapper>
      <TableActionsHeader
        allUserIds={allUserIds}
        isFetching={isFetching}
        setImportJobIds={setImportJobIds}
        tableDispatch={tableDispatch}
        tableState={tableState}
      />
      {users.length ? (
        <>
          <TableWrapper>
            <TableStyled className='users-outline-table'>
              <TableHeader
                columns={tableState.tableColumns}
                dispatch={tableDispatch}
                tableState={tableState}
              />
              <tbody>
                {users.map((user) => {
                  return (
                    <BodyRow id={`users-tr-${user.id}`} key={`users-tr-${user.id}`}>
                      <LeftAlignedDataStyled className='left-table-data'>
                        <NameCell>
                          {canReadBulkUserManagement && (
                            <CheckboxWrapper>
                              <Checkbox
                                checked={
                                  // When we select all users we do not have access to users from other pages because of pagination,
                                  // so we need this logic to indicate unselected users and uncheck select all checkbox in such a case
                                  tableState.unselectedUsers.includes(user.id)
                                    ? false
                                    : tableState.selectAllUsers ||
                                      tableState.selectedUsers.includes(user.id)
                                }
                                id={`users-select-${user.id}`}
                                name={`users-select-${user.id}`}
                                onCheck={() => {
                                  if (
                                    data.total_users_count ===
                                    tableState.selectedUsers.length + 1
                                  ) {
                                    tableDispatch({
                                      type: 'selectAllUsers',
                                      selectAllUsers: true,
                                    });
                                  } else {
                                    tableDispatch({
                                      type: 'toggleUserSelect',
                                      userId: user.id,
                                    });
                                  }
                                }}
                              />
                            </CheckboxWrapper>
                          )}
                          <AvatarWrapper breadcrumb={routes.manageUsers({ slug })} id={user.id}>
                            <Avatar image={user.avatar} name={user.name} shape='circle' size='sm' />
                          </AvatarWrapper>
                          <UserNameWrapper
                            className='notranslate'
                            hiddenUser={user.hidden}
                            pseudoElementContent={t('hidden')}
                          >
                            <ProfileRouterLink
                              breadcrumb={routes.manageUsers({ slug })}
                              id={user.id}
                            >
                              <>{user.name}</>
                            </ProfileRouterLink>
                          </UserNameWrapper>
                        </NameCell>
                      </LeftAlignedDataStyled>
                      {tableState.tableColumns
                        .filter(({ display }) => display !== 'static' && display !== 'hidden')
                        .map(({ columnName, contentAlignment }) => (
                          <TableBodyCell
                            className={columnName === 'email' ? 'notranslate' : ''}
                            contentAlignment={contentAlignment}
                            data={
                              columnName === 'groups'
                                ? user.groups
                                    .filter(({ everyone }) => !everyone)
                                    .map(({ name }) => name)
                                : user[columnName]
                            }
                            id={`td-user-${user.id}-${columnName}`}
                            key={`td-user-${user.id}-${columnName}`}
                          />
                        ))}
                      <TeammateModalProvider userId={user.id}>
                        <Menu
                          changePageToPreviousIfLastDeleted={() => {
                            users.length === 1 &&
                              tableDispatch({
                                type: 'changePageToPrevious',
                              });
                          }}
                          user={user}
                        />
                      </TeammateModalProvider>
                    </BodyRow>
                  );
                })}
              </tbody>
            </TableStyled>
          </TableWrapper>
          <Pagination
            activePage={tableState.page}
            itemsCountPerPage={limitValue}
            onChange={(page) => {
              tableDispatch({ type: 'changePage', page });
            }}
            showPaginationDetails
            totalItemsCount={totalUsersCount}
            totalPages={totalPages}
          />
          {canReadBulkUserManagement &&
            (!!tableState.selectedUsers.length || tableState.selectAllUsers) && (
              <BulkUserManagementMenu
                onCloseRequest={() =>
                  tableDispatch({
                    type: 'selectAllUsers',
                    selectAllUsers: false,
                  })
                }
                selectedCount={
                  tableState.selectAllUsers
                    ? userIdsWithoutUnselectedUsers.length
                    : tableState.selectedUsers.length
                }
                selectedUsersIds={
                  tableState.selectAllUsers
                    ? userIdsWithoutUnselectedUsers
                    : tableState.selectedUsers
                }
              />
            )}
        </>
      ) : (
        <NoResults
          darkImage={SearchesEmptyStateDark}
          heading={t('no_results_heading')}
          iconWidth='45%'
          lightImage={SearchesEmptyState}
          subHeaderCta={{
            action: () => {
              tableDispatch({
                type: 'resetTableState',
                sortColumn: tableState.sortColumn,
                sortDirection: tableState.sortDirection,
              });
            },
            text: emptyStateText.actionLinkText,
          }}
          subHeaderText={emptyStateText.actionText}
        />
      )}
      {perUserPricingEnabled && (
        <OutOfSeatsConfirmationModal
          setShowModal={setShowConfirmationModal}
          showModal={showConfirmationModal}
          usedUserSlots={usedUserSlotsCount}
          userSlots={totalUserSlotsCount}
        />
      )}
    </TableActionsWrapper>
  );
};

export default UsersTable;
