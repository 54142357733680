import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateStepMutation } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import Step from '../../../../../types/Step';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import EmojiPicker from '../../../shared/EmojiPicker';
import { Emoji, StepEmojiWrapper } from '../../shared/styles';

interface Props {
  step: Step;
}

const t = initTranslations('outline_content');

const StepEmojiPicker = ({ step }: Props) => {
  const icon = step.emoji ? { emoji: step.emoji } : {};
  const [updateStep, result] = useUpdateStepMutation();
  const {
    step: { singular: stepTerm },
  } = useAccountTerminology();

  const menuId: RegisteredMenuId = `step-emoji-picker-${step.id}`;
  const { isMenuOpen, closeMenu, handleMenuClick } = useActiveMenuHandler({ menuId });

  const emojiSelected = useCallback(
    (emoji: BaseEmoji) => {
      updateStep({ emoji: emoji.native, id: step.id, courseId: step.courseId });
      closeMenu();
    },
    [closeMenu, step.courseId, step.id, updateStep]
  );

  const clearEmoji = useCallback(() => {
    updateStep({ ...step, emoji: null, courseId: step.courseId });
  }, [step, updateStep]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('emoji_error'),
  });

  return (
    <EmojiPicker
      ariaLabel={t('edit_step_emoji_aria', { stepTerm })}
      emojiPresent={step.emoji}
      isOpen={isMenuOpen}
      onClick={handleMenuClick}
      onClickOutside={(e) => {
        if (!(e.target as Element).classList.contains('step-emoji-picker-trigger')) {
          closeMenu();
        }
      }}
      onEmojiSelect={emojiSelected}
      removeButtonAction={clearEmoji}
      trigger={
        <StepEmojiWrapper className='step-emoji-picker-trigger' onClick={handleMenuClick}>
          {step.emoji ? (
            <Emoji>{step.emoji}</Emoji>
          ) : (
            <Icon className='default-emoji' name='list-ol' size='2xs' weight='light' />
          )}
        </StepEmojiWrapper>
      }
      {...icon}
    />
  );
};

export default StepEmojiPicker;
