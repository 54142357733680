import React from 'react';

import useAddonInfoCardIcon from '../../../../../../../hooks/billing/useAddonInfoCardIcon';
import { AddonInfoCardArrow } from '../../../../../../../lib/gcsImages';
import { getFormattedPrice } from '../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../lib/initTranslations';
import { AddonInfo } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { Addon } from '../../../../../../../types/Addon';
import {
  AddonBillingPeriod,
  AddonFeatureDescription,
  AddonFeatureDescriptionWrapper,
  AddonFeatureIcon,
  AddonFeatureTitle,
  AddonFeaturesWrapper,
  AddonFooterWrapper,
  AddonInfoCardArrowImg,
  AddonInfoCardContainer,
  AddonInfoCardFeaturesList,
  AddonInfoCardFeaturesWrapper,
  AddonInfoCardLogo,
  AddonInfoCardTitle,
  AddonInfoCardWrapper,
  AddonPrice,
} from './styles';

type AddonInfoCardProps = {
  addonName: Addon;
  price: number;
} & Pick<AddonInfo, 'features' | 'marketingName' | 'secondaryColor'>;

const t = initTranslations('simple_pricing.addon_overlay_content.info_card');

const AddonInfoCard = ({
  features,
  marketingName,
  secondaryColor,
  price,
  addonName,
}: AddonInfoCardProps) => {
  const formattedPrice = getFormattedPrice(price);
  const { altText: addonLogoAltText, src: addonIconSrc } = useAddonInfoCardIcon(addonName);

  return (
    <AddonInfoCardWrapper>
      <AddonInfoCardArrowImg alt={t('images_alt.card_arrow')} src={AddonInfoCardArrow} />
      <AddonInfoCardContainer>
        <AddonInfoCardLogo alt={addonLogoAltText} src={addonIconSrc} />
        <AddonInfoCardFeaturesWrapper>
          <AddonInfoCardTitle>{marketingName}</AddonInfoCardTitle>
          <AddonInfoCardFeaturesList>
            {features.map((feature) => (
              <AddonFeaturesWrapper key={feature.title}>
                <AddonFeatureIcon
                  addonSecondaryColor={secondaryColor}
                  name='circle-check'
                  size='sm'
                  weight='regular'
                />
                <AddonFeatureDescriptionWrapper>
                  <AddonFeatureTitle text={feature.title} />
                  <AddonFeatureDescription text={feature.description} />
                </AddonFeatureDescriptionWrapper>
              </AddonFeaturesWrapper>
            ))}
          </AddonInfoCardFeaturesList>
        </AddonInfoCardFeaturesWrapper>
        <AddonFooterWrapper>
          <AddonBillingPeriod text={t('flat_rate.month')} />
          <AddonPrice text={formattedPrice} />
        </AddonFooterWrapper>
      </AddonInfoCardContainer>
    </AddonInfoCardWrapper>
  );
};

export default AddonInfoCard;
