import React from 'react';

import { VideoResponseEmptyStateImage } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import {
  EmptyStateDescription,
  EmptyStateImage,
} from '../../SurveyConsumption/OutlineContent/SurveyQuestionCard/VideoResponseForm/LoomVideoResponseEditor/Toolbar/styles';
import { InfoWrapper } from './styles';

const t = initTranslations('survey_edit.question.video_response_form');

const VideoResponseForm = () => {
  return (
    <InfoWrapper>
      <EmptyStateImage src={VideoResponseEmptyStateImage} />
      <EmptyStateDescription>{t('title')}</EmptyStateDescription>
    </InfoWrapper>
  );
};

export default VideoResponseForm;
