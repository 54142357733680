import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useGetCurriculumElementsForAdminQuery } from '../../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import TaskModal from '../../../../../../design_system/overlays/modals/TaskModal';
import StatusDropdown from '../../../../../editor/components/StatusDropdown/StatusDropdown';
import DetailedTitle from '../../../../../shared/DetailedTitle/DetailedTitle';

const TitleWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${constants.spacerSm3};
  `
);

const SubjectWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd2};
  `
);

const CurriculumElementWrapper = styled.div(
  ({ theme: { constants } }) =>
    css`
      display: flex;
      flex-direction: column;
      gap: ${constants.spacerSm3};
    `
);

export type Props = {
  id: number;
  onClose: () => void;
  primaryButtonTask: () => void;
  secondaryButtonAction: () => void;
  forceFinishToPublish: boolean;
  subject: { title: string; emoji: string | null };
};

const t = initTranslations('curriculums.publish_modal');

const PublishModal = ({
  id,
  onClose,
  primaryButtonTask,
  secondaryButtonAction,
  forceFinishToPublish,
  subject,
}: Props) => {
  const { isLoading, data } = useGetCurriculumElementsForAdminQuery(id);
  const {
    topic: { plural: topicTermPlural, singular: topicTermSingular },
  } = useAccountTerminology();

  return (
    <TaskModal
      heapModalName='publish-curriculum-modal'
      onCloseRequest={onClose}
      primaryButtonTask={primaryButtonTask}
      primaryButtonText={t('finish_all_and_publish')}
      processing={isLoading}
      secondaryButtonText={t('close')}
      subtitle={t('subtitle', {
        topic: topicTermSingular.toLowerCase(),
        topics: topicTermPlural.toLowerCase(),
      })}
      tertiaryButton={{
        text: t('publish_as_is'),
        task: secondaryButtonAction,
        isDisabled: forceFinishToPublish,
      }}
      title={t('title')}
    >
      <div>
        <SubjectWrapper>
          <DetailedTitle
            emoji={subject.emoji}
            fontSize='md1'
            fontWeight='semibold'
            title={subject.title}
          />
          {data?.curriculumElements && (
            <CurriculumElementWrapper>
              {data.curriculumElements.map((curriculumElement) => {
                const { title, status, emoji } = curriculumElement.element;

                return (
                  <TitleWrapper
                    id={`curriculum-element-${curriculumElement.id}`}
                    key={`curriculum-element-${curriculumElement.id}`}
                  >
                    <DetailedTitle
                      emoji={emoji}
                      fontSize='sm5'
                      fontWeight='regular'
                      title={title}
                      truncate='oneLine'
                    />
                    <StatusDropdown
                      curriculumId={id}
                      element={{
                        curriculumElementId: curriculumElement.id,
                        status,
                      }}
                    />
                  </TitleWrapper>
                );
              })}
            </CurriculumElementWrapper>
          )}
        </SubjectWrapper>
      </div>
    </TaskModal>
  );
};

export default PublishModal;
