import { Formik } from 'formik';
import React from 'react';

import {
  DashedLineImage,
  HomePageImage,
  HomePageTabletImage,
  LogoWithTitleImage,
  LogoWithTitleTabletImage,
} from './../../../../lib/gcsImages';
import usePublicConfigs from '../../../../hooks/usePublicConfigs';
import useWindowResize from '../../../../hooks/useWindowResize';
import { getSignUpSchema } from '../../../../lib/validation_schemas/authorization/signUp';
import { multiErrorValidatorForSchema } from '../../../../lib/validation_schemas/multiErrorPassword';
import SignUpPageForm from './SignUpPageForm/SignUpPageForm';
import { FormValues } from './SignUpPageForm/types';
import {
  BottomSvgImageWrapper,
  CenterSvgImageWrapper,
  FormSection,
  GlobalStyle,
  InfoSection,
  SignUpPageContainer,
  SvgImage,
  TopSvgImageWrapper,
} from './styles';
import { SignUpPageProps } from './types';

const SignUpPage = ({ route }: SignUpPageProps) => {
  const { configs } = usePublicConfigs();
  const { isTablet, isMobile } = useWindowResize();
  const multiErrorFormFields = ['password'] as (keyof FormValues)[];
  const updatedPasswordValidationPreviewEnabled = !!configs['UPDATED_PASSWORD_VALIDATION_PREVIEW'];
  const multiErrorFields = updatedPasswordValidationPreviewEnabled ? multiErrorFormFields : [];
  const validationSchema = getSignUpSchema(updatedPasswordValidationPreviewEnabled);

  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  return (
    <SignUpPageContainer id='sign-up-page'>
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        onSubmit={() => {
          /* Submit logic handled in `/SignUpPageForm.tsx` */
        }}
        validate={multiErrorValidatorForSchema<FormValues>(validationSchema, multiErrorFields)}
      >
        <FormSection>
          <SignUpPageForm route={route} />
        </FormSection>
      </Formik>
      <InfoSection>
        <TopSvgImageWrapper>
          <SvgImage src={isTablet || isMobile ? LogoWithTitleTabletImage : LogoWithTitleImage} />
        </TopSvgImageWrapper>
        <CenterSvgImageWrapper>
          <SvgImage src={isTablet || isMobile ? HomePageTabletImage : HomePageImage} />
        </CenterSvgImageWrapper>
        {!isTablet && !isMobile && (
          <BottomSvgImageWrapper>
            <SvgImage src={DashedLineImage} />
          </BottomSvgImageWrapper>
        )}
      </InfoSection>
      <GlobalStyle />
    </SignUpPageContainer>
  );
};

export default SignUpPage;
