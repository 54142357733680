import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../../../hooks/useCurrentUserAbilities';
import { RegisteredMenuId } from '../../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useGetModalUsersQuery } from '../../../../../../../redux/services/resourceApis/users/usersApi';
import { CurriculumOwner } from '../../../../../../../types';
import Flyout from '../../../../../../design_system/overlays/flyout';
import SearchField from '../../../../../../design_system/Triage/fields/SearchField';
import useActiveMenuHandler from '../../../../../../design_system/useActiveMenuHandler';
import OwnedByButton from './OwnedByButton';
import OwnerMenu from './OwnerMenu';

const OwnedByFlyoutContainer = styled.div`
  width: 19.5rem;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const StyledFlyout = styled(Flyout)`
  min-height: 0;
  max-height: 24.125rem;
  overflow-y: scroll;
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  #owned-by-flyout {
    width: auto;
    padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  }

  .flyout-content {
    padding: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const t = initTranslations('curriculums.owner');

const OwnedByFlyoutId: RegisteredMenuId = 'owned-by-flyout';

export type Props = {
  curriculumId: number;
  owner: CurriculumOwner | null;
};

const OwnedByFlyout = ({ curriculumId, owner }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const ability = useCurrentUserAbilities();
  const { id } = useCurrentUser();
  const isCurrentUserOwner = useMemo(() => owner?.id === id, [id, owner]);
  const canEditOwner = ability.can('update', 'CurriculumAdminPlus') || isCurrentUserOwner;
  const { isMenuOpen, closeMenu } = useActiveMenuHandler({ menuId: OwnedByFlyoutId });
  const { data } = useGetModalUsersQuery();

  const users = useMemo(() => {
    if (!data) return [];

    let formattedUsers = [...data];
    const currentUserIndex = data.findIndex((user) => user.id === id);
    formattedUsers = formattedUsers.filter((user) => user.id !== id);
    formattedUsers.unshift(data[currentUserIndex]);

    return formattedUsers;
  }, [data, id]);

  const searchResult = useMemo(() => {
    return users?.filter(({ name }) => {
      const parsedSearchValue = searchValue.toLowerCase();

      return name.toLowerCase().includes(parsedSearchValue);
    });
  }, [searchValue, users]);

  return (
    <StyledFlyout
      className='owned-by-flyout'
      hideHeader
      id={OwnedByFlyoutId}
      menuStyling={{ inset: '92px auto auto 0', transform: 'translateY(0)' }}
      placement='bottom-start'
      triggerButton={
        <OwnedByButton
          active={isMenuOpen}
          ariaLabel={canEditOwner ? t('change_owner') : t('no_owner')}
          className='flyout-trigger'
          disabled={!canEditOwner}
          id='owned-by-button'
          owner={owner}
        />
      }
    >
      <OwnedByFlyoutContainer id='owned-by-flyout'>
        <SearchField
          placeholder={t('placeholder')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <OwnerMenu
          close={closeMenu}
          curriculumId={curriculumId}
          ownerId={owner?.id}
          setSearchValue={setSearchValue}
          users={searchResult}
        />
      </OwnedByFlyoutContainer>
    </StyledFlyout>
  );
};

export default OwnedByFlyout;
