import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.content_page');

const PolicySectorToggle = ({ isDisabled }: { isDisabled: boolean }) => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.policySector}
        className='policy-sector'
        disabled={isDisabled}
        handleToggle={() => setFieldValue('policySector', !values.policySector)}
        id='enable-policy-sector'
        name='policySector'
        tooltipId={isDisabled ? 'disabled-policy-toggle' : ''}
        tooltipText={isDisabled ? t('sector_toggle_disabled') : ''}
      />
      <ToggleInfoSection>
        <ToggleInfoWrapper>
          <FormSectionTitle>{t('policy_toggle_title')}</FormSectionTitle>
          <FormSectionDescription>{t('policy_toggle_description')}</FormSectionDescription>
        </ToggleInfoWrapper>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default PolicySectorToggle;
