import styled from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Scrollbar from '../../../styled/Scrollbar';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { ContentScrollbar } from '../shared/styles';

const FOOTER_HEIGHT = '4.25rem';
const HEADER_HEIGHT = '4rem';
const SIDEBAR_WIDTH = '16.875rem';

export const Button = styled(DefaultButton)`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
`;

// allow horizontal scroll on Body (Preview and Sidebar) while keeping the Header and Footer fixed
export const Body = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  overflow-x: auto;
  ${ContentScrollbar};
`;

// allows children to expand to full height
export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${FOOTER_HEIGHT};
`;

export const Header = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
  border-bottom: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// the grey background around the Content
export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} 5rem 0`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  min-width: 40rem;
  overflow-y: scroll;
  ${ContentScrollbar};
`;

export const Sidebar = styled.div`
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd3}`};
  min-width: ${SIDEBAR_WIDTH};
  overflow-y: auto;
  ${Scrollbar};
`;

export const ModalBody = styled.div`
  ${fontSm5};
`;

export const LinkWrapper = styled.div`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 ${constants.spacerSm3} 0`};
`;

export const ModalCTA = styled.p`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const Subtitle = styled.p`
  width: ${({ theme: { constants } }) => `calc(${SIDEBAR_WIDTH} - ${constants.spacerLg1})`};
  color: ${({ theme: { vars } }) => vars.textSubdued};

  ${fontSm5};
`;

export const VersionGroup = styled.p`
  padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textDisabled};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm4};
`;

export const Version = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;

  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  padding-right: 0;
  width: ${({ theme: { constants } }) => `calc(${SIDEBAR_WIDTH} - ${constants.spacerLg1})`};

  cursor: pointer;
  background-color: ${({ isSelected, theme: { vars } }) =>
    isSelected ? vars.accentSubdued1 : 'transparent'};
  border: ${({ isSelected, theme: { constants, vars } }) =>
    isSelected ? `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}` : 'none'};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

export const VersionName = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-style: italic;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
  ${fontSm4};
`;

export const CurrentVersion = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm4};
`;
