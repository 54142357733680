import styled from 'styled-components';

import { MainSectionContent } from '../../../../shared/FullScreenOverlay/styles';

export const StyledMainSectionContent = styled(MainSectionContent)`
  max-width: 37.5rem;
  gap: 0;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg1} 0`};
`;
