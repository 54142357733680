import React, { useMemo, useState } from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../../hooks/useDisplayFlashOnResponse';
import useUserPermissionSettings from '../../../../../../../hooks/users/useUserPermissionSettings';
import initTranslations from '../../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../../redux/errors/helpers';
import { Permission, PermissionKeys } from '../../../../../../../types/Permission';
import Avatar from '../../../../../../design_system/display/avatar';
import TaskModal, {
  TaskModalProps,
} from '../../../../../../design_system/overlays/modals/TaskModal';
import SingleSelect from '../../../../../../design_system/Triage/fields/SingleSelectField/SingleSelectField';
import Loader from '../../../../../../design_system/Triage/Loader';
import { BulkArchiveUnarchiveModalProps } from '../types';
import {
  RestrictionWrapper,
  SingleSelectWrapper,
  UserAvatar,
  UserName,
  UserWrapper,
  UsersWrapper,
} from './styles';

const BulkUserUpdateModal = ({
  onClose,
  selectedUsersIds,
  onSuccess,
  bulkUpdate,
  bulkUpdateResult,
  fetchRestrictedError,
  fetchRestrictedResult,
  data,
  type,
}: BulkArchiveUnarchiveModalProps) => {
  // Replace the hyphen with an underscore to follow the yaml keys format
  // E.g. update-permissions to update_permissions
  const t = initTranslations(
    `users_table.bulk_user_management_menu.bulk_${type.replace(/-/g, '_')}_modal`
  );
  const { accountPlan } = useCurrentAccount();
  const [permission, setPermission] = useState<PermissionKeys | null>(null);

  const { permissionSettings, isHigherPermission } = useUserPermissionSettings();
  const permissions = Object.keys(permissionSettings);
  const permissionOptions = permissions.map((key: PermissionKeys) => {
    return {
      label: Permission[key],
      value: key,
      searchableTerm: Permission[key],
      hasMetaValue: true,
      metaValue: t(`meta_values.${key}`),
      visible: !(
        isHigherPermission(key) ||
        (accountPlan === 'build' && ['author', 'contributor'].includes(key))
      ),
    };
  });
  const isUpdatePermissionsModal = type === 'update-permissions';

  useDisplayFlashOnResponse({
    result: fetchRestrictedResult,
    errorMessage: messageFromError(fetchRestrictedError)?.join(', '),
  });

  useDisplayFlashOnResponse({
    result: bulkUpdateResult,
    successMessage: t('success_message'),
    successFunction: onSuccess,
    errorMessage: messageFromError(bulkUpdateResult.error)?.join(', '),
  });

  const restrictedUsersIds = useMemo(() => {
    if (data) {
      return data.users.map((user) => user.id);
    }
    return [];
  }, [data]);

  let taskModalArgs: TaskModalProps = {
    title: t('title'),
    onCloseRequest: onClose,
    secondaryButtonText: t('cancel'),
    heapModalName: `bulk-${type}-modal`,
    processing: bulkUpdateResult.isLoading,
    scrollInsideBody: true,
    desktopSize: 'md',
  };

  if (data?.users?.length) {
    taskModalArgs = {
      ...taskModalArgs,
      primaryButtonText: t('cta'),
      primaryButtonTask: () => {
        bulkUpdate({ userIds: restrictedUsersIds, permission });
      },
      isDisabled: isUpdatePermissionsModal && !permission,
    };
  }

  return (
    <TaskModal {...taskModalArgs}>
      {fetchRestrictedResult.isLoading ? (
        <Loader />
      ) : (
        <>
          {isUpdatePermissionsModal && (
            <SingleSelectWrapper>
              <SingleSelect
                className='update-permissions-select'
                defaultValue=''
                disabled={!data?.users?.length}
                fieldLabelText={t('permission_level')}
                hideSelectedOptions={false}
                isSearchable={false}
                menuPosition='fixed'
                name='permission'
                onNonDefaultSelected={(permission: PermissionKeys | null) =>
                  setPermission(permission)
                }
                options={permissionOptions.filter((permission) => permission.visible)}
                value={permission}
              />
            </SingleSelectWrapper>
          )}
          {data?.users?.length ? (
            <UsersWrapper className='restricted-users'>
              {data.users.length !== selectedUsersIds.length && (
                <RestrictionWrapper>{t('restriction_message')}</RestrictionWrapper>
              )}
              {!(isUpdatePermissionsModal && data.users.length === selectedUsersIds.length) &&
                data.users.map((user) => {
                  return (
                    <UserWrapper key={`user-${user.id}`}>
                      <UserAvatar>
                        <Avatar
                          className='avatar'
                          image={user.avatar}
                          name={user.name}
                          shape='circle'
                          size='sm'
                        />
                      </UserAvatar>
                      <UserName className='notranslate'>{user.name}</UserName>
                    </UserWrapper>
                  );
                })}
            </UsersWrapper>
          ) : (
            <RestrictionWrapper>{t('restriction_no_users')}</RestrictionWrapper>
          )}
        </>
      )}
    </TaskModal>
  );
};

export default BulkUserUpdateModal;
