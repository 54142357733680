import React, { useState } from 'react';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { ThreeDotLoaderGif, UsersEmptyState } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import {
  resetSelectedUserIds,
  setLastAddedColumnableId,
} from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useDispatchSetShowModal } from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useCreateBoardColumnsMutation } from '../../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import { useGetUserSelectionModalDataQuery } from '../../../../../redux/services/resourceApis/delegationPlanner/delegationPlannerApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import TaskModal, { TaskModalProps } from '../../../../design_system/overlays/modals/TaskModal';
import NoResults from '../../../../design_system/Triage/NoResults';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import useAutoSaveStatusUpdater from '../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import {
  HeaderActionsContainer,
  StyledLoaderWrapper,
  StyledLoadingImage,
} from '../SelectGroupModal/styles';
import { SelectColumnEntityModalProps } from '../types';
import { StyledSearchField } from './styles';
import UsersSelectList from './UsersSelectList';

const t = initTranslations('delegation_planner.modals.select_column_entity_modal');

const SelectUserModal = ({
  selectedEntityIds: initiallySelectedUserIds,
}: SelectColumnEntityModalProps) => {
  const {
    params: { id: boardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();
  const dispatchShowModal = useDispatchSetShowModal();
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce<string>(searchValue.length >= 2 ? searchValue : '', 500);
  const { data, isLoading: isModalDataLoading } = useGetUserSelectionModalDataQuery({
    searchTerm: debouncedSearchValue,
  });
  const { selectedUserIds } = useAppSelector((state) => state.delegationPlanner);
  const dispatch = useAppDispatch();
  const [addBoardColumns, result] = useCreateBoardColumnsMutation();
  const { isLoading: isColumnsCreating, isSuccess: isColumnsCreated } = result;

  useAutoSaveStatusUpdater([
    { isSaving: isColumnsCreating, isSavedSuccessfully: isColumnsCreated },
  ]);

  const resetSelectUserModal = () => {
    dispatchShowModal('delegationSelectUserModal', false);
    dispatch(setLastAddedColumnableId(selectedUserIds[selectedUserIds.length - 1]));
    dispatch(resetSelectedUserIds());
  };

  const handleAddUser = () => {
    addBoardColumns({
      boardId,
      columnableIds: selectedUserIds,
      columnableType: 'User',
    });
  };

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_message'),
    successFunction: resetSelectUserModal,
  });

  const headerChildren = (
    <HeaderActionsContainer>
      <StyledSearchField
        autoFocus
        placeholder={t('search_entities', { entities: 'people' })}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </HeaderActionsContainer>
  );

  const isAddButtonDisabled: boolean =
    isModalDataLoading || isColumnsCreating || selectedUserIds.length === 0;
  const taskModalArgs: TaskModalProps = {
    title: t('title', { entities: 'people' }),
    isDisabled: isAddButtonDisabled,
    subtitle: t('subtitle', {
      entities: 'people',
      responsibilities: responsibilityPlural.toLowerCase(),
    }),
    processing: false,
    onCloseRequest: resetSelectUserModal,
    primaryButtonText: t('add_entity', {
      entity: selectedUserIds.length > 1 ? 'people' : 'person',
    }),
    primaryButtonTask: handleAddUser,
    heapModalName: 'add-delegation-user-card-modal',
    headerChildren,
  };

  return (
    <TaskModal {...taskModalArgs} desktopSize='lg'>
      {data?.users.length ? (
        <UsersSelectList initiallySelectedUserIds={initiallySelectedUserIds} users={data.users} />
      ) : isModalDataLoading ? (
        <StyledLoaderWrapper>
          <StyledLoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
        </StyledLoaderWrapper>
      ) : (
        <NoResults
          darkImage={UsersEmptyState}
          heading={t('empty_state_text', { entities: 'persons' })}
          iconWidth='45%'
          lightImage={UsersEmptyState}
          minHeight='15rem'
          showBorder={false}
        />
      )}
    </TaskModal>
  );
};

export default SelectUserModal;
