import { Formik } from 'formik';
import React from 'react';

import { useOnboarding } from '../../../../../contexts/OnboardingContext';
import { getCustomizeYourExperienceStepSchema } from '../../../../../lib/validation_schemas/onboarding/customizeYourExperienceStep';
import CustomizeYourExperienceStepForm from './CustomizeYourExperienceStepForm';
import { FormWrapper } from './styles';
import { CustomizeYourExperienceStepData } from './types';

const CustomizeYourExperienceStep = () => {
  const { formData, setFormData, sliderRef } = useOnboarding();

  const initialValues = {
    name: formData.name,
    user_title: formData.user_title,
    industry: formData.industry,
    company_size: formData.company_size,
  };

  const onSubmit = (values: CustomizeYourExperienceStepData) => {
    setFormData({ ...formData, ...values });
    sliderRef?.current?.slickNext();
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={getCustomizeYourExperienceStepSchema()}
      >
        <CustomizeYourExperienceStepForm />
      </Formik>
    </FormWrapper>
  );
};

export default CustomizeYourExperienceStep;
