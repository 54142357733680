import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm5 } from '../../../../styled/TypeSystem';

const TimeWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    gap: ${constants.spacerSm3};
    color: ${vars.textDefault};
    padding: ${constants.spacerSm2} 0;
    flex-shrink: 0;
    cursor: default;

    ${fontSm5};
  `
);

const t = initTranslations('curriculums.edit.settings.time_estimate');

type Props = {
  id: number;
  minutesToReadFormatted: string;
};

const TimeEstimatePill = ({ id, minutesToReadFormatted }: Props) => {
  return (
    <TimeWrapper id={`minutes-to-read-curriculum-${id}`}>
      <Icon name='clock' size='sm' />
      {t('read', { time: minutesToReadFormatted })}
    </TimeWrapper>
  );
};

export default TimeEstimatePill;
