import React, { ReactNode, createContext, useContext } from 'react';

interface Props {
  stepId: number;
}

const StepIdContext = createContext<Props | undefined>(undefined);

export const StepIdProvider = ({ stepId, children }: { stepId: number; children: ReactNode }) => {
  return <StepIdContext.Provider value={{ stepId }}>{children}</StepIdContext.Provider>;
};

export const useStepId = () => {
  const context = useContext(StepIdContext);
  if (!context) {
    throw new Error('useStepId must be used within a StepIdProvider');
  }
  return context.stepId;
};
