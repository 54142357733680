import React from 'react';
import styled from 'styled-components';

import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Loader from '../../../../design_system/Triage/Loader';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import { CenterTextContainer, CoverPhoto } from './styles';
import TopicHeaderThreeDotMenu, { TOPIC_HEADER_THREE_DOT_MENU_ID } from './TopicHeaderThreeDotMenu';

const StyledCoverPhoto = styled(CoverPhoto)<{ isLoading: boolean | undefined }>`
  display: block;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;

const CoverImageContainer = styled.div``;

const ThreeDotWrapper = styled.div<{ remainVisible: boolean }>`
  display: flex;
  opacity: ${({ remainVisible }) => (remainVisible ? 1 : 0)};
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface1};
  transition: opacity 0.2s ease-in-out;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  ${CoverImageContainer}:hover & {
    opacity: 1;
  }
`;

const t = initTranslations('editor.topic.header');

type LoaderProps = { isLoading?: never; message?: never } | { isLoading: boolean; message: string };

type Props = {
  coverImage: string;
  menuId: RegisteredMenuId;
  removeCover: () => void;
} & LoaderProps;

const CoverImage = ({ coverImage, isLoading, message, menuId, removeCover }: Props) => {
  const { isMenuOpen } = useActiveMenuHandler({
    menuId: TOPIC_HEADER_THREE_DOT_MENU_ID,
  });

  const {
    topic: { singular: topicSingular },
  } = useAccountTerminology();

  return (
    <CoverImageContainer>
      <StyledCoverPhoto
        alt={t('cover_alt_text', { topic: topicSingular })}
        isLoading={isLoading}
        src={coverImage}
      />
      {isLoading && (
        <CenterTextContainer>
          <span>{message}</span>
          <Loader />
        </CenterTextContainer>
      )}
      <ThreeDotWrapper data-testid='three-dot-wrapper' remainVisible={isMenuOpen}>
        <TopicHeaderThreeDotMenu flyoutMenuId={menuId} removeCoverImage={removeCover} />
      </ThreeDotWrapper>
    </CoverImageContainer>
  );
};

export default CoverImage;
