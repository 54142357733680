import { IconName } from '@fortawesome/fontawesome-svg-core';
import { PaletteOption } from 'saguaro';

interface TextAlign {
  align: string;
  icon: IconName;
}

export const CUSTOM_TERMINOLOGY_MAX_CHARACTERS = 15;

export const DEFAULT_ACCENT_COLORS = [
  '#006911',
  '#005F65',
  '#0F4DC5',
  '#3E02B1',
  '#95076D',
  '#AB011F',
  '#6A8000',
  '#358270',
  '#116FE8',
  '#6A28EA',
  '#DF077A',
  '#E02D00',
];

export const OFFERED_ACCENT_PALETTES = [
  'purple',
  'magenta',
  'green',
  'red',
  'blue',
  'orange',
] as PaletteOption[];

export const TEXT_ALIGN: TextAlign[] = [
  { align: 'left', icon: 'align-left' },
  { align: 'center', icon: 'align-center' },
  { align: 'right', icon: 'align-right' },
];

export const HORIZONTAL_LINE_WIDTH = ['1px', '2px', '3px', '4px'];
