import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.people_settings');

const DisplayPhoneNumbersToggle = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.enableProfilePhoneNumber}
        className='enable-profile-phone-number-setting'
        handleToggle={() =>
          setFieldValue('enableProfilePhoneNumber', !values.enableProfilePhoneNumber)
        }
        id='enable-profile-phone-number'
        name='enableProfilePhoneNumber'
      />
      <ToggleInfoWrapper>
        <FormSectionTitle>{t('enable_profile_phone_number_title')}</FormSectionTitle>
        <FormSectionDescription>
          {t('enable_profile_phone_number_description')}
        </FormSectionDescription>
      </ToggleInfoWrapper>
    </ToggleSettingContainer>
  );
};

export default DisplayPhoneNumbersToggle;
