import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import translationClassname from '../../../../../lib/translationClassname';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Link from '../../../../design_system/Link/Link';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../styled/TypeSystem';

const TitleWrapper = styled.div<{ isLocked?: boolean }>`
  align-self: center;
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  ${TruncatedText({ noWrap: 'nowrap' })};
`;

const Title = styled(Link)<{ isLocked?: boolean }>`
  user-select: none;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: ${(props) => (props.isLocked ? 'default' : 'pointer')};
  text-decoration: none;

  :hover {
    color: ${({ theme: { vars } }) => vars.textDefault};
    text-decoration: ${(props) => (props.isLocked ? 'none' : 'underline')};
  }
  ${fontSm5};
  ${TruncatedText({ noWrap: 'nowrap' })};
`;

export type TitleProps = {
  route: string;
  title: string;
  isLocked?: boolean;
  editable?: boolean;
  disableTranslation?: boolean;
};

const t = initTranslations('curriculums.tooltips');

const ElementTitle = ({ route, title, isLocked, editable, disableTranslation }: TitleProps) => {
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();

  return (
    <>
      <TitleWrapper isLocked={isLocked}>
        {isLocked && editable && (
          <Tooltip
            id='locked-tooltip'
            text={t('subject_locked', { subject: subjectTermSingular })}
          />
        )}
        <Title
          className={translationClassname(disableTranslation)}
          dataFor={isLocked ? 'locked-tooltip' : undefined}
          disabled={isLocked}
          href={route}
          isLocked={isLocked}
          text={title}
        />
      </TitleWrapper>
    </>
  );
};

export default ElementTitle;
