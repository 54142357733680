import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../../contexts/EditorToolbarContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { usePostStepAttachmentMutation } from '../../../../../../redux/services/axiosService';
import { useDeleteStepAttachmentMutation } from '../../../../../../redux/services/resourceApis/stepAttachments/stepAttachmentsApi';
import { EmbedlyResponse } from '../../../../../../types/EmbedlyResponse';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import Loader from '../../../../../design_system/Triage/Loader';
import useActiveMenuHandler from '../../../../../design_system/useActiveMenuHandler';
import FileInputDropzone from '../../../components/FileInputDropzone/FileInputDropzone';
import { htmlEncodedContent } from '../../../lib/htmlEncodedContent';
import { insertEditorContent } from '../../../lib/insertEditorContent';
import { LoaderWrapper } from '../../../shared/styles';
import FileToolbarButton from '../../../toolbar/buttons/components/insert/FileToolbarButton';
import { StyledFlyout } from '../styles';
import { setFormData } from '../utils';
import PdfIframePreview from './PdfIframePreview';

const t = initTranslations('editor.file');

const IframeWrapper = styled.div`
  height: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type Props = {
  closeFileFlyout: () => void;
  file: File | null;
  fileError: string;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  navigateBackToEmbedlyMenu: () => void;
};

const FileFlyoutIframe = (props: Props) => {
  const { closeFileFlyout, file, fileError, onFileChange, navigateBackToEmbedlyMenu } = props;

  const menuId = 'file-flyout';
  const { isMenuOpen } = useActiveMenuHandler({ menuId });
  const { selectedEmbedMenuItem } = useEditorToolbarContext();
  const [deleteStepAttachment] = useDeleteStepAttachmentMutation();
  const [uploadFile, uploadResult] = usePostStepAttachmentMutation();
  const { isLoading, data } = uploadResult;
  const { editor } = useEditorContext();

  useEffect(() => {
    const fetchData = async () => {
      if (file) {
        const formData = await setFormData(file);
        uploadFile(formData);
      }
    };

    fetchData();
  }, [file, uploadFile]);

  const onCancelTask = useCallback(() => {
    if (data) {
      const { id } = data;

      deleteStepAttachment({ id });
    }

    closeFileFlyout();
    navigateBackToEmbedlyMenu();
  }, [closeFileFlyout, data, deleteStepAttachment, navigateBackToEmbedlyMenu]);

  const setInEditor = useCallback(() => {
    if (data) {
      const { fileLink, fileName } = data;

      const html = `<iframe class='embedly-embed' src='${window.location.origin}/pdf.js/web/viewer.html?file=${fileLink}' frameBorder='0' allow='fullscreen'></iframe>`;

      const constructPdfResponse: EmbedlyResponse = {
        html,
        title: fileName,
        version: '1.0',
        type: 'pdf',
        url: fileLink,
        description: '', // Setting default description here as required in type EmbedlyResponse, and gets set within EmbedCard
      };
      const jsonString = JSON.stringify(constructPdfResponse);

      insertEditorContent({
        editor,
        content: htmlEncodedContent({
          jsonString,
          html: constructPdfResponse.html,
          originalUrl: constructPdfResponse.url,
        }),
      });
    }

    closeFileFlyout();
  }, [closeFileFlyout, data, editor]);

  return (
    <StyledFlyout
      className='file-flyout-iframe'
      id={menuId}
      onClose={closeFileFlyout}
      placement='bottom-start'
      primaryButtonDisabled={!file || !!fileError || isLoading}
      primaryButtonTask={setInEditor}
      primaryButtonText={t('primary_button')}
      secondaryButtonTask={onCancelTask}
      secondaryButtonText={selectedEmbedMenuItem ? t('back') : t('cancel')}
      title={selectedEmbedMenuItem ? t('add_pdf') : t('title')}
      triggerButton={<FileToolbarButton active={isMenuOpen} />}
    >
      <>
        {data ? (
          <IframeWrapper>
            <PdfIframePreview fileLink={data.fileLink} />
          </IframeWrapper>
        ) : (
          <>
            {isLoading ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <>
                <FileInputDropzone
                  acceptedFiles='.pdf'
                  ariaLabel={t('aria_label_file_dropzone')}
                  label={t('label')}
                  onFileChange={onFileChange}
                />
                <AssistiveText
                  id={fileError ? 'file-upload-error-text' : 'file-upload-assistive-text'}
                  text={fileError || t('info_text_pdf')}
                  type={fileError ? 'error' : undefined}
                />
              </>
            )}
          </>
        )}
      </>
    </StyledFlyout>
  );
};

export default FileFlyoutIframe;
