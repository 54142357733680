import React from 'react';
import styled from 'styled-components';

import { BaseId } from '../../../../../../../../lib/idRegistry';
import Icon from '../../../../../../../design_system/display/icons/Icon/Icon';
import { CourseTitleEmoji, SharedCourseTitle, SharedCourseTitleWrapper } from '../../shared/styles';

const TitleWrapper = styled.button`
  justify-content: space-between;
  cursor: pointer;
  background: none;
  border: none;
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  ${SharedCourseTitleWrapper};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export interface Props {
  title: string;
  id: BaseId;
  emoji: string;
  onClick?: () => void;
}

const CourseTitleButton = ({ emoji, id, onClick, title }: Props) => {
  return (
    <TitleWrapper id={id} onClick={onClick}>
      <LeftContainer>
        <CourseTitleEmoji>{emoji}</CourseTitleEmoji>
        <SharedCourseTitle>{title}</SharedCourseTitle>
      </LeftContainer>
      <RightContainer>
        <Icon name='chevron-right' size='xs' />
      </RightContainer>
    </TitleWrapper>
  );
};

export default CourseTitleButton;
