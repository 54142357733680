import { trainualApi } from '../../trainualService';
import { CreateDocumentParams } from './types';

const documentsApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<undefined, CreateDocumentParams>({
      query: (params) => ({
        url: `ajax/documents`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateDocumentMutation } = documentsApi;
