import React, { useContext } from 'react';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import BetaFeatures from './Forms/GeneralSettings/BetaFeatures';
import CompanyInfo from './Forms/GeneralSettings/CompanyInfo';
import LoginRestrictions from './Forms/GeneralSettings/LoginRestrictions';
import OrganizationLogo from './Forms/GeneralSettings/OrganizationLogo';
import RestrictToSSO from './Forms/GeneralSettings/RestrictToSSO';
import TraditionalMfa from './Forms/GeneralSettings/TraditionalMfa';
import { SectionDivider, TabSectionWrapper } from './styles';

const GeneralTabSection = () => {
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();
  const paywallCtx = useContext(PaywallContext);
  const restrictLoginByIpPaywalled = paywallCtx.includes('ip_access_restriction');
  const {
    splitFeatures: { toggleForMfaEnabled },
  } = useCurrentAccount();

  if (isLoadingData || !data) return null;

  const {
    availableOrganizationSizes,
    availableIndustries,
    defaultLogoUrl,
    logoUrl,
    advancedSettings,
  } = data;

  const { anySsoIntegrationConnected } = advancedSettings;

  return (
    <TabSectionWrapper id='general-tab-section'>
      <CompanyInfo
        availableIndustries={availableIndustries}
        availableOrganizationSizes={availableOrganizationSizes}
      />
      <SectionDivider />
      <OrganizationLogo defaultLogoUrl={defaultLogoUrl} logoUrl={logoUrl} />
      <BetaFeatures />
      {toggleForMfaEnabled && <TraditionalMfa />}
      {anySsoIntegrationConnected && <RestrictToSSO />}
      {!restrictLoginByIpPaywalled && <LoginRestrictions />}
    </TabSectionWrapper>
  );
};

export default GeneralTabSection;
