import { useSortable } from '@dnd-kit/sortable';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../hooks/useWindowResize';
import { setGrabCursor } from '../../../../lib/setGrabCursor';
import { openResponsibilityDetailsModal } from '../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { DragStyle } from '../../shared/DragAndDrop/styles';
import { DragAndDropIcon, ResponsibilityListItemWrapper, StyledP } from './styles';
import { ResponsibilityListItemProps } from './types';

const ResponsibilityListItem = ({ assignedResponsibility }: ResponsibilityListItemProps) => {
  const dispatch = useDispatch();
  const {
    id,
    responsibility: { name, id: responsibilityId },
  } = assignedResponsibility;

  const { onHoldPlan, status: accountStatus } = useCurrentAccount();
  const { isDesktop } = useWindowResize();
  const isDragDisabled = useMemo(() => {
    return !isDesktop || accountStatus === 'trial_ended' || onHoldPlan;
  }, [isDesktop, accountStatus, onHoldPlan]);

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: isDragDisabled,
  });

  const handleResponsibilityClick = () => {
    dispatch(openResponsibilityDetailsModal({ id: responsibilityId, mode: 'edit' }));
  };

  useEffect(() => {
    setGrabCursor(isDragging);
  }, [isDragging]);

  return (
    <ResponsibilityListItemWrapper
      id={`editable-${id}-hover-wrapper`}
      onClick={handleResponsibilityClick}
      ref={setNodeRef}
      style={DragStyle(transition, transform, isDragging)}
      {...listeners}
      {...attributes}
    >
      {!isDragDisabled && <DragAndDropIcon name='grip-vertical' weight='solid' />}
      <StyledP>{name}</StyledP>
    </ResponsibilityListItemWrapper>
  );
};

export default ResponsibilityListItem;
