import React, { useState } from 'react';
import styled from 'styled-components';

import NoUsersEmptyStateDark from '../../../../../../../assets/images/dark_mode/empty_state/group-managers-empty-state.svg';
import NoUsersEmptyStateLight from '../../../../../../../assets/images/empty_state/group-managers-empty-state.svg';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetGroupManagersQuery } from '../../../../../redux/services/resourceApis/groups/groupsApi';
import DetailedAvatar from '../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import NoResults from '../../../../design_system/Triage/NoResults';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { routes } from '../../../publicApplication/applicationRouter';
import { Title } from '../../../shared/DetailedTitle/Title';
import GroupManagersModal from '../../modals/GroupManagersModal/GroupManagersModal';

const GroupManagersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const ManagersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${mediaBreakpointPxSm}) {
    gap: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const AvatarWrapper = styled.div`
  width: 25%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  padding-right: ${({ theme: { constants } }) => constants.spacerLg2};

  &:nth-last-of-type(-n + 4) {
    margin-bottom: 0;
  }

  @media (max-width: ${mediaBreakpointPxSm}) {
    width: auto;
  }
`;

interface Props {
  groupId: number;
}

const GroupManagers = ({ groupId }: Props) => {
  const { data: managers, isLoading } = useGetGroupManagersQuery(groupId);
  const { slug } = useCurrentAccount();
  const { id: userId } = useCurrentUser();
  const ability = useCurrentUserAbilities();

  const [groupManagersModalOpen, setGroupManagersModalOpen] = useState(false);
  const t = initTranslations('groups.groups_edit.members.managers');

  if (isLoading) return <></>;
  if (!managers) return <></>;

  const isGroupManager = managers.some((manager) => manager.id === userId);
  const canManage = ability.can('create', 'Group') || isGroupManager;

  return (
    <GroupManagersWrapper id='group-managers'>
      <Title fontSize='md2' fontWeight='semibold' title={t('header')} />
      {!!managers.length ? (
        <ManagersWrapper>
          {canManage && (
            <AvatarWrapper>
              <DetailedAvatar
                avatarIcon='user-gear'
                id='edit-managers'
                label={t('edit')}
                onClick={() => setGroupManagersModalOpen(true)}
              />
            </AvatarWrapper>
          )}
          {managers.map((manager) => (
            <AvatarWrapper
              className='assigned-manager'
              data-user-id={manager.id}
              key={`manager-${manager.id}`}
            >
              <DetailedAvatar
                avatarImage={manager.avatar}
                id={`manager-detailed-avatar-${manager.id}`}
                label={manager.name}
                labelLink={
                  routes.userProfile({
                    slug,
                    id: manager.id,
                    breadcrumb: routes.orgChart({ slug }),
                  }).href
                }
                onAvatarClick={() =>
                  routes
                    .userProfile({
                      slug,
                      id: manager.id,
                    })
                    .push()
                }
                subLabel={manager.title}
              />
            </AvatarWrapper>
          ))}
        </ManagersWrapper>
      ) : (
        <NoResults
          className='groups-members-managers-no-results'
          darkImage={NoUsersEmptyStateDark}
          iconWidth='20%'
          lightImage={NoUsersEmptyStateLight}
          minHeight='unset'
          showBoxShadow
          subHeaderCta={
            canManage
              ? {
                  action: () => setGroupManagersModalOpen(true),
                  text: t('empty_state.action_link'),
                }
              : undefined
          }
          subHeaderText={t(`empty_state.${canManage ? 'header_managerial' : 'header_read'}`)}
        />
      )}
      {groupManagersModalOpen && (
        <GroupManagersModal
          groupId={groupId}
          onCloseRequest={() => setGroupManagersModalOpen(false)}
        />
      )}
    </GroupManagersWrapper>
  );
};

export default GroupManagers;
