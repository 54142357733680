import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../hooks/useCurrentAccount';
import { routes } from '../publicApplication/applicationRouter';
import routeTo from '../publicApplication/routeTo';
import VideoPage from './VideoPage';

export type Props = {
  route: Route<typeof routes.videoEdit>;
};
const VideoEdit = ({ route }: Props) => {
  const {
    params: { id },
  } = route;

  const {
    slug,
    splitFeatures: { videoContentTypeEnabled },
  } = useCurrentAccount();

  if (!videoContentTypeEnabled) {
    routeTo(routes.home({ slug }));
    return null;
  }

  return <VideoPage editing id={id} />;
};

export default VideoEdit;
