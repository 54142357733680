import DOMPurify from 'dompurify';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useVideoData } from '../../../contexts/VideoDataContext';
import VideoDetails from './VideoDetails';

const VideoBody = styled.div`
  ${() => css`
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 768px;
    flex-direction: column;
    container: video-body / inline-size;
  `}
`;

const VideoIframeContainer = styled.div`
  ${({ theme: { vars, constants } }) => css`
    overflow: hidden;
    width: 100cqw;
    height: 56.25cqw;
    flex-shrink: 0;
    box-shadow: ${vars.shadowCenterLarge};
    border-radius: ${constants.borderRadiusXl};
  `}
`;

const VideoIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  position: relative;
  top: -1px;
`;

const VideoContent = () => {
  const { data } = useVideoData();

  const iframeAttributes = useMemo(() => {
    if (!data?.html) {
      return;
    }
    const node = DOMPurify.sanitize(data.html, {
      ALLOWED_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
      RETURN_DOM: true,
    });

    const iframe = node?.children?.[0] as HTMLIFrameElement;

    if (iframe) {
      return {
        allowFullScreen: iframe.allowFullscreen,
        allow: iframe.allow,
        src: iframe.src,
        title: iframe.title,
        className: iframe.className,
      };
    }
    return null;
  }, [data?.html]);

  return (
    <VideoBody>
      <VideoIframeContainer className='chromatic-ignore' data-testid='embedly-video-preview'>
        {iframeAttributes && (
          <VideoIframe
            allow={iframeAttributes.allow}
            allowFullScreen={iframeAttributes.allowFullScreen}
            data-testid='iframe'
            src={iframeAttributes.src}
            title={iframeAttributes.title}
          ></VideoIframe>
        )}
      </VideoIframeContainer>
      <VideoDetails />
    </VideoBody>
  );
};

export default VideoContent;
