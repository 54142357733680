import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route } from 'type-route';

import initTranslations from '../../../../lib/initTranslations';
import { useGetBoardColumnsQuery } from '../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { formatOptions } from '../../../design_system/core/CoreSelectField/CoreSelectField';
import { Option } from '../../../design_system/core/SelectOption/types';
import Icon from '../../../design_system/display/icons/Icon';
import OutsideClickHandler from '../../../design_system/OutsideClickHandler';
import useActiveMenuHandler from '../../../design_system/useActiveMenuHandler';
import Poppable from '../../../Poppable';
import { routes, useRoute } from '../../publicApplication/applicationRouter';
import { ResponsibilityDetailsFormData } from '../modals/ResponsibilityDetailsModal/ResponsibilityDetails/types';
import { POPPER_MODIFIERS } from './_data';
import {
  AlertInfo,
  FormButtonsWrapper,
  FormInputWrapper,
  FormWrapper,
  StyledSingleSelectField,
  WarningInfo,
} from './styles';
import { MoveResponsibilityFormPopupProps } from './types';

const t = initTranslations(
  'delegation_planner.modals.responsibility_details_modal.delegate_responsibility'
);

const MoveResponsibilityFormPopup = ({
  className,
  id,
  menuId,
  trigger,
}: MoveResponsibilityFormPopupProps) => {
  const {
    params: { id: currentBoardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId,
  });
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const { setFieldValue, values, initialValues } =
    useFormikContext<ResponsibilityDetailsFormData>();
  const [selectedColumnId, setSelectedColumnId] = useState(values.targetColumnId);
  const [hasDuplicateInTarget, setHasDuplicateInTarget] = useState(false);
  const { data: boardColumnsResponse } = useGetBoardColumnsQuery({
    boardId: currentBoardId,
  });
  const boardColumns = useMemo(
    () => boardColumnsResponse?.boardColumns ?? [],
    [boardColumnsResponse]
  );
  const initialBoardColumn = boardColumns.find(
    (column) => column.id === +initialValues.targetColumnId
  );
  const isMoveFromGroup = initialBoardColumn?.columnType === 'Group';

  const columnOptions: Option[] = formatOptions(
    boardColumns.map((boardColumn) => ({
      value: String(boardColumn.id),
      label: boardColumn.columnLabel,
    }))
  );

  const targetColumnName = boardColumns.find(
    (column) => column.id === +selectedColumnId
  )?.columnLabel;

  const handleCancel = useCallback(() => {
    setSelectedColumnId(values.targetColumnId);
    closeMenu();
  }, [closeMenu, values.targetColumnId]);

  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!e.target.closest('.move-responsibility-form-trigger')) {
        handleCancel();
      }
    },
    [handleCancel]
  );

  const handleMenuTriggerClick = () => {
    handleMenuClick();
    setHasDuplicateInTarget(false);
    setSelectedColumnId(values.targetColumnId);
  };

  const checkDuplicatesInTargetColumn = useCallback(() => {
    if (selectedColumnId !== values.targetColumnId) {
      const targetColumn = boardColumns.find((column) => column.id === +selectedColumnId);

      const hasDuplicate = !!targetColumn?.boardColumnResponsibilities.some(
        (responsibility) => responsibility.name === values.name
      );

      setHasDuplicateInTarget(hasDuplicate);

      return;
    }

    if (hasDuplicateInTarget) {
      setHasDuplicateInTarget(false);
    }
  }, [selectedColumnId, boardColumns, hasDuplicateInTarget, values.name, values.targetColumnId]);

  useEffect(() => {
    checkDuplicatesInTargetColumn();
  }, [checkDuplicatesInTargetColumn]);

  const handleSubmit = () => {
    const targetColumn = boardColumns.find((column) => column.id === +selectedColumnId);

    if (targetColumn) {
      setFieldValue('targetColumnId', selectedColumnId);
      setFieldValue('targetPosition', targetColumn.boardColumnResponsibilities.length + 1);
    }
    closeMenu();
  };

  return (
    <Poppable
      isOpen={isMenuOpen}
      item={
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <FormWrapper className={className} id={id}>
            <FormInputWrapper>
              <StyledSingleSelectField
                className='move-responsibility-choose-column-select-field'
                defaultValue={selectedColumnId}
                fieldLabelText={t('input_label')}
                hideSelectedOptions={false}
                name='targetColumnId'
                onNonDefaultSelected={(value) => value && setSelectedColumnId(value)}
                options={columnOptions}
                value={selectedColumnId}
              />
              {hasDuplicateInTarget && (
                <WarningInfo>
                  <Icon name='triangle-exclamation' size='xs' />
                  <span>
                    {t('cant_delegate_responsibility_message', {
                      responsibility: responsibilitySingular.toLowerCase(),
                      column: targetColumnName,
                    })}
                  </span>
                </WarningInfo>
              )}

              {isMoveFromGroup && !hasDuplicateInTarget && (
                <AlertInfo>
                  <Icon name='circle-info' size='xs' />
                  <span>
                    {t('info_message', { responsibility: responsibilitySingular.toLowerCase() })}
                  </span>
                </AlertInfo>
              )}
            </FormInputWrapper>
            <FormButtonsWrapper>
              <DefaultButton
                buttonType='tertiary'
                id='delegation-planner-cancel-move-responsibility-button'
                onClick={handleCancel}
                size='md'
                text={t('cancel')}
              />
              <DefaultButton
                buttonType='primary'
                disabled={selectedColumnId === values.targetColumnId || hasDuplicateInTarget}
                id='delegation-planner-save-move-responsibility-button'
                onClick={handleSubmit}
                size='md'
                text={t('delegate')}
              />
            </FormButtonsWrapper>
          </FormWrapper>
        </OutsideClickHandler>
      }
      modifiers={POPPER_MODIFIERS}
      onClick={handleMenuTriggerClick}
      placement='top-end'
      strategy='absolute'
      trigger={<div className='move-responsibility-form-trigger'>{trigger}</div>}
    />
  );
};

export default MoveResponsibilityFormPopup;
