import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { ContentType } from '../../../../../types/ContentType';
import useActiveModalHandler from '../../../../design_system/useActiveModalHandler';
import SuperShareModal from '../../../people/SuperShare/SuperShareModal/SuperShareModal';
import ViewModeDropdown from '../../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../../shared/ViewModeDropdown/ViewOptions';
import CurriculumCompletionsModal from '../CurriculumCompletionsModal';
import PublishDropdown from '../UniversalHeader/PublishDropdown/PublishDropdown';
import ShareButton, { StyledButton } from '../UniversalHeader/ShareButton/ShareButton';
import { TopSurfaceProps } from './TopSurface';

const MainActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const ViewModeDropdownWrapper = styled.div`
  min-width: 9.25rem;
`;

const t = initTranslations('curriculum_edit.manage_completions');

const TopSurfaceActionRow = ({ curriculum }: TopSurfaceProps) => {
  const {
    emoji,
    title,
    can_assign_users: canAssignUsers,
    id,
    published,
    can_publish: canPublish,
    all_users_assigned: allUsersAssigned,
  } = curriculum;

  const { slug } = useCurrentAccount();

  const isAnAdmin = useIsAdmin();
  const ability = useCurrentUserAbilities();
  const manageCompletions = ability.can('read', `ManageCurriculumAssigned-${id}`);
  const showCompletions = isAnAdmin || manageCompletions;
  const { setActiveModal, activeModal, closeActiveModal } = useActiveModalHandler();

  return (
    <MainActionsWrapper id='top-surface-action-row'>
      <ViewModeDropdownWrapper>
        <ViewModeDropdown
          initialSelectedOptionIndex={0}
          modeText
          options={viewOptions({ id, slug, contentType: ContentType.Curriculum })}
        />
      </ViewModeDropdownWrapper>
      {canAssignUsers && (
        <ShareButton
          allUsersAssigned={allUsersAssigned}
          onClick={() => setActiveModal('super-share-modal')}
        />
      )}
      {activeModal === 'super-share-modal' && (
        <SuperShareModal
          curriculumId={id}
          setShowSuperShareModal={(show: boolean) => {
            setActiveModal(show ? 'super-share-modal' : null);
          }}
          showSuperShareModal={activeModal === 'super-share-modal'}
        />
      )}
      {canPublish && (
        <PublishDropdown
          allUsersAssigned={allUsersAssigned}
          id={id}
          published={published}
          subject={{ title, emoji }}
        />
      )}
      {showCompletions && (
        <StyledButton
          id='curriculum-completions-button'
          onClick={() => {
            setActiveModal('manage-completions');
          }}
        >
          {t('manage_completions')}
        </StyledButton>
      )}

      {activeModal === 'manage-completions' && (
        <CurriculumCompletionsModal curriculumId={id} onCloseRequest={closeActiveModal} />
      )}
    </MainActionsWrapper>
  );
};

export default TopSurfaceActionRow;
