import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useMemo } from 'react';

import { useAccountTerminology } from '../../components/AccountTerminologyProvider';
import { CourseSvg, FlowchartSvg, SurveySvg, VideoSvg } from '../../lib/gcsImages';
import initTranslations from '../../lib/initTranslations';
import { ElementKind } from '../../types/CurriculumElement';
import useCurrentAccount from '../useCurrentAccount';

const t = initTranslations('curriculum_outline_view');

type CurriculumElementInfo = {
  iconName: IconName;
  detailedIcon: string;
  title: string;
  description: string;
  kind: ElementKind;
};

export const useCurriculumElementInfo = (): CurriculumElementInfo[] => {
  const { contentFlowchartsEnabled, videoContentTypeEnabled } = useCurrentAccount().splitFeatures;
  const { step, curriculum, topic } = useAccountTerminology();

  return useMemo(() => {
    const courseInfo: CurriculumElementInfo = {
      kind: 'course',
      detailedIcon: CourseSvg,
      iconName: 'file-lines',
      title: topic.singular,
      description: t('curriculum_element_dropdown.course.description', {
        subject: curriculum.singular.toLowerCase(),
        steps: step.plural.toLowerCase(),
      }),
    };

    const flowchartInfo: CurriculumElementInfo = {
      kind: 'flowchart',
      detailedIcon: FlowchartSvg,
      iconName: 'shapes',
      title: t('curriculum_element_dropdown.flowchart.title'),
      description: t('curriculum_element_dropdown.flowchart.description'),
    };

    const videoInfo: CurriculumElementInfo = {
      kind: 'video',
      detailedIcon: VideoSvg,
      iconName: 'video',
      title: t('curriculum_element_dropdown.video.title'),
      description: t('curriculum_element_dropdown.video.description'),
    };

    const surveyInfo: CurriculumElementInfo = {
      kind: 'survey',
      detailedIcon: SurveySvg,
      iconName: 'clipboard-check',
      title: t('curriculum_element_dropdown.survey.title'),
      description: t('curriculum_element_dropdown.survey.description', {
        subject: curriculum.singular.toLowerCase(),
      }),
    };

    const curriculumElementInfo: CurriculumElementInfo[] = [];

    curriculumElementInfo.push(courseInfo);
    if (contentFlowchartsEnabled) curriculumElementInfo.push(flowchartInfo);
    if (videoContentTypeEnabled) curriculumElementInfo.push(videoInfo);
    curriculumElementInfo.push(surveyInfo);

    return curriculumElementInfo;
  }, [
    contentFlowchartsEnabled,
    curriculum.singular,
    step.plural,
    topic.singular,
    videoContentTypeEnabled,
  ]);
};
