import React, { useCallback, useEffect, useState } from 'react';
import { Route } from 'type-route';

import { ReactComponent as LoomLogo } from '../../../../../images/loom_logo.svg';
import { ReactComponent as ChooseOneSvg } from '../../../../../images/surveys/choose-one.svg';
import { ReactComponent as MultipleChoiceSvg } from '../../../../../images/surveys/multiple-choice.svg';
import { ReactComponent as TrueFalseSvg } from '../../../../../images/surveys/true-false.svg';
import initTranslations from '../../../../../lib/initTranslations';
import {
  useDeleteSurveyQuestionMutation,
  useUpdateSurveyQuestionMutation,
} from '../../../../../redux/services/resourceApis/surveyQuestions/surveyQuestionsApi';
import { AdjacentCurriculumElement } from '../../../../../types/Editor';
import { SurveyQuestion as SurveyQuestionType } from '../../../../../types/SurveyQuestion';
import Icon from '../../../../design_system/display/icons/Icon';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';
import RichTextareaWithInsert from '../../../editor/components/RichTextareaWithInsert';
import { bubbleToolbarButtons } from '../../../editor/toolbar/buttons/shared';
import { routes } from '../../../publicApplication/applicationRouter';
import DropdownWithPoppableMenu from '../../../shared/DropdownWithPoppableMenu';
import { StyledSvgWrapper } from '../../shared/styles';
import {
  CardContent,
  CardFooter,
  CardHeader,
  OptionsFormWrapper,
  QuestionCard,
  QuestionPosition,
} from '../../shared/SurveyQuestion/styles';
import VideoResponseForm from '../../shared/VideoResponseForm/VideoResponseForm';
import ChooseOptionsForm from './ChooseOptionsForm/ChooseOptionsForm';
import DropdownMenuOption from './DropdownMenuOption/DropdownMenuOption';
import {
  SurveyQuestionDropdownMenuOption,
  SurveyQuestionDropdownOptionType,
} from './DropdownMenuOption/types';
import EditorFormControlButtons from './EditorFormControlButtons';
import WrittenResponseForm from './WrittenResponseForm/WrittenResponseForm';

const t = initTranslations('survey_edit.question');

const SurveyQuestion = ({
  questions,
  adjacentCurriculumElements,
  route,
}: {
  questions: SurveyQuestionType[];
  adjacentCurriculumElements: AdjacentCurriculumElement;
  route: Route<typeof routes.surveyQuestionEditor>;
}) => {
  const {
    params: { slug, id, surveyId },
  } = route;
  const currentQuestionIndex = questions.findIndex((question) => question.id === id);
  const currentQuestion = questions[currentQuestionIndex];
  const navigationParams = {
    prevQuestionId: questions[currentQuestionIndex - 1]?.id,
    nextQuestionId: questions[currentQuestionIndex + 1]?.id,
    previous: adjacentCurriculumElements.previous || null,
    next: adjacentCurriculumElements.next || null,
  };
  const [
    deleteQuestion,
    { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess, reset: resetDelete },
  ] = useDeleteSurveyQuestionMutation({});
  const [updateQuestion] = useUpdateSurveyQuestionMutation();
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const handleQuestionDelete = () => {
    deleteQuestion({ id, surveyId });
  };

  const handleQuestionAfterDeleteRedirect = useCallback(() => {
    resetDelete();
    setShowDeletionModal(false);

    if (!!navigationParams.prevQuestionId) {
      routes
        .surveyQuestionEditor({ id: navigationParams.prevQuestionId, surveyId, slug })
        .replace();
    } else {
      routes.surveyEditor({ id: surveyId, slug }).replace();
    }
  }, [navigationParams.prevQuestionId, resetDelete, slug, surveyId]);

  const handleDropdownOptionClick = useCallback(
    (optionType: SurveyQuestionDropdownOptionType) => {
      if (optionType === 'destroy') {
        setShowDeletionModal(true);
        return;
      }

      if (!!currentQuestion?.answerType && optionType !== currentQuestion?.answerType) {
        updateQuestion({ id, surveyId, answerType: optionType });
      }
    },
    [currentQuestion?.answerType, id, surveyId, updateQuestion]
  );

  const SurveyMenuOptions: SurveyQuestionDropdownMenuOption[] = [
    {
      icon: (
        <StyledSvgWrapper svgColor='default'>
          <MultipleChoiceSvg />
        </StyledSvgWrapper>
      ),
      title: t('multiple_choice'),
      optionType: 'multiple_choice',
    },
    {
      icon: (
        <StyledSvgWrapper svgColor='default'>
          <ChooseOneSvg />
        </StyledSvgWrapper>
      ),
      optionType: 'choose_one_option',
      title: t('choose_one_option'),
    },
    {
      icon: (
        <StyledSvgWrapper svgColor='default'>
          <TrueFalseSvg />
        </StyledSvgWrapper>
      ),
      optionType: 'true_or_false',
      title: t('true_or_false'),
    },
    {
      icon: <Icon name='pen' size='xs' weight='regular' />,
      title: t('written_response'),
      optionType: 'written_response',
    },
    {
      icon: (
        <StyledSvgWrapper svgColor='default'>
          <LoomLogo />
        </StyledSvgWrapper>
      ),
      title: t('video_response'),
      optionType: 'video_response',
    },
    {
      icon: <Icon name='trash-can' size='xs' weight='regular' />,
      title: t('delete_question'),
      optionType: 'destroy',
    },
  ];

  const menuOptions = SurveyMenuOptions.map((option) => (
    <DropdownMenuOption
      icon={option.icon}
      key={option.title}
      optionType={option.optionType}
      title={option.title}
    />
  ));

  useEffect(() => {
    if (isDeleteSuccess) {
      handleQuestionAfterDeleteRedirect();
    }
  }, [handleQuestionAfterDeleteRedirect, isDeleteSuccess]);

  if (!currentQuestion) return <></>;

  const { text, position, options, answerType, wordcount, textJson } = currentQuestion;
  const activeDropdownOptionIndex = menuOptions.map((e) => e.props.optionType).indexOf(answerType);

  const cardBody = (answerType: SurveyQuestionDropdownOptionType) => {
    switch (answerType) {
      case 'multiple_choice':
      case 'choose_one_option':
      case 'true_or_false':
        return <ChooseOptionsForm options={options} surveyAnswerType={answerType} />;
      case 'written_response':
        return <WrittenResponseForm wordCount={wordcount} />;
      case 'video_response':
        return <VideoResponseForm />;
      default:
        return <></>;
    }
  };

  return (
    <QuestionCard id={`question-edit-card-${id}`}>
      <CardHeader>
        <QuestionPosition>{position}</QuestionPosition>
        <DropdownWithPoppableMenu
          id={`question-dropdown-menu-${id}`}
          menuId={`question-dropdown-menu-${id}`}
          menuPlacement='bottom-start'
          options={menuOptions}
          selectedOption={menuOptions[activeDropdownOptionIndex]}
          setSelectedOption={(index) => {
            handleDropdownOptionClick(SurveyMenuOptions[index].optionType);
          }}
          style='survey'
        />
      </CardHeader>
      <CardContent>
        <OptionsFormWrapper>
          <RichTextareaWithInsert
            bubbleToolbarButtons={bubbleToolbarButtons}
            content={textJson || text}
            editable
            placeholderText={t('rich_text_area_placeholder')}
            questionId={id}
            surveyId={surveyId}
          />
          {cardBody(answerType)}
        </OptionsFormWrapper>
      </CardContent>
      <CardFooter>
        <EditorFormControlButtons {...navigationParams} />
      </CardFooter>
      {showDeletionModal && (
        <ConfirmationModal
          actionFunction={handleQuestionDelete}
          actionText={t('delete_confirmation.delete')}
          heapModalName='delete-survey-question-modal'
          message={t('delete_confirmation.message')}
          onCloseRequest={() => setShowDeletionModal(false)}
          processing={isDeleteLoading}
          title={t('delete_confirmation.title')}
        />
      )}
    </QuestionCard>
  );
};

export default SurveyQuestion;
