import React from 'react';
import styled, { css } from 'styled-components';

import { useCurriculumElementInfo } from '../../../../../../hooks/curriculum/useCurriculumElementInfo';
import initTranslations from '../../../../../../lib/initTranslations';
import { setSelectedContentType } from '../../../../../../redux/domains/curriculums/curriculumsSlice';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { fontMd1 } from '../../../../../styled/TypeSystem';
import CreateContentButton from './CreateContentButton';

const CreateContentButtonWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-flow: row wrap;
    gap: ${constants.spacerMd2};
    margin-top: ${constants.spacerMd2};
  `
);

const GettingStartedSection = styled.div(
  ({ theme: { vars } }) => css`
    color: ${vars.textSubdued};
    ${fontMd1};
  `
);

const EmptyStateWrapper = styled.div(
  ({ theme: { constants } }) => css`
    padding-inline: ${constants.spacerMd3};
  `
);

const t = initTranslations('curriculums.empty_state');

const EmptyState = () => {
  const addContentButtons = useCurriculumElementInfo();
  const dispatch = useAppDispatch();

  return (
    <EmptyStateWrapper>
      <GettingStartedSection>{t('get_started_with')}</GettingStartedSection>
      <CreateContentButtonWrapper>
        {addContentButtons.map((config, index) => {
          return (
            <CreateContentButton
              icon={config.detailedIcon}
              id={config.kind}
              key={config.kind}
              onClick={() => {
                dispatch(setSelectedContentType(index));
              }}
              subText={config.description}
              text={config.title}
            />
          );
        })}
      </CreateContentButtonWrapper>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
