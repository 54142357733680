import { kebabCase, startCase } from 'lodash';
import React from 'react';

import { getAddonVideoPreview } from '../../../../../../../lib/billing/getAddonVideoPreview';
import { trainualPlusFeatureSvg } from '../../../../../../../lib/gcsImages';
import { AddonTrainualPlusFeatureData } from '../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import FeatureVideo from '../../../BillingPage/shared/FeatureVideo/FeatureVideo';
import { FeatureDescription, FeatureIcon, FeatureSectionWrapper, FeatureTitle } from './styles';

type FeatureSectionProps = {
  setIsOpenedLightboxVideoPlayer: (value: boolean) => void;
} & AddonTrainualPlusFeatureData;

const FeatureSection = ({
  title,
  description,
  videoLink,
  name,
  setIsOpenedLightboxVideoPlayer,
}: FeatureSectionProps) => {
  const iconUrl = trainualPlusFeatureSvg({ name });
  const videoPreviewSrc = getAddonVideoPreview(name);
  const showFeatureVideo = videoLink && videoPreviewSrc;

  return (
    <FeatureSectionWrapper id={`${kebabCase(name)}-feature-section`}>
      <FeatureIcon alt={startCase(name)} iconName={name} src={iconUrl} />
      <FeatureTitle
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <FeatureDescription text={description} />
      {showFeatureVideo && (
        <FeatureVideo
          closePlayerCallback={() => setIsOpenedLightboxVideoPlayer(false)}
          openPlayerCallback={() => setIsOpenedLightboxVideoPlayer(true)}
          overviewVideoLinks={videoLink}
          videoPreviewSrc={videoPreviewSrc}
        />
      )}
    </FeatureSectionWrapper>
  );
};

export default FeatureSection;
