import React from 'react';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import Portal from '../../Portal';
import { routes } from '../../publicApplication/applicationRouter';
import { EditorWrapper } from '../shared/styles';
import CollaborationEditor from './CollaborationEditor';
import DeprecatedEditorPageBase from './deprecated/DeprecatedEditorPageBase';

export type Props = {
  route: Route<typeof routes.editor>;
};

const EditorPage = ({ route }: Props) => {
  const {
    params: { id: stepId },
  } = route;

  const {
    splitFeatures: { stepCollaborationEnabled },
  } = useCurrentAccount();

  if (stepCollaborationEnabled) {
    return (
      <Portal>
        <EditorWrapper className='editor-step'>
          <CollaborationEditor stepIdParam={stepId} />
        </EditorWrapper>
      </Portal>
    );
  }

  return (
    <Portal>
      <EditorWrapper className='editor-step'>
        <DeprecatedEditorPageBase stepId={stepId} />
      </EditorWrapper>
    </Portal>
  );
};

export default EditorPage;
