import { useSpring } from '@react-spring/web';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DraggableProvided, Droppable } from 'react-beautiful-dnd';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useWindowResize from '../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../lib/initTranslations';
import { useUpdateTopicMutation } from '../../../../../../redux/services/resourceApis/courses/topicsApi';
import { useCreateStepMutation } from '../../../../../../redux/services/resourceApis/steps/stepsApi';
import { AddStepParams } from '../../../../../../redux/services/resourceApis/steps/types';
import { MetaUserAccess } from '../../../../../../types/Curriculum';
import { EditableCourseCurriculumElement } from '../../../../../../types/CurriculumElement';
import { Emoji } from '../../../../../../types/Emoji';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Icon from '../../../../../design_system/display/icons/Icon';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../../../styled/Breakpoint';
import { fontSm4 } from '../../../../../styled/TypeSystem';
import {
  ActionRowWrapper,
  ChildContainerRow,
  EmojiWrapper,
  RowActionsContainer,
  RowCardBody,
  RowContainer,
  RowIcon,
  RowIconContainer,
} from '../../../../curriculums/shared/CurriculumRowStyles';
import { ReadingTimeProvider } from '../../../../editor/shared/ReadTimeFlyout/ReadingTimeContext';
import ReadTimeFlyout from '../../../../editor/shared/ReadTimeFlyout/ReadTimeFlyout';
import { routes } from '../../../../publicApplication/applicationRouter';
import ActionRow from '../../../../shared/ActionRow/ActionRow';
import StatusBadgeActions from '../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import EditableCurriculumElementTitle from '../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import CurriculumElementThreeDot from '../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { PlaceholderBlock } from '../../../../shared/DragAndDrop/styles';
import { Placeholder } from '../../../../shared/DragAndDrop/types';
import { getChevronIconName, viewableUserAccess } from '../../../../shared/helpers';
import EmojiSelect from '../../../components/SubjectEmoji/EmojiSelect';
import LibraryStepsList from '../../LibraryStepsList/LibraryStepsList';

const SignatureLabel = styled.span`
  display: flex;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  font-style: italic;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    display: block;
  }

  @media (min-width: ${mediaBreakpointPxLg}) {
    display: flex;
  }
  ${fontSm4};
`;

const ESignature = styled.div`
  white-space: nowrap;
`;

const ESignatureRowContainer = styled(RowContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme: { vars } }) => vars.borderSurface1};
`;

const ESignatureRowContent = styled.div`
  display: flex;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm4};
`;

export type Props = {
  curriculumId: number;
  isLocked?: boolean;
  curriculumElement: EditableCourseCurriculumElement;
  signaturable: boolean;
  provided: DraggableProvided;
  placeholder: Placeholder;
  blockEditPrivileges: boolean;
  emoji?: Emoji;
  userAccess?: MetaUserAccess;
  isContentLibrary?: boolean;
};

const t = initTranslations('curriculums_view');

const CourseTableRow = ({
  curriculumId,
  isLocked,
  curriculumElement,
  signaturable,
  provided,
  placeholder,
  blockEditPrivileges,
  emoji,
  userAccess,
  isContentLibrary = false,
}: Props) => {
  const { slug } = useCurrentAccount();
  const [isExpanded, setIsExpanded] = useState(false);
  const [createStep] = useCreateStepMutation();
  const [updateCourse, updateCourseResult] = useUpdateTopicMutation();
  const { isMobile } = useWindowResize();
  const [isEditing, setIsEditing] = useState(false);

  const {
    elementId: courseId,
    eSignatureDisplayedAndRequired,
    canBeModified,
    id,
    element,
    disableTranslation,
  } = curriculumElement;
  const { title, status, readingTime, autoGeneratedReadingTime } = element;
  const {
    step: { singular: stepTermSingular, plural: stepTermPlural },
  } = useAccountTerminology();
  const expandToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);
  const primaryButtonAction = useCallback(
    (value: string) => {
      const params: AddStepParams = {
        courseId,
        title: value,
      };
      return createStep(params);
    },
    [courseId, createStep]
  );

  const [stepRef, bounds] = useMeasure();
  const { isSuccess, error } = updateCourseResult;

  const { flash } = useFlashNotification();

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  const courseUrl = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return routes.courseConsume({ slug, id: courseId }).href;
    } else {
      return routes.courseEditor({ slug, id: courseId }).href;
    }
  }, [courseId, curriculumId, slug, userAccess]);

  const containerStyle = useSpring({
    height: isExpanded ? bounds.height + 8 : 0,
  });

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='curriculum-element-item'
      id={`curriculum-element-course-${courseId}`}
    >
      <RowContainer>
        <RowIconContainer align='center' id={`course-${courseId}-toggle`} onClick={expandToggle}>
          <RowIcon
            ariaLabel={isExpanded ? t('collapse') : t('expand')}
            buttonSize='sm'
            name={getChevronIconName({ isActive: !isExpanded, initialDirection: 'right' })}
            weight='light'
          />
        </RowIconContainer>

        <RowCardBody isLocked={isLocked || eSignatureDisplayedAndRequired || blockEditPrivileges}>
          <EmojiWrapper id={`course-${courseId}-row-emoji-picker`}>
            <EmojiSelect
              clearEmoji={() => {
                updateCourse({ id: courseId, emoji: null });
              }}
              emoji={emoji}
              noEmojiIconName='file-lines'
              onEmojiSelect={(emoji: BaseEmoji) => {
                updateCourse({ emoji: emoji.native, id: courseId });
              }}
              usePortal
            />
          </EmojiWrapper>
          <EditableCurriculumElementTitle
            disableTranslation={disableTranslation}
            editable={canBeModified && !blockEditPrivileges}
            id={courseId}
            isEditing={isEditing}
            isLocked={isLocked}
            isSuccess={isSuccess}
            route={courseUrl}
            setIsEditing={setIsEditing}
            title={title}
            updateElement={({ id, title }) => updateCourse({ id, title })}
          />
          {eSignatureDisplayedAndRequired && signaturable && (
            <SignatureLabel>
              <ESignature>{t('e_signature')}</ESignature>
              <div>{t('required')}</div>
            </SignatureLabel>
          )}
          <RowActionsContainer>
            {!isContentLibrary && (
              <ReadingTimeProvider>
                <ReadTimeFlyout
                  autoGeneratedReadingTime={autoGeneratedReadingTime}
                  courseId={courseId}
                  placement='auto'
                  readingTime={readingTime}
                />
              </ReadingTimeProvider>
            )}
            <StatusBadgeActions
              curriculumElement={{
                id: curriculumElement.id,
                status,
              }}
              curriculumId={curriculumId}
              infoText={t('steps', {
                count: curriculumElement.element.stepsCount,
                step:
                  curriculumElement.element.stepsCount === 1
                    ? stepTermSingular.toLowerCase()
                    : stepTermPlural.toLowerCase(),
              })}
            />
            {!blockEditPrivileges && (
              <CurriculumElementThreeDot
                canBeModified={canBeModified}
                curriculumElement={curriculumElement}
                curriculumId={curriculumId}
                eSignatureDisplayedAndRequired={eSignatureDisplayedAndRequired}
                isCurriculumLocked={isLocked}
                renameClickHandler={() => setIsEditing(true)}
                signaturable={signaturable}
              />
            )}
          </RowActionsContainer>
        </RowCardBody>
      </RowContainer>
      <ChildContainerRow style={containerStyle}>
        <div ref={stepRef}>
          <Droppable
            droppableId={`step-${id}-course-${courseId}`}
            key={id}
            type={isExpanded && !eSignatureDisplayedAndRequired ? `droppableStep` : 'closed'}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                className='steps-group-handler'
                ref={provided.innerRef}
                style={{
                  position: 'relative',
                }}
              >
                {isExpanded && (
                  <LibraryStepsList
                    blockEditPrivileges={blockEditPrivileges}
                    canBeModified={canBeModified}
                    courseId={courseId}
                    curriculumId={curriculumId}
                    eSignatureDisplayedAndRequired={eSignatureDisplayedAndRequired}
                    id={id}
                    isLocked={isLocked}
                    isMobile={isMobile}
                    userAccess={userAccess}
                  />
                )}
                {provided.placeholder}
                {!isEmpty(placeholder) && snapshot.isDraggingOver && (
                  <PlaceholderBlock
                    style={{
                      top: placeholder.clientY,
                      left: placeholder.clientX,
                      height: placeholder.clientHeight,
                      width: placeholder.clientWidth,
                    }}
                  />
                )}
                {!isLocked && (
                  <>
                    {!blockEditPrivileges && (
                      <ActionRowWrapper>
                        <ActionRow
                          curriculumId={curriculumId}
                          disabled={!canBeModified}
                          errorText={t('enter_title')}
                          hasMaxValue
                          id={`course-${id}-action-row`}
                          maxError={t('max_length_error')}
                          name='new-step-row'
                          placeholder={t('create_new_step', {
                            step: stepTermSingular.toLowerCase(),
                          })}
                          primaryButtonAction={primaryButtonAction}
                          primaryButtonId={`inline-create-step-button-${id}`}
                          primaryButtonText={t('add_step', {
                            step: stepTermSingular.toLowerCase(),
                          })}
                          tooltipText={t('disabled_by_e_signature')}
                          withBorder={false}
                        />
                      </ActionRowWrapper>
                    )}
                    {eSignatureDisplayedAndRequired && (
                      <ESignatureRowContainer>
                        <ESignatureRowContent>
                          <Icon name='signature' size='xs' weight='solid' />
                          <span>{t('e_signature_is_required')}</span>
                        </ESignatureRowContent>
                      </ESignatureRowContainer>
                    )}
                  </>
                )}
              </div>
            )}
          </Droppable>
        </div>
      </ChildContainerRow>
    </div>
  );
};

export default CourseTableRow;
