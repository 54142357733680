import React from 'react';

import { usePlanBrandColors } from '../../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../../hooks/billing/usePlanTitle';
import { usePricePerInterval } from '../../../../../../../../hooks/billing/usePricePerInterval';
import { getPlanCardStatusText } from '../../../../../../../../lib/billing/getPlanCardStatusText';
import { MoveToNewPlanArrowImage } from '../../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../../lib/initTranslations';
import {
  CurrentSimplePricingPlan,
  NewSimplePricingPlan,
} from '../../../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { Discount } from '../../../../../../../../types/Discount';
import EmptyStateCard from '../../../../../shared/BillingFullScreenOverlay/PlanCards/EmptyStateCard/EmptyStateCard';
import {
  MoveToNewPlanImg,
  PlanCardBillingCycleText,
  PlanCardDetailsWrapper,
  PlanCardDivider,
  PlanCardFooterWrapper,
  PlanCardPrice,
  PlanCardSeatsIncludedText,
  PlanCardStatus,
  StyledPlanCardTitle,
  StyledPlanCardWrapper,
} from '../../../../../shared/BillingFullScreenOverlay/PlanCards/styles';

const t = initTranslations('simple_pricing.upgrade_plan_overlay_content.plan_cards');

type Props = {
  plan: CurrentSimplePricingPlan | NewSimplePricingPlan;
  discount: Discount | undefined;
  isCurrentPlanSameAsNew: boolean;
};

const PlanCard = ({ plan, discount, isCurrentPlanSameAsNew }: Props) => {
  const { name, employeesSize, billingInterval, totalPrice } = plan;
  const isNewPlan = 'nextPaymentDate' in plan;
  const isAnnualInterval = billingInterval === 'year';
  const isHoldPlan = name === 'hold';
  const isFree3SeatsPlan = name === 'free';
  const cardId = `${isNewPlan ? 'new' : 'current'}-plan-card`;
  const { color: planBrandColor } = usePlanBrandColors({ name });
  const planTitle = usePlanTitle({ name });
  const pricePerInterval = usePricePerInterval({
    isAnnualInterval,
    isHoldPlan,
    isFree3SeatsPlan,
    totalPrice,
    discount,
  });

  if (isNewPlan && isCurrentPlanSameAsNew) {
    return <EmptyStateCard key='empty-state-card' />;
  }

  return (
    <StyledPlanCardWrapper id={cardId} isNewPlan={isNewPlan} key={cardId}>
      {!isNewPlan && (
        <MoveToNewPlanImg alt={t('alt_move_to_new_plan')} src={MoveToNewPlanArrowImage} />
      )}

      <PlanCardStatus text={getPlanCardStatusText(isNewPlan)} />
      <StyledPlanCardTitle planBrandColor={planBrandColor} text={planTitle} />

      <PlanCardDetailsWrapper isNewPlan={isNewPlan}>
        {!!employeesSize && !isHoldPlan && (
          <PlanCardSeatsIncludedText text={t('employees_size', { count: employeesSize })} />
        )}
        <PlanCardBillingCycleText
          text={t('billing_cycle', {
            interval: isAnnualInterval ? t('yearly') : t('monthly'),
          })}
        />
      </PlanCardDetailsWrapper>
      <PlanCardFooterWrapper>
        <PlanCardDivider planBrandColor={planBrandColor} />
        <PlanCardDetailsWrapper isNewPlan={isNewPlan}>
          <PlanCardPrice text={pricePerInterval} />
        </PlanCardDetailsWrapper>
      </PlanCardFooterWrapper>
    </StyledPlanCardWrapper>
  );
};

export default PlanCard;
