import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Icon from '../../../../design_system/display/icons/Icon';
import {
  MultilineTruncatedText,
  TruncatedText,
} from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd1, fontMd3, fontSm4 } from '../../../../styled/TypeSystem';
import RouterLink from '../../../publicApplication/RouterLink';

export const TemplateOutlineRowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  flex-direction: inherit;

  @media (max-width: ${mediaBreakpointPxSm}) {
    flex-direction: column;
  }
`;

export const TemplateOutlineRowIconWrapper = styled.div`
  display: flex;
  height: 2rem;
  min-width: 2rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  background: ${({ theme: { vars } }) => vars.foundationBase1};
  ${fontMd3};
`;

export const TemplateOutlineRowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.constants.spacerMd2};
  min-width: 16rem;
  max-width: 70%;
  width: 100%;

  @media (max-width: ${mediaBreakpointPxSm}) {
    max-width: unset;
    margin: ${({ theme }) => theme.constants.spacerMd2};
  }
`;

export const TemplateOutlineRowTitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TemplateOutlineRowTitle = styled(RouterLink)`
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${TruncatedText({})};
  ${fontMd1};
`;

export const TemplateOutlineRowDescription = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${fontSm4};
`;

export const TemplateOutlineRowMetadata = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm4};
`;

export const TemplateOutlineRowMetadataIcon = styled(Icon)`
  margin: 0 ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const TemplateOutlineRowCategoryBadgeWrapper = styled.div`
  display: flex;
  min-width: 11.25rem;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  padding: 0 ${({ theme }) => theme.constants.spacerMd2};

  @media (max-width: ${mediaBreakpointPxSm}) {
    align-self: flex-start;
    padding: 0 0 ${({ theme }) => theme.constants.spacerMd2} 0;
  }
`;

export const TemplateOutlineRowViewButton = styled(DefaultButton)`
  margin: 0 ${({ theme }) => theme.constants.spacerMd2} 0 auto;
  &:has(font) {
    min-width: auto;
  }

  @media (max-width: ${mediaBreakpointPxSm}) {
    align-self: stretch;
    margin: unset;
  }
`;
