import React from 'react';

import usePrivateConfigs from '../../../../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import Link from '../../../../../../design_system/Link';
import { ErrorMessageWrapper, Text, Title } from './styles';

const t = initTranslations('e_signature.error');

const ESignatureErrorMessage = () => {
  const { configs } = usePrivateConfigs();
  const { productTerm } = useAccountTerminology();

  return (
    <ErrorMessageWrapper id='e-signature-error-message'>
      <Title>{t('title')}</Title>
      <Text text={t('description_1')} />
      <Text
        text={
          <>
            {t('description_2')}
            <Link
              href={configs['E_SIGNATURE_HELP_PAGE']}
              size='sm'
              target='_blank'
              text={t('link', { productTerm, skipNoTranslate: true })}
            />
            {t('description_3')}
          </>
        }
      />
    </ErrorMessageWrapper>
  );
};

export default ESignatureErrorMessage;
