import { useFormikContext } from 'formik';
import React from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { SectionDivider } from '../../../AdvancedSettings/styles';
import { SettingsData } from '../../types';
import SignaturePermissionsForm from './SignaturePermissionsForm';
import SignatureToggle from './SignatureToggle';

const SignaturePermissionSection = () => {
  const {
    hasESignatureFeature,
    splitFeatures: { eSignatureToggleSectionEnabled },
  } = useCurrentAccount();
  const showSignaturesSectionWithToggle = eSignatureToggleSectionEnabled && !hasESignatureFeature;
  const { values } = useFormikContext<SettingsData>();

  return (
    <>
      {showSignaturesSectionWithToggle ? (
        <>
          <SectionDivider />
          <SignatureToggle />
          {values.allowSignatures && <SignaturePermissionsForm />}
        </>
      ) : (
        values.allowSignatures && (
          <>
            <SectionDivider />
            <SignaturePermissionsForm />
          </>
        )
      )}
    </>
  );
};

export default SignaturePermissionSection;
