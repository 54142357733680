import styled, { css } from 'styled-components';

import { CreateFromTemplateExpanded, ImportDocumentExpanded } from '../../../../../lib/gcsImages';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd5, fontSm5 } from '../../../../styled/TypeSystem';

export const OverlayContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const OverlayTitle = styled.a`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontMd5}
`;

const sharedTextStyles = css`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};

  ${fontSm5}
`;

export const OverlaySubtitle = styled.h5`
  margin-bottom: 5.5rem;

  ${sharedTextStyles}
`;

export const CreateCurriculumOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10.5rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

export const OptionImage = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5%);
  }

  &:hover ${OptionImage}.create-from-scratch {
    transform: rotate(10deg);
  }

  &:hover ${OptionImage}.create-from-template {
    content: url(${CreateFromTemplateExpanded});
  }

  &:hover ${OptionImage}.create-from-document {
    content: url(${ImportDocumentExpanded});
  }
`;

export const OptionImageWrapper = styled.div`
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  ${OptionItem}:hover & {
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidth2xl} solid ${vars.accentSubdued1}`};
  }
`;

export const OptionText = styled.div`
  ${sharedTextStyles}
`;
