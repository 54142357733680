import { WebSocketStatus } from '@hocuspocus/provider';
import React, { useState } from 'react';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import useContentStyles from '../../../../../hooks/useContentStyles';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { LimitedStep } from '../../../../../types/Step';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import CollaborationUsers from '../../components/CollaborationUsers/CollaborationUsers';
import StepHeading from '../../components/StepHeading/StepHeading';
import StepVersionIcon from '../../components/StepHeading/StepVersionIcon';
import StepTitle from '../../components/StepTitle/StepTitle';
import { useStepRange } from '../../hooks/useStepRange';
import {
  ContentBlock,
  Divider,
  HeaderIcons,
  StepContentBody,
  StyledEditorContent,
  StyledHrEdit,
} from '../../shared/styles';
import NavigationButtons from '../components/NavigationButtons/NavigationButtons';
import NoTranslationModal from '../NoTranslationModal';
import { EditorProps } from './Editor';

type Props = {
  courseSteps: LimitedStep[];
} & EditorProps;

const StepContent = ({
  collabState,
  collabUsers,
  courseSteps,
  step: { courseId, title, id, emoji, disableTranslation },
}: Props) => {
  const { editor } = useEditorContext();
  const contentStyles = useContentStyles();
  const [showNoTranslationModal, setShowNoTranslationModal] = useState(true);
  const { stepPosition, isLastStep } = useStepRange(courseSteps, id);
  const { language, editorTranslationPopupAccepted } = useCurrentUser();
  const {
    hasMultiLanguageFeature,
    splitFeatures: { readVersionHistoryEnabled },
  } = useCurrentAccount();

  const {
    step: { singular: stepSingular },
  } = useAccountTerminology();

  const enableNoTranslationModal =
    showNoTranslationModal &&
    hasMultiLanguageFeature &&
    language &&
    language != 'en' &&
    !editorTranslationPopupAccepted;

  return (
    <>
      <ContentBlock hasPaddingBottom={isLastStep}>
        <StepHeading
          currentItem={stepPosition}
          rangeTitle={stepSingular}
          totalItems={courseSteps.length}
        >
          <HeaderIcons>
            {collabState === WebSocketStatus.Connected && (
              <CollaborationUsers users={collabUsers} />
            )}
            {readVersionHistoryEnabled && (
              <>
                <Divider />
                <StepVersionIcon id={id} />
              </>
            )}
          </HeaderIcons>
        </StepHeading>
        <StepContentBody className='step-content' hasBottomBorderRadius={isLastStep}>
          <ContentBlock>
            <StepTitle
              courseId={courseId}
              disableTranslation={disableTranslation}
              emoji={emoji}
              id={id}
              title={title}
            />
            <StyledHrEdit />
            <StyledEditorContent
              $contentStyles={contentStyles}
              className='editor-content'
              editor={editor}
            />
          </ContentBlock>
          <NavigationButtons stepId={id} />
        </StepContentBody>
      </ContentBlock>
      {enableNoTranslationModal && (
        <NoTranslationModal closeModal={() => setShowNoTranslationModal(false)} />
      )}
    </>
  );
};

export default StepContent;
