import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

import ColorPickerTrigger from '../../../design_system/buttons/DeprecatedColorPicker/ColorPickerTrigger';
import ColorPicker from '../../../design_system/ColorPicker/ColorPicker';
import ColorPickerInput from '../../../design_system/ColorPicker/ColorPickerInput';
import { FILL_COLORS } from '../../../design_system/ColorPicker/constants';
import FillSwatch from '../../../design_system/ColorPicker/Swatches/FillSwatch';
import { SwatchesWrapper } from '../../../design_system/ColorPicker/Swatches/styles';
import { paletteColorDecoder } from '../../../design_system/helpers';
import useActiveMenuHandler from '../../../design_system/useActiveMenuHandler';
import DropdownWithPoppableMenu from '../../shared/DropdownWithPoppableMenu';
import { HORIZONTAL_LINE_WIDTH } from '../shared/constants/accountSettings';
import { ColorPickerWrapper, HorizontalLineRow, Line, LineItem, SelectedLine } from './styles';
import { HorizontalLineProps } from './types';

const FLYOUT_ID = 'horizontal-line-color';

const HorizontalLine = ({ brandStylesHr, setHrColor, setHrWidth }: HorizontalLineProps) => {
  const { palettes } = useTheme();
  const { closeMenu } = useActiveMenuHandler({ menuId: FLYOUT_ID });

  const decodedHrColor = useMemo(
    () => paletteColorDecoder(palettes, brandStylesHr['border-color']),
    [brandStylesHr, palettes]
  );

  const handleHrColorChange = (color: string) => {
    setHrColor(color);
    closeMenu();
  };

  const lineOptions = HORIZONTAL_LINE_WIDTH.map((item, index) => (
    <LineItem key={`option-item-${index}`}>
      <Line color={decodedHrColor} height={item} />
    </LineItem>
  ));

  const selectedLineIndex = HORIZONTAL_LINE_WIDTH.indexOf(brandStylesHr['border-width']);

  const selectedLine = (
    <SelectedLine>
      <Line color={decodedHrColor} height={brandStylesHr['border-width']} />
    </SelectedLine>
  );

  return (
    <HorizontalLineRow>
      <DropdownWithPoppableMenu
        activeOptionIndex={selectedLineIndex}
        id='horizontal-line-dropdown'
        menuId='horizontal-line-menu'
        menuPlacement='bottom-end'
        options={lineOptions}
        selectedOption={selectedLine}
        setSelectedOption={(index) => setHrWidth(HORIZONTAL_LINE_WIDTH[index])}
      />
      <ColorPickerWrapper>
        <ColorPicker
          flyoutId={FLYOUT_ID}
          strategy='absolute'
          trigger={
            <ColorPickerTrigger
              ariaLabel='horizontal-line-color'
              buttonId='horizontal-line-color-button'
              color={decodedHrColor}
              type='background-color'
            />
          }
        >
          <SwatchesWrapper rowCount={8}>
            {FILL_COLORS.map((color) => {
              return (
                <FillSwatch
                  activeColor={decodedHrColor}
                  color={paletteColorDecoder(palettes, color)}
                  key={color}
                  onClick={() => handleHrColorChange(color)}
                />
              );
            })}
          </SwatchesWrapper>
          <ColorPickerInput color={decodedHrColor} onChange={handleHrColorChange} />
        </ColorPicker>
      </ColorPickerWrapper>
    </HorizontalLineRow>
  );
};

export default HorizontalLine;
