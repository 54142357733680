import React from 'react';

import { openResponsibilityDetailsModal } from '../../../../../../redux/domains/responsibilityDetailsModal/responsibilityDetailsModalSlice';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { Responsibility } from '../../../../../../types/Responsibility';
import { StyledResponsibilityItem } from '../../../../shared/Responsibility/styles';
import { ResponsibilityNameListWrapper } from './styles';
import { ResponsibilityNameListProps } from './types';

const ResponsibilityNameList = ({ responsibilities, isGroup }: ResponsibilityNameListProps) => {
  const dispatch = useAppDispatch();

  const handleResponsibilityClick = (responsibility: Responsibility) => {
    dispatch(
      openResponsibilityDetailsModal({
        id: responsibility.id,
        mode: isGroup ? 'view' : 'edit',
      })
    );
  };

  const responsibiliableType = isGroup ? 'group' : 'user';

  return (
    <ResponsibilityNameListWrapper>
      {responsibilities.map((responsibility) => (
        <StyledResponsibilityItem
          className='user-group-responsibility-name'
          key={`${responsibiliableType}_responsibility_${responsibility.id}`}
          onClick={() => handleResponsibilityClick(responsibility)}
        >
          {responsibility.name}
        </StyledResponsibilityItem>
      ))}
    </ResponsibilityNameListWrapper>
  );
};

export default ResponsibilityNameList;
