import React from 'react';

import { couponToDiscount } from '../../../../../lib/billing/stripeCouponToDiscount';
import capitalize from '../../../../../lib/capitalize';
import { getFormattedPrice } from '../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../types/BillingInterval';
import { SimplePricingBillingPlanName } from '../../../../../types/BillingPlanName';
import { Coupon } from '../../../../../types/Coupon';
import CheckoutAddonPrice from '../CheckoutAddonPrice/CheckoutAddonPrice';
import {
  CheckoutPriceDetailsWrapper,
  CheckoutTotalSectionTitle,
  CheckoutTotalSectionWrapper,
} from './styles';

type CheckoutWithAddonsTotalSectionProps = {
  totalPrice: number;
  coupon: Coupon;
  billingInterval: BillingInterval;
  planName: SimplePricingBillingPlanName;
};

const t = initTranslations('checkout_overlay');

const CheckoutWithAddonsPlanDetailsSection = ({
  totalPrice,
  coupon,
  billingInterval,
  planName,
}: CheckoutWithAddonsTotalSectionProps) => {
  const discount = couponToDiscount(coupon);
  const formattedPlanName = t('plan_name', { planName: capitalize(planName) });

  return (
    <CheckoutTotalSectionWrapper>
      <CheckoutTotalSectionTitle text={formattedPlanName} />
      <CheckoutPriceDetailsWrapper>
        <CheckoutAddonPrice
          billingInterval={billingInterval}
          price={getFormattedPrice(totalPrice)}
          strike={!!discount}
        />

        {discount && (
          <CheckoutAddonPrice
            billingInterval={billingInterval}
            price={getFormattedPrice(totalPrice, discount)}
          />
        )}
      </CheckoutPriceDetailsWrapper>
    </CheckoutTotalSectionWrapper>
  );
};

export default CheckoutWithAddonsPlanDetailsSection;
