import { Addon } from '../../types/Addon';
import ESignaturePreviewSrc1 from '../../videos/e-signature-preview-1.mp4';
import ESignaturePreviewSrc2 from '../../videos/e-signature-preview-2.mp4';

export function getAddonOverlayVideoPreviews(addon: Addon) {
  switch (addon) {
    case Addon.ESignature:
      return [ESignaturePreviewSrc1, ESignaturePreviewSrc2];
  }
}
