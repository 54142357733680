import styled, { css } from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import Toggle from '../../../design_system/input/controls/Toggle';
import Link from '../../../design_system/Link';
import { mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { fontMd1, fontMd3, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { FormGroup } from '../../shared/FormGroup';

export const StyledToggle = styled(Toggle)`
  align-items: flex-start;
  max-width: 20%;
`;

export const TabSectionWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
  display: flex;
  flex-direction: column;
`;

export const ToggleSettingContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
`;

export const ToggleInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FormSectionTitle = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontBold,
  })};

  ::first-letter {
    text-transform: capitalize;
  }
  ${fontMd1};
`;

export const FormSectionDescription = styled.div`
  ${({ theme: { vars } }) => ({ color: vars.textSubdued })};

  ::first-letter {
    text-transform: capitalize;
  }
  ${fontSm5};
`;

export const ToggleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const StyledFormGroup = styled(FormGroup)`
  width: 100%;
  @media (min-width: ${mediaBreakpointPxMd}) {
    width: calc(50% - ${({ theme: { constants } }) => constants.spacerSm3});
  }
`;

export const SaveButtonWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    padding: ${constants.spacerMd2};
    background: ${vars.foundationSurface1};
    border-top: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
  `
);

export const OptionWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

export const MultiSelectInputRow = styled.div`
  width: 100%;

  @media (min-width: ${mediaBreakpointPxMd}) {
    width: 50%;
  }
`;

export const MutiSelectLabel = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontSemibold,
  })};

  ::first-letter {
    text-transform: capitalize;
  }
  ${fontSm5};
`;

export const SubduedDescription = styled.div`
  margin-top: 0;
  ${({ theme: { vars } }) => ({ color: vars.textPlaceholder })};
  ${fontSm4};
`;

export const SpecializationTagTerminologyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const PageHeader = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontBold,
    marginTop: constants.spacerLg1,
  })};
  ${fontMd3};
`;

export const FormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.constants.spacerSm3};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerSm3} 0 ${constants.spacerMd2}`};
`;

export const SectionDivider = styled.hr`
  border-width: ${({ theme: { constants } }) => constants.borderWidthSm} 0 0 0;
  border-style: solid;
  border-color: ${({ theme: { vars } }) => vars.borderSurface2};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const SignatureInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

export const InfoTitle = styled.div`
  ${({ theme: { constants, vars } }) => ({
    color: vars.textDefault,
    fontWeight: constants.fontSemibold,
  })};
`;

export const StyledLink = styled(Link)`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  margin-left: ${({ theme: { constants } }) => constants.spacerSm2};
`;
