import styled, { css } from 'styled-components';

import { fontMd1 } from '../../../../styled/TypeSystem';

export const CheckoutAddonItem = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    justify-content: space-between;
    gap: ${constants.spacerSm3};
    padding: ${constants.spacerSm3} 0;
  `}
`;

export const CheckoutAddonItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckoutAddonItemName = styled.div`
  ${({ theme: { constants } }) => css`
    font-weight: ${constants.fontSemibold};
    ${fontMd1};
  `}
`;
