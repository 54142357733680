import React from 'react';

import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { FiltersEmptyStateDark, FiltersEmptyStateLight } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { CardBody, CardLink } from '../IntegrationCard/styles';
import {
  CardDescription,
  CardReadMore,
  CardTitle,
  CardTitleWrapper,
  StyledCard,
  StyledCardImage,
} from './styles';

const t = initTranslations('integrations.default_card');

const DefaultIntegrationCard = () => {
  const { configs } = usePrivateConfigs();
  const integrationPortalUrl = configs['INTEGRATION_PORTAL_URL'];
  return (
    <CardLink className='default-integration-card' href={integrationPortalUrl}>
      <StyledCard>
        <CardTitleWrapper>
          <CardTitle>{t('title')}</CardTitle>
        </CardTitleWrapper>
        <CardBody>
          <StyledCardImage
            alt={t('title')}
            darkImage={FiltersEmptyStateDark}
            lightImage={FiltersEmptyStateLight}
          />
          <CardDescription>{t('description')}</CardDescription>
          <CardReadMore>{t('share_feedback')}</CardReadMore>
        </CardBody>
      </StyledCard>
    </CardLink>
  );
};

export default DefaultIntegrationCard;
