import { DragEndEvent } from '@dnd-kit/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../redux/baseQueries/types';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { messageFromError } from '../../../../redux/errors/helpers';
import {
  useUpdateResponsibilityMutation,
  useUpdateResponsibilityPositionMutation,
} from '../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import { ResponsibilitiesResponse } from '../../../../redux/services/resourceApis/responsibilities/types';
import { ResponsibiliableType } from '../../../../types/AssignedResponsibility';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import LoadingContainer from '../../../design_system/LoadingContainer';
import InputLineField from '../../groups/InputLineField';
import { RESPONSIBILITY_MAX_CHARACTERS } from '../../groups/shared/constants/groups';
import DragAndDropListWrapper from '../../shared/DragAndDrop/DragAndDropListWrapper';
import ResponsibilityListItem from '../ResponsibilitiesListItem';

const SpacedLi = styled.li`
  list-style: none;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
`;

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  position: relative;
`;

const t = initTranslations('responsibilities');

interface Props {
  responsibiliable_id: number;
  responsibiliable_type: ResponsibiliableType;
  result: UseQueryResult<ResponsibilitiesResponse>;
}

const ResponsibilitiesList = ({ responsibiliable_id, responsibiliable_type, result }: Props) => {
  const [assignedResponsibilityEditId, setAssignedResponsibilityEditId] = useState<string | null>(
    null
  );
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const {
    isLoading: isAssignedResponsibilitiesDataLoading,
    error: assignedResponsibilitiesError,
    data: assignedResponsibilitiesData,
  } = result;

  const [
    updateResponsibility,
    {
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
      error: updateError,
      reset: updateReset,
    },
  ] = useUpdateResponsibilityMutation();
  const [updateResponsibilityPosition, updatePositionResult] =
    useUpdateResponsibilityPositionMutation();

  useEffect(() => {
    if (isUpdateSuccess) updateReset();
  }, [isUpdateSuccess, updateReset]);

  useDisplayFlashOnResponse({
    result: updatePositionResult,
    errorMessage:
      messageFromError(updatePositionResult.error)?.join(', ') ||
      t('remove_failure', { responsibility: responsibilitySingular.toLowerCase() }),
  });

  if (isAssignedResponsibilitiesDataLoading) return <LoadingContainer />;
  if (assignedResponsibilitiesError)
    return <BasicErrorDisplay error={assignedResponsibilitiesError} />;
  if (!assignedResponsibilitiesData) return <></>;
  const { assigned_responsibilities: assignedResponsibilities } = assignedResponsibilitiesData;

  const handleOnDragEnd = ({ over, active }: DragEndEvent): void => {
    if (over) {
      const overIndex = assignedResponsibilities
        .map((assignedResponsibility) => assignedResponsibility.id)
        .indexOf(over.id as string);
      updateResponsibilityPosition({
        id: active.id as string,
        position: overIndex + 1,
        responsibiliable_id,
        responsibiliable_type,
      });
    }
  };

  return (
    <DragAndDropListWrapper
      handleDragEnd={handleOnDragEnd}
      itemHeight='3.5rem'
      items={assignedResponsibilities}
    >
      <StyledUl>
        {assignedResponsibilities.map((assignedResponsibility) => {
          const { id } = assignedResponsibility;

          if (assignedResponsibility.id === assignedResponsibilityEditId) {
            return (
              <SpacedLi className='bottom-bordered-li' key={assignedResponsibilityEditId}>
                <InputLineField
                  buttonId='save-input-line-name-textarea'
                  cancelOrClose={() => setAssignedResponsibilityEditId(null)}
                  closeAfterSave={isUpdateSuccess}
                  initialValue={assignedResponsibility.responsibility.name}
                  inputFor={t('add_responsibility_input_for', {
                    responsibility: responsibilitySingular,
                  })}
                  isLoading={isUpdateLoading}
                  isSuccess={isUpdateSuccess}
                  maxCharacters={RESPONSIBILITY_MAX_CHARACTERS}
                  placeholder={t('inline_edit.placeholder', {
                    responsibility: responsibilitySingular.toLowerCase(),
                  })}
                  responseError={messageFromError(updateError)?.join(', ')}
                  submitChanges={(name) =>
                    updateResponsibility({
                      id: assignedResponsibilityEditId,
                      name: name.trim(),
                    })
                  }
                  textAreaId='input-line-name-textarea'
                />
              </SpacedLi>
            );
          } else {
            return (
              <ResponsibilityListItem assignedResponsibility={assignedResponsibility} key={id} />
            );
          }
        })}
      </StyledUl>
    </DragAndDropListWrapper>
  );
};

export default ResponsibilitiesList;
