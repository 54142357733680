import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import { GetElementResponse } from '../../../../../redux/services/resourceApis/elements/types';
import { EditorFlowchartResponse } from '../../../../../redux/services/resourceApis/flowcharts/types';
import { ContentType } from '../../../../../types/ContentType';
import IconButton from '../../../../design_system/buttons/IconButton';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { ThreeDotMenuOptionProps } from '../../../../design_system/Triage/menus/ThreeDotMenu/types';
import StatusDropdown from '../../../editor/components/StatusDropdown/StatusDropdown';
import { routes } from '../../../publicApplication/applicationRouter';
import { AutoSaveStatus } from '../../../shared/AutoSaveStatusUpdater/types';
import DeprecatedAutoSaveStatusIndicator from '../../../shared/DeprecatedAutoSaveStatusIndicator/DeprecatedAutoSaveStatusIndicator';
import ViewModeDropdown from '../../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../../shared/ViewModeDropdown/ViewOptions';
import BaseElementTopNav from './BaseElementTopNav';
import TopNavBreadcrumb from './TopNavBreadcrumb';
import TopNavLoader from './TopNavLoader';

const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const DropdownWrapper = styled.div`
  width: 100%;
`;

const t = initTranslations('curriculums.element_top_nav');

export type Props = {
  contentType: Exclude<ContentType, ContentType.Course>;
  data: GetElementResponse | EditorFlowchartResponse | undefined;
  editing?: boolean;
  isLoading: boolean;
  saveStatus?: AutoSaveStatus;
  showSaving?: boolean;
  threeDotMenuOptions?: ThreeDotMenuOptionProps[];
};

const TopNavContent = ({
  contentType,
  data,
  editing = false,
  isLoading,
  showSaving = false,
  threeDotMenuOptions,
}: Props) => {
  const { slug } = useCurrentAccount();
  const abilities = useCurrentUserAbilities();

  if (isLoading) return <TopNavLoader />;
  if (!data) return null;

  const {
    title,
    emoji,
    curriculum,
    curriculumElement: { id: curriculumElementId },
    status,
    id: elementId,
  } = data;
  const { id: curriculumId, title: curriculumTitle, emoji: curriculumEmoji } = curriculum;
  const canEditCurriculum = abilities.can('update', `EditCurriculum-${curriculumId}`);

  const route = editing
    ? routes.curriculumEdit({ id: curriculumId, slug })
    : routes.curriculumShow({ id: curriculumId, slug });

  return (
    <>
      <TopNavBreadcrumb
        contentType={contentType}
        contentTypeTitle={title}
        elementId={elementId}
        emoji={emoji}
        subjectEmoji={curriculumEmoji}
        subjectHref={route.href}
        subjectTitle={curriculumTitle}
      />
      <ActionsWrapper>
        {showSaving && (
          <DeprecatedAutoSaveStatusIndicator status={AutoSaveStatus.Saving} updatedAtInWords='' />
        )}

        {threeDotMenuOptions && threeDotMenuOptions.length > 0 && (
          <ThreeDotMenu id='element-top-nav-three-dot-menu' menuOptions={threeDotMenuOptions} />
        )}
        {canEditCurriculum && (
          <DropdownWrapper>
            <ViewModeDropdown
              initialSelectedOptionIndex={editing ? 0 : 1}
              options={viewOptions({
                id: elementId,
                slug,
                contentType,
              })}
              titleBold
            />
          </DropdownWrapper>
        )}
        {editing && (
          <DropdownWrapper>
            <StatusDropdown curriculumId={curriculumId} element={{ curriculumElementId, status }} />
          </DropdownWrapper>
        )}
        <IconButton
          ariaLabel={t('close_button.aria_label', { contentType })}
          buttonSize='md'
          id='close-content-button'
          name='xmark'
          onClick={() => route.push()}
          weight='regular'
        />
      </ActionsWrapper>
    </>
  );
};

const ElementTopNav = ({
  contentType,
  data,
  editing,
  isLoading,
  showSaving,
  threeDotMenuOptions,
}: Props) => {
  return (
    <BaseElementTopNav>
      <TopNavContent
        contentType={contentType}
        data={data}
        editing={editing}
        isLoading={isLoading}
        showSaving={showSaving}
        threeDotMenuOptions={threeDotMenuOptions}
      />
    </BaseElementTopNav>
  );
};

export default ElementTopNav;
