import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../../contexts/TrainingPathContext';
import { useTrainingPathSetContext } from '../../../../../../contexts/TrainingPathSetContext';
import initTranslations from '../../../../../../lib/initTranslations';
import IconButton from '../../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import Hoverable from '../../../../../design_system/Hoverable';
import InputField from '../../../../../design_system/Triage/InputField';
import { fontMd3 } from '../../../../../styled/TypeSystem';

const Container = styled.div`
  display: flex;
  width: auto;
`;

const InputStyle = css`
  min-width: 10rem;
  max-width: 60rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerSm3}`};
  ${fontMd3};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
`;

const StyledTitleInputField = styled(InputField)<{ isIdle: boolean }>(
  ({ theme: { constants }, isIdle }) => css`
    border-radius: ${constants.borderRadiusMd};
    width: auto;
    ${InputStyle};

    ${isIdle &&
    css`
      border-color: transparent;
      padding: 0;
    `};
  `
);

const HiddenSpan = styled.span`
  ${InputStyle};
`;

const t = initTranslations('training_path.path_config.config_set');

const MAX_TITLE_LENGTH = 50;
const titleTooLongError = t('title_too_long_error', { max: MAX_TITLE_LENGTH });

type Props = {
  showAndFocus: boolean;
  setShowAndFocus: (value: boolean) => void;
  titleVisible: boolean;
  setId: string;
};

const TitleInput = ({ setId, showAndFocus, setShowAndFocus, titleVisible }: Props) => {
  const {
    trainingPathSet: { title },
    updateErrors,
    updateTrainingPathSet,
  } = useTrainingPathSetContext();
  const { updateInProgress } = useTrainingPathContext();
  const [titleFocused, setTitleFocused] = useState(false);
  const [titleHovered, setTitleHovered] = useState(false);
  const [titleError, setTitleError] = useState<string>();
  const [titleValue, setTitleValue] = useState(title);

  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  useEffect(() => {
    if (updateErrors?.title) {
      const errorMessage = updateErrors.title.join(', ');
      if (!!errorMessage) {
        setTitleError(errorMessage);
        inputRef.current?.focus();
      }
    }
  }, [updateErrors]);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      inputRef.current.style.width = `${spanRef.current.offsetWidth + 2}px`;
    }
  }, [titleValue]);

  const validateAndSubmitTitle = useCallback(() => {
    if (titleError) {
      inputRef.current?.focus();
      return;
    }
    setShowAndFocus(false);
    setTitleFocused(false);
    title !== titleValue && updateTrainingPathSet({ title: titleValue });
  }, [titleError, setShowAndFocus, title, titleValue, updateTrainingPathSet]);

  if (showAndFocus || !!title) {
    return (
      <Container>
        <Hoverable className='edit-set-title' setIsHovered={setTitleHovered}>
          <StyledTitleInputField
            autoFocus={showAndFocus || titleFocused}
            errorText={titleError}
            forwardRef={inputRef}
            isIdle={!titleFocused && !titleHovered}
            name='title'
            onBlur={() => !updateInProgress && validateAndSubmitTitle()}
            onChange={({ target }) => {
              const title = target.value;
              const titleError = title.length > MAX_TITLE_LENGTH ? titleTooLongError : undefined;
              setTitleValue(target.value);
              setTitleError(titleError);
            }}
            onFocus={(e) => {
              if (updateInProgress) {
                e.preventDefault();
                e.currentTarget.blur();
                return;
              }
              setTitleFocused(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              } else {
                e.persist();
              }
            }}
            placeholder={t('title_placeholder')}
            type='text'
            value={titleValue || ''}
          />
          <HiddenSpan
            ref={spanRef}
            style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'pre' }}
          >
            {titleValue}
          </HiddenSpan>
        </Hoverable>
        <Tooltip
          id={`hide-show-set-title-tooltip-${setId}`}
          text={titleVisible ? t('hide_title_tooltip') : t('show_title_tooltip')}
        />
        <IconButton
          ariaLabel={titleVisible ? t('hide_title_aria_label') : t('show_title_aria_label')}
          className='hide-show-set-title-toggle'
          name={titleVisible ? 'eye' : 'eye-slash'}
          onClick={() => updateTrainingPathSet({ titleVisible: !titleVisible })}
          tooltipId={`hide-show-set-title-tooltip-${setId}`}
        />
      </Container>
    );
  }

  return null;
};

export default TitleInput;
