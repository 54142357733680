import React, { useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import {
  ReportsEmptyState as EmptyState,
  ReportsEmptyStateDark as EmptyStateDark,
} from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { useGetSurveyReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import {
  ActionButtons,
  ActionsHeaderContainer,
  RightAlignedHeaderActions,
} from '../../AllUsersReportPage/AllUsersReportTable/ActionsHeader/styles';
import { TableStyled, TableWrapper } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { sortIcon } from '../../SortIcon';
import SurveyDownloadCsvReport from '../SurveyDownloadCsvReport';
import SurveyReportTableHeader from './SurveyReportTableHeader';
import TableRow from './TableRow';
import { Action, TableHeaderState } from './types';

const SurveyReportTable = ({ surveyId }: { surveyId: number }) => {
  const t = initTranslations('reports');
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/curriculum_report`;
  const [cookies, setCookie] = useCookies(['survey_report_selected_sort']);
  const isCookies = cookies.survey_report_selected_sort;
  const sortColumn = isCookies && cookies.survey_report_selected_sort['column'];
  const sortDirection = isCookies && cookies.survey_report_selected_sort['dir'];

  const initialHeaderState: TableHeaderState = {
    sortCol: sortColumn ?? 'position',
    sortColDir: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
    page: 1,
  };

  const tableHeaderReducer = (state: TableHeaderState, action: Action): TableHeaderState => {
    switch (action.type) {
      case 'changePage': {
        return { ...state, page: action.page };
      }
      case 'sortColumnChange': {
        const sortDirection =
          state.sortCol === action.sortCol && state.sortColDir === 'asc' ? 'desc' : 'asc';
        return {
          ...state,
          sortCol: action.sortCol,
          sortColDir: sortDirection,
          sortIcon: sortIcon(sortDirection),
        };
      }
      case 'restoreSurveyReportDefault': {
        if (!action.sortCol || !action.sortColDir) return state;
        return {
          sortCol: action.sortCol,
          sortColDir: action.sortColDir,
          sortIcon: sortIcon(action.sortColDir),
          page: 1,
        };
      }
    }
  };

  const [tableHeaderState, dispatch] = useReducer(tableHeaderReducer, initialHeaderState);

  useEffect(() => {
    setCookie(
      'survey_report_selected_sort',
      JSON.stringify({ column: tableHeaderState.sortCol, dir: tableHeaderState.sortColDir }),
      { path: cookiePath }
    );
  }, [cookiePath, setCookie, tableHeaderState.sortColDir, tableHeaderState.sortCol]);

  const { data, isFetching, isError } = useGetSurveyReportQuery({
    sortCol: tableHeaderState.sortCol,
    sortColDir: tableHeaderState.sortColDir,
    page: tableHeaderState.page,
    id: surveyId,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    survey_questions: surveyQuestion,
    limit_value: limitValue,
    total_pages: totalPages,
    total_survey_questions_count: totalElementsCount,
  } = data;

  return (
    <>
      <ActionsHeaderContainer>
        <RightAlignedHeaderActions>
          <ActionButtons>
            <SurveyDownloadCsvReport surveyId={surveyId} />
          </ActionButtons>
        </RightAlignedHeaderActions>
      </ActionsHeaderContainer>
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {surveyQuestion.length ? (
            <>
              <TableWrapper>
                <TableStyled className='survey-report-table'>
                  <SurveyReportTableHeader
                    dispatch={dispatch}
                    tableHeaderState={tableHeaderState}
                  />
                  <tbody>
                    {surveyQuestion.map((survey) => {
                      return <TableRow key={survey.id} surveys={survey} />;
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={tableHeaderState.page}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatch({ type: 'changePage', page });
                }}
                showPaginationDetails
                totalItemsCount={totalElementsCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('empty_state', { item: 'Tests' })}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default SurveyReportTable;
