import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';

const t = initTranslations('courses_and_templates');

const CoursesAndTemplates = () => {
  return (
    <PageContent>
      <PageHeader subtitle={t('subtitle')} title={t('title')} />
    </PageContent>
  );
};

export default CoursesAndTemplates;
