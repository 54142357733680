import { toCamelCase, toSnakeCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import {
  BrandingDataResponse,
  ClearbitDataResponse,
  CreateChatLogsParams,
  GetChatLogsResponse,
  GetCurriculumPreviewParams,
  GetCurriculumPreviewResponse,
  GetFollowUpQuestionResponse,
  GetGroupsPreviewResponse,
  GetPeoplePreviewResponse,
  RequestParams,
  ResetAiBotResponse,
  SummaryDataResponse,
  WebsiteContentDataResponse,
} from './types';

export const aiBotApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getBrandingData: builder.query<BrandingDataResponse, RequestParams>({
      query: (params) => ({
        url: 'ajax/ai_bot/poc/branding_data',
        params: toSnakeCase(params),
      }),
      transformResponse: (returnValue: BrandingDataResponse) => {
        return toCamelCase<BrandingDataResponse>(returnValue);
      },
    }),
    getWebsiteContent: builder.query<WebsiteContentDataResponse, RequestParams>({
      query: (params) => ({
        url: 'ajax/ai_bot/poc/website_content',
        params: toSnakeCase(params),
      }),
      transformResponse: (returnValue: WebsiteContentDataResponse) => {
        return toCamelCase<WebsiteContentDataResponse>(returnValue);
      },
    }),
    getChatLogs: builder.query<GetChatLogsResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/chat_logs',
      }),
      transformResponse: (returnValue: GetChatLogsResponse) => {
        return toCamelCase<GetChatLogsResponse>(returnValue);
      },
      providesTags: () => [{ type: 'ChatLog', id: 'LIST' }],
    }),
    createChatLog: builder.mutation<void, CreateChatLogsParams>({
      query: (params) => ({
        method: 'POST',
        url: 'ajax/ai_bot/chat_logs',
        body: toSnakeCase(params),
      }),
      invalidatesTags: [
        { type: 'ChatLog', id: 'LIST' },
        { type: 'DraftGroup', id: 'LIST' },
      ],
    }),
    resetActiveChatLog: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: 'ajax/ai_bot/chat_logs/reset_active_chat_log',
      }),
      invalidatesTags: [{ type: 'ChatLog', id: 'LIST' }],
    }),
    revertChatLogWithRelatedRecords: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: 'ajax/ai_bot/chat_logs/revert_chat_log_with_related_records',
      }),
      invalidatesTags: [{ type: 'ChatLog', id: 'LIST' }],
    }),
    getFollowUpQuestion: builder.query<GetFollowUpQuestionResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/chat_logs/follow_up_question',
      }),
    }),
    getGroupsPreview: builder.query<GetGroupsPreviewResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/chat_logs/groups_preview',
      }),
      providesTags: [{ type: 'DraftGroup', id: 'LIST' }],
    }),
    getPeoplePreview: builder.query<GetPeoplePreviewResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/chat_logs/people_preview',
      }),
    }),
    getCurriculumPreview: builder.query<GetCurriculumPreviewResponse, GetCurriculumPreviewParams>({
      query: (params: GetCurriculumPreviewParams) => ({
        url: 'ajax/ai_bot/chat_logs/curriculum_preview',
        params: toSnakeCase(params),
      }),
      transformResponse: (response: GetCurriculumPreviewResponse) => {
        return toCamelCase(response);
      },
    }),
    getClearbitData: builder.query<ClearbitDataResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/clearbit',
      }),
    }),
    getSummaryData: builder.query<SummaryDataResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/summary',
      }),
      transformResponse: (response: SummaryDataResponse) => toCamelCase(response),
    }),
    resetAiBot: builder.mutation<ResetAiBotResponse, void>({
      query: () => ({
        url: 'ajax/ai_bot/sessions',
        method: 'DELETE',
      }),
      transformResponse: (response: ResetAiBotResponse) => toCamelCase(response),
      invalidatesTags: [{ type: 'ChatLog', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateChatLogMutation,
  useGetChatLogsQuery,
  useGetClearbitDataQuery,
  useGetSummaryDataQuery,
  useGetGroupsPreviewQuery,
  useGetCurriculumPreviewQuery,
  useGetPeoplePreviewQuery,
  useResetAiBotMutation,
  useResetActiveChatLogMutation,
  useRevertChatLogWithRelatedRecordsMutation,
} = aiBotApi;
