import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useContentMetrics from '../../../../../hooks/useContentMetrics';
import useContentStyles from '../../../../../hooks/useContentStyles';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useScrollTopOnChange from '../../../../../hooks/useScrollTopOnChange';
import initTranslations from '../../../../../lib/initTranslations';
import { useUpdateStepContentMutation } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { GetStepResponse } from '../../../../../redux/services/resourceApis/steps/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import StepTitle from '../../components/StepTitle/StepTitle';
import { useStepRange } from '../../hooks/useStepRange';
import { SAVE_TIMEOUT } from '../../shared/constants/editor';
import {
  CardBackgroundShadowed,
  ContentLayout,
  EditorContentWrapper,
  LabelWrapper,
  StyledEditorContent,
  StyledHrEdit,
} from '../../shared/styles';
import { CREATE_TOOLBAR_HEIGHT } from '../../toolbar/Toolbar';
import NavigationButtons from '../components/NavigationButtons/NavigationButtons';
import NoTranslationModal from '../NoTranslationModal';
import TopicHeader from '../TopicHeader/TopicHeader';

interface Props {
  step: GetStepResponse;
}

const t = initTranslations('editor');
const DeprecatedEditor = ({ step }: Props) => {
  const { editor, setSaveState } = useEditorContext();
  const [updateStep, { isLoading: isUpdating, isSuccess }] = useUpdateStepContentMutation();
  const contentStyles = useContentStyles();
  const getMetrics = useContentMetrics();
  const { id, title, courseId, emoji: stepEmoji, disableTranslation } = step;
  const editorContentRef = useScrollTopOnChange(step.id);
  const {
    course: { title: courseTitle, emoji: courseEmoji, steps, cover },
  } = useEditorCourseContext();
  const { currentStepIndex } = useStepRange(steps, step.id);
  const stepPosition = currentStepIndex + 1;
  const {
    step: { singular: stepSingular },
  } = useAccountTerminology();
  const [showNoTranslationModal, setShowNoTranslationModal] = useState(true);
  const { language, editorTranslationPopupAccepted } = useCurrentUser();
  const { hasMultiLanguageFeature } = useCurrentAccount();
  const enableNoTranslationModal =
    showNoTranslationModal &&
    hasMultiLanguageFeature &&
    language &&
    language != 'en' &&
    !editorTranslationPopupAccepted;

  const debouncedUpdate = useMemo(
    () =>
      debounce(() => {
        const { wordCount, imageCount, iframeCount, embedCardCount } = getMetrics();
        const contentJson = editor.isEmpty
          ? null
          : {
              ...editor.getJSON(),
              metaData: { wordCount, imageCount, iframeCount, embedCardCount },
            };

        updateStep({
          id,
          contentJson,
          useContentJson: true,
        });
      }, SAVE_TIMEOUT),
    [editor, getMetrics, id, updateStep]
  );

  useEffect(() => {
    const onUpdate = () => {
      debouncedUpdate();
    };

    editor.on('update', onUpdate);

    return () => {
      editor.off('update', onUpdate);
    };
  }, [editor, debouncedUpdate]);

  useEffect(() => {
    if (isUpdating) {
      setSaveState('saving');
    } else if (isSuccess) {
      setSaveState('saved');
    }
  }, [setSaveState, isUpdating, isSuccess]);

  return (
    <EditorContentWrapper
      data-testid='editor'
      ref={editorContentRef}
      toolbarAdjustment={CREATE_TOOLBAR_HEIGHT}
    >
      {enableNoTranslationModal && (
        <NoTranslationModal closeModal={() => setShowNoTranslationModal(false)} />
      )}
      <ContentLayout>
        <TopicHeader
          coverImage={cover}
          disableTranslation={disableTranslation}
          topicEmoji={courseEmoji}
          topicId={courseId}
          topicTitle={courseTitle}
        />
        <CardBackgroundShadowed>
          <LabelWrapper id='step-label'>
            {t('label.step', {
              step: stepSingular,
              index: stepPosition,
              totalSteps: steps.length,
            })}
          </LabelWrapper>
          <div className='step-content'>
            <StepTitle
              courseId={courseId}
              disableTranslation={disableTranslation}
              emoji={stepEmoji}
              id={id}
              title={title}
            />
            <StyledHrEdit />
            <StyledEditorContent
              $contentStyles={contentStyles}
              className='editor-content'
              editor={editor}
            />
            <NavigationButtons stepId={id} />
          </div>
        </CardBackgroundShadowed>
      </ContentLayout>
    </EditorContentWrapper>
  );
};

export default DeprecatedEditor;
