import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useVideoData } from '../../../../contexts/VideoDataContext';
import useCurriculumElement from '../../../../hooks/useCurriculumElement';
import { useCompleteVideoMutation } from '../../../../redux/services/resourceApis/videos/videosApi';
import { AdjacentCurriculumElementProps } from '../../../../types/Editor';
import FooterLoader from '../../flowcharts/shared/FooterLoader';
import NavigationButtons from './NavigationButtons';

const NavigationButtonsWrapper = styled.div`
  width: 100%;
`;

const ConsumeVideoFooterContent = () => {
  const { data, isLoading } = useVideoData();
  const [
    triggerCompletion,
    { isLoading: isCompletionLoading, isSuccess: isCompletionSuccess, reset },
  ] = useCompleteVideoMutation();
  const elementAttributes = useCurriculumElement();

  const navigateAfterCompletion = useCallback(
    (nextElement: AdjacentCurriculumElementProps | null) => {
      if (!nextElement) return;

      const { id: elementId, elementKind } = nextElement;
      const { showRoute } = elementAttributes({ elementId, elementKind });

      showRoute.push();
    },
    [elementAttributes]
  );

  useEffect(() => {
    if (isCompletionSuccess && data) {
      navigateAfterCompletion(data.adjacentCurriculumElements.next);
    }
    return () => {
      reset();
    };
  }, [isCompletionSuccess, data, navigateAfterCompletion, reset]);

  if (isLoading) return <FooterLoader />;
  if (!data) return null;

  const {
    id,
    status,
    adjacentCurriculumElements,
    completed,
    curriculum: { completionRequired, published },
  } = data;

  return (
    <NavigationButtonsWrapper>
      <NavigationButtons
        completionRequired={completionRequired}
        curriculumPublished={published}
        elementStatusFinished={status === 'finished'}
        isCompleted={completed}
        isLoading={isCompletionLoading}
        markComplete={() => triggerCompletion(id)}
        {...adjacentCurriculumElements}
      />
    </NavigationButtonsWrapper>
  );
};

export default ConsumeVideoFooterContent;
