import React, { useEffect, useState } from 'react';

import { getCalculateTimeLeft } from '../../../../../lib/getCalculateTimeLeft';
import initTranslations from '../../../../../lib/initTranslations';
import { CheckoutCustomLinkTimerWrapper } from './styles';

type CheckoutCustomLinkTimerProps = {
  expiredAt: string;
};

const t = initTranslations('checkout_overlay.timer');

const CheckoutCustomLinkTimer = ({ expiredAt }: CheckoutCustomLinkTimerProps) => {
  const expiredAtDate = new Date(`${expiredAt} 00:00:00`);
  const [timeLeft, setTimeLeft] = useState(getCalculateTimeLeft(expiredAtDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getCalculateTimeLeft(expiredAtDate));
    }, 1000);

    return () => clearTimeout(timer);
  });
  const { seconds, minutes, hours, days } = timeLeft;
  const showDays = days > 0;

  return (
    <CheckoutCustomLinkTimerWrapper id='checkout-custom-link-timer' isWarningState={!showDays}>
      {t('only')}
      {showDays && t('days', { count: days })}
      {t('hours', { count: hours })}
      {t('minutes', { count: minutes })}
      {t('seconds', { count: seconds })}
    </CheckoutCustomLinkTimerWrapper>
  );
};

export default CheckoutCustomLinkTimer;
