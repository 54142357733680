import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useMemo, useReducer } from 'react';
import * as yup from 'yup';

import { useLocalesContext } from '../../../../contexts/LocalesContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import {
  useGetAccountSettingsQuery,
  useUpdateAccountDataMutation,
} from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import FieldLabel from '../../../design_system/core/FieldLabel';
import Link from '../../../design_system/Link';
import CollapsibleSurface from '../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import { SPECIALIZATION_LABEL_COLORS } from '../../DelegationPlanner/SpecializationColorPicker/constants';
import useLocalStorageState from '../../publicApplication/utils/useLocalStorageState';
import { ButtonRightPositionWrapper } from '../OrganizationProfile/styles';
import { MAX_SPECIALIZATIONS_COUNT, MAX_SPECIALIZATION_TAG_CHARACTERS } from './constants';
import SpecializationFormElement from './SpecializationFormElement';
import {
  AddNewSpecializationActionWrapper,
  SpecializationTagTerminologyWrapper,
  SpecializationTagsFormWrapper,
  SpecializationTagsWrapper,
  TextPlaceholder,
} from './styles';
import { SpecializationFormValue, SpecializationFormValues } from './types';

const t = initTranslations('account_settings.responsibility_specializations');

const SpecializationTagSchema = yup.object().shape({
  specializations: yup.array().of(
    yup.object().shape({
      terminology: yup
        .string()
        .required(t('field_errors.required'))
        .max(
          MAX_SPECIALIZATION_TAG_CHARACTERS,
          t('field_errors.too_long', { max: MAX_SPECIALIZATION_TAG_CHARACTERS })
        ),
    })
  ),
});

const ResponsibilitySpecializations = () => {
  const { delegationPlannerEnabled } = useCurrentAccount();
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);
  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const { isLoading } = result;
  const [invalidateDPSpecializationsTags, setInvalidateDPSpecializationsTags] =
    useLocalStorageState({
      key: 'invalidateDPSpecializationsTags',
      initialValue: true,
    });

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });
  const { nonEnglishLocale } = useLocalesContext();

  const initialFormValues: SpecializationFormValues = useMemo(() => {
    if (!data) return { specializations: [] };

    return {
      specializations: data.responsibilitySpecializations.map(({ id, terminology, color }) => ({
        id,
        terminology,
        color,
      })),
    };
  }, [data]);

  const isTagsLimitReached = useCallback((specializations: SpecializationFormValue[]) => {
    return specializations.filter((tag) => !tag._destroy).length === MAX_SPECIALIZATIONS_COUNT;
  }, []);

  const handleFormSubmit = ({ specializations }: SpecializationFormValues) => {
    updateAccountData({
      responsibility_specializations_attributes: specializations,
    }).then(() => setInvalidateDPSpecializationsTags(!invalidateDPSpecializationsTags));
  };

  if (isLoadingData) return <></>;
  if (!data) return <></>;
  if (!delegationPlannerEnabled || !data.responsibilitySpecializations.length) return <></>;

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='responsibility-specializations-surface'
      isCollapsed={collapsed}
      title={t('title')}
    >
      <SpecializationTagTerminologyWrapper>
        <FieldLabel text={t('field_label', { responsibility: responsibilitySingular })} />
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          onSubmit={handleFormSubmit}
          validationSchema={SpecializationTagSchema}
        >
          {({ dirty, values: { specializations }, handleSubmit }) => (
            <SpecializationTagsFormWrapper>
              <Form onSubmit={handleSubmit}>
                <FieldArray name='specializations'>
                  {({ remove, push }) => (
                    <>
                      <SpecializationTagsWrapper>
                        {specializations.map((specialization, index) =>
                          specialization._destroy ? null : (
                            <SpecializationFormElement
                              arrayIndex={index}
                              formValue={specialization}
                              key={index}
                              removeFormElement={remove}
                            />
                          )
                        )}
                      </SpecializationTagsWrapper>
                      <AddNewSpecializationActionWrapper nonEnglishLocale={nonEnglishLocale}>
                        <Link
                          behavesAs='button'
                          disabled={isTagsLimitReached(specializations)}
                          onClick={() =>
                            push({
                              terminology: '',
                              color: SPECIALIZATION_LABEL_COLORS[0],
                            })
                          }
                          prefixIconName='plus'
                          text={t('add_specialization')}
                          underlineBehavior='dynamic'
                        />
                        <TextPlaceholder>
                          {t('add_specialization_limit', { max: MAX_SPECIALIZATIONS_COUNT })}
                        </TextPlaceholder>
                      </AddNewSpecializationActionWrapper>
                    </>
                  )}
                </FieldArray>
                <ButtonRightPositionWrapper>
                  <DefaultButton
                    disabled={!dirty}
                    id='save-specialization-tag-terminology'
                    loading={isLoading}
                    onClick={() => handleSubmit}
                    text={t('submit_form')}
                  />
                </ButtonRightPositionWrapper>
              </Form>
            </SpecializationTagsFormWrapper>
          )}
        </Formik>
      </SpecializationTagTerminologyWrapper>
    </CollapsibleSurface>
  );
};

export default ResponsibilitySpecializations;
