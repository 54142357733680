import styled from 'styled-components';

import { CornerBg } from '../../../lib/gcsImages';
import Link from '../../design_system/Link';
import P from '../../design_system/text/P';
import { deprecated, fontSm5 } from '../../styled/TypeSystem';

export const SingUpLinkExpiredWrapper = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
`;

export const SingUpLinkExpiredContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 56.25rem;
  width: 100%;
`;

export const SingUpLinkExpiredColumn = styled.div`
  max-width: 31.25rem;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  padding-top: 4.375rem;
  padding-left: ${({ theme: { constants } }) => constants.spacerLg3};
  background: url(${CornerBg}) no-repeat 0 0;
  color: ${({ theme: { vars } }) => vars.textSurface};
`;

export const SingUpLinkExpiredTitle = styled.h1`
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${deprecated.fontLg4};
`;

export const SingUpLinkExpiredText = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSurface};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;

export const SingUpLinkExpiredLink = styled(Link)`
  color: ${({ theme: { vars } }) => vars.textSurface};

  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.textSurface};
  }
`;
