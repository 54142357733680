import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useCheckSidebarState } from '../../../../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { setTempColumns } from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetBoardColumnsQuery } from '../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import {
  useApplyBoardChangesMutation,
  useGetBoardDataQuery,
  useGetBoardsListQuery,
} from '../../../../redux/services/resourceApis/boards/boardsApi';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import DynamicOverlayProvider from '../../shared/DynamicOverlay/DynamicOverlayProvider';
import PageContent from '../../shared/page_content';
import TrainualPlusDelegationPlannerBanner from '../banners/TrainualPlusDelegationPlannerBanner/TrainualPlusDelegationPlannerBanner';
import BoardController from '../BoardController/BoardController';
import BoardEmptyState from '../BoardEmptyState/BoardEmptyState';
import BoardModals from '../BoardModals/BoardModals';
import BoardSkeleton from '../BoardSkeleton/BoardSkeleton';
import BoardTools from '../BoardTools/BoardTools';
import BoardTrack from '../BoardTrack/BoardTrack';
import PageTools from '../PageTools/PageTools';
import { useBoardLocalStorageEffects } from './hooks/useBoardLocalStorageEffects';
import { useBoardObservableEffects } from './hooks/useBoardObservableEffects';
import { useBoardUIEffects } from './hooks/useBoardUIEffects';
import { useCurrentBoardId } from './hooks/useCurrentBoardId';
import {
  BoardApplyingStateWrapper,
  BoardHeader,
  HeaderBannerWrapper,
  PageContentWrapper,
  PagePaddingBox,
} from './styles';
import { PositionValues } from './types';

const t = initTranslations('delegation_planner');

const BoardPage = () => {
  const { delegationPlannerEnabled, slug } = useCurrentAccount();
  const { flash } = useFlashNotification();
  const currentBoardId = useCurrentBoardId();
  const dispatch = useAppDispatch();
  const [currentBoardIsDeleted, setCurrentBoardIsDeleted] = useState(false);
  const [boardTrackElement, setBoardTrackElement] = useState<HTMLDivElement | null>(null);
  const [boardTrackHasScroll, setBoardTrackHasScroll] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const pageContentWrapperRef = useRef<HTMLDivElement>(null);
  const [initialPageContentWrapperPositionValues, setInitialPageContentWrapperPositionValues] =
    useState<PositionValues>({
      top: 0,
      left: 0,
      bottom: 0,
    });
  const isExpandedSidebar = useCheckSidebarState('isExpanded') as boolean;

  const { data: boardsResponse } = useGetBoardsListQuery();
  const { data: currentBoard, isFetching: isBoardDataFetching } = useGetBoardDataQuery(
    { id: currentBoardId },
    { skip: currentBoardIsDeleted }
  );
  const {
    data: boardColumnsResponse,
    isLoading,
    isFetching,
  } = useGetBoardColumnsQuery({
    boardId: currentBoardId,
  });

  const [applyChanges, applyChangesResult] = useApplyBoardChangesMutation();
  const { isLoading: isChangesApplying } = applyChangesResult;

  const boards = boardsResponse?.boards || [];
  const boardColumns = useMemo(
    () => boardColumnsResponse?.boardColumns || [],
    [boardColumnsResponse]
  );
  const { currentBoardColumnResponsibility, currentColumnId, isFullscreenModeEnabled } =
    useAppSelector((state) => state.delegationPlanner);
  const currentBoardColumn = boardColumns.find((column) => column.id === currentColumnId);

  const selectedUserIds: number[] = [];
  const selectedGroupIds: number[] = [];
  const isEmptyBoard = boardColumns.length === 0;
  const isBoardApplied = currentBoard?.status === 'finished';

  boardColumns.forEach((column) => {
    if (column.columnType === 'User') {
      selectedUserIds.push(column.columnableId);
    } else if (column.columnType === 'Group') {
      selectedGroupIds.push(column.columnableId);
    }
  });

  const tempColumns = useAppSelector((state) => state.delegationPlanner.tempColumns);

  const handleSetInitialPageContentWrapperPositionValues = useCallback(() => {
    if (pageContentWrapperRef.current) {
      const { top, left, bottom } = pageContentWrapperRef.current.getBoundingClientRect();
      setInitialPageContentWrapperPositionValues({ top, left, bottom });
    }
  }, [pageContentWrapperRef]);

  const handleBoardControllerScroll = useCallback(() => {
    if (boardTrackElement) {
      const { scrollWidth, clientWidth } = boardTrackElement;

      setBoardTrackHasScroll(scrollWidth > clientWidth);
    }
  }, [boardTrackElement]);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);

    handleSetInitialPageContentWrapperPositionValues();
    handleBoardControllerScroll();
  }, [handleBoardControllerScroll, handleSetInitialPageContentWrapperPositionValues]);

  const handleApplyChanges = () => {
    applyChanges({ boardId: currentBoardId });
  };

  const resetMoveResponsibility = () => {
    dispatch(setTempColumns(boardColumns));
  };

  useEffect(() => {
    if (!delegationPlannerEnabled) {
      flash('error', t('no_page_found'));
      routeTo(routes.home({ slug }));
    }
  }, [delegationPlannerEnabled, flash, slug]);

  useEffect(handleBoardControllerScroll, [
    boardTrackElement,
    tempColumns.length,
    handleBoardControllerScroll,
  ]);

  useEffect(() => {
    if (boardColumns) {
      dispatch(setTempColumns(boardColumns));
    }
  }, [boardColumns, tempColumns.length, dispatch]);

  useEffect(() => {
    setTimeout(handleSetInitialPageContentWrapperPositionValues, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContentWrapperRef.current, isExpandedSidebar]);

  useBoardLocalStorageEffects();
  useBoardObservableEffects(applyChangesResult);
  useBoardUIEffects({
    boardTrackElement,
    boardTrackHasScroll,
    handleResize,
    isFetching,
    isLoading,
  });

  return (
    <DynamicOverlayProvider>
      <PageContent id='delegation-planner-page' mode='fill-content'>
        <HeaderBannerWrapper>
          <TrainualPlusDelegationPlannerBanner />
        </HeaderBannerWrapper>

        <PageContentWrapper
          isFullscreenModeEnabled={isFullscreenModeEnabled}
          positionValues={initialPageContentWrapperPositionValues}
          ref={pageContentWrapperRef}
        >
          <BoardHeader isApplying={isChangesApplying}>
            <PageTools
              boards={boards}
              currentBoard={currentBoard}
              setCurrentBoardIsDeleted={setCurrentBoardIsDeleted}
            />

            <BoardTools
              currentBoard={currentBoard}
              handleApplyChanges={handleApplyChanges}
              isBoardApplied={isBoardApplied}
              isBoardDataFetching={isBoardDataFetching}
              isChangesApplying={isChangesApplying}
              isEmptyBoard={isEmptyBoard}
              isFullscreenModeEnabled={isFullscreenModeEnabled}
            />
          </BoardHeader>

          <BoardApplyingStateWrapper isApplying={isChangesApplying}>
            {isLoading ? (
              <PagePaddingBox>
                <BoardSkeleton />
              </PagePaddingBox>
            ) : isEmptyBoard ? (
              <BoardEmptyState />
            ) : (
              <BoardTrack boardColumns={boardColumns} setBoardTrackElement={setBoardTrackElement} />
            )}

            {boardTrackElement && boardTrackHasScroll && (
              <BoardController
                boardTrackElement={boardTrackElement}
                columnsCount={boardColumns.length}
                sensorWidthRecalculateDependencies={[windowWidth]}
              />
            )}
          </BoardApplyingStateWrapper>

          <BoardModals
            currentBoardColumn={currentBoardColumn}
            currentBoardColumnResponsibility={currentBoardColumnResponsibility}
            resetMoveResponsibility={resetMoveResponsibility}
            selectedGroupIds={selectedGroupIds}
            selectedUserIds={selectedUserIds}
          />
        </PageContentWrapper>
      </PageContent>
    </DynamicOverlayProvider>
  );
};

export default BoardPage;
