import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import AssistiveText from '../../../../../design_system/core/AssistiveText';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import P from '../../../../../design_system/text/P';
import MultiSelectField from '../../../../../design_system/Triage/fields/MultiSelectField';
import { routes } from '../../../../publicApplication/applicationRouter';
import {
  FormSectionDescription,
  FormSectionTitle,
  FormSectionWrapper,
  InfoTitle,
  MultiSelectInputRow,
  SignatureInfoBlock,
  StyledIcon,
  StyledLink,
  ToggleInfoWrapper,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.signature_settings');

const SignaturePermissionsForm = () => {
  const {
    stripePlanId,
    slug,
    onOneOfAnnualUnlimitedPlans,
    onCustomPlan,
    hasESignatureFeature,
    splitFeatures: { eSignatureToggleSectionEnabled },
  } = useCurrentAccount();
  const showSignaturesSectionWithToggle = eSignatureToggleSectionEnabled && !hasESignatureFeature;
  const { data } = useGetAccountSettingsQuery();
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const paywallCtx = useContext(PaywallContext);
  const signaturesLocked = paywallCtx.includes('e_signature');

  if (!data) return null;

  const {
    userPermissions,
    advancedSettings: { accountPlanName },
  } = data;

  const unlimitedCompanyPlan = stripePlanId === 'unlimited_comp';
  const showYearlyESignature = onOneOfAnnualUnlimitedPlans || onCustomPlan;

  const userPermissionOptions = userPermissions.map((permission) => ({
    ...permission,
    isFixed: permission.value === 'billing_admin',
  }));

  return (
    <FormSectionWrapper>
      {!showSignaturesSectionWithToggle && (
        <ToggleInfoWrapper>
          <FormSectionTitle>
            {t('title')}
            {signaturesLocked && <StyledIcon name='lock' weight='regular' />}
          </FormSectionTitle>
          <FormSectionDescription>{t('description')}</FormSectionDescription>
        </ToggleInfoWrapper>
      )}
      <MultiSelectInputRow>
        <MultiSelectField
          className='signature_permissions_select'
          defaultValue={[]}
          onNonDefaultSelected={(value: string[]) => setFieldValue('selectedPermissions', value)}
          options={formatOptions(userPermissionOptions)}
          value={values.selectedPermissions}
        />
        <AssistiveText
          id='signature-permissions-assistive-text-help'
          text={t('assistive_text')}
          type='help'
        />
        {!hasESignatureFeature && (
          <SignatureInfoBlock>
            <InfoTitle>{t('plan_name', { name: accountPlanName || t('scale') })}</InfoTitle>
            <P
              text={
                showYearlyESignature
                  ? t('billing_info_annual')
                  : unlimitedCompanyPlan
                  ? t('unlimited_info')
                  : t('billing_info_monthly')
              }
            />
            {!unlimitedCompanyPlan && (
              <p>
                <span>{t('see')}</span>
                <StyledLink
                  color='accent'
                  href={routes.billing({ slug }).href}
                  text={t('billing_page')}
                />
                <span>{t('more_details')}</span>
              </p>
            )}
          </SignatureInfoBlock>
        )}
      </MultiSelectInputRow>
    </FormSectionWrapper>
  );
};

export default SignaturePermissionsForm;
