import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import { deleteQueryParam } from '../../../../lib/getQueryParams';
import initTranslations from '../../../../lib/initTranslations';
import { useUpdateAccountDataMutation } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import Hoverable from '../../../design_system/Hoverable';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import ImageUpload from '../../image_upload/ImageUpload';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';
import { ToggleContainer } from '../AdvancedSettings/styles';
import { LogoContainer, LogoWrapper, Title } from './styles';
import { LogoUpdateProps, LogoUpdateState, UpdateLogoAction } from './types';

const t = initTranslations('account_settings.brand_styles');

const reducer = (state: LogoUpdateState, action: UpdateLogoAction): LogoUpdateState => {
  switch (action.type) {
    case 'showModal':
      return { ...state, showModal: true };
    case 'closeModal':
      return { ...state, showModal: false };
    case 'changeLogo':
      return { ...state, logoImageUrl: action.logoImageUrl };
  }
};

const UpdateLogo = ({ logoBackgroundColor, logoUrl, defaultLogoUrl }: LogoUpdateProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const displayUpdateLogoModal = queryParams.get('show_update_logo_modal') === 'true';
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  useEffect(() => {
    if (displayUpdateLogoModal) {
      dispatch({ type: 'showModal' });
      deleteQueryParam('show_update_logo_modal');
    }
  }, [displayUpdateLogoModal]);

  const initialState = {
    logoImageUrl: logoUrl,
    showModal: false,
  };

  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');

  const [state, dispatch] = useReducer(reducer, initialState);

  const { showModal } = state;
  const [updatedLogo, setUpdatedLogo] = useState({ avatar: logoUrl });
  const [isToggleHovered, setIsToggleHovered] = useState(false);

  const [updateLogoData, result] = useUpdateAccountDataMutation();

  const { isLoading } = result;

  const updateLogo = () => {
    const formData: FormData = new FormData();
    formData.append('logo', updatedLogo.avatar);
    updateLogoData(formData);
  };

  const successFunction = useCallback(() => {
    dispatch({ type: 'closeModal' });
  }, []);

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
    successFunction,
  });

  useEffect(() => {
    if (updatedLogo.avatar === undefined) {
      dispatch({ type: 'changeLogo', logoImageUrl: defaultLogoUrl });
    }
  }, [defaultLogoUrl, updatedLogo]);

  const taskModalArgs: TaskModalProps = {
    title: t('logo.update_logo'),
    onCloseRequest: () => dispatch({ type: 'closeModal' }),
    processing: isLoading,
    primaryButtonText: t('logo.update_logo'),
    primaryButtonTask: updateLogo,
    secondaryButtonText: t('logo.cancel'),
    desktopSize: 'lg',
    heapModalName: 'logo-update-modal',
  };

  return (
    <>
      <Title>{t('logo.site_logo')}</Title>
      <LogoContainer>
        <LogoWrapper data-testid='logo-wrapper' logoBackgroundColor={logoBackgroundColor}>
          <img alt='logo' src={logoUrl} />
        </LogoWrapper>
        {brandStylesLocked ? (
          <ToggleContainer>
            <PaywallTooltip
              description={t('lock_brand_styles_paywall.description')}
              modifiers={[{ name: 'offset', options: { offset: [70, 0] } }]}
              shouldBeVisible={isToggleHovered && brandStylesLocked}
              title={t('lock_brand_styles_paywall.title')}
            />
            <Hoverable setIsHovered={setIsToggleHovered}>
              <DefaultButton
                buttonType='secondary'
                iconName='lock'
                id='show-update-logo-modal-button'
                onClick={() => navigateToBillingOverlay()}
                text={t('logo.update_logo')}
              />
            </Hoverable>
          </ToggleContainer>
        ) : (
          <DefaultButton
            buttonType='secondary'
            id='show-update-logo-modal-button'
            onClick={() => dispatch({ type: 'showModal' })}
            text={t('logo.update_logo')}
          />
        )}
      </LogoContainer>
      {showModal && (
        <TaskModal {...taskModalArgs}>
          <ImageUpload
            backgroundImageColor={logoBackgroundColor}
            backgroundImageDefault={defaultLogoUrl}
            imageForm='rectangle'
            imageSize='lg'
            imageUrl={logoUrl}
            resetButtonText={t('logo.remove_logo')}
            setImage={setUpdatedLogo}
            text={t('logo.we_suggest_using_a_transparent_png_file')}
            title={t('logo.update_logo')}
            uploadButtonText={t('logo.update_logo')}
          />
        </TaskModal>
      )}
    </>
  );
};

export default UpdateLogo;
