import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { AccessControl, accessControls } from '../../../../../../types/SuperShare';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import { Option } from '../../../../../design_system/core/SelectOption/types';
import SingleSelectField from '../../../../../design_system/Triage/fields/SingleSelectField';
import {
  FormSectionDescription,
  FormSectionTitle,
  FormSectionWrapper,
  MultiSelectInputRow,
  ToggleInfoWrapper,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings');
const DefaultCurriculumAccessControlSection = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  const {
    curriculum: { singular: curriculumTermSingular },
  } = useAccountTerminology();
  const options: Option[] = accessControls.map((setting: AccessControl): Option => {
    return {
      label: t(`access_control.${setting}.title`),
      metaValue: t(`access_control.${setting}.description`, {
        curriculum: curriculumTermSingular.toLowerCase(),
      }),
      searchableTerm: '',
      value: setting,
    };
  });

  return (
    <FormSectionWrapper id='content-discoverability-select'>
      <ToggleInfoWrapper>
        <FormSectionTitle>{t('access_control.title')}</FormSectionTitle>
        <FormSectionDescription>
          {t('access_control.description', { curriculum: curriculumTermSingular.toLowerCase() })}
        </FormSectionDescription>
      </ToggleInfoWrapper>

      <MultiSelectInputRow>
        <SingleSelectField
          className='account-settings-default-curriculum-access-control-dropdown'
          defaultValue={values.defaultAccessControl}
          hideSelectedOptions={false}
          isSearchable={false}
          menuPosition='fixed'
          name='defaultAccessControl'
          onNonDefaultSelected={(value: AccessControl) =>
            setFieldValue('defaultAccessControl', value)
          }
          options={options}
          placeholder={t('category_placeholder')}
          value={values.defaultAccessControl}
        />
      </MultiSelectInputRow>
    </FormSectionWrapper>
  );
};

export default DefaultCurriculumAccessControlSection;
