import React from 'react';
import styled from 'styled-components';

import ConsumeVideoFooterContent from './ConsumeVideoFooterContent';
import EditVideoFooterContent from './EditVideoFooterContent';

const StyledFooter = styled.footer`
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  box-shadow: ${({ theme: { vars } }) => `0 -5px 10px 0 ${vars.shadowColorTopSmall}`};
  box-sizing: border-box;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

const VideoFooter = ({ editing }: { editing: boolean }) => {
  return (
    <StyledFooter>
      {editing ? <EditVideoFooterContent /> : <ConsumeVideoFooterContent />}
    </StyledFooter>
  );
};

export default VideoFooter;
