import DOMPurify from 'dompurify';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import ContentEditable from 'react-contenteditable';
import styled, { css } from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import initTranslations from '../../../../../lib/initTranslations';
import { sanitizeInput } from '../../../../../lib/sanitize';
import translationClassname from '../../../../../lib/translationClassname';
import { useUpdateTopicMutation } from '../../../../../redux/services/resourceApis/courses/topicsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import { ErrorText } from '../../../../design_system/Triage/InputField';
import { deprecated, fontMd1, fontSm4 } from '../../../../styled/TypeSystem';
import { getValidationSchema } from '../../shared/validators';

const TitleWrapper = styled.div<{ isBreadcrumbTitle: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ isBreadcrumbTitle }) => (isBreadcrumbTitle ? fontMd1 : deprecated.fontLg3)}
`;

const StyledContentEditor = styled(ContentEditable)<{
  $isBreadcrumbTitle: boolean;
  placeholder: string;
  $error: boolean;
}>`
  margin: 0;
  width: 100%;
  overflow: hidden;
  max-height: 20rem;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};

  :empty:before {
    content: attr(placeholder);
    display: block;
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
  }

  &:focus {
    outline: none;
  }

  ${({ $isBreadcrumbTitle, $error, theme: { constants, vars } }) =>
    $isBreadcrumbTitle &&
    css`
      text-align: center;
      padding: ${constants.spacerSm3} 0;
      :focus-within {
        background-color: ${vars.accentSubdued1};
        border: ${constants.borderWidthSm} solid ${vars.accentPrimaryDefault};
        border-radius: ${constants.borderRadiusMd};
        color: ${vars.textDefault};
        caret-color: ${vars.textDefault};
      }
      :empty:before {
        content: attr(placeholder);
        display: block;
        color: transparent;
      }
      ${$error
        ? css`
            background-color: ${vars.stateBackgroundError};
            border: ${constants.borderWidthSm} solid ${vars.stateError};
            border-radius: ${constants.borderRadiusMd};
            :focus-within {
              background-color: ${vars.stateBackgroundError};
              border: ${constants.borderWidthSm} solid ${vars.stateError};
            }
          `
        : css`
            background-color: transparent;
          `}
    `}
`;

const ErrorContainer = styled.div<{ errors: boolean }>`
  display: flex;
  align-items: center;
  height: 0.125rem; /* This prevents layout shift when the error message appears */
  opacity: ${({ errors }) => (errors ? '1' : '0')};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.stateError};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontSm4}

  p {
    margin: 0; /* Remove default margin on p tag to ensure the error icon is vertically centered */
  }
`;

const t = initTranslations('editor.topic');

type FormValues = {
  topicId: number;
  emoji?: string | null;
  title: string;
  isBreadcrumbTitle?: boolean;
  disableTranslation?: boolean;
};

const TopicTitle = ({
  isBreadcrumbTitle = false,
  emoji,
  title,
  topicId,
  disableTranslation,
}: FormValues) => {
  const {
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();
  const [update] = useUpdateTopicMutation();
  const [currentTitle, setCurrentTitle] = useState(title);
  const { setIsEditingTitle } = useEditorContext();

  return (
    <Formik
      enableReinitialize
      initialValues={{ emoji, title, topicId, isBreadcrumbTitle }}
      onSubmit={(values) => {
        const newTitle = sanitizeInput(values.title);
        setCurrentTitle(newTitle);
        return update({ ...values, title: newTitle, id: topicId });
      }}
      validationSchema={getValidationSchema()}
    >
      {({
        isSubmitting,
        isValidating,
        isValid,
        errors,
        setFieldValue,
        handleSubmit,
        values,
      }: FormikProps<FormValues>) => {
        if (isSubmitting && !isValidating && !isValid) {
          setFieldValue('title', currentTitle);
        }

        const handleBlur = () => {
          setIsEditingTitle(false);
          handleSubmit();
        };

        const handleFocus = () => {
          !isBreadcrumbTitle ? setIsEditingTitle(true) : null;
        };

        return (
          <TitleWrapper isBreadcrumbTitle={isBreadcrumbTitle}>
            <StyledContentEditor
              $error={Object.keys(errors).length > 0}
              $isBreadcrumbTitle={isBreadcrumbTitle}
              className={translationClassname(disableTranslation)}
              html={values.title}
              id={isBreadcrumbTitle ? 'topic-title-breadcrumb' : 'topic-title'}
              inputMode='text'
              onBlur={handleBlur}
              onChange={(e) => {
                let sanitized = DOMPurify.sanitize(e.target.value, { ALLOWED_TAGS: [] });
                sanitized = sanitized.replace(/&nbsp;/g, ' ');

                setFieldValue('title', sanitized);
              }}
              onFocus={handleFocus}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              placeholder={t('placeholder', { topic: topicTermSingular.toLowerCase() })}
            />
            <ErrorContainer errors={!!errors.title}>
              <Icon name='circle-x' size='2xs' weight='solid' />
              <ErrorText>{errors.title}</ErrorText>
            </ErrorContainer>
          </TitleWrapper>
        );
      }}
    </Formik>
  );
};

export default TopicTitle;
