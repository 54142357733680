import React from 'react';

import { useStepId } from '../../../../contexts/StepIdContext';
import initTranslations from '../../../../lib/initTranslations';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { Subtitle } from './styles';

const t = initTranslations('editor.version_history');

const Title = () => {
  const stepId = useStepId();
  const { data: stepData } = useGetStepQuery(stepId);

  const stepTitle = stepData?.title;

  return (
    <div>
      <h2>{t('title')}</h2>
      <Subtitle dangerouslySetInnerHTML={{ __html: t('subtitle', { stepTitle }) }} />
    </div>
  );
};

export default Title;
