import styled from 'styled-components';

import { MenuButton } from '../../../design_system/Triage/menus/ThreeDotMenu/styles';

export const BoardColumnToolsContainer = styled.div<{ isVisibleThreeDot: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex: none;
  width: 5rem;
  height: fit-content;

  ${MenuButton} {
    display: ${({ isVisibleThreeDot }) => (isVisibleThreeDot ? 'flex' : 'none')};
  }

  [class^='Badge'] {
    display: ${({ isVisibleThreeDot }) => (isVisibleThreeDot ? 'none' : 'block')};
  }
`;
