import styled, { css } from 'styled-components';

import { BackgroundImageLg, BackgroundImageMd, BackgroundImageSm } from '../../../../lib/gcsImages';
import BaseLink from '../../../design_system/Link/Link';
import BaseP from '../../../design_system/text/P/P';
import {
  mediaBreakpointPx2xl,
  mediaBreakpointPxMd,
  mediaBreakpointPxSm,
  mediaBreakpointPxXl,
} from '../../../styled/Breakpoint';
import { deprecated, fontMd1, fontMd3, fontSm5 } from '../../../styled/TypeSystem';
import PublicPage from '../PublicPage';

export const FormStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const FormWrapper = styled.div`
  ${FormStyles};
`;

export const ActionWrapper = styled.div`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  width: 100%;
  flex-direction: column-reverse;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxMd}) {
    gap: 0;
    margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
    flex-direction: row;
    justify-content: space-between;
  }

  ${fontSm5};
`;

export const LogoWrapper = styled.div<{ logoBackgroundColor?: string; height?: string }>`
  --side-indents: 0.92rem;

  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + var(--side-indents) * 2);
  height: 7.5rem;
  border-top-left-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border-top-right-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  ${({ logoBackgroundColor }) => logoBackgroundColor && `background: ${logoBackgroundColor};`};
  ${({ height }) => height && `height: ${height};`};

  @media (min-width: 415px) {
    --side-indents: 1.5rem;
  }
`;

export const Logo = styled.img`
  max-width: 11.25rem;
  max-height: 5rem;
`;

export const FormHeader = styled(FormWrapper)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const H1 = styled.h1`
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
  margin: 0;

  ${deprecated.fontMd4};
`;

export const StyledTitle = styled.h1`
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin: 0;

  ${fontMd3};
  margin-bottom: -${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledSubtitle = styled.h2`
  margin: 0;
  text-align: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  color: ${({ theme: { vars } }) => vars.textSubdued};

  span {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }

  ${fontSm5};
`;

export const P = styled(BaseP)`
  font-weight: ${({ theme: { constants } }) => constants.fontLight};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  ${fontMd1};
`;

// DS Override: Need a background for the sign up page
export const PageWithBackgroundImage = styled(PublicPage)`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  @media (min-width: ${mediaBreakpointPxSm}) {
    background: url(${BackgroundImageSm}) 0 0 no-repeat;
    background-size: cover;
  }
  @media (min-width: ${mediaBreakpointPxXl}) {
    background: url(${BackgroundImageMd}) 0 0 no-repeat;
    background-size: cover;
  }
  @media (min-width: ${mediaBreakpointPx2xl}) {
    background: url(${BackgroundImageLg}) 0 0 no-repeat;
    background-size: cover;
  }
`;

export const TermsWrapper = styled.div`
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

// DS Override: Needed to not show external icons for these links
export const TermsLink = styled(BaseLink)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  svg {
    display: none;
  }
`;

export const StarWrapper = styled.div<{ align?: 'left' | 'right' }>`
  display: flex;
  align-items: ${(props) => props.align || 'left'};
  justify-content: ${(props) => props.align || 'left'};
  width: 100%;
  height: 7.5rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export const Stars = styled.img<{ rotate?: number }>`
  max-width: 11.25rem;
  max-height: 5rem;

  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg);`};
`;

export const CheckEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 320px);
  overflow: hidden;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const Envelope = styled.img`
  max-height: 5rem;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const StyledLink = styled(BaseLink)`
  text-decoration: underline;
  text-underline-position: auto;
`;

export const Animation = styled.div<{ animated: boolean }>`
  transition: transform 0.5s ease;
  transform: translateY(${(props) => (props.animated ? '0%' : '15%')});
`;
