import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import BrandStylesSection from './Forms/ContentSettings/BrandStylesSection';
import CompanySectorToggle from './Forms/ContentSettings/CompanySectorToggle';
import ContentPageDefaultView from './Forms/ContentSettings/ContentPageDefaultView';
import ContentPageToggle from './Forms/ContentSettings/ContentPageToggle';
import CustomTerminologySection from './Forms/ContentSettings/CustomTerminologySection';
import DefaultCurriculumAccessControlSection from './Forms/ContentSettings/DefaultCurriculumAccessControlSection';
import DownloadPDF from './Forms/ContentSettings/DownloadPDF';
import PolicySectorToggle from './Forms/ContentSettings/PolicySectorToggle';
import PublicShare from './Forms/ContentSettings/PublicShare';
import SignaturePermissionSection from './Forms/ContentSettings/SignaturePermissionSection';
import { PageHeader, SectionDivider } from './styles';

const t = initTranslations('account_settings.content_settings');

const ContentTabSection = () => {
  const { data, isLoading } = useGetAccountSettingsQuery();

  if (!data || isLoading) return null;

  const { brandStyles, defaultBrandStyles, companyToggleDisabled, policyToggleDisabled } = data;
  return (
    <div id='content-tab-section'>
      <PageHeader>{t('header')}</PageHeader>
      <PublicShare />
      <SectionDivider />
      <DownloadPDF />
      <SignaturePermissionSection />
      <SectionDivider />
      <DefaultCurriculumAccessControlSection />
      <SectionDivider />
      <BrandStylesSection brandStyles={brandStyles} defaultBrandStyles={defaultBrandStyles} />
      <PageHeader>{t('content_page.subheader')}</PageHeader>
      <ContentPageToggle />
      <SectionDivider />
      <CompanySectorToggle isDisabled={companyToggleDisabled} />
      <SectionDivider />
      <PolicySectorToggle isDisabled={policyToggleDisabled} />
      <SectionDivider />
      <ContentPageDefaultView />
      <SectionDivider />
      <CustomTerminologySection />
    </div>
  );
};

export default ContentTabSection;
