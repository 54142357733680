import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { TodoListIcon } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteTrainingPathSetMutation } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import IconButton from '../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { Title } from '../../../shared/DetailedTitle/Title';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6.25rem;
`;

const Line = styled.div`
  width: 2px;
  height: 1.25rem;
  background-color: ${({ theme: { vars } }) => vars.borderDefault};
`;

const Card = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    background-color: ${vars.foundationSurface1};
    padding: ${constants.spacerMd1} ${constants.spacerMd3};
    align-items: center;
    height: 3.75rem;
    width: fit-content;
    gap: ${constants.spacerMd2};
    ${fontSm5};
  `
);

const StyledIconButton = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  padding: 0;

  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.textSubdued};
  }
`;

const t = initTranslations('training_path.path_config.delete_split');

export type Props = {
  trainingPathSetUuid: string;
};

const DeleteSplit = ({ trainingPathSetUuid }: Props) => {
  const {
    user: { id: userId },
    updateInProgress,
    setUpdateInProgress,
  } = useTrainingPathContext();
  const [deleteSet, deleteResult] = useDeleteTrainingPathSetMutation();
  const { isLoading } = deleteResult;

  useDisplayFlashOnResponse({
    result: deleteResult,
    errorMessage: t('update_failed'),
    successFunction: () => {
      setUpdateInProgress(false);
      deleteResult.reset();
    },
    errorFunction: () => {
      setUpdateInProgress(false);
      deleteResult.reset();
    },
  });

  const deleteTrainingPathSet = useCallback(() => {
    if (updateInProgress || isLoading) return;

    setUpdateInProgress(true);
    deleteSet({ userId, trainingPathSetUuid });
  }, [updateInProgress, isLoading, setUpdateInProgress, deleteSet, userId, trainingPathSetUuid]);

  return (
    <Wrapper>
      <Line />
      <Card>
        <img alt='Todo list icon' src={TodoListIcon} />
        <Title fontWeight='regular' title={t('message')} />
        <div data-for='delete-split-tooltip' data-tip='true' id='delete-split-tooltip'>
          <Tooltip id='delete-split-tooltip' multiline text={t('remove_split_tooltip')} />
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <StyledIconButton
              ariaLabel={t('aria_label')}
              buttonSize='md'
              disabled={updateInProgress}
              id={`delete-split-button-${trainingPathSetUuid}`}
              name='trash-can'
              onClick={deleteTrainingPathSet}
            />
          )}
        </div>
      </Card>
      <Line />
    </Wrapper>
  );
};

export default DeleteSplit;
