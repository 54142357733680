import { useFormik } from 'formik';
import React, { FC, KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import {
  setCurrentColumnId,
  setCurrentResponsibility,
} from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { useCreateBoardColumnResponsibilityMutation } from '../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import AssistiveText from '../../../design_system/core/AssistiveText';
import useActiveMenuHandler from '../../../design_system/useActiveMenuHandler';
import { routes, useRoute } from '../../publicApplication/applicationRouter';
import useAutoSaveStatusUpdater from '../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import DurationTimeSetter from '../DurationTimeSetter/DurationTimeSetter';
import { RESPONSIBILITY_NAME_MAX_CHARACTERS } from '../shared/constants/boards';
import { getAddResponsibilityValidationSchema } from '../shared/utils/validators';
import { BoardResponseError } from '../types';
import { initialValues } from './_data';
import {
  AddResponsibilityCardWrapper,
  AddResponsibilityContainer,
  CharacterCounter,
  FormButtonsContainer,
  Textarea,
} from './styles';
import { AddResponsibilityCardProps, AddResponsibilityFormInitialValues } from './types';

const t = initTranslations('delegation_planner.add_responsibility_card');

const AddResponsibilityCard: FC<AddResponsibilityCardProps> = ({ columnId, hideCard, isOpen }) => {
  const {
    params: { id: boardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const {
    responsibility: { singular: responsibilitySingular },
  } = useAccountTerminology();

  const menuId: RegisteredMenuId = `duration-time-setter-frequency-popup-${columnId}`;

  const { isMenuOpen } = useActiveMenuHandler({ menuId });
  const fieldRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useAppDispatch();
  const [createResponsibility, createResponsibilityResult] =
    useCreateBoardColumnResponsibilityMutation();

  const { isLoading, isSuccess, error } = createResponsibilityResult;
  const createBoardError = error as BoardResponseError;

  useAutoSaveStatusUpdater([{ isSaving: isLoading, isSavedSuccessfully: isSuccess }]);

  const handleAddResponsibility = (values: AddResponsibilityFormInitialValues) => {
    const { name, time, frequency } = values;
    createResponsibility({ boardId, id: columnId, responsibility: { name }, time, frequency });
  };

  const formik = useFormik<AddResponsibilityFormInitialValues>({
    initialValues,
    validationSchema: getAddResponsibilityValidationSchema(
      t('input.errors.required', { responsibility: responsibilitySingular })
    ),
    onSubmit: handleAddResponsibility,
  });

  const handleResetForm = useCallback(() => {
    hideCard();
    formik.resetForm();
  }, [formik, hideCard]);

  const { values, touched, errors, handleChange, setFieldValue, handleSubmit, setFieldError } =
    formik;
  const isTitleError = !!(touched.name && errors.name);
  const textAreaPlaceholder = isTitleError
    ? errors.name
    : t('textarea_placeholder', {
        responsibility: responsibilitySingular.toLowerCase(),
      });

  const handleKeyDown = (e: KeyboardEvent) => {
    const { key } = e;

    if (key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    } else if (key === 'Escape') {
      e.preventDefault();
      hideCard();
    }
  };

  const handleSetCreatedResponsibilityAsCurrent = useCallback(() => {
    if (!createResponsibilityResult.data) return;

    const { id: boardColumnResponsibilityId, responsibilityId } = createResponsibilityResult.data;
    const { name, time, frequency } = values;

    dispatch(setCurrentColumnId(columnId));
    dispatch(
      setCurrentResponsibility({
        name,
        description: '',
        contentUrls: [],
        responsibilityId,
        specializations: [],
        isPositionChanged: true,
        id: boardColumnResponsibilityId,
        workingHours: { responsibilityId, frequency, time },
      })
    );
    // We want to dispatch highlight logic only with createResponsibilityResult.data change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResponsibilityResult.data]);

  useDisplayFlashOnResponse({
    result: createResponsibilityResult,
    successFunction: handleSetCreatedResponsibilityAsCurrent,
    errorMessage: t('error_message'),
  });

  useEffect(() => {
    if (isSuccess) {
      handleResetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (createBoardError) {
      setFieldError('name', createBoardError.data.baseErrors[0]);
    }
  }, [createBoardError, setFieldError]);

  useEffect(() => {
    if (isOpen && fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [isOpen]);

  return (
    <AddResponsibilityContainer isOpen={isOpen} isVisible={isMenuOpen}>
      <AddResponsibilityCardWrapper isError={isTitleError}>
        <CharacterCounter isError={isTitleError}>
          {values.name ? values.name.trim().length : 0}/{RESPONSIBILITY_NAME_MAX_CHARACTERS}
        </CharacterCounter>

        <Textarea
          isError={isTitleError}
          maxLength={RESPONSIBILITY_NAME_MAX_CHARACTERS}
          name='name'
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={textAreaPlaceholder}
          ref={fieldRef}
          value={values.name}
        />

        <DurationTimeSetter
          elementId={columnId}
          errorText={errors.frequency || errors.time}
          frequency={values.frequency}
          hours={values.time}
          setFrequency={(frequency) => setFieldValue('frequency', frequency)}
          setHours={(hours) => setFieldValue('time', hours)}
          useFrequencyDropdownPortal
        />
      </AddResponsibilityCardWrapper>
      {isTitleError && <AssistiveText id='input-error-text' text={errors.name} type='error' />}
      <FormButtonsContainer isError={isTitleError}>
        <DefaultButton
          buttonType='secondary'
          fullWidth
          id={`delegation-planner-add-responsibility-button-${columnId}`}
          onClick={handleResetForm}
          text='Cancel'
        />
        <DefaultButton
          buttonType='primary'
          fullWidth
          id={`delegation-planner-cancel-add-responsibility-button-${columnId}`}
          onClick={() => handleSubmit()}
          text='Create'
          type='submit'
        />
      </FormButtonsContainer>
    </AddResponsibilityContainer>
  );
};

export default AddResponsibilityCard;
