import React, { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../../contexts/TrainingPathContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { daysUntil, getDueDateStatus } from '../../../../../../lib/dateUtils';
import initTranslations from '../../../../../../lib/initTranslations';
import { useSubjectAssignmentForUserMutation } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useUpdateTrainingPathContentMutation } from '../../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { TrainingPathContent } from '../../../../../../redux/services/resourceApis/trainingPaths/types';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import IconButton from '../../../../../design_system/buttons/IconButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import SourceBadge from '../../../../../design_system/navigation/SourceBadge/SourceBadge';
import SingleSelectField from '../../../../../design_system/Triage/fields/SingleSelectField';
import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import TooltipBadge from '../../../../people/Profile/SubjectsAssigned/TooltipBadge';
import { routes } from '../../../../publicApplication/applicationRouter';
import DetailedTitle from '../../../../shared/DetailedTitle/DetailedTitle';
import { Title } from '../../../../shared/DetailedTitle/Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const Card = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    background-color: ${vars.foundationSurface1};
    padding: ${constants.spacerMd1} ${constants.spacerMd2};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 60rem;
    gap: ${constants.spacerSm3};

    &:hover {
      background-color: ${vars.foundationBase1};
    }
  `
);

const IconWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
  `
);

const StyledSingleSelectField = styled(SingleSelectField)`
  width: 5.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  white-space: nowrap;
  flex-grow: 1;
  justify-content: flex-end;
`;

const DueDateBtn = styled.button`
  display: flex;
  width: 11rem;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  background-color: transparent;
  border: none;
`;

const RequiredForWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 10rem;
  `
);

const ProgressWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const PercentWidth = styled.div`
  display: flex;
  width: 3rem;
  justify-content: flex-end;
`;

const ProgressBarWidth = styled.div`
  display: flex;
  align-items: center;
  width: 6rem;
`;

const t = initTranslations('training_path.path_config.set_content_row');
const profileFlashNotifications = initTranslations('profile.flash_notifications');

type RequiredSourceBadgeProps = {
  trainingPathContent: TrainingPathContent;
};

const RequiredSourceBadge = ({ trainingPathContent }: RequiredSourceBadgeProps) => {
  const { requiredForUser, requiredGroups, curriculum } = trainingPathContent;
  const { user } = useTrainingPathContext();

  const requiredCurriculumAssignments = useMemo(() => {
    if (requiredForUser) {
      return [...requiredGroups, t('required_assignments.individual')];
    }

    return requiredGroups;
  }, [requiredForUser, requiredGroups]);

  if (requiredCurriculumAssignments.length > 1) {
    const sourceSubtext = `+${requiredCurriculumAssignments.length - 1}`;

    return (
      <>
        <Tooltip
          content={requiredCurriculumAssignments.join(', ')}
          id={`required-via-tooltip-${curriculum.id}`}
        />
        <SourceBadge
          id={`required-via-pill-${user.id}`}
          readOnly
          sourceName='group'
          sourceSubtext={sourceSubtext}
          sourceText={requiredCurriculumAssignments[0]}
          tooltipId={`required-via-tooltip-${curriculum.id}`}
        />
      </>
    );
  }

  if (requiredForUser) {
    return (
      <SourceBadge
        id={`required-via-pill-${user.id}`}
        readOnly
        sourceImageUrl={user.avatar}
        sourceName='users'
        sourceText={t('required_assignments.individual')}
        userName={user.name}
      />
    );
  }

  return (
    <SourceBadge
      id={`required-via-pill-${user.id}`}
      readOnly
      sourceName='group'
      sourceText={requiredGroups[0]}
    />
  );
};

export type SetContentRowProps = {
  trainingPathContent: TrainingPathContent;
  trainingPathSetAvailableAt: string | null;
  index: number;
  trainingPathSetUuid: string;
  contentCount: number;
};

const SetContentRow = ({
  trainingPathContent,
  trainingPathSetAvailableAt,
  index,
  trainingPathSetUuid,
  contentCount,
}: SetContentRowProps) => {
  const {
    curriculum: { id, emoji, title, published },
    dueDateAt,
    completionPercentage,
    position,
    requiredGroups,
  } = trainingPathContent;

  const {
    user: { id: userId },
    updateInProgress,
    setUpdateInProgress,
  } = useTrainingPathContext();

  const { slug } = useCurrentAccount();

  // TODO Add Back in https://trainual.atlassian.net/browse/GS1-5223
  // const [isCardHovered, setIsCardHovered] = useState(false);
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();
  const [subjectAssignment, subjectAssignmentResult] = useSubjectAssignmentForUserMutation();
  const [updateTrainingPathContent, updateContentResult] = useUpdateTrainingPathContentMutation({});

  useDisplayFlashOnResponse({
    result: subjectAssignmentResult,
    successMessage: profileFlashNotifications('unassignment'),
    successFunction: () => {
      setUpdateInProgress(false);
      subjectAssignmentResult.reset();
    },
    errorFunction: () => {
      setUpdateInProgress(false);
      subjectAssignmentResult.reset();
    },
  });

  useDisplayFlashOnResponse({
    result: updateContentResult,
    errorMessage: t('update_failed'),
    successFunction: () => {
      setUpdateInProgress(false);
      updateContentResult.reset();
    },
    errorFunction: () => {
      setUpdateInProgress(false);
      updateContentResult.reset();
    },
  });

  const dropdownOptions = useMemo(() => {
    return Array.from({ length: contentCount }, (_, i) => i + 1).map((value) => ({
      value: `${value}`,
      label: `${value}`,
      searchableTerm: `${value}`,
    }));
  }, [contentCount]);

  const curriculumEditPath = routes.curriculumEdit({ slug, id });

  const requiredForGroup = requiredGroups.length > 0;
  // TODO Add Back in https://trainual.atlassian.net/browse/GS1-5223
  // const displayClearCompletion = completionPercentage > 0 && isCardHovered;

  const unpublished = !published;
  const dueDateStatus = getDueDateStatus(dueDateAt);
  const displayDueDate =
    dueDateAt && published && completionPercentage !== 100 && dueDateStatus.state !== 'no_due_date';

  const availableDateConflictsWithDueDate =
    trainingPathSetAvailableAt &&
    dueDateAt &&
    daysUntil(new Date(trainingPathSetAvailableAt)) >= daysUntil(new Date(dueDateAt));

  return (
    <Draggable
      draggableId={`content+${id}+${trainingPathSetUuid}`}
      index={index}
      isDragDisabled={updateInProgress}
      key={id}
    >
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          // TODO Add Back in https://trainual.atlassian.net/browse/GS1-5223
          // onMouseEnter={() => {
          //   setIsCardHovered(true);
          // }}
          // onMouseLeave={() => {
          //   setIsCardHovered(false);
          // }}
        >
          <Card className='set-content-row' id={`set-curriculum-row-${id}`}>
            <IconWrapper {...provided.dragHandleProps}>
              <Icon name='grip-vertical' size='2xs' weight='solid' />
            </IconWrapper>
            <StyledSingleSelectField
              className='set-content-row-position-dropdown'
              defaultValue={`${index + 1}`}
              disabled={contentCount === 1}
              isSearchable
              onNonDefaultSelected={(value) => {
                if (updateInProgress) return;
                setUpdateInProgress(true);
                updateTrainingPathContent({
                  position: value ? parseInt(value) : position,
                  userId,
                  curriculumId: id,
                  trainingPathSetUuid,
                  oldSetUuid: trainingPathSetUuid,
                });
              }}
              /* TODO: Replace `options` with total # of curriculums, maybe don't even need to map them, maybe just literally take the total number & make options based on that */
              options={dropdownOptions}
              size='xs'
              value={`${index + 1}`}
            />
            <DetailedTitle
              emoji={emoji}
              fontSize='md1'
              fontWeight='regular'
              route={curriculumEditPath.href}
              title={title}
              truncate='twoLines'
            />
            <Wrapper>
              {displayDueDate && (
                <DueDateBtn
                  onClick={() => {
                    /* TODO: Update with onClick action */
                  }}
                >
                  {availableDateConflictsWithDueDate ? (
                    <DetailedTitle
                      fontColor='stateError'
                      fontSize='md1'
                      fontWeight='regular'
                      iconName='circle-exclamation'
                      route={curriculumEditPath.href}
                      title={t(dueDateStatus.state, {
                        count: dueDateStatus.number_of_days,
                      })}
                      tooltipId='due-date-conflict-tooltip'
                      tooltipText={t('tooltips.unlock_date_conflicts_with_due_in_days', {
                        curriculum: curriculumSingular.toLowerCase(),
                      })}
                    />
                  ) : ['due_today', 'past_due'].includes(dueDateStatus.state) ? (
                    <DetailedTitle
                      fontColor='stateError'
                      fontSize='md1'
                      fontWeight='regular'
                      iconName='circle-exclamation'
                      route={curriculumEditPath.href}
                      title={t(dueDateStatus.state, {
                        count: dueDateStatus.number_of_days,
                      })}
                    />
                  ) : (
                    <Title
                      fontColor='textSubdued'
                      fontSize='md1'
                      fontWeight='regular'
                      title={t(dueDateStatus.state, {
                        count: dueDateStatus.number_of_days,
                      })}
                    />
                  )}
                </DueDateBtn>
              )}
              {unpublished && (
                <TooltipBadge
                  badgeSize='md'
                  badgeText={t('badges.unpublished')}
                  badgeType='caution'
                  tooltipId='set-curriculum-row-unpublished-tooltip'
                  tooltipText={t('tooltips.unpublished_badge', {
                    curriculum: curriculumSingular.toLowerCase(),
                  })}
                />
              )}
              <RequiredForWrapper>
                <RequiredSourceBadge trainingPathContent={trainingPathContent} />
              </RequiredForWrapper>
              <ProgressWrapper>
                {/* TODO Add Back in https://trainual.atlassian.net/browse/GS1-5223 */}
                {/* {displayClearCompletion && (
                <Title
                  fontSize='sm5'
                  fontWeight='regular'
                  onClick={() => {}}
                  title={t('ctas.clear_completion')}
                />
              )} */}
                <PercentWidth>
                  <Title fontSize='md1' title={`${completionPercentage}%`} />
                </PercentWidth>
                <ProgressBarWidth>
                  <ProgressBar
                    id={`curriculum-${id}-progress-bar`}
                    percent={completionPercentage}
                    thickness='lg'
                  />
                </ProgressBarWidth>
              </ProgressWrapper>
              <Tooltip
                id={`remove-from-set-tooltip-${id}`}
                text={
                  requiredForGroup
                    ? t('tooltips.remove_from_set_blocked_by_group', {
                        subject: curriculumSingular.toLowerCase(),
                      })
                    : t('tooltips.remove_from_set', {
                        subject: curriculumSingular.toLowerCase(),
                      })
                }
              />
              <IconButton
                ariaLabel={t('aria_labels.remove_from_set')}
                buttonSize='sm'
                className='content-trash-can'
                disabled={requiredForGroup}
                name='trash-can'
                onClick={() => {
                  subjectAssignment({
                    addSubjectIds: null,
                    removeSubjectIds: [id],
                    userId,
                  });
                }}
                tooltipId={`remove-from-set-tooltip-${id}`}
              />
            </Wrapper>
          </Card>
        </Container>
      )}
    </Draggable>
  );
};

export default SetContentRow;
