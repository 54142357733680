import styled, { css } from 'styled-components';

import { fontSm5 } from '../../../../../../styled/TypeSystem';

export const FlyoutTextWrapper = styled.div<{ reverse: boolean }>`
  ${({ theme: { constants }, reverse }) => css`
    display: flex;
    flex-direction: ${reverse ? 'column-reverse' : 'column'};
    margin-bottom: ${constants.spacerMd1};
    ${fontSm5};
  `}
`;

export const FlyoutText = styled.p`
  ${({ theme: { constants } }) => css`
    font-weight: ${constants.fontMedium};
    margin-bottom: ${constants.spacerSm3};
  `}
`;

export const FlyoutSubtext = styled.p`
  ${({ theme: { constants, vars } }) => css`
    font-weight: ${constants.fontRegular};
    color: ${vars.textSubdued};
    margin-bottom: ${constants.spacerSm3};
  `}
`;
