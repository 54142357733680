import React from 'react';
import styled from 'styled-components';

import translationClassname from '../../../../../lib/translationClassname';
import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { fontSm4 } from '../../../../styled/TypeSystem';

const Title = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme: { vars } }) => vars.textSubdued};
    text-decoration-thickness: 1px !important;
  }
  ${fontSm4};
  ${MultilineTruncatedText({ lineClamp: 2 })};
`;

export type Props = {
  dataFor?: string;
  id: string;
  title: string;
  titleRef?: React.MutableRefObject<HTMLSpanElement | null>;
  disableTranslation?: boolean;
};

const CurriculumTitle = ({ dataFor, title, titleRef, id, disableTranslation }: Props) => {
  return (
    <Title
      className={translationClassname(disableTranslation)}
      data-for={dataFor}
      id={id}
      ref={titleRef}
    >
      {title}
    </Title>
  );
};

export default CurriculumTitle;
