import React from 'react';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteTrainingPathMutation } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import TaskModal from '../../../../design_system/overlays/modals/TaskModal';
import useActiveModalHandler from '../../../../design_system/useActiveModalHandler';

const t = initTranslations('training_path.path_config.config_header.destroy_confirmation_modal');

const DestroyTPConfirmationModal = () => {
  const {
    user: { id: userId },
  } = useTrainingPathContext();
  const { closeActiveModal } = useActiveModalHandler();
  const [destroyPath, destroyPathResult] = useDeleteTrainingPathMutation({
    fixedCacheKey: 'shared-destroy-training-path',
  });

  useDisplayFlashOnResponse({
    result: destroyPathResult,
    errorMessage: t('clear_failed'),
    successFunction: () => {
      destroyPathResult.reset();
      closeActiveModal();
    },
    errorFunction: () => {
      destroyPathResult.reset();
    },
  });

  return (
    <TaskModal
      desktopSize='lg'
      heapModalName='tp-destroy-path-modal'
      isDisabled={destroyPathResult.isLoading}
      onCloseRequest={closeActiveModal}
      primaryButtonTask={() => destroyPath({ userId, deletePublished: true })}
      primaryButtonText={t('submit_button')}
      processing={destroyPathResult.isLoading}
      secondaryButtonText={t('cancel_button')}
      subtitle={t('subheader')}
      title={t('header')}
    />
  );
};

export default DestroyTPConfirmationModal;
