import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  Droppable,
} from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

import { useTrainingPathSetContext } from '../../../../../../contexts/TrainingPathSetContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { TrainingPathContent } from '../../../../../../redux/services/resourceApis/trainingPaths/types';
import IconButton from '../../../../../design_system/buttons/IconButton';
import Icon from '../../../../../design_system/display/icons/Icon';
import Link from '../../../../../design_system/Link';
import { CollapsibleContent } from '../../../../../design_system/Triage/layout/CollapsibleSurface';
import { fontMd1 } from '../../../../../styled/TypeSystem';
import AssignSubjectsModal from '../../../../people/AssignSubjectsModal/AssignSubjectsModal';
import { Title } from '../../../../shared/DetailedTitle/Title';
import CreateSplit from '../../CreateSplit/CreateSplit';
import SetContentRow from '../SetContentRow/SetContentRow';
import DelayToggle from './DelayFields/DelayToggle';
import DelayTypeFlyout from './DelayFields/DelayTypeFlyout';
import DelayValueInput from './DelayFields/DelayValueInput';
import ForceOrderToggle from './ForceOrderToggle';
import SetDelayMessage from './SetDelayMessage/SetDelayMessage';
import TitleInput from './TitleInput';

const Wrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusLg};
  `
);

const ContentsContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm2};
    padding: ${constants.spacerMd3};
    border-top: ${constants.borderWidthSm} solid ${vars.borderSurface2};
  `
);

const HeaderContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${constants.spacerMd2};
    padding: ${constants.spacerSm3} ${constants.spacerMd3};
  `
);

const RightContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconButton = styled(IconButton)<{ isCollapsed: boolean }>`
  transition: transform 0.2s ease-in;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const HoverableContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const FlexRowContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    gap: ${constants.spacerMd1};
  `
);

const DelayDataWrapper = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
  width: ${({ visible }) => (visible ? 'auto' : '0')};
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.5s;
`;

const ContentWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerSm2};
  `
);

const CompletedStepsDiv = styled.div`
  ${fontMd1};
`;

const t = initTranslations('training_path.path_config.config_set');

type CompletedStepsProps = {
  trainingPathContents: TrainingPathContent[];
};

const CompletedSteps = ({ trainingPathContents }: CompletedStepsProps) => {
  const completedSteps = trainingPathContents.filter(
    (content) => content.completionPercentage === 100
  ).length;
  const totalSteps = trainingPathContents.length;

  return (
    <CompletedStepsDiv>
      {t('complete', { count: completedSteps, out_of: totalSteps })}
    </CompletedStepsDiv>
  );
};

type ConfigSetHeaderProps = {
  dragHandle: DraggableProvidedDragHandleProps | undefined;
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const ConfigSetHeader = ({ dragHandle, isCollapsed, setIsCollapsed }: ConfigSetHeaderProps) => {
  const {
    userId,
    trainingPathSet: {
      trainingPathContents,
      title,
      trainingPathSetUuid,
      titleVisible,
      minutesToReadFormatted,
    },
    delayEnabled,
  } = useTrainingPathSetContext();
  const [showAssignSubjectsModal, setShowAssignSubjectsModal] = useState(false);
  const [showAndFocusTitleInput, setShowAndFocusTitleInput] = useState(false);

  useEffect(() => {
    setShowAndFocusTitleInput(!!title);
  }, [title]);

  return (
    <HeaderContainer className='config-set-header'>
      <IconWrapper {...dragHandle}>
        <Icon name='grip-vertical' size='2xs' weight='solid' />
      </IconWrapper>
      <HoverableContainer>
        <TitleInput
          setId={trainingPathSetUuid}
          setShowAndFocus={setShowAndFocusTitleInput}
          showAndFocus={showAndFocusTitleInput}
          titleVisible={titleVisible}
        />
        <FlexRowContainer>
          <CompletedSteps trainingPathContents={trainingPathContents} />
          <ForceOrderToggle />
          <DelayToggle />
          {delayEnabled && (
            <DelayDataWrapper className='delay-fields' visible={delayEnabled}>
              <DelayValueInput />
              <DelayTypeFlyout />
            </DelayDataWrapper>
          )}
          {!showAndFocusTitleInput && !title && (
            <Link
              behavesAs='button'
              className='show-title-trigger'
              color='monochrome'
              onClick={() => {
                setShowAndFocusTitleInput(true);
              }}
              prefixIconName='pencil'
              text={t('add_title')}
              underlineBehavior='dynamic'
            />
          )}
        </FlexRowContainer>
      </HoverableContainer>

      <RightContainer>
        <Title
          fontColor='textSubdued'
          fontSize='md1'
          title={t('read_time', { minutesToReadFormatted })}
          truncate='oneLine'
        />
        <IconWrapper>
          <IconButton
            ariaLabel={t('add_content_aria_label')}
            id='add-training-path-content-button'
            name='plus'
            onClick={() => setShowAssignSubjectsModal(true)}
            weight='regular'
          />
          <StyledIconButton
            ariaLabel={t('set_chevron_aria_label')}
            buttonSize='sm'
            id='training-path-chevron-button'
            isCollapsed={isCollapsed}
            name='chevron-down'
            onClick={() => setIsCollapsed(!isCollapsed)}
            weight='solid'
          />
        </IconWrapper>
      </RightContainer>

      {showAssignSubjectsModal && (
        <AssignSubjectsModal
          closeModal={() => setShowAssignSubjectsModal(false)}
          trainingPathSetUuid={trainingPathSetUuid}
          userId={userId}
        />
      )}
    </HeaderContainer>
  );
};

export type ConfigSetProps = {
  provided: DraggableProvided;
};

const ConfigSet = ({ provided }: ConfigSetProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {
    trainingPathSet: { trainingPathContents, availableAt, trainingPathSetUuid },
  } = useTrainingPathSetContext();

  return (
    <Wrapper id={`config-set-${trainingPathSetUuid}`}>
      <ConfigSetHeader
        dragHandle={provided.dragHandleProps}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <Droppable droppableId={`content+${trainingPathSetUuid}`} type='content'>
        {(provided) => (
          <CollapsibleContent isCollapsed={isCollapsed} isCollapsible>
            <ContentsContainer {...provided.droppableProps} ref={provided.innerRef}>
              <SetDelayMessage />
              {trainingPathContents.map((content, index) => (
                <ContentWrapper
                  className='config-set-wrapper'
                  key={`overview-set-content-${content.curriculum.id}`}
                >
                  {index !== 0 && <CreateSplit curriculumId={content.curriculum.id} />}
                  <SetContentRow
                    contentCount={trainingPathContents.length}
                    index={index}
                    trainingPathContent={content}
                    trainingPathSetAvailableAt={availableAt}
                    trainingPathSetUuid={trainingPathSetUuid}
                  />
                </ContentWrapper>
              ))}
              {provided.placeholder}
            </ContentsContainer>
          </CollapsibleContent>
        )}
      </Droppable>
    </Wrapper>
  );
};

export default ConfigSet;
