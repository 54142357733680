import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../../../lib/initTranslations';
import Avatar from '../../../../../../../design_system/display/avatar';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../../../design_system/display/Tooltip/Tooltip';
import { TruncatedText } from '../../../../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../../../../styled/TypeSystem';
import { getChevronIconName } from '../../../../../../shared/helpers';

const TruncateSpan = styled.span`
  pointer-events: none;
  ${TruncatedText({})};
`;

const NoShrinkSpan = styled.span`
  display: flex;
  align-items: center;
  pointer-events: none;
  flex-shrink: 0;
`;

const StyledButton = styled.button<{ disabled: boolean }>`
  ${({ theme: { constants, vars }, disabled }) => css`
    display: flex;
    gap: ${constants.spacerSm2};
    align-items: center;
    justify-content: center;
    background: ${vars.foundationSurface1};
    cursor: ${disabled ? 'default' : 'pointer'};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    border-radius: ${constants.borderRadiusEndcap};
    padding: 0.375rem ${constants.spacerMd1};
    outline: none;
    max-width: 15rem;
    color: ${vars.textDefault};
    height: ${constants.heightMd};

    &:hover,
    &:active,
    &:focus {
      border-color: ${disabled ? vars.borderSurface2 : vars.accentPrimaryDefault};
    }

    ${fontSm5};
  `};
`;

const StyledAvatar = styled(Avatar)`
  margin-right: ${({ theme: { constants } }) => constants.spacerLg3};
`;

type SourceBadgeProps = {
  ariaLabel: string;
  buttonText: string;
  className: string;
  active: boolean;
  disabled?: boolean;
  id: string;
  ownerName?: string;
  sourceImageUrl?: string;
};

const t = initTranslations('curriculums.owner');

const OwnedByButton = ({
  active,
  ariaLabel,
  buttonText,
  className,
  disabled = false,
  id,
  ownerName,
  sourceImageUrl,
}: SourceBadgeProps) => {
  const ownedByBtnRef = React.useRef<HTMLButtonElement>(null);
  return (
    <>
      <Tooltip
        id='owner-tooltip'
        text={disabled ? t('admin_access_required') : t('change_owner')}
      />
      <StyledButton
        aria-label={ariaLabel}
        className={className}
        data-active={active}
        data-for='owner-tooltip'
        data-tip
        disabled={disabled}
        id={id}
        ref={ownedByBtnRef}
      >
        {ownerName ? (
          <NoShrinkSpan>
            <Avatar image={sourceImageUrl} name={ownerName} shape='circle' size='xs' />
          </NoShrinkSpan>
        ) : (
          disabled && (
            <>
              <StyledAvatar icon='user' name='' shape='circle' size='xs' />
              <span />
            </>
          )
        )}
        <TruncateSpan>{buttonText}</TruncateSpan>
        {!disabled && (
          <NoShrinkSpan>
            <Icon
              name={getChevronIconName({ isActive: active, initialDirection: 'up' })}
              size='xs'
              weight='regular'
            />
          </NoShrinkSpan>
        )}
      </StyledButton>
    </>
  );
};

export default OwnedByButton;
