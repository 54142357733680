import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import TooltipHoverIcon from '../../../../../design_system/core/Labels/ControlLabel/TooltipHoverIcon';
import ProgressBar from '../../../../../design_system/Triage/ProgressBar';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';

const Div = styled.div`
  ${({ theme: { constants, vars } }) => css`
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface1};
    color: ${vars.textDefault};
  `};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme: { constants, vars } }) => css`
    border-bottom: ${constants.borderWidthSm} solid ${vars.borderSurface1};
    padding: ${constants.spacerMd2};
  `};
`;

const Title = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 8.75rem;
  ${({ theme: { constants } }) => css`
    gap: ${constants.spacerSm2};
    font-weight: ${constants.fontBold};
  `};
  ${fontMd1};
`;

const Content = styled.div`
  ${({ theme: { constants, vars } }) => css`
    color: ${vars.textSubdued};
    padding: ${constants.spacerMd2};
    p {
      margin: ${constants.spacerSm3} 0;
      display: flex;
      align-items: center;
      gap: ${constants.spacerSm2};
    }
  `};
  ${fontSm4};
`;

const t = initTranslations('user_profile.training_path_profile_card.progress_panel');

export interface ProgressBlockProps {
  completionPercentage: number;
  coursesRemainingCount: number;
  curriculumsRemainingCount: number;
  surveysRemainingCount: number;
  readingTimeRemaining: string;
}

const ProgressBlock = ({
  completionPercentage,
  coursesRemainingCount,
  curriculumsRemainingCount,
  surveysRemainingCount,
  readingTimeRemaining,
}: ProgressBlockProps) => {
  const {
    curriculum: { singular: curriculumSingular, plural: curriculumPlural },
    topic: { singular: topicSingular, plural: topicPlural },
  } = useAccountTerminology();

  return (
    <Div>
      <Header>
        <Title>{t('title')}</Title>
        <ProgressBarWrapper>
          {completionPercentage}%
          <ProgressBar percent={completionPercentage} thickness='lg' />
        </ProgressBarWrapper>
      </Header>

      <Content>
        <p>
          <b>{completionPercentage}%</b> {t('completion_score')}
        </p>
        <p>
          {t('subjects_remaining', {
            count: curriculumsRemainingCount,
            subject: curriculumSingular.toLowerCase(),
            subjects: curriculumPlural.toLowerCase(),
          })}
        </p>
        <p>
          {t('courses_remaining', {
            count: coursesRemainingCount,
            course: topicSingular.toLowerCase(),
            courses: topicPlural.toLowerCase(),
          })}
        </p>
        <p>{t('tests_remaining', { count: surveysRemainingCount })}</p>
        <p>
          <b>{readingTimeRemaining}</b> {t('reading_time_remaining')}
          <TooltipHoverIcon tooltipId='path-summary-tooltip' tooltipText={t('tooltip_text')} />
        </p>
      </Content>
    </Div>
  );
};

export default ProgressBlock;
