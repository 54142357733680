import React, { useCallback } from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../hooks/useWindowResize';
import { RegisteredMenuId } from '../../../../lib/idRegistry';
import Poppable from '../../../Poppable';
import { mediaBreakpointXl } from '../../../styled/Breakpoint';
import { MenuOptions } from '../../core/MenuOption/shared';
import OutsideClickHandler from '../../OutsideClickHandler';
import { GetMenuButton } from '../../Triage/menus/ThreeDotMenu';
import useActiveMenuHandler from '../../useActiveMenuHandler';

const ThreeDotWrapper = styled.div`
  width: fit-content;
`;

export type Props = {
  id: RegisteredMenuId;
  children: React.ReactNode;
  isDisabled?: boolean;
};

const ControlledThreeDotMenu = ({ children, isDisabled, ...propsForMenuButton }: Props) => {
  const { width } = useWindowResize();

  const placement = width >= mediaBreakpointXl ? 'bottom-start' : 'bottom-end';

  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId: propsForMenuButton.id,
  });

  const handleOutsideClick = useCallback(
    (e: MouseEvent & { target: Element }) => {
      if (!(e.target as Element).classList.contains('three-dot-menu-button')) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  return (
    <ThreeDotWrapper>
      <Poppable
        isOpen={isMenuOpen}
        item={
          <OutsideClickHandler onOutsideClick={(e) => handleOutsideClick(e)}>
            <MenuOptions className='open-three-dot-menu'>{children}</MenuOptions>
          </OutsideClickHandler>
        }
        onClick={() => {
          if (!isDisabled) {
            handleMenuClick();
          }
        }}
        placement={placement}
        trigger={GetMenuButton(propsForMenuButton)}
      />
    </ThreeDotWrapper>
  );
};

export default ControlledThreeDotMenu;
