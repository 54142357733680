import React from 'react';

import usePublicConfigs from '../../../hooks/usePublicConfigs';
import { SignUpLinkExpiredImage } from '../../../lib/gcsImages';
import initTranslations from '../../../lib/initTranslations';
import {
  SingUpLinkExpiredColumn,
  SingUpLinkExpiredContainer,
  SingUpLinkExpiredLink,
  SingUpLinkExpiredText,
  SingUpLinkExpiredTitle,
  SingUpLinkExpiredWrapper,
} from './styles';

const t = initTranslations('sign_up_link_expired');

export type SingUpLinkExpiredProps = {
  titleKey: string;
};

const SingUpLinkExpired = ({ titleKey }: SingUpLinkExpiredProps) => {
  const { configs } = usePublicConfigs();
  const helpPageUrl = configs['HELP_PAGE_URL'];

  return (
    <SingUpLinkExpiredWrapper id='sing-up-link-expired'>
      <SingUpLinkExpiredContainer>
        <SingUpLinkExpiredColumn>
          <SingUpLinkExpiredTitle>{t(titleKey)}</SingUpLinkExpiredTitle>
          <SingUpLinkExpiredText
            text={
              <>
                {t('reach_out_to_your_account_executive')}
                <SingUpLinkExpiredLink
                  href={helpPageUrl}
                  target='_blank'
                  text={t('contact_support')}
                />
              </>
            }
          />
        </SingUpLinkExpiredColumn>
        <img alt={t('expired_link_image_alt')} src={SignUpLinkExpiredImage} />
      </SingUpLinkExpiredContainer>
    </SingUpLinkExpiredWrapper>
  );
};

export default SingUpLinkExpired;
