import React, { useMemo } from 'react';
import styled from 'styled-components';

import { TrainingPathSet } from '../../../../../../redux/services/resourceApis/trainingPaths/types';
import { ProfileUser } from '../../../../../../redux/services/resourceApis/users/types';
import { fontSm4 } from '../../../../../styled/TypeSystem';
import { CompletionStatus } from '../../../../shared/ProgressItem/ProgressTimeline/ProgressTimeline';
import OverviewSetHeader from './OverviewSetHeader';
import SetContentRow from './SetContentRow/SetContentRow';

export type OverviewSetProps = {
  set: TrainingPathSet;
  user: Pick<ProfileUser, 'id' | 'name'>;
  showFavorite?: boolean;
  setStatus: CompletionStatus;
};

const StyledSetWrapper = styled.div`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderRadiusXs} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  overflow: hidden;
  ${fontSm4};
`;

const OverviewSet = ({
  set: {
    delayType,
    delayValue,
    delayState,
    forceOrder,
    trainingPathContents,
    trainingPathSetUuid,
    title,
    titleVisible,
    minutesToReadFormatted,
  },
  user,
  showFavorite = false,
  setStatus,
}: OverviewSetProps) => {
  const firstIndexNotLockedByForcedOrder = useMemo(() => {
    if (!forceOrder) return null;
    return trainingPathContents.findIndex(
      ({ completionPercentage, curriculum: { published } }) =>
        completionPercentage < 100 && published
    );
  }, [forceOrder, trainingPathContents]);

  const blockedByStatus = useMemo(() => {
    return (
      setStatus === 'not started' ||
      (delayState.status !== 'no_delay' &&
        ['not_started', 'in_progress'].includes(delayState.status))
    );
  }, [delayState.status, setStatus]);

  const blockedByForcedOrder = (index: number) => {
    return (
      firstIndexNotLockedByForcedOrder !== null &&
      firstIndexNotLockedByForcedOrder !== -1 &&
      index > firstIndexNotLockedByForcedOrder
    );
  };

  const isBlocked = (index: number) => {
    return blockedByStatus || blockedByForcedOrder(index);
  };

  return (
    <StyledSetWrapper id={`training-path-overview-set-${trainingPathSetUuid}`}>
      <OverviewSetHeader
        delayState={delayState}
        delayType={delayType}
        delayValue={delayValue}
        forceOrder={forceOrder}
        minutesToReadFormatted={minutesToReadFormatted}
        title={title}
        titleVisible={titleVisible}
      />

      {trainingPathContents.map((content, index) => (
        <SetContentRow
          blocked={isBlocked(index)}
          key={`overview-set-content-${content.curriculum.id}`}
          showFavorite={showFavorite}
          trainingPathContent={content}
          user={user}
        />
      ))}
    </StyledSetWrapper>
  );
};

export default OverviewSet;
