import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTeammateModal } from '../../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import {
  useAbilityToEditUser,
  useAbilityToViewProfile,
} from '../../../../../hooks/users/ProfileSharedAbilities';
import { deleteQueryParam, fetchQueryParameters } from '../../../../../lib/getQueryParams';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { ProfileUser } from '../../../../../redux/services/resourceApis/users/types';
import { useResendInviteMutation } from '../../../../../redux/services/resourceApis/users/usersApi';
import { Group } from '../../../../../types/Group';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Avatar from '../../../../design_system/display/avatar';
import P from '../../../../design_system/text/P';
import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';
import { fontMd5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';
import { Title } from '../../../shared/DetailedTitle/Title';
import { ClickableLinkText } from '../../../shared/DetailedTitle/TitleStyles';
import OutOfSeatsConfirmationModal from '../../OutOfSeatsConfirmationModal';
import { ClickableAvatar } from '../../Shared/Person/ClickableAvatar';
import TeammateModal from '../../TeammateModal/TeammateModal';
import ProfileGroupPills from '../Shared/ProfileGroupPills';
import SecondaryUserDetail, { LabelText } from '../Shared/SecondaryUserDetail';
import SecondaryUserDetailStatus from '../Shared/SecondaryUserDetailStatus';
import { JobTitle } from '../Shared/styles';
import UserEmail from '../Shared/UserEmail';
import UserPhone from '../Shared/UserPhone';
import AvatarUpload from './AvatarUpload/AvatarUpload';
import FollowUserProfileButton from './FollowUserProfileButton';

const UserProfileWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  padding: ${({
    theme: {
      constants: { spacerMd3, spacerLg3 },
    },
  }) => `${spacerMd3} ${spacerMd3} ${spacerLg3} ${spacerMd3}`};
  width: 100%;
  box-shadow: 0 5px 15px ${({ theme: { vars } }) => vars.shadowBackground1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 22.5rem;
    box-sizing: border-box;
  }
`;

const UserPrimaryDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const UserName = styled.p`
  word-break: break-word;
  font-weight: ${({ theme: { constants } }) => constants.fontExtrabold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: ${({
    theme: {
      constants: { spacerSm3 },
    },
  }) => `${spacerSm3} ${spacerSm3} 0 ${spacerSm3}`};
  text-align: center;
  ${fontMd5};
`;

const PronounsWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

// DS Override: Need to match design color and margin
const Pronouns = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const StyledDefaultButton = styled(DefaultButton)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const StyledHR = styled.hr`
  border: 0;
  height: 1px;
  background: ${({ theme: { vars } }) => vars.borderSurface2};
  margin: ${({ theme: { constants } }) => constants.spacerMd2} 0;
  width: 100%;
`;

const UserSecondaryDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 100%;
`;

const DirectReportsWrapper = styled.div`
  .direct-reports-user {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  }

  .direct-reports-user:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionTitleWrapper = styled.div`
  margin: 0 0 ${({ theme: { constants } }) => constants.spacerSm3} 0;
`;

const DirectReportsUser = styled.div`
  display: flex;
`;

const AvatarWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const DirectReportsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const DirectReportsJob = styled(LabelText)`
  color: ${({ theme: { vars } }) => vars.textDisabled};
`;

const t = initTranslations('user_profile');

const GroupsSection = ({ groups }: { groups: Group[] }) => {
  return (
    <>
      <StyledHR />
      <Wrapper id='groups'>
        <SectionTitleWrapper>
          <Title fontSize='md1' fontWeight='semibold' title={t('groups')} />
        </SectionTitleWrapper>
        <ProfileGroupPills groups={groups} />
      </Wrapper>
    </>
  );
};

export interface Props {
  user: ProfileUser;
  className?: string;
}

const UserProfileCard = ({ user, className = '' }: Props) => {
  const userGroups = user.groups.filter(({ everyone }) => !everyone);
  const { setTeammateModalVisible } = useTeammateModal();
  const {
    noFreeSlots,
    onHoldPlan,
    slug,
    totalUserSlotsCount,
    usedUserSlotsCount,
    splitFeatures: { perUserPricingEnabled },
  } = useCurrentAccount();
  const { id: currentUserId } = useCurrentUser();
  const ability = useCurrentUserAbilities();
  const canFollowUser = ability.can('follow_user', user);
  const profilePhoneNumberEnabled = ability.can('read', 'ProfilePhoneNumber');
  const profileEmailEnabled = ability.can('read', 'ProfileEmail');
  const showSendInviteLink = ability.can('invite', user) && !onHoldPlan;
  const showResendInviteLink = ability.can('resend_invite', user) && !onHoldPlan;
  const ableToEditUser = useAbilityToEditUser(user.id);
  const ableToViewReportsToProfile = useAbilityToViewProfile(currentUserId);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [resendInvite, inviteResult] = useResendInviteMutation();
  const {
    isLoading: isSendingInvitation,
    error: resetInviteError,
    reset: resendInviteReset,
  } = inviteResult;

  useDisplayFlashOnResponse({
    result: inviteResult,
    successMessage: t('invite_info.success'),
    successFunction: resendInviteReset,
    errorMessage: messageFromError(resetInviteError)?.join(', '),
  });

  useEffect(() => {
    const queryParams = fetchQueryParameters();
    if (queryParams.get('show_edit_user_modal') === 'true') {
      deleteQueryParam('show_edit_user_modal');
      setTeammateModalVisible(true);
    }
  }, [setTeammateModalVisible]);

  const isPendingUser = user.status === 'pending';
  const isNotInvitedUser = user.status === 'not invited';

  const handleSendInviteClick = () => {
    if (isNotInvitedUser) {
      if (perUserPricingEnabled && noFreeSlots) {
        setShowConfirmationModal(true);
      } else {
        if (!isSendingInvitation) resendInvite(user.id);
      }
    }

    if (isPendingUser && !isSendingInvitation) return resendInvite(user.id);
  };

  const showInviteLinkForPending = isPendingUser ? showResendInviteLink : false;
  const showInviteLink = isNotInvitedUser ? showSendInviteLink : showInviteLinkForPending;
  const resendInviteText = isPendingUser ? t('invite_link.resend_invite') : '';
  const inviteUserText = isNotInvitedUser ? t('invite_link.send_invite') : resendInviteText;
  const invitationText = isPendingUser
    ? `${user.invitation_sent_at} ${t('invite_link.pending')}`
    : user.invitation_sent_at;
  const breadcrumbRoute = ability.can('read', 'User')
    ? routes.manageUsers({ slug })
    : routes.directory({ slug });

  return (
    <>
      <UserProfileWrapper className={className} id='user-profile-card'>
        <UserPrimaryDetails id='user-profile-primary-details'>
          {ability.can('update_avatar', user) ? (
            <AvatarUpload user={user} />
          ) : (
            <Avatar
              ariaLabel={t('avatar_aria_label', { user_name: user.name })}
              image={user.avatar}
              name={user.name}
              shape='circle'
              size='2xl'
            />
          )}
          <UserName className='notranslate'>{user.name}</UserName>
          {user.title && <JobTitle>{user.title}</JobTitle>}
          {user.pronouns && (
            <PronounsWrapper>
              <Pronouns className='notranslate' text={`(${user.pronouns})`} />
            </PronounsWrapper>
          )}
          <ButtonsWrapper>
            {ableToEditUser && (
              <StyledDefaultButton
                buttonType='secondary'
                id='edit-user-button'
                onClick={() => setTeammateModalVisible(true)}
                size='sm'
                text={t('edit_user')}
              />
            )}
            {canFollowUser && <FollowUserProfileButton userId={user.id} />}
          </ButtonsWrapper>
        </UserPrimaryDetails>
        <StyledHR />
        <UserSecondaryDetails id='user-profile-secondary-details'>
          {profilePhoneNumberEnabled && <UserPhone phoneNumberValue={user.phone_number} />}
          {profileEmailEnabled && <UserEmail emailValue={user.email} />}
          <SecondaryUserDetailStatus
            inviteUserText={inviteUserText}
            labelText={invitationText}
            labelValue={t('invite_info.invite_date')}
            onSendInviteClick={handleSendInviteClick}
            showInviteLink={showInviteLink}
          />
          {user.reports_to && (
            <SecondaryUserDetail
              labelText={user.reports_to.name}
              labelValue={t('reports_to')}
              to={
                ableToViewReportsToProfile && user.reports_to.show_in_directory
                  ? routes.userProfile({
                      slug,
                      id: user.reports_to.id,
                    })
                  : undefined
              }
            />
          )}
        </UserSecondaryDetails>
        {!!user.direct_reports.length && (
          <>
            <StyledHR />
            <Wrapper id='direct-reports'>
              <SectionTitleWrapper>
                <Title fontSize='md1' fontWeight='semibold' title={t('direct_reports_title')} />
              </SectionTitleWrapper>
              <DirectReportsWrapper>
                {user.direct_reports.map((directReportsUser) => (
                  <DirectReportsUser className='direct-reports-user' key={directReportsUser.id}>
                    <AvatarWrapper>
                      {ableToViewReportsToProfile && (
                        <ClickableAvatar
                          ariaLabel={t('avatar_aria_label', { user_name: directReportsUser.name })}
                          avatarShape='circle'
                          avatarSize='md'
                          breadcrumb={breadcrumbRoute}
                          user={directReportsUser}
                        />
                      )}
                      {!ableToViewReportsToProfile && (
                        <Avatar
                          ariaLabel={t('avatar_aria_label', { user_name: directReportsUser.name })}
                          image={directReportsUser.avatar}
                          name={directReportsUser.name}
                          shape='circle'
                          size='md'
                        />
                      )}
                    </AvatarWrapper>
                    <DirectReportsInfo>
                      {ableToViewReportsToProfile && (
                        <ClickableLinkText
                          className='react_profile_direct_reports_select-name'
                          {...routes.userProfile({
                            slug,
                            id: directReportsUser.id,
                          }).link}
                        >
                          {directReportsUser.name}
                        </ClickableLinkText>
                      )}
                      {!ableToViewReportsToProfile && <p>{directReportsUser.name}</p>}
                      <DirectReportsJob className='react_profile_direct_reports_select-title'>
                        {directReportsUser.title}
                      </DirectReportsJob>
                    </DirectReportsInfo>
                  </DirectReportsUser>
                ))}
              </DirectReportsWrapper>
            </Wrapper>
          </>
        )}
        {!!userGroups.length && <GroupsSection groups={userGroups} />}
      </UserProfileWrapper>
      <TeammateModal action='edit' />
      {perUserPricingEnabled && (
        <OutOfSeatsConfirmationModal
          setShowModal={setShowConfirmationModal}
          showModal={showConfirmationModal}
          usedUserSlots={usedUserSlotsCount}
          userSlots={totalUserSlotsCount}
        />
      )}
    </>
  );
};

export default UserProfileCard;
