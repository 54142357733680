import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetCurriculumElementsForAdminQuery } from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useGetCurriculumForEditQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import Loader from '../../../../design_system/Triage/Loader';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import CurriculumBreadCrumbs from '../../shared/BreadCrumbs';
import { BreadCrumbsToggleWrapper } from '../../shared/styles';
import { CTABanner } from './AIOutliner/CTABanner/CTABanner';
import TopSurface from './TopSurface';

const Wrapper = styled.div<{ customTopPadding?: string }>`
  padding-top: ${({ theme: { constants }, customTopPadding }) =>
    customTopPadding ? customTopPadding : constants.spacerLg2};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerLg2};
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 80rem;

  @media (min-width: ${mediaBreakpointPxMd}) {
    padding-inline: ${({ theme: { constants } }) => constants.spacerLg2};
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

export interface Props {
  id: number;
}

const BodyHeader = ({ id }: Props) => {
  const { permission } = useCurrentUser();
  const canViewOutline = permission === 'admin' || permission === 'billing_admin';
  const {
    isLoading: isLoadingCurriculum,
    error,
    data: curriculum,
  } = useGetCurriculumForEditQuery(id);
  const { isLoading: isLoadingCurriculumElements, data: curriculumElements } =
    useGetCurriculumElementsForAdminQuery(id);
  const [showAICTABanner, setShowAICTABanner] = useState(false);
  const isLoading = isLoadingCurriculum || isLoadingCurriculumElements;

  useEffect(() => {
    setShowAICTABanner(curriculumElements?.curriculumElements.length === 0);
  }, [curriculumElements?.curriculumElements.length]);

  if (isLoading)
    return (
      <LoaderWrapper>
        <Loader size='lg' />
      </LoaderWrapper>
    );
  if (error) return <BasicErrorDisplay error={error} />;
  if (!curriculum) return <></>;

  const {
    emoji,
    title,
    advanced_settings: { disable_translation: disableTranslation },
  } = curriculum;

  return (
    <Wrapper>
      <BreadCrumbsToggleWrapper id='edit-curriculum-breadcrumbs'>
        <CurriculumBreadCrumbs
          disableTranslation={disableTranslation}
          emoji={emoji}
          isEdit={canViewOutline}
          title={title}
        />
      </BreadCrumbsToggleWrapper>
      {showAICTABanner && <CTABanner setShowAICTABanner={setShowAICTABanner} />}
      <TopSurface curriculum={curriculum} />
    </Wrapper>
  );
};

export default BodyHeader;
