import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import useWindowResize from '../../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../../lib/initTranslations';
import InputFieldWithCharacterCounter from '../../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { CUSTOM_TERMINOLOGY_MAX_CHARACTERS } from '../../../shared/constants/accountSettings';
import { SettingsData } from '../../types';
import TerminologyPluralsSettingToggle from './TerminologyPluralsSettingToggle';

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const InputSection = styled.div`
  display: flex;
  align-items: end;
`;

const TermWrapper = styled.div`
  flex-grow: 1;
`;

const t = initTranslations('account_settings.content_settings.custom_terminology_form');

const CustomTerminologyForm = () => {
  const { values, errors, handleChange, setFieldValue } = useFormikContext<SettingsData>();
  const { isMobile } = useWindowResize();

  return (
    <InputGrid>
      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.subjectTerm}
            id='subject-term'
            inputFor='subjectTerm'
            label={t('curriculum.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='subjectTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.subjectTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizeSubjectTerm}
          className='subject-term-pluralize'
          displaySettingLabel
          handleToggle={() => setFieldValue('pluralizeSubjectTerm', !values.pluralizeSubjectTerm)}
          id='enable-subject-term-pluralize'
          name='pluralizeSubjectTerm'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.companyTerm}
            id='company-term'
            inputFor='companyTerm'
            label={t('company.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='companyTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.companyTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizeCompanyTerm}
          className='company-term-pluralize'
          displaySettingLabel={!isMobile}
          handleToggle={() => setFieldValue('pluralizeCompanyTerm', !values.pluralizeCompanyTerm)}
          id='enable-company-term-pluralize'
          name='pluralizeCompanyTerm'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.topicTerm}
            id='topic-term'
            inputFor='topicTerm'
            label={t('course.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='topicTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.topicTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizeTopicTerm}
          className='topic-term-pluralize'
          handleToggle={() => setFieldValue('pluralizeTopicTerm', !values.pluralizeTopicTerm)}
          id='enable-topic-term-pluralize'
          name='pluralizeTopicTerm'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.policyTerm}
            id='policy-term'
            inputFor='policyTerm'
            label={t('policy.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='policyTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.policyTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizePolicyTerm}
          className='policy-term-pluralize'
          handleToggle={() => setFieldValue('pluralizePolicyTerm', !values.pluralizePolicyTerm)}
          id='enable-policy-term-pluralize'
          name='pluralizePolicyTerm'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.stepTerm}
            id='step-term'
            inputFor='stepTerm'
            label={t('step.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='stepTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.stepTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizeStepTerm}
          className='step-term-pluralize'
          handleToggle={() => setFieldValue('pluralizeStepTerm', !values.pluralizeStepTerm)}
          id='enable-step-term-pluralize'
          name='pluralizeStepTerm'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.processTerm}
            id='process-term'
            inputFor='processTerm'
            label={t('process.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='processTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.processTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizeProcessTerm}
          className='process-term-pluralize'
          handleToggle={() => setFieldValue('pluralizeProcessTerm', !values.pluralizeProcessTerm)}
          id='enable-process-term-pluralize'
          name='pluralizeProcessTerm'
        />
      </InputSection>

      <InputSection>
        <TermWrapper>
          <InputFieldWithCharacterCounter
            errorText={errors.responsibilityTerm}
            id='responsibility-term'
            inputFor='responsibilityTerm'
            label={t('responsibility.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='responsibilityTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.responsibilityTerm}
          />
        </TermWrapper>
        <TerminologyPluralsSettingToggle
          checked={values.pluralizeResponsibilityTerm}
          className='responsibility-term-pluralize'
          handleToggle={() =>
            setFieldValue('pluralizeResponsibilityTerm', !values.pluralizeResponsibilityTerm)
          }
          id='enable-responsibility-term-pluralize'
          name='pluralizeResponsibilityTerm'
        />
      </InputSection>
    </InputGrid>
  );
};

export default CustomTerminologyForm;
