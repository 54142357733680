import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import IconButton from '../../../../design_system/buttons/IconButton';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import { routes } from '../../../publicApplication/applicationRouter';

type Props = {
  id: number;
};

const t = initTranslations('editor.version_history');

const StepVersionIcon = ({ id }: Props) => {
  const { slug } = useCurrentAccount();

  return (
    <>
      <Tooltip id='version-history-icon' text={t('tooltip')} />
      <div data-for='version-history-icon' data-tip='true'>
        <IconButton
          ariaLabel={t('aria_label')}
          name='history'
          onClick={() => routes.stepVersionHistory({ id, slug }).push()}
          weight='regular'
        />
      </div>
    </>
  );
};

export default StepVersionIcon;
