import React from 'react';
import styled, { css } from 'styled-components';

import useSlideout from '../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../lib/initTranslations';
import IconButton from '../../../../../design_system/buttons/IconButton';

const StyledIconButton = styled(IconButton)`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${constants.borderRadiusCircle};
    background-color: ${vars.foundationBase2};
    border: ${constants.borderWidthMd} solid ${vars.borderSurface2};

    &:hover,
    &:focus {
      border: ${constants.borderWidthMd} solid ${vars.accentPrimaryDefault};
    }
  `}
`;

const t = initTranslations('curriculums.edit.settings');

export const SettingsButton = () => {
  const { open, close, isOpen } = useSlideout('subject-edit-settings');

  return (
    <StyledIconButton
      ariaLabel={t('aria_label_settings')}
      id='settings-button'
      name='gear'
      onClick={() => (isOpen ? close() : open())}
    />
  );
};
