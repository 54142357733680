import React from 'react';
import styled, { css } from 'styled-components';

import { useGetUserProgressQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import Avatar from '../../../design_system/display/avatar';
import SearchField from '../../../design_system/Triage/fields/SearchField';

type MockTopNavProps = {
  userId: number;
};

const Container = styled.div(
  ({ theme: { constants } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: ${constants.spacerSm3} ${constants.spacerLg2};
    gap: ${constants.spacerMd2};
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  `
);

const StyledSearchField = styled(SearchField)`
  width: 100%;
`;

const MockCTAsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const MockCTA = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 2rem;
    height: 2rem;
    background-color: ${vars.foundationBase3};
    border-radius: ${constants.borderRadiusCircle};
  `
);

const UserAvatar = ({ userId }: MockTopNavProps) => {
  const { data, isLoading } = useGetUserProgressQuery(userId);

  if (isLoading || !data) return <MockCTA />;

  const { avatar, name, completionPercentage } = data;

  return (
    <Avatar
      className='top-nav-user-avatar-trigger'
      completionPercent={completionPercentage}
      image={avatar || undefined}
      name={name}
      shape='circle'
      size='sm'
    />
  );
};

const MockTopNav = ({ userId }: MockTopNavProps) => {
  return (
    <Container>
      <StyledSearchField
        placeholder=''
        searchValue=''
        setSearchValue={() => {
          /* Do nothing */
        }}
      />
      <MockCTAsWrapper>
        <MockCTA />
        <MockCTA />
        <MockCTA />
        <UserAvatar userId={userId} />
      </MockCTAsWrapper>
    </Container>
  );
};

export default MockTopNav;
