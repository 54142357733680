import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Hoverable from '../../../../../design_system/Hoverable';
import PaywallTooltip from '../../../../shared/tooltips/PaywallTooltip';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledIcon,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.content_page');

const ContentPageToggle = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const [isToggleHovered, setToggleHovered] = useState(false);
  const paywallCtx = useContext(PaywallContext);
  const cardViewLibraryLocked = paywallCtx.includes('curriculum_advanced_settings');
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();

  return (
    <>
      {cardViewLibraryLocked && (
        <PaywallTooltip
          description={t('content_paywall.description')}
          modifiers={[{ name: 'offset', options: { offset: [25, 0] } }]}
          shouldBeVisible={isToggleHovered}
          title={t('content_paywall.title')}
        />
      )}
      <Hoverable setIsHovered={setToggleHovered}>
        <ToggleSettingContainer>
          <StyledToggle
            checked={values.enableSectorLibraries}
            className='sector-libraries'
            disabled={cardViewLibraryLocked}
            handleToggle={() =>
              setFieldValue('enableSectorLibraries', !values.enableSectorLibraries)
            }
            id='enable-sector-libraries'
            name='enableSectorLibraries'
          />
          <ToggleInfoSection>
            <ToggleInfoWrapper>
              <FormSectionTitle>
                {t('toggle_title')}
                {cardViewLibraryLocked && <StyledIcon name='lock' weight='regular' />}
              </FormSectionTitle>
              <FormSectionDescription>
                {t('toggle_description', { subject: curriculumSingular.toLowerCase() })}
              </FormSectionDescription>
            </ToggleInfoWrapper>
          </ToggleInfoSection>
        </ToggleSettingContainer>
      </Hoverable>
    </>
  );
};

export default ContentPageToggle;
