import React from 'react';

import { useCheckCustomV5Plan } from '../../../../../hooks/billing/useCheckCustomV5Plan';
import { getFormattedPrice } from '../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../lib/initTranslations';
import { AddonItem } from '../../../../../redux/services/resourceApis/billing/types';
import { BillingInterval } from '../../../../../types/BillingInterval';
import { StyledFeatureIcon } from '../../Includes/styles';
import CheckoutAddonPrice from '../CheckoutAddonPrice/CheckoutAddonPrice';
import { CheckoutWithAddonHr } from '../styles';
import { CheckoutAddonItem, CheckoutAddonItemName, CheckoutAddonItemNameWrapper } from './styles';

const t = initTranslations('checkout_overlay');

type CheckoutAddonsSectionProps = {
  addons: AddonItem[];
  planName: string;
  billingInterval: BillingInterval;
};

const CheckoutAddonsSection = ({
  addons,
  planName,
  billingInterval,
}: CheckoutAddonsSectionProps) => {
  const isCustomV5Plan = useCheckCustomV5Plan();
  const addonPricePerMonth = (price: number) =>
    getFormattedPrice(price, undefined, billingInterval === 'year');

  return (
    <>
      {addons.map((addon, index) => (
        <CheckoutAddonItem key={index}>
          <CheckoutAddonItemNameWrapper>
            <StyledFeatureIcon name='circle-check' planName={planName} weight='solid' />
            <CheckoutAddonItemName>{t('add_on', { addonName: addon.name })}</CheckoutAddonItemName>
          </CheckoutAddonItemNameWrapper>
          {!isCustomV5Plan && (
            <CheckoutAddonPrice
              billingInterval={billingInterval}
              isAddon
              price={addonPricePerMonth(addon.price)}
            />
          )}
        </CheckoutAddonItem>
      ))}
      <CheckoutWithAddonHr />
    </>
  );
};

export default CheckoutAddonsSection;
