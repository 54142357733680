import React from 'react';

import { usePlanBrandColors } from '../../../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../../../hooks/billing/usePlanTitle';
import { usePricePerInterval } from '../../../../../../../../hooks/billing/usePricePerInterval';
import { getPlanCardStatusText } from '../../../../../../../../lib/billing/getPlanCardStatusText';
import { MoveToNewPlanArrowImage } from '../../../../../../../../lib/gcsImages';
import { getFormattedPricePerUser } from '../../../../../../../../lib/getFormattedPrice';
import initTranslations from '../../../../../../../../lib/initTranslations';
import {
  CurrentPerUserPlan,
  NewPerUserPlan,
} from '../../../../../../../../redux/services/resourceApis/billing/perUserPricingTypes';
import { Discount } from '../../../../../../../../types/Discount';
import EmptyStateCard from '../../../../../shared/BillingFullScreenOverlay/PlanCards/EmptyStateCard/EmptyStateCard';
import {
  MoveToNewPlanImg,
  PlanCardBillingCycleText,
  PlanCardDetailsWrapper,
  PlanCardDivider,
  PlanCardFooterWrapper,
  PlanCardPrice,
  PlanCardSeatsIncludedText,
  PlanCardStatus,
  StyledPlanCardTitle,
  StyledPlanCardWrapper,
} from '../../../../../shared/BillingFullScreenOverlay/PlanCards/styles';

const t = initTranslations('per_user_pricing.manage_plan_overlay_content.plan_cards');

type Props = {
  plan: CurrentPerUserPlan | NewPerUserPlan;
  discount: Discount | undefined;
  isCurrentPlanSameAsNew: boolean;
  isManageSeatsOverlay: boolean;
};

const PlanCard = ({ plan, discount, isCurrentPlanSameAsNew, isManageSeatsOverlay }: Props) => {
  const { name, seatsIncluded, billingInterval, totalPrice } = plan;
  const isNewPlan = 'nextPaymentDate' in plan;
  const isAnnualInterval = billingInterval === 'year';
  const isHoldPlan = name === 'hold';
  const isFree3SeatsPlan = name === 'free';
  const cardId = `${isNewPlan ? 'new' : 'current'}-plan-card`;
  const { color: planBrandColor } = usePlanBrandColors({ name });
  const planTitle = usePlanTitle({ name });
  const pricePerInterval = usePricePerInterval({
    isAnnualInterval,
    isHoldPlan,
    isFree3SeatsPlan,
    totalPrice,
    discount,
  });

  const showEmptyState = (isNewPlan: boolean) => {
    return isNewPlan && isCurrentPlanSameAsNew;
  };

  if (showEmptyState(isNewPlan)) return <EmptyStateCard key='empty-state-card' />;

  return (
    <StyledPlanCardWrapper id={cardId} isNewPlan={isNewPlan} key={cardId}>
      {!isNewPlan && (
        <MoveToNewPlanImg alt={t('alt_move_to_new_plan')} src={MoveToNewPlanArrowImage} />
      )}

      <PlanCardStatus
        text={getPlanCardStatusText(isNewPlan, isManageSeatsOverlay)}
        withMarginBottom={isManageSeatsOverlay}
      />
      {!isManageSeatsOverlay && (
        <StyledPlanCardTitle planBrandColor={planBrandColor} text={planTitle} />
      )}

      <PlanCardDetailsWrapper isNewPlan={isNewPlan}>
        {!isHoldPlan && seatsIncluded && (
          <PlanCardPrice
            className='plan-card-price-per-user-per-month'
            text={t('price_per_user_per_month', {
              price: getFormattedPricePerUser(
                totalPrice,
                seatsIncluded,
                isAnnualInterval,
                discount
              ),
            })}
          />
        )}
        {!!seatsIncluded && !isHoldPlan && (
          <PlanCardSeatsIncludedText
            className='plan-card-seats-included'
            text={t('seats_included', { count: seatsIncluded })}
          />
        )}
        <PlanCardBillingCycleText
          text={t('billing_cycle', {
            interval: isAnnualInterval ? t('yearly') : t('monthly'),
          })}
        />
      </PlanCardDetailsWrapper>
      <PlanCardFooterWrapper>
        <PlanCardDivider planBrandColor={planBrandColor} />
        <PlanCardDetailsWrapper isNewPlan={isNewPlan}>
          <PlanCardPrice text={pricePerInterval} />
        </PlanCardDetailsWrapper>
      </PlanCardFooterWrapper>
    </StyledPlanCardWrapper>
  );
};

export default PlanCard;
