import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { MetaUserAccess } from '../../../../../types/Curriculum';
import { EditableCurriculumElement } from '../../../../../types/CurriculumElement';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import EmojiSelect from '../../../ContentLibrary/components/SubjectEmoji/EmojiSelect';
import { routes } from '../../../publicApplication/applicationRouter';
import EditableCurriculumElementTitle from '../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import { viewableUserAccess } from '../../../shared/helpers';
import { EmojiWrapper } from '../CurriculumRowStyles';
import { LoadingTitle, TitleWrapper } from './styles';

type Props = {
  blockEditPrivileges: boolean;
  curriculumId: number;
  curriculumElement: EditableCurriculumElement;
  isLocked?: boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  userAccess?: MetaUserAccess;
};

const t = initTranslations('curriculums_view');

const ElementEmojiAndTitle = ({
  blockEditPrivileges,
  curriculumId,
  curriculumElement,
  isLocked,
  isEditing,
  setIsEditing,
  userAccess,
}: Props) => {
  const { slug } = useCurrentAccount();
  const { flash } = useFlashNotification();

  const { canBeModified, elementId, elementKind, element } = curriculumElement;
  const { showRoute, editRoute, iconName, useUpdateElement } = useCurriculumElement()({
    elementKind,
    elementId,
  });
  const [updateElement, { isSuccess, error }] = useUpdateElement();
  const { emoji, title } = element;

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  const url = useMemo(() => {
    if (!userAccess || userAccess === 'pending') {
      return routes.curriculumRequestAccess({ id: curriculumId, slug }).href;
    } else if (viewableUserAccess(userAccess)) {
      return showRoute.href;
    } else {
      return editRoute.href;
    }
  }, [curriculumId, editRoute.href, showRoute.href, slug, userAccess]);

  return (
    <>
      <EmojiWrapper id={`${elementKind}-${elementId}-row-emoji-picker`}>
        <EmojiSelect
          clearEmoji={() => {
            updateElement({ id: elementId, emoji: null });
          }}
          emoji={emoji}
          noEmojiIconName={iconName}
          onEmojiSelect={(emoji: BaseEmoji) => {
            updateElement({ emoji: emoji.native, id: elementId });
          }}
          usePortal
        />
      </EmojiWrapper>
      {elementKind !== 'survey' || !element.inProcessingQueue ? (
        <EditableCurriculumElementTitle
          editable={canBeModified && !blockEditPrivileges}
          id={elementId}
          isEditing={isEditing}
          isLocked={isLocked}
          isSuccess={isSuccess}
          route={url}
          setIsEditing={setIsEditing}
          title={title}
          updateElement={({ id, title }) => updateElement({ id, title })}
        />
      ) : (
        <TitleWrapper>
          <LoadingTitle>{title}</LoadingTitle>
        </TitleWrapper>
      )}
    </>
  );
};

export default ElementEmojiAndTitle;
