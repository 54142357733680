import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import {
  GeneratedTestNotification,
  TrainualLogoAvatar,
  UploadNotification,
} from '../../../../../lib/gcsImages';
import { Notification } from '../../../../../redux/services/resourceApis/notifications/types';
import MessageCard from '../../../../design_system/MessageCard/MessageCard';
import { routes } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import NotificationDetails from '../../../shared/notifications/NotificationDetails';

type NotificationCardProps = {
  notification: Notification;
};

const NotificationCard = ({ notification }: NotificationCardProps) => {
  const { slug } = useCurrentAccount();
  const {
    activity: {
      owner: { is_active: isActive, avatar, id, name },
      key,
    },
    created_at,
  } = notification;

  const getAvatarImage = () => {
    if (key === 'curriculum.uploaded_with_ai') {
      return UploadNotification;
    } else if (key === 'survey_survey.generated_with_ai') {
      return GeneratedTestNotification;
    } else if (isActive) {
      return avatar;
    } else {
      return TrainualLogoAvatar;
    }
  };
  const isClickableAvatar =
    isActive && !['curriculum.uploaded_with_ai', 'survey_survey.generated_with_ai'].includes(key);
  const userAvatar = getAvatarImage();
  const onAvatarClickHandler = () =>
    routeTo(
      routes.userProfile({
        slug,
        id,
        breadcrumb: routes.notifications({ slug }),
      })
    );

  return (
    <MessageCard
      createdAt={created_at}
      isClickableAvatar={isClickableAvatar}
      message={<NotificationDetails activity={notification.activity} />}
      onAvatarClick={onAvatarClickHandler}
      userAvatar={userAvatar}
      userName={name}
    />
  );
};

export default NotificationCard;
