import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import { FA_BRAND_ICONS, FA_SOLID_ICONS } from '../../../../../../../lib/font_libraries';
import { LibraryEmptyState as NoResultsGraphic } from '../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../lib/initTranslations';
import Icon from '../../../../../../design_system/display/icons/Icon';
import Flyout from '../../../../../../design_system/overlays/flyout';
import SearchField from '../../../../../../design_system/Triage/fields/SearchField';
import NoResults from '../../../../../../design_system/Triage/NoResults';
import Pagination from '../../../../../../design_system/Triage/Paginate';
import useActiveMenuHandler from '../../../../../../design_system/useActiveMenuHandler';
import Scrollbar from '../../../../../../styled/Scrollbar';
import { insertEditorContent } from '../../../../lib/insertEditorContent';
import { SharedFlyoutStyles } from '../../../../shared/styles';
import LabelToolbarButton from '../LabelToolbarButton';

const IconButton = styled.button`
  width: 100%;
  height: ${({ theme: { constants } }) => constants.heightSm};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  cursor: pointer;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease-in-out;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid transparent; /* This holds the space for the border so the icons don't jump around */
  background-color: transparent;

  svg {
    height: ${({ theme: { constants } }) => constants.heightXs};
    margin-top: ${({ theme: { constants } }) => constants.spacerSm1};
    color: ${({ theme: { vars } }) => vars.textDefault};
  }

  &:hover {
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    transform: scale(1.05);
  }
`;

const IconWrapper = styled.div<{ hasResults: boolean; shouldScroll: boolean }>`
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 ${constants.spacerSm3} 0`};
  /* Conditionally switch between grid and flex to account for no results state */
  display: ${({ hasResults }) => (hasResults ? 'grid' : 'flex')};
  grid-template-columns: ${({ hasResults, theme: { constants } }) =>
    hasResults ? `repeat(auto-fill, minmax(${constants.heightSm}, 1fr))` : 'none'};
  grid-gap: ${({ hasResults, theme: { constants } }) => (hasResults ? constants.spacerSm2 : '0')};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  width: 100%;
  max-width: 23.5rem;
  height: inherit;
  max-height: 15rem;
  overflow: hidden ${({ shouldScroll }) => (shouldScroll ? 'auto' : 'hidden')};
  ${Scrollbar};
`;

const ResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

const StyledFlyout = styled(Flyout)`
  ${SharedFlyoutStyles};

  width: 25rem;

  .flyout-footer {
    padding: 0;
  }
`;

const ITEMS_PER_PAGE = 99;
const SHOULD_SCROLL_THRESHOLD = 50;
const MENU_ID = 'font-awesome-icon-flyout';

const t = initTranslations('editor_toolbar.insert_options');

const IconToolbarButton = () => {
  const { editor } = useEditorContext();
  const { isMenuOpen, closeMenu } = useActiveMenuHandler({ menuId: MENU_ID });

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);

  const insertIconIntoContent = useCallback(
    (iconName: IconName, type: 'fas' | 'fab') => {
      const iconHtml = `<i class="fa fa-${iconName} ${type}"></i>`;
      insertEditorContent({
        editor,
        content: iconHtml,
      });
      closeMenu();
    },
    [closeMenu, editor]
  );

  const filteredIcons = useMemo(() => {
    const uniqueIcons = {
      solid: FA_SOLID_ICONS,
      brand: FA_BRAND_ICONS,
    };

    if (!searchTerm) return [...uniqueIcons.solid, ...uniqueIcons.brand];

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return [
      ...uniqueIcons.solid.filter((icon) => icon.iconName.includes(lowerCaseSearchTerm)),
      ...uniqueIcons.brand.filter((icon) => icon.iconName.includes(lowerCaseSearchTerm)),
    ];
  }, [searchTerm]);

  const { hasIcons, totalPages, pageIcons, shouldScroll } = useMemo(() => {
    const startIdx = (page - 1) * ITEMS_PER_PAGE;
    const endIdx = startIdx + ITEMS_PER_PAGE;
    const pageIcons = filteredIcons.slice(startIdx, endIdx);

    const hasIcons = filteredIcons.length > 0;
    const totalPages = Math.ceil(filteredIcons.length / ITEMS_PER_PAGE);
    const shouldScroll = filteredIcons.length > SHOULD_SCROLL_THRESHOLD;

    return {
      hasIcons,
      totalPages,
      pageIcons,
      shouldScroll,
    };
  }, [filteredIcons, page]);

  useEffect(() => {
    if (!isMenuOpen) {
      setSearchTerm('');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  return (
    <StyledFlyout
      className='icon-flyout'
      id={MENU_ID}
      placement='bottom-start'
      title={t('add_icon')}
      triggerButton={
        <LabelToolbarButton
          active={isMenuOpen}
          ariaLabel={t('aria_for_icon')}
          className='flyout-trigger'
          dataTestId='icon-button'
          iconName='icons'
          iconType='name'
          id='icon-button'
          label='Icon'
        />
      }
    >
      <SearchField autoFocus searchValue={searchTerm} setSearchValue={setSearchTerm} />
      <IconWrapper
        className='icon-button-wrapper'
        hasResults={hasIcons}
        shouldScroll={shouldScroll}
      >
        {hasIcons && (
          <>
            {pageIcons.map((icon) => {
              const iconWeight = icon.prefix === 'fas' ? 'solid' : 'brands';
              return (
                <IconButton
                  className={`fa fa-${iconWeight} fa-${icon.iconName}`}
                  key={`icon-${iconWeight}-${icon.iconName}`}
                  onClick={() =>
                    insertIconIntoContent(
                      icon.iconName,
                      icon.prefix === 'fas' ? icon.prefix : 'fab'
                    )
                  }
                >
                  <Icon name={icon.iconName} weight={iconWeight} />
                </IconButton>
              );
            })}
          </>
        )}
        {!hasIcons && (
          <ResultsWrapper>
            <NoResults
              darkImage={NoResultsGraphic}
              heading={t('no_results')}
              iconWidth='100'
              lightImage={NoResultsGraphic}
              minHeight='10rem'
              showBorder={false}
              subHeaderCta={{
                action: () => setSearchTerm(''),
                text: t('clear_search'),
              }}
            />
          </ResultsWrapper>
        )}
      </IconWrapper>
      {hasIcons && (
        <PaginationWrapper>
          <Pagination
            activePage={page}
            hideFirstLastPages
            itemsCountPerPage={ITEMS_PER_PAGE}
            onChange={(page) => setPage(page)}
            pageRangeDisplayed={5}
            totalItemsCount={filteredIcons.length}
            totalPages={totalPages}
          />
        </PaginationWrapper>
      )}
    </StyledFlyout>
  );
};

export default IconToolbarButton;
