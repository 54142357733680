import styled, { css } from 'styled-components';

import P from '../../../../design_system/text/P';
import { fontMd1 } from '../../../../styled/TypeSystem';

export const CheckoutWithAddonsTotalSectionWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    display: flex;
    gap: ${constants.spacerMd2};
    padding: ${constants.spacerSm3} 0;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const CheckoutWithAddonsTotalSectionTitle = styled(P)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontMd1};
`;
