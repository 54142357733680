import React, { ReactNode, createContext, useContext } from 'react';

import { useAccountTerminology } from '../components/AccountTerminologyProvider';
import usePageTitle from '../hooks/usePageTitle';
import { EditorFlowchartResponse } from '../redux/services/resourceApis/flowcharts/types';

type BaseFlowchartEditorContext = {
  data: EditorFlowchartResponse | undefined;
  isLoading: boolean;
  isError: boolean;
};

type FlowchartEditorContext = BaseFlowchartEditorContext;

type Props = {
  children: ReactNode;
} & BaseFlowchartEditorContext;

const FlowchartEditorDataContext = createContext({} as FlowchartEditorContext);
export const useFlowchartEditorData = () => useContext(FlowchartEditorDataContext);

const FlowchartEditorDataProvider = ({ children, data, isError, isLoading }: Props) => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceName: data?.title, resourceType: 'flowchart', productTerm });
  return (
    <FlowchartEditorDataContext.Provider
      value={{
        data,
        isError,
        isLoading,
      }}
    >
      {children}
    </FlowchartEditorDataContext.Provider>
  );
};

export { FlowchartEditorDataProvider };
