import styled, { css } from 'styled-components';

import { fontLg2, fontMd5 } from '../../../../styled/TypeSystem';

export const StyledCheckoutPriceDetails = styled.div<{
  strike: boolean;
  isAddon: boolean;
  isTotal: boolean;
}>`
  ${({ theme: { vars, constants }, strike, isAddon, isTotal }) => css`
    color: ${strike ? vars.trainualBrandPurpleSurface : vars.trainualBrandPurpleMedium};
    font-weight: ${constants.fontBold};
    text-align: right;

    ${strike &&
    css`
      text-decoration-line: line-through;
      margin-right: ${constants.spacerLg3};
    `}

    ${isAddon &&
    css`
      &::before {
        content: '+ ';
        color: ${vars.trainualBrandPurpleSurface};
      }
    `}

    ${isTotal ? fontLg2 : fontMd5};
  `}
`;

export const CheckoutPriceBillingInterval = styled.span<{ isTotal: boolean }>`
  ${({ theme: { vars }, isTotal }) => css`
    color: ${vars.trainualBrandPurpleSurface};
    ${isTotal ? fontLg2 : fontMd5};
  `}
`;
