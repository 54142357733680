import React from 'react';

import { EmptyStateData } from '../../../../../hooks/useEmptyStateData';
import { LibraryEmptyStateSvg } from '../../../../../lib/gcsImages';
import NoResults from '../../../../design_system/Triage/NoResults';

const LibraryTableEmptyState = ({
  emptyStateData,
  hideEmptyState,
}: {
  emptyStateData: EmptyStateData;
  hideEmptyState: boolean;
}) => {
  if (emptyStateData && hideEmptyState) return null;
  if (emptyStateData) {
    const { action, actionMessage, message } = emptyStateData;

    return (
      <div id='library-empty-state-wrapper'>
        <NoResults
          className='library-table-no-results'
          darkImage={LibraryEmptyStateSvg}
          heading={message}
          iconWidth='20%'
          lightImage={LibraryEmptyStateSvg}
          minHeight='unset'
          showBackground={false}
          showBorder={false}
          subHeaderCta={
            action && actionMessage
              ? {
                  action,
                  text: actionMessage,
                }
              : undefined
          }
        />
      </div>
    );
  }
  return null;
};

export default LibraryTableEmptyState;
