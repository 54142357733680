import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  SectionDivider,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.general_settings');

const TraditionalMfa = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <>
      <SectionDivider />
      <ToggleSettingContainer>
        <StyledToggle
          checked={values.mfaOn}
          className='mfa-on-setting'
          handleToggle={() => setFieldValue('mfaOn', !values.mfaOn)}
          id='mfa-on'
          name='mfaOn'
        />
        <ToggleInfoSection>
          <ToggleInfoWrapper>
            <FormSectionTitle>{t('mfa_on_title')}</FormSectionTitle>
            <FormSectionDescription>{t('mfa_on_description')}</FormSectionDescription>
          </ToggleInfoWrapper>
        </ToggleInfoSection>
      </ToggleSettingContainer>
    </>
  );
};

export default TraditionalMfa;
