import React from 'react';
import styled from 'styled-components';

import { useVideoData } from '../../../contexts/VideoDataContext';
import { ContentType } from '../../../types/ContentType';
import { ThreeDotMenuOptionProps } from '../../design_system/Triage/menus/ThreeDotMenu/types';
import ElementTopNav from '../curriculums/shared/ElementTopNav/ElementTopNav';

type Props = {
  menuOptions: ThreeDotMenuOptionProps[];
  editing?: boolean;
};

const TopNavWrapper = styled.div``;

const VideoTopNav = ({ menuOptions, editing = false }: Props) => {
  const { data, isLoading } = useVideoData();

  return (
    <TopNavWrapper>
      <ElementTopNav
        contentType={ContentType.Video}
        data={data}
        editing={editing}
        isLoading={isLoading}
        threeDotMenuOptions={menuOptions}
      />
    </TopNavWrapper>
  );
};

export default VideoTopNav;
