import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useEffect, useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { ThreeDotLoaderGif } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import scrollToElement from '../../../../../../lib/scrollToElement';
import { useUpdateSurveyMutation } from '../../../../../../redux/services/resourceApis/surveys/surveysApi';
import { MetaUserAccess } from '../../../../../../types/Curriculum';
import { SurveyCurriculumElement } from '../../../../../../types/CurriculumElement';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import {
  EmojiWrapper,
  RowActionsContainer,
  RowCardBody,
  RowContainer,
} from '../../../../curriculums/shared/CurriculumRowStyles';
import { routes, useRoute } from '../../../../publicApplication/applicationRouter';
import StatusBadgeActions from '../../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import EditableCurriculumElementTitle from '../../../../shared/CurriculumElements/EditableCurriculumElementTitle';
import CurriculumElementThreeDot from '../../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { getSurveyUrlFromAccess } from '../../../../shared/helpers';
import EmojiSelect from '../../../components/SubjectEmoji/EmojiSelect';
import {
  LoadingImage,
  LoadingText,
  LoadingTitle,
  TestGeneratingLoadingContainer,
  TitleWrapper,
} from './styles';

export type Props = {
  curriculumElement: SurveyCurriculumElement;
  isCurriculumLocked?: boolean;
  curriculumId: number;
  provided: DraggableProvided;
  blockEditPrivileges: boolean;
  userAccess?: MetaUserAccess;
};

const t = initTranslations('curriculums_view');

const SurveyTableRow = ({
  curriculumElement,
  isCurriculumLocked,
  curriculumId,
  provided,
  blockEditPrivileges,
  userAccess,
}: Props) => {
  const route = useRoute();
  const {
    params: { scrollToElementId },
  } = route as Route<typeof routes.curriculumEdit>;
  const { slug } = useCurrentAccount();
  const [updateSurvey, updateSurveyResult] = useUpdateSurveyMutation();
  const { isSuccess, error } = updateSurveyResult;
  const { elementId, element, disableTranslation } = curriculumElement;
  const { title, status, inProcessingQueue, emoji, questionCount } = element;

  const [isEditing, setIsEditing] = useState(false);
  const { flash } = useFlashNotification();
  const surveyUrl = useMemo(
    () => getSurveyUrlFromAccess(userAccess, curriculumId, slug, elementId),
    [curriculumId, elementId, slug, userAccess]
  );

  useEffect(() => {
    if (error) {
      flash('error', t('update_title_error'));
    }
  }, [error, flash]);

  useEffect(() => {
    if (inProcessingQueue && elementId === scrollToElementId) {
      scrollToElement(`curriculum-element-survey-${elementId}`);
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [elementId, inProcessingQueue, scrollToElementId]);

  return (
    <RowContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='curriculum-element-item'
      id={`curriculum-element-survey-${elementId}`}
      processing={inProcessingQueue}
    >
      <RowCardBody isLocked={isCurriculumLocked || blockEditPrivileges}>
        <EmojiWrapper id={`survey-${elementId}-row-emoji-picker`}>
          <EmojiSelect
            clearEmoji={() => {
              updateSurvey({ id: elementId, emoji: null });
            }}
            emoji={emoji}
            noEmojiIconName='clipboard-check'
            onEmojiSelect={(emoji: BaseEmoji) => {
              updateSurvey({ emoji: emoji.native, id: elementId });
            }}
            usePortal
          />
        </EmojiWrapper>
        {inProcessingQueue ? (
          <TitleWrapper>
            <LoadingTitle>{title}</LoadingTitle>
          </TitleWrapper>
        ) : (
          <EditableCurriculumElementTitle
            disableTranslation={disableTranslation}
            editable={!blockEditPrivileges}
            id={elementId}
            isEditing={isEditing}
            isSuccess={isSuccess}
            route={surveyUrl}
            setIsEditing={setIsEditing}
            title={title}
            updateElement={({ id, title }) => updateSurvey({ id, name: title })}
          />
        )}
        <RowActionsContainer>
          {inProcessingQueue ? (
            <TestGeneratingLoadingContainer>
              <LoadingText>{t('generating_test')}</LoadingText>
              <LoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
            </TestGeneratingLoadingContainer>
          ) : (
            <>
              <StatusBadgeActions
                curriculumElement={{
                  id: curriculumElement.id,
                  status,
                }}
                curriculumId={curriculumId}
                infoText={t('questions', {
                  count: questionCount,
                })}
              />
              {!blockEditPrivileges && (
                <CurriculumElementThreeDot
                  canBeModified
                  curriculumElement={curriculumElement}
                  curriculumId={curriculumId}
                  eSignatureDisplayedAndRequired={false}
                  isCurriculumLocked={isCurriculumLocked}
                  renameClickHandler={() => setIsEditing(true)}
                />
              )}
            </>
          )}
        </RowActionsContainer>
      </RowCardBody>
    </RowContainer>
  );
};

export default SurveyTableRow;
