import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import MultiSelectField from '../../../../../design_system/Triage/fields/MultiSelectField';
import { Title } from '../../../../shared/DetailedTitle/Title';
import {
  FormSectionDescription,
  FormSectionTitle,
  MultiSelectInputRow,
  MutiSelectLabel,
  OptionWrapper,
  StyledToggle,
  SubduedDescription,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.people_settings');

const ShareRoleChart = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const { data } = useGetAccountSettingsQuery();

  const groupOptions = useMemo(
    () =>
      data?.accountGroups
        ? formatOptions(data?.accountGroups).map((option, index) => {
            if (!option.label) {
              return option;
            }

            const kind = data.accountGroups[index]?.kind;
            const label = (
              <OptionWrapper>
                <Title fontWeight='regular' title={option.label.toString()} />
                <Title
                  fontColor='placeholder'
                  fontWeight='regular'
                  title={`(${t(`group_kinds.${kind}`)})`}
                />
              </OptionWrapper>
            );

            return { ...option, label };
          })
        : [],
    [data?.accountGroups]
  );

  if (!data) return null;

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.enableRoleChart}
        className='group-chart-setting'
        handleToggle={(e) => setFieldValue('enableRoleChart', e.target.checked)}
        id='enable-group-chart'
        name='enableRoleChart'
      />
      <ToggleInfoSection>
        <ToggleInfoWrapper>
          <FormSectionTitle>{t('role_chart_title')}</FormSectionTitle>
          <FormSectionDescription>{t('role_chart_description')}</FormSectionDescription>
        </ToggleInfoWrapper>
        {values.enableRoleChart && (
          <div id='group-chart-groups-form'>
            <MutiSelectLabel>{t('groups_restricted_from_role_chart.title')}</MutiSelectLabel>
            <MultiSelectInputRow>
              <MultiSelectField
                className='group-chart-groups-select'
                defaultValue={[]}
                name='groupsRestrictedFromRoleChart'
                onNonDefaultSelected={(value: string[]) =>
                  setFieldValue('groupsRestrictedFromRoleChart', value)
                }
                options={groupOptions}
                placeholder={t('groups_restricted_from_role_chart.restrict_groups_placeholder')}
                value={values.groupsRestrictedFromRoleChart}
              />
            </MultiSelectInputRow>
            <SubduedDescription>
              {t('groups_restricted_from_role_chart.description')}
            </SubduedDescription>
          </div>
        )}
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default ShareRoleChart;
