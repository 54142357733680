import React, { useCallback, useEffect, useReducer } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';

import { getCroppedImg } from '../../../../../lib/cropImage';
import { DropZoneImage } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { readFile } from '../../../../../lib/readFile';
import { userInitials } from '../../../../../lib/userNaming';
import { PixelCrop } from '../../../../../types/Cropper';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import ImageUploadControls from '../../../image_upload/ImageUploadControls';
import { imageUploadReducer } from './reducer';
import {
  AvatarPlaceholder,
  DescriptionSection,
  DescriptionText,
  Dropzone,
  ImageUploadButtonsContainer,
  ImageUploadContainer,
  ImageUploadTopContainer,
  ImageUploadView,
  PlaceholdersContainer,
  StyledUploadButton,
  SvgImage,
} from './styles';
import { ImageImportOption, ImageUploadProps } from './types';

const t = initTranslations('home.onboarding.image_upload');

const ImageUpload = ({
  imageSource,
  setImage,
  uploadButtonText,
  resetButtonText,
  imageForm = 'circle',
  descriptionText,
  name = '',
  uploadButtonId,
  resetButtonId,
}: ImageUploadProps) => {
  const initialImageUploadState: ImageImportOption = {
    zoom: 1,
    rotation: 0,
    imageSrc: imageSource,
    crop: { x: 0, y: 0 },
    uploadedImageSize: { width: 1, height: 1 },
    croppedAreaPixels: { x: 0, y: 0, width: 1, height: 1 },
  };
  const [imageUploadState, dispatch] = useReducer(imageUploadReducer, initialImageUploadState);
  const { rotation, imageSrc, uploadedImageSize, crop, zoom, croppedAreaPixels } = imageUploadState;
  const aspect = imageForm === 'circle' ? 1 : uploadedImageSize.width / uploadedImageSize.height;
  const nameExists = !!name;

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileChange(acceptedFiles[0]);
    },
    // We don't want to change this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    onDrop,
  });

  const onFileChange = async (file: File | null) => {
    if (file) {
      const imageSrc = (await readFile(file)) as string;
      dispatch({ type: 'setImageSrc', imageSrc });
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels: PixelCrop) => {
    dispatch({ type: 'setCroppedAreaPixels', croppedAreaPixels });
  }, []);

  const resetImage = () => {
    setImage(undefined);
    dispatch({ type: 'resetImage' });
  };

  const imageAspect = (url: string) => {
    const img = new Image();

    img.addEventListener('load', function () {
      dispatch({
        type: 'setUploadedImageSize',
        uploadedImageSize: {
          width: this.naturalWidth,
          height: this.naturalHeight,
        },
      });
    });
    img.src = url;
  };

  const cropImage = useCallback(
    (imageSrc) => {
      (async () => {
        try {
          const croppedImage = await getCroppedImg({
            imageSrc,
            pixelCrop: croppedAreaPixels,
            rotation,
          });
          setImage(croppedImage ? (croppedImage as string) : undefined);
        } catch (e) {
          console.error(e);
        }
      })();
    },
    // We don't want to include setImage in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [croppedAreaPixels, rotation]
  );

  useEffect(() => {
    if (imageSrc) {
      cropImage(imageSrc);
      imageAspect(imageSrc);
    }
  }, [cropImage, imageSrc]);

  return (
    <ImageUploadContainer isImageAttached={!!imageSrc}>
      <Dropzone {...getRootProps({ className: 'dropzone' })}>
        <input className='dz-hidden-input' {...getInputProps()} />
        <ImageUploadTopContainer imageForm={imageForm}>
          {!!imageSrc ? (
            <>
              <ImageUploadView imageForm={imageForm}>
                <Cropper
                  aspect={aspect}
                  classes={{ mediaClassName: 'onboard-cropped-image' }}
                  crop={crop}
                  image={imageSrc}
                  onCropChange={(crop) => dispatch({ type: 'setCrop', crop })}
                  onCropComplete={onCropComplete}
                  onRotationChange={(rotation) => dispatch({ type: 'setRotation', rotation })}
                  onZoomChange={(zoom) => dispatch({ type: 'setZoom', zoom })}
                  rotation={rotation}
                  style={{ cropAreaStyle: { boxShadow: 'none' } }}
                  zoom={zoom}
                />
              </ImageUploadView>
              <ImageUploadControls
                rotation={rotation}
                setRotation={(rotation) => dispatch({ type: 'setRotation', rotation })}
                setZoom={(zoom) => dispatch({ type: 'setZoom', zoom })}
                zoom={zoom}
              />
            </>
          ) : (
            <PlaceholdersContainer>
              {imageForm === 'circle' ? (
                <AvatarPlaceholder showInitials={nameExists}>
                  {nameExists && userInitials(name)}
                </AvatarPlaceholder>
              ) : (
                <SvgImage src={DropZoneImage} />
              )}
            </PlaceholdersContainer>
          )}
        </ImageUploadTopContainer>
        <ImageUploadButtonsContainer>
          {!!imageSrc && (
            <DefaultButton
              buttonType='tertiary'
              id={resetButtonId}
              onClick={resetImage}
              size='md'
              text={resetButtonText}
            />
          )}
          <StyledUploadButton
            buttonType='secondary'
            iconName='arrow-down-to-line'
            id={uploadButtonId}
            isCentered={!imageSrc}
            onClick={(event) => {
              event.preventDefault(); // Prevent default form submission behavior
              open();
            }}
            size='md'
            text={uploadButtonText}
          />
        </ImageUploadButtonsContainer>
        <DescriptionSection>
          <DescriptionText>{t('file_size_limit')}</DescriptionText>
          <DescriptionText>{descriptionText}</DescriptionText>
        </DescriptionSection>
      </Dropzone>
    </ImageUploadContainer>
  );
};

export default ImageUpload;
