import { Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import InputFieldWithCharacterCounter from '../../../design_system/Triage/InputFieldWithCharacterCounter';
import { getValidationSchema } from '../../editor/shared/validators';
import ElementTitle from './ElementTitle';

const Wrapper = styled.div`
  flex: 1;
`;

interface FormValues {
  id: number;
  title: string;
}

type Props = {
  id: number;
  title: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  maxCharacters?: number;
  isActive?: boolean;
  route: string;
  isLocked?: boolean;
  editable?: boolean;
  isSuccess: boolean;
  disableTranslation?: boolean;
  updateElement: ({ title, id }: FormValues) => void;
};

const EditableCurriculumElementTitle = ({
  id,
  editable,
  isEditing,
  title,
  maxCharacters,
  setIsEditing,
  route,
  isLocked,
  isSuccess,
  updateElement,
  disableTranslation,
}: Props) => {
  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess, setIsEditing]);

  return (
    <>
      {isEditing ? (
        <Wrapper className='editable-title'>
          <Formik
            enableReinitialize
            initialValues={{ title, id }}
            onSubmit={(values) => updateElement(values)}
            validationSchema={getValidationSchema()}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
            }: FormikProps<FormValues>) => {
              const handleTitleUpdate = () => {
                if (values.title.trim() === '') {
                  setFieldValue('title', title);
                }

                if (values.title !== title) {
                  handleSubmit();
                }
                setIsEditing(false);
              };

              return (
                <InputFieldWithCharacterCounter
                  autoFocus
                  errorText={errors.title}
                  maxCharacters={maxCharacters}
                  name='title'
                  onBlur={handleTitleUpdate}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleTitleUpdate();
                    }
                  }}
                  value={values.title}
                />
              );
            }}
          </Formik>
        </Wrapper>
      ) : (
        <ElementTitle
          disableTranslation={disableTranslation}
          editable={editable}
          isLocked={isLocked}
          route={route}
          title={title}
        />
      )}
    </>
  );
};

export default EditableCurriculumElementTitle;
