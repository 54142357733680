import * as yup from 'yup';

import initTranslations from '../../initTranslations';

const t = initTranslations('home.onboarding.customize_your_experience_step');

export const getCustomizeYourExperienceStepSchema = () =>
  yup.object().shape({
    name: yup.string().trim().required(t('errors.company_name_required')),
    user_title: yup.string().trim().required(t('errors.job_title_required')),
    industry: yup.string().required(t('errors.industry_required')),
  });
