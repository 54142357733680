import React from 'react';
import styled, { css } from 'styled-components';

import { VideoModalUploadDragAndDrop, VideoModalUploadGraphic } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { fontSm5 } from '../../../styled/TypeSystem';
import { CheckBannerProps } from '../MarketingSidebar/MarketingSidebar';
import { VideoModalCheckBannerSection } from './VideoModal';

const VideoModalGraphic = styled.img(
  ({ theme: { constants } }) => css`
    width: 100%;
    padding-top: ${constants.spacerMd3};
    padding-bottom: ${constants.spacerLg1};
  `
);

const DragAndDropWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${constants.borderWidthSm} dashed ${vars.accentSubdued4};
    border-radius: ${constants.borderRadiusLg};
    padding: ${constants.spacerMd2};
    margin-top: ${constants.spacerMd3};
    margin-bottom: ${constants.spacerLg1};
    background-color: ${vars.foundationBase1};
  `
);

const DragAndDropTextArea = styled.p(
  ({ theme: { vars } }) => css`
    width: 11rem;
    text-align: center;
    color: ${vars.textSubdued};
    ${fontSm5}
  `
);

const t = initTranslations('video.video_modal');

const CheckBannerOptions: Array<CheckBannerProps> = [
  {
    subtitle: t('check_banner_text.upload_and_host.subtitle'),
    title: t('check_banner_text.upload_and_host.title'),
  },
  {
    subtitle: t('check_banner_text.watch_accountability.subtitle'),
    title: t('check_banner_text.watch_accountability.title'),
  },
  {
    subtitle: t('check_banner_text.search_within_video.subtitle'),
    title: t('check_banner_text.search_within_video.title'),
  },
  {
    subtitle: t('check_banner_text.time_stamped_transcripts.subtitle'),
    title: t('check_banner_text.time_stamped_transcripts.title'),
  },
];

const UploadModalBody = ({ hostedVideoEnabled }: { hostedVideoEnabled: boolean }) => {
  return (
    <>
      {hostedVideoEnabled ? (
        <DragAndDropWrapper>
          <img src={VideoModalUploadDragAndDrop} />
          <DragAndDropTextArea>{t('drag_and_drop')}</DragAndDropTextArea>
        </DragAndDropWrapper>
      ) : (
        <VideoModalGraphic src={VideoModalUploadGraphic} />
      )}
      <VideoModalCheckBannerSection checkBannerOptions={CheckBannerOptions} />
    </>
  );
};

export default UploadModalBody;
