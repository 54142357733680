import React, { useState } from 'react';

import { TrainingPathIllustration as Image } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import NoResults from '../../../../design_system/Triage/NoResults';
import AssignSubjectsModal from '../../../people/AssignSubjectsModal/AssignSubjectsModal';

const t = initTranslations('training_path.path_config.empty_state');

export interface EmptyStateProps {
  name: string;
  userId: number;
}

const EmptyState = ({ name, userId }: EmptyStateProps) => {
  const [showAssignSubjectsModal, setShowAssignSubjectsModal] = useState(false);

  return (
    <>
      {showAssignSubjectsModal && (
        <AssignSubjectsModal closeModal={() => setShowAssignSubjectsModal(false)} userId={userId} />
      )}
      <NoResults
        className='training-path-config-no-results'
        darkImage={Image}
        heading={t('header')}
        iconWidth='57%'
        lightImage={Image}
        minHeight='unset'
        showBackground={false}
        showBorder={false}
        subHeaderCta={{
          action: () => setShowAssignSubjectsModal(true),
          text: t('link_text'),
          location: 'below',
        }}
        subHeaderText={t('subtext', { name })}
      />
    </>
  );
};

export default EmptyState;
