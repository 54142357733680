import { Form, Formik, FormikErrors, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { Route } from 'type-route';

import TrainualLogo from '../../../../../../../assets/images/trainual-purple.png';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import initTranslations from '../../../../../lib/initTranslations';
import isEmail from '../../../../../lib/isEmail';
import { loginApi } from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Link from '../../../../design_system/Link';
import InputField from '../../../../design_system/Triage/InputField';
import { useFlashNotification } from '../../../../FlashNotificationContext';
import { routes } from '../../applicationRouter';
import PublicPage from '../../PublicPage';
import {
  ActionWrapper,
  FormWrapper,
  H1,
  Logo,
  LogoWrapper,
  StyledSubtitle,
  StyledTitle,
} from '../../styles/shared';
import CheckEmail from '../CheckEmail/CheckEmail';

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: '',
};

const t = initTranslations('public_application.universal_login');

const validateForm = ({ email }: FormValues) => {
  const errors: FormikErrors<FormValues> = {};
  if (!email || email.trim() === '') {
    errors.email = t('form.errors.blank');
  } else if (!isEmail(email)) {
    errors.email = t('form.errors.invalid');
  }
  return errors;
};

type UniversalLoginProps = {
  route: Route<typeof routes.universalLogin>;
};

export type LoginFormProps = UniversalLoginProps & {
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  setVisibleCheckEmail: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoginForm = ({ route, setUserEmail, setVisibleCheckEmail }: LoginFormProps) => {
  const { configs } = usePublicConfigs();
  const { flash } = useFlashNotification();
  const { isValid, isValidating, values, touched, errors, handleChange } =
    useFormikContext<FormValues>();
  const [universalLoginTrigger, result] = loginApi.useLazyUniversalLoginQuery();
  const { isLoading, isFetching, data } = result;
  const isActionInProgress = isLoading || isFetching;
  const universalLoginWithOtpEnabled = !!configs['UNIVERSAL_LOGIN_WITH_OTP'];

  const successFunction = useCallback(() => {
    if (!data) return;
    const { users } = data;
    const email = values.email;

    if (universalLoginWithOtpEnabled) {
      setUserEmail(email);
      setVisibleCheckEmail(true);
    } else {
      if (users.length === 0) {
        flash('info', t('form.selection_request'));
      } else if (users.length === 1) {
        const slug = users[0].account.slug;
        routes.login({ email, slug, redirect_path: route.params.redirect_path }).push();
      } else {
        routes.accountSelection({ email, redirect_path: route.params.redirect_path }).push();
      }
    }
  }, [
    flash,
    route.params.redirect_path,
    data,
    values.email,
    universalLoginWithOtpEnabled,
    setUserEmail,
    setVisibleCheckEmail,
  ]);

  useDisplayFlashOnResponse({
    result,
    successFunction,
  });

  const submit = useCallback(() => {
    if (isValid && !isValidating) {
      universalLoginTrigger({ email: values.email });
    }
  }, [isValid, isValidating, universalLoginTrigger, values.email]);

  return universalLoginWithOtpEnabled ? (
    <Form autoComplete='off'>
      <FormWrapper>
        <LogoWrapper height='auto'>
          <Logo src={TrainualLogo} />
        </LogoWrapper>
        <StyledTitle>{t('mfa.title')}</StyledTitle>
        <StyledSubtitle>{t('mfa.subtitle')}</StyledSubtitle>
        <InputField
          autoFocus
          errorText={touched.email && errors.email}
          iconName='envelope'
          label={t('email_label')}
          name='email'
          onChange={handleChange}
          placeholder={t('mfa.email_placeholder')}
          value={values.email}
        />
        <DefaultButton
          disabled={!isValid}
          fullWidth
          id='universal-login-submit-button'
          loading={isActionInProgress}
          onClick={submit}
          text={t('mfa.sign_in_cta')}
          type='submit'
        />
        <span>
          {t('register_prompt')}
          <Link href={routes.root().href} text={t('mfa.register_cta')} />
        </span>
      </FormWrapper>
    </Form>
  ) : (
    <Form autoComplete='off'>
      <FormWrapper>
        <LogoWrapper>
          <Logo src={TrainualLogo} />
        </LogoWrapper>
        <H1>{t('title')}</H1>
        <InputField
          autoFocus
          errorText={touched.email && errors.email}
          iconName='envelope'
          label={t('email_label')}
          name='email'
          onChange={handleChange}
          placeholder={t('email_placeholder')}
          value={values.email}
        />
        <ActionWrapper>
          <span>
            {t('register_prompt')}
            <Link href={routes.root().href} text={t('register_cta')} />
          </span>
          <DefaultButton
            disabled={isActionInProgress}
            id='universal-login-submit-button'
            loading={isActionInProgress}
            onClick={submit}
            text={t('sign_in_cta')}
            type='submit'
          />
        </ActionWrapper>
      </FormWrapper>
    </Form>
  );
};

const UniversalLogin = ({ route }: UniversalLoginProps) => {
  const [visibleCheckEmail, setVisibleCheckEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  return visibleCheckEmail ? (
    <PublicPage id='check-email-page' reversedStars>
      <CheckEmail email={userEmail} setVisibleCheckEmail={setVisibleCheckEmail} />
    </PublicPage>
  ) : (
    <PublicPage id='universal-login-page'>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          /* API request occurs in `LoginForm` */
        }}
        validate={validateForm}
        validateOnMount
      >
        <LoginForm
          route={route}
          setUserEmail={setUserEmail}
          setVisibleCheckEmail={setVisibleCheckEmail}
        />
      </Formik>
    </PublicPage>
  );
};

export default UniversalLogin;
