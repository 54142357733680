import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { fontSm5 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';

const t = initTranslations('zoom_control');

export const ZoomControlWrapperStyles = css`
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderDefault};
  box-sizing: border-box;
  border-radius: ${({ theme: { constants } }) => constants.borderWidth2xl};
  display: flex;
  justify-content: space-between;
  height: ${({ theme: { constants } }) => constants.heightMd};
`;

const ZoomControlWrapper = styled.div`
  ${ZoomControlWrapperStyles};

  width: 8rem;
`;

export const StyledButton = styled.button<{ $alignment: 'left' | 'right' | 'center' }>`
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    border-color: inherit;
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  ${({ $alignment, theme: { constants, vars } }) =>
    $alignment === 'left' &&
    css`
      border-right: ${constants.borderWidthSm} solid ${vars.borderDefault};
      border-radius: ${constants.borderWidthXl} 0 0 ${constants.borderWidthXl};
      width: 2.5rem;
    `}

  ${({ $alignment, theme: { constants, vars } }) =>
    $alignment === 'right' &&
    css`
      border-left: ${constants.borderWidthSm} solid ${vars.borderDefault};
      border-radius: 0 ${constants.borderWidthXl} ${constants.borderWidthXl} 0;
      width: 2.5rem;
    `}

  ${({ $alignment, theme: { vars, constants } }) =>
    $alignment === 'center' &&
    css`
      padding: 0 ${constants.spacerMd2};
      color: ${vars.textDefault};
      ${fontSm5};
    `}
`;

const CenterButton = styled(StyledButton)`
  padding: 0 ${({ theme }) => theme.constants.spacerSm1};
  min-width: 3.5rem;
`;

export type Props = {
  onDecrement: () => void;
  onIncrement: () => void;
  onClickLabel?: () => void;
  label: string;
};

const ZoomControl = ({ onDecrement, onIncrement, onClickLabel, label }: Props) => {
  return (
    <ZoomControlWrapper className='zoom-control'>
      <StyledButton
        $alignment='left'
        aria-label={t('aria_label_zoom_out')}
        id='zoom-decrease'
        onClick={onDecrement}
      >
        <Icon name='minus' weight='regular' />
      </StyledButton>
      <CenterButton $alignment='center' id='zoom-label' onClick={onClickLabel}>
        {label}
      </CenterButton>
      <StyledButton
        $alignment='right'
        aria-label={t('aria_label_zoom_in')}
        id='zoom-increase'
        onClick={onIncrement}
      >
        <Icon name='plus' weight='regular' />
      </StyledButton>
    </ZoomControlWrapper>
  );
};

export default ZoomControl;
