import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import { togglePills } from '../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { Pill, PillText } from './styles';
import { SpecializationPillProps } from './types';

const TruncatedPillText = styled.span`
  ${TruncatedText({})};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  flex-shrink: 1;
`;

const WrappedPillText = styled(PillText)`
  display: flex;
`;

export const MAX_SCORE = 5;

const t = initTranslations('delegation_planner.specialization_pills');

const SpecializationPill = ({
  id,
  isAlwaysExpanded,
  title,
  score,
  color,
  percentage,
}: SpecializationPillProps) => {
  const multilineText = () => (
    <div>
      <p>{title}</p>
      <p>{t('rating', { score, max_score: MAX_SCORE })}</p>
    </div>
  );

  const arePillsExpanded = useAppSelector((state) => state.delegationPlanner.arePillsExpanded);
  const displayTooltip = !(arePillsExpanded || isAlwaysExpanded);
  const dispatch = useAppDispatch();

  const handleToggle: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    !isAlwaysExpanded && dispatch(togglePills());
  };

  return (
    <>
      {displayTooltip && (
        <Tooltip content={multilineText()} id={`${id}-tooltip`} multiline place='top' />
      )}
      <div data-for={`${id}-tooltip`} data-tip onClick={handleToggle}>
        <Pill
          arePillsExpanded={arePillsExpanded}
          color={color}
          isAlwaysExpanded={isAlwaysExpanded}
          percentage={percentage}
          score={score}
        >
          {(isAlwaysExpanded || arePillsExpanded) && (
            <WrappedPillText>
              <TruncatedPillText>{title}</TruncatedPillText>
              <span className='notranslate'>
                {score}/{MAX_SCORE}
              </span>
            </WrappedPillText>
          )}
        </Pill>
      </div>
    </>
  );
};

export default SpecializationPill;
