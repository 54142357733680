import styled from 'styled-components';

import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../styled/Breakpoint';

export const WidgetContainer = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${mediaBreakpointPxMd}) and (max-width: ${mediaBreakpointPxLg}) {
    flex-basis: ${({ theme: { constants } }) => `calc(50% - ${constants.spacerMd2})`};
  }
`;
