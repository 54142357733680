import React from 'react';

import { GroupsIndexEmptyStateDark, GroupsIndexEmptyStateLight } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import NoResults from '../../../design_system/Triage/NoResults';

const EmptyState = () => {
  const t = initTranslations('groups');

  return (
    <NoResults
      darkImage={GroupsIndexEmptyStateDark}
      heading={t('no_groups_heading')}
      iconWidth='35%'
      lightImage={GroupsIndexEmptyStateLight}
      showBackground={false}
      showBorder={false}
      subHeaderText={t('no_groups_advice')}
    />
  );
};

export default EmptyState;
