import { useSortable } from '@dnd-kit/sortable';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useCurriculumElementInfo } from '../../../../../hooks/curriculum/useCurriculumElementInfo';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../../lib/initTranslations';
import { setSelectedContentType } from '../../../../../redux/domains/curriculums/curriculumsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useCreateElementMutation } from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { CreateElementParams } from '../../../../../redux/services/resourceApis/curriculumElements/types';
import { ElementKind } from '../../../../../types/CurriculumElement';
import { ActionRowWrapper } from '../../../curriculums/shared/CurriculumRowStyles';
import { useRouterHelper } from '../../../publicApplication/applicationRouter';
import routeTo from '../../../publicApplication/routeTo';
import VideoModal from '../../../videos/VideoModal/VideoModal';
import ActionRow from '../../ActionRow/ActionRow';
import DescriptiveDropdownOption from '../../DescriptiveDropdownOption/DescriptiveDropdownOption';
import { ActionRowProps } from './types';

const t = initTranslations('curriculum_outline_view');
const CurriculumElementActionRow = memo((props: ActionRowProps) => {
  const { autoFocus, curriculumId } = props;
  const {
    accountPlan,
    splitFeatures: { videoContentTypeEnabled },
  } = useCurrentAccount();
  const routeState = useRouterHelper();
  const isCurriculumAdminRoute =
    routeState?.route.name == 'curriculumAdmin' || routeState?.route.name == 'curriculumEdit';

  const dispatch = useAppDispatch();
  const selectedContentType = useAppSelector((state) => state.curriculums.selectedContentType) || 0;
  const elementAttributes = useCurriculumElement();

  const [videoTitle, setVideoTitle] = useState<string>();
  const [showVideoModal, setShowVideoModal] = useState(false);
  const curriculumElements = useCurriculumElementInfo();
  const selectedDropdownOption = curriculumElements[selectedContentType];

  const selectedDropdownOptionTitle = selectedDropdownOption.title;
  const dropdownProps = useMemo(() => {
    return {
      dropdownId: 'curriculum-element-type-selection-dropdown',
      dropdownMenuId: 'curriculum-element-type-selection-dropdown-menu',
      options: curriculumElements.map((curriculumElement) => {
        return (
          <DescriptiveDropdownOption
            description={curriculumElement.description}
            iconName={curriculumElement.iconName}
            id={`curriculum-element-dropdown-type-${curriculumElement.kind}`}
            key={curriculumElement.kind}
            title={curriculumElement.title}
          />
        );
      }),
      selectedOptionIndex: selectedContentType,
      selectedOptionText: selectedDropdownOptionTitle,
      setSelectedOptionIndex: (selected: number) => {
        dispatch(setSelectedContentType(selected));
      },
    };
  }, [curriculumElements, dispatch, selectedContentType, selectedDropdownOptionTitle]);
  const singlePlaceholderTranslation = t('enter_content_title', {
    contentType: selectedDropdownOptionTitle.toLowerCase(),
  });
  const [createElement, createElementResult] = useCreateElementMutation();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const { setNodeRef } = useSortable({ id: `actionRowCourse-${curriculumId}` });

  const createCurriculumElement = useCallback(
    (title: string, signatureRequired: boolean, url?: string, html?: string) => {
      if (selectedDropdownOption.kind === 'video') {
        if (videoTitle && url && html) {
          return createElement({
            elementKind: 'video',
            element: { title: videoTitle, html, url, video_type: 'external' },
            curriculumId,
          });
        }
      }

      const selectedCourseWithSignatureRequired =
        selectedDropdownOption.kind === 'course' && signatureRequired;
      const elementKind = selectedCourseWithSignatureRequired
        ? 'course'
        : (selectedDropdownOption.kind as ElementKind);
      const baseParams = { element: { title }, elementKind, curriculumId };
      const courseWithSignatureParams = { ...baseParams, signatureRequired };
      const params = selectedCourseWithSignatureRequired ? courseWithSignatureParams : baseParams;

      return createElement(params as CreateElementParams);
    },
    [createElement, curriculumId, selectedDropdownOption, videoTitle]
  );

  const videoSelected =
    selectedDropdownOptionTitle === t('curriculum_element_dropdown.video.title');

  // On "Create" content this will create a curriculum element for all types but Video.
  // In the case of Video we want to open a modal to allow them to choose external or hosted video creation
  const handleCreate = useCallback(
    (title: string, signatureRequired: boolean) => {
      if (selectedDropdownOptionTitle === t('curriculum_element_dropdown.video.title')) {
        setVideoTitle(title);
        setShowVideoModal(true);
      } else {
        return createCurriculumElement(title, signatureRequired);
      }
    },
    [createCurriculumElement, selectedDropdownOptionTitle]
  );

  const handleCreateAndRedirect = useCallback(
    (title: string, signatureRequired: boolean) => {
      handleCreate(title, signatureRequired);
      setShouldNavigate(true);
    },
    [handleCreate]
  );

  useEffect(() => {
    if (shouldNavigate) {
      if (!createElementResult.data) return;

      const { elementKind, id: elementId } = createElementResult.data;
      const { editRoute } = elementAttributes({ elementKind, elementId });

      routeTo(editRoute);
    }
  }, [createElementResult.data, shouldNavigate, elementAttributes]);

  const createVideoAction = useCallback(
    (url: string, html: string) => {
      if (videoTitle && url) {
        createCurriculumElement('', false, url, html);
        setShouldNavigate(true);
      }
    },
    [createCurriculumElement, videoTitle]
  );

  return (
    <ActionRowWrapper
      id='curriculum-element-action-row-wrapper'
      isSubjectOutline={isCurriculumAdminRoute}
      ref={setNodeRef}
    >
      <ActionRow
        autoFocus={autoFocus}
        curriculumId={curriculumId}
        disablePrimaryButton={!!selectedContentType && 'build' === accountPlan}
        dropdown={dropdownProps}
        errorText={t('enter_title')}
        hasMaxValue
        maxError={t('max_error')}
        name='new-curriculum-element-title'
        placeholder={singlePlaceholderTranslation}
        primaryButtonAction={handleCreateAndRedirect}
        primaryButtonId={`inline-create-curriculum-element-button-${curriculumId}`}
        primaryButtonText={t('create')}
        primaryOnKeyDownAction={handleCreate}
        skipSubmitting={videoSelected}
        withBorder={false}
        withoutTransparentBorder
      />
      {showVideoModal && videoContentTypeEnabled && (
        <VideoModal onClose={() => setShowVideoModal(false)} onCreateVideo={createVideoAction} />
      )}
    </ActionRowWrapper>
  );
});
CurriculumElementActionRow.displayName = 'CurriculumElementActionRow';

export default CurriculumElementActionRow;
