import React, { ChangeEvent, useEffect, useState } from 'react';

import capitalize from '../../../../lib/capitalize';
import initTranslations from '../../../../lib/initTranslations';
import TextareaWithCharacterCount, {
  InputFontSize,
} from '../../input/fields/TextareaWithCharacterCount';
import TextDisplay from './TextDisplay';

const t = initTranslations('groups');

export interface Props {
  attributeName: string;
  initialValue: string | undefined;
  label?: string;
  maxCharacters?: number;
  placeholder: string;
  resourceName: string;
  inputFontSize: InputFontSize;
  disableTranslation?: boolean;

  submitChanges(value: string | undefined): void;

  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const ClickInlineTextarea = ({
  attributeName,
  initialValue,
  label,
  maxCharacters,
  placeholder,
  resourceName,
  inputFontSize,
  submitChanges,
  disableTranslation,
  onFocus,
  onBlur,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const characters = value ?? '';
  const charactersUsed = characters.length;

  const clearErrors = () => setErrorMessage('');

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    clearErrors();
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (maxCharacters && charactersUsed > maxCharacters) {
      setErrorMessage(
        t('inline_textarea.error_message_character_length', {
          resourceName: capitalize(resourceName),
          attributeName,
          maxCharacters,
        })
      );
    }
  }, [attributeName, resourceName, charactersUsed, maxCharacters]);

  const validateAndSubmit = async () => {
    if (!!errorMessage) return;

    setIsEditing(false);

    if (value === initialValue) return;

    submitChanges(value);
  };

  if (isEditing) {
    return (
      <TextareaWithCharacterCount
        attributeName={attributeName}
        id={`editable-${attributeName}-field`}
        inputFontSize={inputFontSize}
        label={label}
        maxCharacters={maxCharacters}
        noTopMargin
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        resourceName={resourceName}
        submitChanges={validateAndSubmit}
        value={value || ''}
      />
    );
  }

  return (
    <TextDisplay
      attributeName={attributeName}
      disableTranslation={disableTranslation}
      inputFontSize={inputFontSize}
      isPlaceholder={!initialValue || placeholder === initialValue}
      setEditState={() => setIsEditing(true)}
      text={initialValue || placeholder}
    />
  );
};

export default ClickInlineTextarea;
