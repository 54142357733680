import styled from 'styled-components';

export const StyledResponsibilityItem = styled.li`
  margin-bottom: ${({ theme: { constants } }) =>
    `calc(${constants.spacerSm3} + ${constants.spacerSm1})`};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }
`;
