import { FormikErrors, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../lib/initTranslations';
import {
  useDoneEditingCurriculumMutation,
  useGetCurriculumCandidatesQuery,
} from '../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import {
  useModalDataByCurriculumQuery,
  useModalDataByStepQuery,
} from '../../../../redux/services/resourceApis/shareChanges/shareChangesApi';
import {
  CompletionNotification,
  Completions,
} from '../../../../redux/services/resourceApis/shareChanges/types';
import { useDoneEditingStepMutation } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Checkbox from '../../../design_system/input/controls/Checkbox';
import TaskModal, { TaskModalProps } from '../../../design_system/overlays/modals/TaskModal';
import TextAreaField from '../../../design_system/Triage/fields/TextAreaField';
import Loader, { LoaderContainer } from '../../../design_system/Triage/Loader';
import RadioField from '../../../design_system/Triage/RadioField';
import { useFlashNotification } from '../../../FlashNotificationContext';
import { fontSm5 } from '../../../styled/TypeSystem';
import PeopleAssignedModal, { Users } from '../modals/PeopleAssignedModal';

const LoaderWrapper = styled.div`
  min-height: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTextAreaField = styled(TextAreaField)`
  resize: none;
  outline: none;
  height: 6rem;
`;

const ResetOptionsSectorWrapper = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const ResetOptionsHeader = styled.h4`
  ${fontSm5};
`;

const AdvancedResetOptions = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const StyledCheckbox = styled(Checkbox)`
  width: fit-content;
  cursor: pointer;
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 ${constants.spacerMd1}`};
`;

const NotifyInfoSection = styled.div(
  ({ theme: { constants, vars } }) => css`
    padding: ${constants.spacerMd2};
    font-weight: ${constants.fontSemibold};
    background-color: ${vars.foundationBase1};
    color: ${vars.textDefault};
    ${fontSm5};
  `
);

const NotifyInfoLink = styled.span<{ ableToAddMoreUsers: boolean }>`
  color: ${({ ableToAddMoreUsers, theme: { vars } }) =>
    ableToAddMoreUsers ? vars.accentPrimaryDefault : vars.textDefault};
  cursor: ${({ ableToAddMoreUsers }) => (ableToAddMoreUsers ? 'pointer' : 'default')};
`;

type ModalText = { title?: string; subtitle?: string };

type DataSourceProps =
  | { dataSource: 'curriculum'; stepId?: never }
  | { dataSource: 'step'; stepId: number };

export type Props = {
  closeRequest: () => void;
  curriculumId: number;
  curriculumTitle: string;
  completionRequired: boolean | undefined;
} & DataSourceProps &
  ModalText;

interface FormValues {
  message: string;
  completions: Completions;
  reset: 'true' | 'false';
  share_changes_immediately: boolean;
}

const t = initTranslations('share_changes_modal');

const ShareChangesModal = ({
  closeRequest,
  curriculumId,
  completionRequired,
  subtitle = t('subtitle'),
  title = t('title'),
  stepId,
  dataSource,
}: Props) => {
  const ability = useCurrentUserAbilities();
  const {
    curriculum: { singular: curriculumTerm },
    step: { singular: stepTerm },
    topic: { singular: topicTerm },
  } = useAccountTerminology();
  const [users, setUsers] = useState<Users>({
    candidates: [],
    notified_users: [],
  });
  const [showPeopleModal, setShowPeopleModal] = useState(false);
  const { flash } = useFlashNotification();
  const { data: candidates } = useGetCurriculumCandidatesQuery(curriculumId);
  const { data: stepModalData, isLoading: stepQueryLoading } = useModalDataByStepQuery(stepId, {
    skip: dataSource === 'curriculum',
  });
  const { data: curriculumModalData, isLoading: curriculumQueryLoading } =
    useModalDataByCurriculumQuery(curriculumId, {
      skip: dataSource === 'step',
    });

  const isLoading = curriculumQueryLoading || stepQueryLoading;

  const fetchPeopleAssigned = () => {
    if (ableToAddMoreUsers && candidates) {
      setUsers(candidates);
      setShowPeopleModal(true);
    }
  };

  const notifiedUsersLength = candidates?.notified_users.length;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const optionalSettingsUnlocked =
    ability.can('update', 'CurriculumAdminPlus') && completionRequired;
  const ableToAddMoreUsers =
    ability.can('read', `ManageCurriculumAssigned-${curriculumId}`) ||
    ability.can('update', 'CurriculumAdminPlus');
  const eSignatureRequired = Boolean(
    curriculumModalData?.signature_required || stepModalData?.signature_required
  );

  const keepOptions = [
    {
      value: 'false',
      name: 'reset',
      text: t('keep_current_completion_scores'),
    },
  ];

  const resetOptions = [
    {
      value: 'true',
      name: 'reset',
      text: t('reset_completion_scores'),
    },
  ];

  const advancedResetOptionsForCurriculum = [
    {
      value: Completions.Curriculum,
      name: 'completions',
      text: t('reset_the_entire_subject', {
        subject: curriculumTerm,
        title: curriculumModalData
          ? curriculumModalData?.curriculum.title
          : stepModalData?.curriculum.title,
      }),
    },
  ];

  const advancedResetOptionsForCourse = [
    {
      value: Completions.Course,
      name: 'completions',
      text: t('reset_the_topic', {
        topic: topicTerm,
        title: stepModalData?.course.title,
      }),
    },
  ];

  const advancedResetOptionsForStep = [
    {
      value: Completions.Step,
      name: 'completions',
      text: t('reset_the_step', {
        step: stepTerm,
        title: stepModalData?.step.title,
      }),
    },
  ];

  const [doneEditingCurriculum, curriculumResult] = useDoneEditingCurriculumMutation();
  const { isError: curriculumError, isSuccess: curriculumSuccess } = curriculumResult;

  const [doneEditingStep, stepResult] = useDoneEditingStepMutation();
  const { isError: stepError, isSuccess: stepSuccess } = stepResult;

  const curriculumDoneEditing = useCallback(
    (values: FormValues) => {
      doneEditingCurriculum({
        id: curriculumId,
        message: values.message,
        completions: values.completions,
        clearCompletions:
          values.completions === Completions.Keep
            ? CompletionNotification.GeneralUpdate
            : CompletionNotification.ActionableUpdate,
        shareChangesImmediately: values.share_changes_immediately ? '1' : '0',
        dataSource,
      });
    },
    [doneEditingCurriculum, curriculumId, dataSource]
  );

  const stepsDoneEditing = useCallback(
    (values: FormValues) => {
      {
        !!stepModalData &&
          doneEditingStep({
            id: stepModalData.step.id,
            message: values.message,
            completions: values.completions,
            clearCompletions:
              values.completions === Completions.Keep
                ? CompletionNotification.GeneralUpdate
                : CompletionNotification.ActionableUpdate,
            shareChangesImmediately: values.share_changes_immediately ? '1' : '0',
            curriculumId,
            courseId: stepModalData.course.id,
            dataSource,
          });
      }
    },
    [doneEditingStep, curriculumId, stepModalData, dataSource]
  );

  const handleResetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);

    if (e.target.value === 'false') {
      formik.setFieldValue('completions', Completions.Keep);
    } else {
      if (dataSource === 'curriculum') {
        formik.setFieldValue('completions', Completions.Curriculum);
      } else {
        formik.setFieldValue('completions', Completions.Step);
      }
    }
  };

  const submitForm = useCallback(
    (values) => {
      setIsSubmitting(true);
      if (dataSource === 'curriculum') {
        curriculumDoneEditing(values);
      } else {
        stepsDoneEditing(values);
      }
    },
    [curriculumDoneEditing, dataSource, stepsDoneEditing]
  );

  const formik = useFormik({
    initialValues: {
      message: '',
      completions: Completions.Keep,
      reset: 'false',
      share_changes_immediately: true,
    },
    enableReinitialize: true,
    validate: (values: FormValues) => validateForm(values),
    onSubmit: (values: FormValues) => submitForm(values),
  });

  const validateForm = useCallback((values: FormValues) => {
    const { message } = values;
    const errors: FormikErrors<FormValues> = {};
    if (!message) {
      errors.message = t('inputs.errors.required_field');
    }
    return errors;
  }, []);

  useEffect(() => {
    if (curriculumSuccess || stepSuccess) {
      flash(
        'info',
        formik.values.share_changes_immediately
          ? t('share_immediately_flash', { count: notifiedUsersLength })
          : t('share_later_flash', { count: notifiedUsersLength })
      );
      closeRequest();
    }
  }, [
    notifiedUsersLength,
    closeRequest,
    curriculumSuccess,
    flash,
    formik.values.share_changes_immediately,
    stepSuccess,
  ]);

  useEffect(() => {
    if (curriculumError || stepError) {
      setIsSubmitting(false);
    }
  }, [curriculumError, stepError]);

  const notifyInfoText = formik.values.share_changes_immediately
    ? t('notify_immediately')
    : t('notify_later');

  const taskModalArgs: TaskModalProps = {
    heapModalName: 'share-changes-modal',
    title,
    subtitle,
    onCloseRequest: closeRequest,
    primaryButtonText: t('share_changes'),
    processing: isSubmitting,
    isDisabled: false,
    primaryButtonTask: formik.handleSubmit,
  };

  return (
    <>
      {!showPeopleModal && (
        <TaskModal {...taskModalArgs}>
          {isLoading ? (
            <LoaderWrapper>
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            </LoaderWrapper>
          ) : (
            <>
              <StyledTextAreaField
                autoFocus
                errorText={formik.touched.message && formik.errors.message}
                id='notify-team-message'
                label={t('add_a_note')}
                name='message'
                onChange={formik.handleChange}
                placeholder={t('placeholder')}
                value={formik.values.message}
              />
              {optionalSettingsUnlocked && !eSignatureRequired && (
                <ResetOptionsSectorWrapper className='reset-options-radio-buttons'>
                  <ResetOptionsHeader>{t('choose_an_option')}</ResetOptionsHeader>
                  <RadioField
                    onChange={handleResetChange}
                    options={keepOptions}
                    orientation='vertical'
                    truncate={false}
                    value={formik.values.reset}
                  />
                  <RadioField
                    onChange={handleResetChange}
                    options={resetOptions}
                    orientation='vertical'
                    truncate={false}
                    value={formik.values.reset}
                  />
                  {formik.values.reset === 'true' && (
                    <AdvancedResetOptions>
                      {stepModalData && (
                        <>
                          <RadioField
                            onChange={formik.handleChange}
                            options={advancedResetOptionsForStep}
                            orientation='vertical'
                            truncate={false}
                            value={formik.values.completions}
                          />
                          <RadioField
                            onChange={formik.handleChange}
                            options={advancedResetOptionsForCourse}
                            orientation='vertical'
                            truncate={false}
                            value={formik.values.completions}
                          />
                          <RadioField
                            onChange={formik.handleChange}
                            options={advancedResetOptionsForCurriculum}
                            orientation='vertical'
                            truncate={false}
                            value={formik.values.completions}
                          />
                        </>
                      )}
                      {curriculumModalData && (
                        <RadioField
                          onChange={formik.handleChange}
                          options={advancedResetOptionsForCurriculum}
                          orientation='vertical'
                          truncate={false}
                          value={formik.values.completions}
                        />
                      )}
                    </AdvancedResetOptions>
                  )}
                </ResetOptionsSectorWrapper>
              )}
              <StyledCheckbox
                checked={formik.values.share_changes_immediately}
                id='share_changes_immediately'
                label={t('notify_team_right_away')}
                name='share_changes_immediately'
                onCheck={formik.handleChange}
              />
              <NotifyInfoSection>
                <NotifyInfoLink
                  ableToAddMoreUsers={ableToAddMoreUsers}
                  id='assign-users-trigger'
                  onClick={fetchPeopleAssigned}
                >
                  {candidates && (
                    <span>{t('people_assigned', { count: candidates.notified_users.length })}</span>
                  )}
                </NotifyInfoLink>
                <span>{notifyInfoText}</span>
              </NotifyInfoSection>
            </>
          )}
        </TaskModal>
      )}

      <PeopleAssignedModal
        curriculumId={curriculumId}
        setShowShareChangesModal={() => {
          setShowPeopleModal(false);
        }}
        setUsers={setUsers}
        showPeopleAssignedModal={showPeopleModal}
        users={users}
      />
    </>
  );
};

export default ShareChangesModal;
