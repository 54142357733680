import React, { useReducer, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../contexts/EditorContext';
import { useEditorCourseContext } from '../../../../contexts/EditorCourseContext';
import { EditorToolbarProvider } from '../../../../contexts/EditorToolbarContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useSlideout from '../../../../hooks/useSlideout';
import initTranslations from '../../../../lib/initTranslations';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import IconButton from '../../../design_system/buttons/IconButton';
import { STEPS_SLIDEOUT } from '../../../helpers/slideOut';
import {
  mediaBreakpointLg,
  mediaBreakpointPx2xl,
  mediaBreakpointPxLg,
} from '../../../styled/Breakpoint';
import AutoSaveStatusIndicator from '../../shared/./AutoSaveStatusIndicator/AutoSaveStatusIndicator';
import ShareChangesModal from '../../curriculums/ShareChangesModal/ShareChangesModal';
import { routes } from '../../publicApplication/applicationRouter';
import GoBackIconButton from '../components/GoBackIconButton/GoBackIconButton';
import ToolbarTabs from '../components/ToolbarTabs';
import { initialToolbarState, toolbarReducer } from '../lib/toolbarReducer';
import { CenterDiv, LeftDiv, RightDiv, SharedToolbarStyles } from '../shared/styles';
import BaseToolbar, { LockedToolbar, ToolbarAll } from './';
import NotifyButton from './buttons/components/notify/NotifyButton';
import InsertToolbar from './InsertToolbar/InsertToolbar';

export const CREATE_TOOLBAR_HEIGHT = '7.625rem';

const ToolbarWrapper = styled.div`
  ${SharedToolbarStyles};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} 0 ${constants.spacerMd2}`};
  flex-wrap: wrap;
`;

const FormatToolbar = styled(BaseToolbar)`
  padding: ${({ theme: { constants } }) => constants.spacerMd1};
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: ${mediaBreakpointPxLg}) {
    flex-wrap: nowrap;
  }

  @media (max-width: ${mediaBreakpointPx2xl}) {
    justify-content: flex-start;
  }
`;

const WrappedCenterDiv = styled(CenterDiv)`
  margin-bottom: 0;
  justify-content: center;
`;

const WrappedLeftDiv = styled(LeftDiv)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const WrappedIconButton = styled(IconButton)`
  display: none;

  @media (max-width: ${mediaBreakpointLg - 1}px) {
    display: flex;
  }
`;

const WrappedEditorToolbarProvider = styled.div`
  flex-basis: 100%;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow-x: unset;

  @media (max-width: ${mediaBreakpointPxLg}) {
    overflow-x: scroll;
  }
`;

const t = initTranslations('editor_toolbar');

interface Props {
  step: GetStepResponse;
  isLocked: boolean;
  isFetching: boolean;
}

const Toolbar = ({ step, isLocked, isFetching }: Props) => {
  const { saveState } = useEditorContext();
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const {
    curriculum: { title, id, completionRequired },
    stepId,
  } = useEditorCourseContext();
  const [{ action }, toolbarDispatch] = useReducer(toolbarReducer, initialToolbarState);
  const { slug, lockBrandStyles } = useCurrentAccount();
  const { lastEditedBy, lastEditedOn, updatedAtInWords } = step.updatedAtData;
  const { open, close, isOpen } = useSlideout(STEPS_SLIDEOUT);

  return (
    <>
      <ToolbarWrapper className='editor-toolbar'>
        <WrappedLeftDiv>
          <WrappedIconButton
            ariaLabel=''
            id='steps-slideout-button'
            name='bars'
            onClick={() => {
              isOpen ? close() : open();
            }}
          />
          <NotifyButton setShowNotifyModal={setShowNotifyModal} />
        </WrappedLeftDiv>
        <WrappedCenterDiv>
          <ToolbarTabs checkedOption={action} toolbarDispatch={toolbarDispatch} />
        </WrappedCenterDiv>
        <RightDiv>
          <AutoSaveStatusIndicator
            autosaveStatus={saveState}
            isFetching={isFetching}
            lastEditedBy={lastEditedBy}
            lastEditedOn={lastEditedOn}
            updatedAtInWords={updatedAtInWords}
          />
          {!isLocked && (
            <GoBackIconButton onClick={() => routes.curriculumEdit({ slug, id }).push()} />
          )}
        </RightDiv>
        <WrappedEditorToolbarProvider>
          <EditorToolbarProvider
            buttons={lockBrandStyles ? LockedToolbar : ToolbarAll}
            context='docked'
          >
            {action === 'insert' && <InsertToolbar />}
            {action === 'format' && <FormatToolbar />}
          </EditorToolbarProvider>
        </WrappedEditorToolbarProvider>
      </ToolbarWrapper>

      {showNotifyModal && (
        <ShareChangesModal
          closeRequest={() => {
            setShowNotifyModal(false);
          }}
          completionRequired={completionRequired}
          curriculumId={id}
          curriculumTitle={title}
          dataSource='step'
          stepId={stepId}
          subtitle={t('notify.subtitle')}
          title={t('notify.title')}
        />
      )}
    </>
  );
};

export default Toolbar;
