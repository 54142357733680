import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../../../lib/initTranslations';
import { CurriculumOwner } from '../../../../../../../types';
import Avatar from '../../../../../../design_system/display/avatar';
import Icon from '../../../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { TruncatedText } from '../../../../../../design_system/Triage/TruncatedText';
import { fontSm5 } from '../../../../../../styled/TypeSystem';
import { getChevronIconName } from '../../../../../shared/helpers';

const TruncateSpan = styled.span`
  pointer-events: none;
  ${TruncatedText({})};
`;

const Subdued = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

const NoShrinkSpan = styled.span`
  display: flex;
  align-items: center;
  pointer-events: none;
  flex-shrink: 0;
`;

const StyledButton = styled.button<{ disabled: boolean }>`
  ${({ theme: { constants, vars }, disabled }) => css`
    display: flex;
    gap: ${constants.spacerSm2};
    align-items: center;
    justify-content: center;
    background: ${vars.foundationSurface1};
    cursor: ${disabled ? 'default' : 'pointer'};
    border: none;
    padding: 0.375rem 0;
    outline: none;
    max-width: 20rem;
    color: ${vars.textDefault};
    height: ${constants.heightMd};

    ${fontSm5};
  `};
`;

const StyledAvatar = styled(Avatar)`
  margin-right: ${({ theme: { constants } }) => constants.spacerLg3};
`;

type SourceBadgeProps = {
  ariaLabel: string;
  className: string;
  active: boolean;
  disabled?: boolean;
  id: string;
  owner: CurriculumOwner | null;
};

const t = initTranslations('curriculums.owner');

const OwnedByButton = ({
  active,
  ariaLabel,
  className,
  disabled = false,
  id,
  owner,
}: SourceBadgeProps) => {
  const ownedByBtnRef = React.useRef<HTMLButtonElement>(null);
  const { id: userId } = useCurrentUser();
  const isCurrentUserOwner = useMemo(() => owner?.id === userId, [userId, owner]);
  const ability = useCurrentUserAbilities();
  const canEditOwner = ability.can('update', 'CurriculumAdminPlus') || isCurrentUserOwner;
  const textSpan = useMemo(() => {
    if (isCurrentUserOwner) {
      return <TruncateSpan>{t('owned_by_you')}</TruncateSpan>;
    } else if (!owner) {
      return (
        <TruncateSpan>{canEditOwner ? t('display_owner_placeholder') : t('no_owner')}</TruncateSpan>
      );
    } else {
      return (
        <TruncateSpan>
          {owner.name} <Subdued>{t('owner')}</Subdued>
        </TruncateSpan>
      );
    }
  }, [canEditOwner, isCurrentUserOwner, owner]);
  return (
    <>
      <Tooltip
        id='owner-tooltip'
        text={disabled ? t('admin_access_required') : t('change_owner')}
      />
      <StyledButton
        aria-label={ariaLabel}
        className={className}
        data-active={active}
        data-for='owner-tooltip'
        data-tip
        disabled={disabled}
        id={id}
        ref={ownedByBtnRef}
      >
        {owner?.name ? (
          <NoShrinkSpan>
            <Avatar image={owner.avatar} name={owner.name} shape='circle' size='xs' />
          </NoShrinkSpan>
        ) : (
          disabled && (
            <>
              <StyledAvatar icon='user' name='' shape='circle' size='xs' />
              <span />
            </>
          )
        )}
        {textSpan}
        {!disabled && (
          <NoShrinkSpan>
            <Icon
              name={getChevronIconName({ isActive: active, initialDirection: 'up' })}
              size='xs'
              weight='regular'
            />
          </NoShrinkSpan>
        )}
      </StyledButton>
    </>
  );
};

export default OwnedByButton;
