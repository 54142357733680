import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import {
  useCheckModalState,
  useDispatchSetShowModal,
} from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import ContentModal from '../../../../curriculums/modals/CurriculumModal/ContentModal';
import { TypeName } from '../../../../shared/ContentFlyout/types';
import ResponsibilityLinkedContent from '../ResponsibilityLinkedContent/ResponsibilityLinkedContent';
import { CONTENTABLE_URLS } from '../ResponsibilityLinkedContent/utils';
import AddSpecializations from './AddSpecializations/AddSpecializations';
import Description from './Description/Description';
import OptionsMenu from './OptionsMenu/OptionsMenu';
import ResponsibilityDurationTimeSetter from './ResponsibilityDurationTimeSetter/ResponsibilityDurationTimeSetter';
import { ResponsibilityDetailsContainer, SectionContainer } from './styles';
import {
  ResponsibilityDetailsFormData,
  ResponsibilityDetailsProps,
  TResponsibilityContentUrl,
} from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal');

const ResponsibilityDetails: FC<ResponsibilityDetailsProps> = ({
  responsibility,
  responsibiliableId,
  type,
}) => {
  const {
    curriculum: { singular: subjectTermSingular },
    responsibility: { singular: responsibilityTermSingular },
  } = useAccountTerminology();
  const { slug, delegationPlannerEnabled } = useCurrentAccount();
  const isGroup = type === 'Group';
  const dispatchShowModal = useDispatchSetShowModal();
  const isCurriculumModalOpen = useCheckModalState('delegationCreateAndConnectCurriculumModal');
  const { values, setValues } = useFormikContext<ResponsibilityDetailsFormData>();

  const handleConnectCreatedCurriculumContent = (curriculumId: number, curriculumTitle: string) => {
    const typeName = TypeName.Curriculum;
    const url = CONTENTABLE_URLS({ id: curriculumId, slug })[typeName];
    const contentUrl: TResponsibilityContentUrl = {
      url,
      title: curriculumTitle,
      contentableId: curriculumId,
      contentableType: typeName,
    };

    setValues({
      ...values,
      contents_urls: [contentUrl, ...values.contents_urls],
    });
  };

  return (
    <ResponsibilityDetailsContainer>
      <SectionContainer>
        {delegationPlannerEnabled && (
          <>
            <ResponsibilityDurationTimeSetter responsibility={responsibility} />
            <Description />
          </>
        )}
        <ResponsibilityLinkedContent
          isGroup={isGroup}
          responsibiliableId={responsibiliableId}
          responsibiliableName={responsibility?.responsibiliableName}
        />
      </SectionContainer>
      <SectionContainer>
        {delegationPlannerEnabled && (
          <AddSpecializations
            isGroup={isGroup}
            responsibiliableName={responsibility?.responsibiliableName}
            responsibilitySpecializations={responsibility?.assignedResponsibilitySpecializations}
          />
        )}

        <OptionsMenu responsibilityId={responsibility?.assignedResponsibilityId || ''} />
      </SectionContainer>
      {isCurriculumModalOpen && (
        <ContentModal
          afterCreateCurriculumAction={handleConnectCreatedCurriculumContent}
          closeRequest={() => dispatchShowModal('delegationCreateAndConnectCurriculumModal', false)}
          providedFormSubtitle={t('curriculum_modal.subtitle', {
            subject: subjectTermSingular.toLowerCase(),
            responsibility: responsibilityTermSingular.toLowerCase(),
          })}
          providedFormTitle={t('curriculum_modal.title', {
            subject: subjectTermSingular.toLowerCase(),
          })}
        />
      )}
    </ResponsibilityDetailsContainer>
  );
};

export default ResponsibilityDetails;
