import React from 'react';
import styled from 'styled-components';

import { LibraryEmptyStateSvg } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import NoResults from '../../../design_system/Triage/NoResults';

// DS Override: Reducing the margin-top to remove scrollbar when there are no results
const StyledNoResults = styled(NoResults)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

type Props = {
  hasSearched: boolean;
};

const t = initTranslations('content_search');

const ContentSearchEmptyState = ({ hasSearched }: Props) => {
  const subHeaderText = hasSearched ? t('no_results') : t('search_for_content');

  return (
    <StyledNoResults
      darkImage={LibraryEmptyStateSvg}
      iconWidth='50%'
      lightImage={LibraryEmptyStateSvg}
      minHeight='5rem'
      showBackground={false}
      showBorder={false}
      showSubheader
      subHeaderText={subHeaderText}
    />
  );
};

export default ContentSearchEmptyState;
