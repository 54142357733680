import React from 'react';

import { CheckoutDataResponse } from '../../../../../redux/services/resourceApis/checkout/types';
import CheckoutAddonsSection from '../CheckoutAddonsSection/CheckoutAddonsSection';
import CheckoutTotalSection from '../CheckoutTotalSection/CheckoutTotalSection';
import CheckoutWithAddonsPlanDetailsSection from '../CheckoutWithAddonsPlanDetailsSection/CheckoutWithAddonsPlanDetailsSection';
import CheckoutWithAddonsTotalSection from '../CheckoutWithAddonsTotalSection/CheckoutWithAddonsTotalSection';
import { CheckoutHr, CheckoutWithAddonHr } from '../styles';

type CheckoutPricingInformationSectionFactoryProps = {
  data: CheckoutDataResponse;
};

const CheckoutPricingInformationSectionFactory = ({
  data,
}: CheckoutPricingInformationSectionFactoryProps) => {
  const addons = data?.upcomingInvoice?.addonItems || [];
  const hasAddons = addons.length > 0;

  return hasAddons ? (
    <>
      <CheckoutWithAddonsPlanDetailsSection
        billingInterval={data.upcomingInvoice.newPlan.billingInterval}
        coupon={data.upcomingInvoice.coupon}
        planName={data.upcomingInvoice.newPlan.name}
        totalPrice={data.upcomingInvoice.newPlan.totalPrice}
      />
      <CheckoutWithAddonHr />
      <CheckoutAddonsSection
        addons={addons}
        billingInterval={data.upcomingInvoice.newPlan.billingInterval}
        planName={data.upcomingInvoice.newPlan.name}
      />
      <CheckoutWithAddonsTotalSection
        billingInterval={data.upcomingInvoice.newPlan.billingInterval}
        totalPrice={data.upcomingInvoice.newPlan.dueToday}
      />
    </>
  ) : (
    <>
      <CheckoutHr />
      <CheckoutTotalSection
        billingInterval={data.upcomingInvoice.newPlan.billingInterval}
        coupon={data.upcomingInvoice.coupon}
        totalPrice={data.upcomingInvoice.newPlan.totalPrice}
      />
    </>
  );
};

export default CheckoutPricingInformationSectionFactory;
