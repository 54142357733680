import { Formik, FormikErrors } from 'formik';
import React, { useReducer } from 'react';

import useDisplayFlashOnResponse from '../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../lib/initTranslations';
import isValidPhoneNumber from '../../../../lib/isValidPhoneNumber';
import {
  useGetAccountSettingsQuery,
  useUpdateAccountDataMutation,
} from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import { OrganizationProfileData } from '../../../../redux/services/resourceApis/accountSettings/types';
import CollapsibleSurface from '../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import OrganizationProfileForm from './OrganizationProfileForm';

const t = initTranslations('account_settings.organization_profile');

const OrganizationProfile = () => {
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();

  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const { isLoading } = result;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);

  if (isLoadingData) return <></>;
  if (!data) return <></>;

  const { availableOrganizationSizes, availableIndustries, employeeSize, phone, name, industry } =
    data;

  const initialValues = {
    name,
    industry,
    employeeSize,
    phone,
  };

  const onSubmit = (value: OrganizationProfileData) => {
    updateAccountData(value);
  };

  const validate = ({ name, phone }: OrganizationProfileData) => {
    const errors: FormikErrors<OrganizationProfileData> = {};
    if (!name) errors.name = t('errors.required');
    if (phone) {
      if (!isValidPhoneNumber(phone)) {
        errors.phone = t('errors.invalid');
      }
    }
    return errors;
  };

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='organization-profile-settings-surface'
      isCollapsed={collapsed}
      title={t('title')}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        <OrganizationProfileForm
          availableIndustries={availableIndustries}
          availableOrganizationSizes={availableOrganizationSizes}
          isLoading={isLoading}
        />
      </Formik>
    </CollapsibleSurface>
  );
};

export default OrganizationProfile;
