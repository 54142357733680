import React from 'react';
import { useTheme } from 'styled-components';

import { RegisteredMenuId } from '../../../../lib/idRegistry';
import initTranslations from '../../../../lib/initTranslations';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import ColorPickerTrigger from '../../../design_system/buttons/DeprecatedColorPicker/ColorPickerTrigger';
import DefaultColorPicker from '../../../design_system/ColorPicker/ColorPicker';
import ColorPickerInput from '../../../design_system/ColorPicker/ColorPickerInput';
import { TEXT_COLORS } from '../../../design_system/ColorPicker/constants';
import { SwatchesWrapper } from '../../../design_system/ColorPicker/Swatches/styles';
import TextSwatch from '../../../design_system/ColorPicker/Swatches/TextSwatch';
import { paletteColorDecoder } from '../../../design_system/helpers';
import useActiveMenuHandler from '../../../design_system/useActiveMenuHandler';

const t = initTranslations('account_settings.brand_styles.text_style_bar');

type Props = {
  activeColor: string;
  defaultFontColor: string;
  updateFontColor: (color: string) => void;
  flyoutId: RegisteredMenuId;
};

const TextStyleColorPicker = ({
  activeColor,
  defaultFontColor,
  updateFontColor,
  flyoutId,
}: Props) => {
  const { palettes } = useTheme();

  const { closeMenu } = useActiveMenuHandler({ menuId: flyoutId });

  const updateColor = (color: string) => {
    updateFontColor(color);
    closeMenu();
  };

  return (
    <DefaultColorPicker
      flyoutId={flyoutId}
      strategy='absolute'
      trigger={
        <ColorPickerTrigger
          ariaLabel={t('text_color')}
          buttonId='account-text-color'
          color={activeColor}
          type='text-color'
        />
      }
    >
      <SwatchesWrapper rowCount={7}>
        {TEXT_COLORS.map((color) => {
          return (
            <TextSwatch
              activeColor={activeColor}
              color={paletteColorDecoder(palettes, color)}
              key={color}
              onClick={() => updateColor(color)}
            />
          );
        })}
      </SwatchesWrapper>
      <ColorPickerInput color={activeColor} onChange={updateColor} />
      <DefaultButton
        buttonType='tertiary'
        disabled={activeColor == defaultFontColor}
        fullWidth
        iconName='arrow-rotate-left'
        id='account-text-color-removal-button'
        onClick={() => updateColor(defaultFontColor)}
        text={t('reset_to_default')}
      />
    </DefaultColorPicker>
  );
};

export default TextStyleColorPicker;
