import React from 'react';
import styled from 'styled-components';
import { Sector } from 'types/Sector';

import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import Menu from './Menu';

const Wrapper = styled.div`
  align-self: flex-start;
`;

const t = initTranslations('curriculums.three_dot_menu');

type Props = {
  curriculumId: number;
  curriculumTitle: string;
  sector: Sector;
  completionRequired: boolean | undefined;
  canDeleteSubject: boolean;
  containsSignature?: boolean; // TODO: Remove when refactor GS2-3562 happens
  reportsAccess?: boolean;
  published: boolean;
};

const CurriculumThreeDot = ({
  curriculumId,
  completionRequired,
  sector,
  curriculumTitle,
  canDeleteSubject,
  reportsAccess,
  containsSignature,
  published,
}: Props) => {
  const menuId: RegisteredMenuId = `curriculum-edit-three-dot-${curriculumId}`;
  const { isMenuOpen } = useActiveMenuHandler({ menuId });

  return (
    <>
      {!isMenuOpen && <Tooltip id='curriculum-three-dot-tip' text={t('more_options')} />}
      <Wrapper
        className='curriculum-three-dot-wrapper'
        data-for='curriculum-three-dot-tip'
        data-tip
      >
        <Menu
          canDeleteSubject={canDeleteSubject}
          completionRequired={completionRequired}
          containsSignature={containsSignature}
          curriculumId={curriculumId}
          curriculumTitle={curriculumTitle}
          id={menuId}
          published={published}
          reportsAccess={reportsAccess}
          sector={sector}
        />
      </Wrapper>
    </>
  );
};

export default CurriculumThreeDot;
