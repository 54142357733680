import React from 'react';
import styled from 'styled-components';

import { useAccountTerminology } from '../../../../../components/AccountTerminologyProvider';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import usePageTitle from '../../../../../hooks/usePageTitle';
import initTranslations from '../../../../../lib/initTranslations';
import translationClassname from '../../../../../lib/translationClassname';
import { Emoji } from '../../../../../types/Emoji';
import Breadcrumbs from '../../../../design_system/navigation/Breadcrumbs';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { routes } from '../../../publicApplication/applicationRouter';
import { BreadcrumbType } from '../../../publicApplication/route_types/breadcrumb';
import getCurrentPageText from './currentPageTextHelper';

// DS Override: Need to match design styles
const StyledBreadcrumb = styled(Breadcrumbs)`
  margin-bottom: 0;
  display: inline-block;

  @media (min-width: ${mediaBreakpointPxSm}) {
    display: flex;
  }
`;

type Props = {
  emoji: Emoji;
  title: string;
  isEdit?: boolean;
  breadcrumb?: BreadcrumbType;
  disableTranslation?: boolean;
};

const t = initTranslations('curriculums.breadcrumbs');

const CurriculumBreadCrumbs = ({ emoji, title, isEdit, breadcrumb, disableTranslation }: Props) => {
  const { slug } = useCurrentAccount();
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceName: title, resourceType: 'curriculum', productTerm });

  let linkText, path;

  if (breadcrumb) {
    linkText = breadcrumb.name;
    path = breadcrumb.href;
  } else {
    if (isEdit) {
      linkText = t('content');
      path = routes.content({ slug }).href;
    } else {
      linkText = t('home');
      path = routes.home({ slug }).href;
    }
  }

  const currentPageText = getCurrentPageText({ emoji, title });

  return (
    <StyledBreadcrumb
      currentPageText={currentPageText}
      isRedirectTextSentenceCase={!isEdit}
      redirectPath={path}
      redirectText={linkText}
      secondClassName={translationClassname(disableTranslation)}
    />
  );
};

export default CurriculumBreadCrumbs;
