import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import { useOrgChartConfigSlideout } from '../../../../../../contexts/OrgChartConfigSlideoutContext';
import { ReportsToResource } from '../../../../../../redux/services/resourceApis/orgChart/types';
import LoadingContainer from '../../../../../design_system/LoadingContainer';
import Line from '../../../../../design_system/Triage/layout/Line';
import FlexContainer from '../../../../../styled/FlexContainer';
import ReportsToConflictModal from '../ReportsToConflictModal';
import ResourceListItem from './ResourceListItem';
import { ResourceName, ResourcesUl } from './styles';
import { ResourceListProps } from './types';

const ResourceList = ({ formatResourceOptions }: ResourceListProps) => {
  const theme = useTheme();
  const { dataIsLoading, resources, orgChartResourceType } = useOrgChartConfigSlideout();
  const [conflictResources, setConflictResources] = useState<ReportsToResource[]>([]);
  const conflictModalOpen = useMemo(() => {
    return conflictResources.length === 2;
  }, [conflictResources.length]);

  if (dataIsLoading) {
    return <LoadingContainer />;
  }

  return (
    <FlexContainer align='flex-start' direction='column'>
      <ResourcesUl className='resources-list'>
        {resources
          .filter(({ isSelectedRoot }) => !isSelectedRoot)
          .map((resource) => (
            <div
              className='notranslate'
              key={`resource-${resource.id}-${resource.selectedParentId || 'no-parent'}`}
            >
              <ResourceName>{resource.name}</ResourceName>
              <ResourceListItem
                formatResourceOptions={formatResourceOptions}
                resource={resource}
                setConflictResources={setConflictResources}
              />
              <Line margin={`${theme.constants.spacerMd2} 0`} />
            </div>
          ))}
      </ResourcesUl>
      {conflictModalOpen && (
        <ReportsToConflictModal
          closeModal={() => {
            setConflictResources([]);
          }}
          conflictResources={conflictResources}
          formatResourceOptions={formatResourceOptions}
          resourceType={orgChartResourceType}
          resources={resources}
        />
      )}
    </FlexContainer>
  );
};

export default ResourceList;
