import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import {
  TeammateModalProvider,
  useTeammateModal,
} from '../../../../../../contexts/TeammateContext';
import { useShowVerifyStatusBadge } from '../../../../../../hooks/curriculum/useShowVerifyStatusBadge';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { VerifyContentStatus } from '../../../../../../redux/services/resourceApis/reports/types';
import { CurriculumOwner } from '../../../../../../types';
import { Sector } from '../../../../../../types/Sector';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import { fontSm4 } from '../../../../../styled/TypeSystem';
import AddTeammateWithConfirmation from '../../../../people/TeammateModal/AddTeammateWithConfirmation';
import ContentModal from '../../../modals/CurriculumModal/ContentModal';
import OwnedByFlyout from '../../Settings/OwnerDropdown/OwnedByFlyout/OwnedByFlyout';
import TimeEstimatePill from '../../Settings/TimeEstimatePill';
import AdminVerifyStatusBadge from './VerifyStatusBadge/AdminVerifyStatusBadge';

const OverviewWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd3};
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  border-bottom: ${({ theme: { vars, constants } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

const Header = styled.div`
  ${({ theme: { vars, constants } }) => css`
    color: ${vars.textSubdued};
    font-weight: ${constants.fontSemibold};
    margin-top: ${constants.spacerMd3};
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

    ${fontSm4}
  `}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const t = initTranslations('curriculums.edit.settings');

type Props = {
  curriculumId: number;
  owner: CurriculumOwner | null;
  minutesToRead: number;
  minutesToReadFormatted: string;
  verifyContentStatus: VerifyContentStatus | undefined;
  verifyContentFrequencyInDays: number | null;
  verifiedContentAt: string | undefined;
  nextVerifiedContentAt: string | undefined;
  sector: Sector;
};

const SubjectOverview = ({
  curriculumId,
  owner,
  minutesToRead,
  minutesToReadFormatted,
  verifyContentStatus,
  verifyContentFrequencyInDays,
  verifiedContentAt,
  nextVerifiedContentAt,
  sector,
}: Props) => {
  const { accountPlan } = useCurrentAccount();
  const { teammateModalVisible, teammateConfirmationModalVisible } = useTeammateModal();
  const [showCurriculumModal, setShowCurriculumModal] = useState(false);
  const showVerifyStatusBadge = useShowVerifyStatusBadge({ verifyContentStatus, owner });
  const showAddTeammateWithConfirmation = teammateModalVisible || teammateConfirmationModalVisible;
  const isVerifiedContent = verifyContentStatus === 'verified';

  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();

  return (
    <TeammateModalProvider>
      <OverviewWrapper>
        <Header>{t('overview_header', { curriculum: curriculumSingular })}</Header>
        <Body>
          {accountPlan !== 'build' && (
            <>
              <OwnedByFlyout curriculumId={curriculumId} owner={owner} />
              {showAddTeammateWithConfirmation && <AddTeammateWithConfirmation />}
            </>
          )}
          {minutesToRead > 0 && (
            <TimeEstimatePill id={curriculumId} minutesToReadFormatted={minutesToReadFormatted} />
          )}
          {showVerifyStatusBadge && (
            <AdminVerifyStatusBadge
              curriculumId={curriculumId}
              isVerifiedContent={isVerifiedContent}
              nextVerifyDate={nextVerifiedContentAt}
              owner={owner}
              setShowCurriculumModal={setShowCurriculumModal}
              verifiedDate={verifiedContentAt}
              verifyPeriod={verifyContentFrequencyInDays}
            />
          )}
          {showCurriculumModal && (
            <ContentModal
              advancedSettingsOpen
              closeRequest={() => setShowCurriculumModal(false)}
              curriculumId={curriculumId}
              sector={sector}
            />
          )}
        </Body>
      </OverviewWrapper>
    </TeammateModalProvider>
  );
};

export default SubjectOverview;
