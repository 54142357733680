import styled from 'styled-components';

import { mediaBreakpointPx2xl, mediaBreakpointPxMd } from '../../../styled/Breakpoint';

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};

  @media (max-width: ${mediaBreakpointPxMd}) {
    justify-content: flex-start;
  }

  @media (max-width: ${mediaBreakpointPx2xl}) {
    gap: unset;
  }
`;
