import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import styled, { css, useTheme } from 'styled-components';

import { EmbedlyProvider, useEmbedlyContext } from '../../../../contexts/EmbedlyProvider';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import initTranslations from '../../../../lib/initTranslations';
import { EmbedlyVideo } from '../../../../types/EmbedlyResponse';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import TaskModal from '../../../design_system/overlays/modals/TaskModal';
import { CheckBanner, CheckBannerProps } from '../MarketingSidebar/MarketingSidebar';
import ExternalLinkModalBody from './ExternalLinkModalBody';
import UploadModalBody from './UploadModalBody';

const ChipButtonsWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    gap: ${constants.spacerMd1};
  `
);

// DS Override: Update button styles to match designs - update border color, text color, and background color
const StyledDefaultButton = styled(DefaultButton)<{ $selected: boolean }>(
  ({ theme: { constants, vars }, $selected }) => css`
    width: 100%;
    height: 3.5rem;
    border: ${$selected
      ? `${constants.borderWidthSm} solid ${vars.accentPrimaryDefault}`
      : `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
    border-radius: ${constants.borderRadiusLg};
    color: ${$selected ? vars.accentPrimaryDefault : vars.textDefault};
    background-color: ${$selected ? vars.accentSubdued1 : vars.foundationSurface1};

    &:hover,
    &:focus {
      color: ${$selected ? vars.accentPrimaryDefault : vars.textDefault};
      border: ${constants.borderWidthSm} solid ${vars.accentPrimaryDefault};
    }
  `
);

const CheckBannerWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd2};
    padding-bottom: ${constants.spacerMd3};
    align-items: flex-start;
  `
);

const CheckBannerRow = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    gap: ${constants.spacerMd1};
  `
);

const t = initTranslations('video.video_modal');

type CheckBannerSectionProps = {
  checkBannerOptions: Array<CheckBannerProps>;
};

export const VideoModalCheckBannerSection = ({ checkBannerOptions }: CheckBannerSectionProps) => {
  return (
    <CheckBannerWrapper>
      <CheckBannerRow>
        <CheckBanner {...checkBannerOptions[0]} />
        <CheckBanner {...checkBannerOptions[1]} />
      </CheckBannerRow>
      <CheckBannerRow>
        <CheckBanner {...checkBannerOptions[2]} />
        <CheckBanner {...checkBannerOptions[3]} />
      </CheckBannerRow>
    </CheckBannerWrapper>
  );
};

type SelectedView = 'upload' | 'external-link';

export type Props = {
  onClose: () => void;
  onCreateVideo: (url: string, html: string) => void;
};

const VideoModalComponent = ({ onClose, onCreateVideo }: Props) => {
  const [selectedOption, setSelectedOption] = useState<SelectedView>('upload');
  const theme = useTheme();
  const {
    splitFeatures: { hostedVideoEnabled },
  } = useCurrentAccount();
  const { embedlyData, errorMessage, inputUrl, isFormValid } = useEmbedlyContext();

  // temp need these for beta waitlist
  const [cookies, setCookie] = useCookies(['trainualVideoBetaJoined']);
  const { id: accountId } = useCurrentAccount();
  const { email } = useCurrentUser();

  const VideoModalBodyComponent = {
    upload: <UploadModalBody hostedVideoEnabled={hostedVideoEnabled} />,
    'external-link': <ExternalLinkModalBody />,
  }[selectedOption];

  const buttonText = (() => {
    if (selectedOption === 'upload') {
      // temp if statement for beta waitlist
      if (cookies.trainualVideoBetaJoined) {
        return t('joined_beta_waitlist');
      }
      return hostedVideoEnabled ? t('upload_video') : t('join_beta_waitlist');
    }
    return t('insert_external_link');
  })();

  const buttonAction = () => {
    const videoData = embedlyData as EmbedlyVideo;
    if (selectedOption === 'upload' && !hostedVideoEnabled) {
      // zapier webhook for beta waitlist
      fetch('https://hooks.zapier.com/hooks/catch/19434250/26fn4pa/', {
        method: 'POST',
        body: JSON.stringify({
          email,
          account_id: accountId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setCookie('trainualVideoBetaJoined', 'true', {
              path: '/',
              expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
            });
          } else {
            console.log('Failed to send webhook');
          }
        })
        .catch((error) => {
          console.log('Error sending webhook:', error);
        });
    } else if (inputUrl && videoData?.html) {
      if (inputUrl) onCreateVideo(inputUrl, videoData?.html);
    }
  };

  const isDisabled = () => {
    switch (selectedOption) {
      case 'upload':
        // temp if statement for beta waitlist
        if (cookies.trainualVideoBetaJoined) {
          return true;
        }
        return hostedVideoEnabled ? !isFormValid : false;
      case 'external-link':
        return !inputUrl || !!errorMessage || !isFormValid;
      // TODO: Add more cases here
      // case 'screen-record':
      //   return false;
      default:
        return true; // Handle any unexpected cases
    }
  };

  return (
    <TaskModal
      desktopSize='xl'
      heapModalName='video-modal'
      isDisabled={isDisabled()}
      onCloseRequest={onClose}
      primaryButtonTask={buttonAction}
      primaryButtonText={buttonText}
      processing={false}
      title={t('title')}
    >
      <ChipButtonsWrapper>
        <StyledDefaultButton
          $selected={selectedOption === 'upload'}
          buttonType='secondary'
          iconColor={
            selectedOption === 'upload' ? theme.vars.accentPrimaryDefault : theme.vars.textDefault
          }
          iconName='upload'
          id='video-modal-upload'
          onClick={() => {
            setSelectedOption('upload');
          }}
          text={t('upload_chip_label')}
        />
        <StyledDefaultButton
          $selected={selectedOption === 'external-link'}
          buttonType='secondary'
          iconColor={
            selectedOption === 'external-link'
              ? theme.vars.accentPrimaryDefault
              : theme.vars.textDefault
          }
          iconName='link'
          id='video-modal-external-link'
          onClick={() => {
            setSelectedOption('external-link');
          }}
          text={t('external_link_chip_label')}
        />
      </ChipButtonsWrapper>
      {VideoModalBodyComponent}
    </TaskModal>
  );
};

const VideoModal = (props: Props) => (
  <EmbedlyProvider>
    <VideoModalComponent {...props} />
  </EmbedlyProvider>
);

export default VideoModal;
