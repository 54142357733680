import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { DefaultCollapsedLogo } from '../../../../lib/gcsImages';
import hexToRGBA from '../../../../lib/HexToRGBA';
import Icon from '../../../design_system/display/icons/Icon';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import { routes } from '../../publicApplication/applicationRouter';
import RouterLink from '../../publicApplication/RouterLink';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  width: 80px;
`;

const Sidebar = styled.div(
  ({ theme: { vars } }) => css`
    flex-grow: 1;
    display: flex;
    background-color: ${vars.foundationSurface1};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }
  `
);

const ToggleButton = styled.button`
  z-index: 2;
  position: absolute;
  top: 13px;
  right: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { constants } }) => constants.heightSm};
  height: ${({ theme: { constants } }) => constants.heightSm};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: none;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  &:hover {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }

  &:before {
    content: '';
    width: 19px;
    height: 42px;
    background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
    position: absolute;
    top: -5px;
    left: -3px;
  }

  &:focus {
    outline: none;
  }

  svg {
    position: relative;
    z-index: 2;
  }
`;

const SidebarContainer = styled.div(
  ({ theme: { vars } }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${vars.foundationSurface1};
  `
);

const LogoWrapper = styled.div`
  @media (min-width: ${mediaBreakpointPxXl}) {
    padding: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const LogoWithRoute = styled(RouterLink)<{ logoBackgroundColor: string | null }>(
  ({ logoBackgroundColor }) => css`
    display: flex;
    width: 100%;
    max-width: 13.5rem;
    height: 3.75rem;
    align-items: center;
    margin-right: 0;
    position: relative;
    z-index: 2;

    ${logoBackgroundColor && { backgroundColor: logoBackgroundColor }};

    @media (min-width: ${mediaBreakpointPxXl}) {
      transition: all 0.5s ease;
      padding: ${({ theme }) => theme.constants.spacerSm2};
    }
  `
);

const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

const MenuOptionsWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${constants.spacerMd2};
    background-color: ${vars.foundationSurface1};
    flex-grow: 1;
    padding: ${constants.spacerSm3} ${constants.spacerMd2} ${constants.spacerMd2};
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  `
);

const PlaybookItemContainer = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    font-weight: ${constants.fontMedium};
    justify-content: center;
    align-items: center;
    border-radius: ${constants.borderRadiusCircle};
    color: ${vars.accentPrimaryDefault};
    background-color: ${hexToRGBA(vars.accentPrimaryDefault, 0.05)};
    width: 2.5rem;
    height: 2.5rem;
  `
);

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MockPlaybookItem = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    font-weight: ${constants.fontMedium};
    justify-content: flex-start;
    border-radius: ${constants.borderRadiusCircle};
    height: 2.5rem;
    width: 2.5rem;
  `
);

const MockPlaybookItem1 = styled(MockPlaybookItem)(
  ({ theme: { vars } }) => css`
    background-color: ${vars.foundationBase3};
  `
);

const MockPlaybookItem2 = styled(MockPlaybookItem)(
  ({ theme: { vars } }) => css`
    background-color: ${vars.foundationBase2};
  `
);

const MockPlaybookItem3 = styled(MockPlaybookItem)(
  ({ theme: { vars } }) => css`
    background-color: ${vars.foundationBase1};
  `
);

const MockSideNav = () => {
  const { logoUrl, withDefaultLogo, logoBackgroundColor, slug } = useCurrentAccount();

  const logoSrc = useMemo(() => {
    return withDefaultLogo ? DefaultCollapsedLogo : logoUrl;
  }, [logoUrl, withDefaultLogo]);

  return (
    <Container>
      <Sidebar>
        <ToggleButton>
          <Icon name='chevron-right' size='2xs' weight='solid' />
        </ToggleButton>
        <SidebarContainer>
          <LogoWrapper id='sidebar-logo-wrapper'>
            <LogoWithRoute logoBackgroundColor={logoBackgroundColor} to={routes.home({ slug })}>
              <StyledImage src={logoSrc} />
            </LogoWithRoute>
          </LogoWrapper>
          <MenuOptionsWrapper>
            <PlaybookItemContainer>
              <IconWrapper>
                <Icon name='house-chimney' size='sm' weight='regular' />
              </IconWrapper>
            </PlaybookItemContainer>
            <MockPlaybookItem1 />
            <MockPlaybookItem2 />
            <MockPlaybookItem3 />
          </MenuOptionsWrapper>
        </SidebarContainer>
      </Sidebar>
    </Container>
  );
};

export default MockSideNav;
