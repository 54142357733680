import { TiptapCollabProvider } from '@hocuspocus/provider';
import { watchPreviewContent } from '@tiptap-pro/extension-collaboration-history';
import { Editor as TiptapEditor } from '@tiptap/react';
import { useEffect } from 'react';

interface Props {
  editor: TiptapEditor | null;
  provider: TiptapCollabProvider | null;
}

/**
 * Custom React hook to unbind changes in the editor to the provider.
 * Use this to enable previewing versions without mutating the content.
 * Without this hook, the content in the collaboration server would be updated.
 *
 * @param {Props} editor - The editor instance to watch for changes.
 * @param {Props} provider - The provider instance to provide content changes.
 * @returns {void}
 */
const useContentWatcher = ({ editor, provider }: Props): void => {
  useEffect(() => {
    if (!editor || !provider) return;

    const unbindContentWatcher = watchPreviewContent(provider, (content) =>
      editor.commands.setContent(content)
    );

    return () => {
      unbindContentWatcher();
    };
  }, [editor, provider]);
};

export default useContentWatcher;
