import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  SectionDivider,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.general_settings');

const RestrictToSSO = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <>
      <SectionDivider />
      <ToggleSettingContainer>
        <StyledToggle
          checked={values.ssoOnlyAccount}
          className='sso-only-account-setting'
          handleToggle={() => setFieldValue('ssoOnlyAccount', !values.ssoOnlyAccount)}
          id='sso-only-account-setting'
          name='ssoOnlyAccount'
        />
        <ToggleInfoSection>
          <ToggleInfoWrapper>
            <FormSectionTitle>{t('sso_only_account_title')}</FormSectionTitle>
            <FormSectionDescription>{t('sso_only_account_description')}</FormSectionDescription>
          </ToggleInfoWrapper>
        </ToggleInfoSection>
      </ToggleSettingContainer>
    </>
  );
};

export default RestrictToSSO;
