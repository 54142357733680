import React, { useReducer } from 'react';

import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useLogoutMutation } from '../../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import CollapsibleSurface from '../../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import routeTo from '../../../publicApplication/routeTo';
import { FieldDescription, FormSectionContainer, FormSectionWrapper } from '../Styles';

const t = initTranslations('settings');

const DeviceSettings = () => {
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);
  const { resetAnalyticsUser } = useAnalyticsUserEvents();
  const [logout, result] = useLogoutMutation();

  useDisplayFlashOnResponse({
    result,
    successFunction: () => {
      resetAnalyticsUser();
      result.data && result.data.redirect_url && routeTo(result.data.redirect_url);
    },
    errorMessage: messageFromError(result.error)?.join(', '),
  });

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='device-settings-surface'
      isCollapsed={collapsed}
      title={t('devices.title')}
    >
      <FormSectionWrapper>
        <FormSectionContainer>
          <FieldDescription>{t('devices.sign_out_of_all_devices.description')}</FieldDescription>
          <DefaultButton
            buttonType='primary'
            id='sign-out-from-all-devices-button'
            onClick={() => logout({ logout_all_sessions: true })}
            text={t('devices.sign_out_of_all_devices.button')}
          />
        </FormSectionContainer>
      </FormSectionWrapper>
    </CollapsibleSurface>
  );
};

export default DeviceSettings;
