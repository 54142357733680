import { truncate } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { CompletionsEmptyState, FiltersEmptyStateLight } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import {
  changePage,
  selectAllUsers,
  setAllIds,
  setSearchValue,
  toggleUserSelect,
} from '../../../../../redux/domains/curriculumCompletions/curriculumCompletionsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useUpdateCompletionsMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CompletionAction } from '../../../../../redux/services/resourceApis/curriculums/types';
import { Assignment, ManageCompletionResponse } from '../../../../../types/Curriculum';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Avatar from '../../../../design_system/display/avatar';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import Checkbox from '../../../../design_system/input/controls/Checkbox';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import SourceBadge from '../../../../design_system/navigation/SourceBadge/SourceBadge';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import Scrollbar from '../../../../styled/Scrollbar';
import { fontMd1, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';
import ProfileRouterLink from '../../../people/Profile/ProfileRouterLink';
import SuperShareModal from '../../../people/SuperShare/SuperShareModal/SuperShareModal';
import { routes } from '../../../publicApplication/applicationRouter';
import ESignatureStatus from '../../../shared/ESignatureStatus/ESignatureStatus';
import { NameText, NameTitleWrapper } from '../../CurriculumEdit/ManageCompletions/styles';
import NameHeader from './NameHeader';

const CurriculumCompletionWrapper = styled.div``;

const TableWrapper = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  position: relative;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  ${Scrollbar};

  @media (min-width: ${mediaBreakpointPxMd}) {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }
`;

const StyledTable = styled.table`
  width: 100%;
  overflow: hidden;
  border-collapse: collapse;
`;

const CheckboxWrapper = styled.div`
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 12rem;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const ProgressBarWrapper = styled.div`
  width: 8.75rem;
`;

const ProgressPercentage = styled.span`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
  width: 3rem;
  justify-content: flex-end;
  ${fontMd1};
`;

const SharedCellStyling = css`
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
`;

const NameCellWidth = css`
  width: 21rem;
`;

const CompletionScoreCellWidth = css`
  width: 13rem;
`;

const CompletionActionCellWidth = css`
  width: 18rem;
`;

const ESignatureCellWidth = css`
  width: 10rem;
`;

const DataCell = styled.td`
  ${SharedCellStyling};
`;

const NameDataCell = styled.td`
  ${NameCellWidth};
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd2};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  min-width: ${({ theme: { constants } }) => constants.spacerSm3};

  box-shadow: none;
`;

const NoResultsDiv = styled.div`
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const NameDataWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SharedViaDataCell = styled(DataCell)`
  width: fit-content;
  max-width: fit-content;
  padding: 0;
`;

const CompletionScoreDataCell = styled(DataCell)`
  ${CompletionScoreCellWidth};
`;

const ESignatureDataCell = styled(DataCell)`
  ${ESignatureCellWidth};
`;

const CompletionActionDataCell = styled(DataCell)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd3};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const HeaderCell = styled.th`
  ${SharedCellStyling};
`;

const SharedViaHeaderCell = styled(HeaderCell)`
  width: fit-content;
  max-width: fit-content;
  padding: 0;
`;

const CompletionScoreHeaderCell = styled(HeaderCell)`
  ${CompletionScoreCellWidth};
`;

const ESignatureDataCellHeaderCell = styled(HeaderCell)`
  ${ESignatureCellWidth};
`;

const CompletionActionHeaderCell = styled(HeaderCell)`
  ${CompletionActionCellWidth};
`;

const AvatarWrapper = styled(ProfileRouterLink)`
  padding-right: ${({ theme }) => theme.constants.spacerSm3};
`;

const HeaderRow = styled.tr`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  text-align: left;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  ${fontSm4};
`;

const DataRow = styled.tr`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  height: 3.5rem;
  text-align: left;
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};

  &:last-of-type {
    border-bottom: unset;
  }

  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationBase1};
  }

  ${fontSm5};
`;

const t = initTranslations('curriculum_edit.manage_completions');

const individualOrGroup = (assignment: Assignment) => {
  return assignment?.type === 'User' ? t('table.individual') : assignment?.name;
};

type SetupSharedViaSourceBadge = {
  assignments: Assignment[];
};

const setupSharedViaSourceBadge = ({ assignments }: SetupSharedViaSourceBadge) => {
  if (assignments.length === 0) {
    return '';
  }

  let sourceText = individualOrGroup(assignments[0]);

  if (assignments.length > 1) {
    sourceText = truncate(sourceText, { length: 30 }) + ` + ${assignments.length - 1}`;
  }

  return sourceText;
};

interface Props {
  curriculumId: number;
  data: ManageCompletionResponse | undefined;
  isLoading: boolean;
}

const CurriculumCompletions = ({ curriculumId, isLoading, data }: Props) => {
  const { slug, hasESignatureFeature } = useCurrentAccount();
  const {
    curriculum: { singular: curriculumSingular },
  } = useAccountTerminology();

  const dispatch = useAppDispatch();
  const { page, searchValue, selectedUsers, allUsersSelected, searchClear } = useAppSelector(
    (state) => state.curriculumCompletions
  );

  const [showSuperShareModal, setShowSuperShareModal] = useState(false);

  const [updateCompletions, result] = useUpdateCompletionsMutation();
  const handleSingleActionSelection = useCallback(
    (action: CompletionAction, userId: number) => {
      updateCompletions({
        id: curriculumId,
        userIds: [userId],
        actionType: action,
        search: searchValue,
        page,
      });
    },
    [curriculumId, page, searchValue, updateCompletions]
  );

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('table.error_message'),
    successFunction: () => {
      result.reset();
      dispatch(selectAllUsers(false));
    },
  });

  useEffect(() => {
    if (data?.allUserIds) {
      dispatch(selectAllUsers(false));
      dispatch(setAllIds(data.allUserIds));
    }
  }, [data?.allUserIds, dispatch]);

  if (isLoading) return <LoadingContainer />;
  if (!data) return null;

  const { users, signaturesRequired, limitValue, totalPages, totalUserCount } = data;
  const emptySearchResults = users.length === 0;
  const noAssignedUsers = !searchValue && users.length === 0;
  const showESignatureColumn = hasESignatureFeature && signaturesRequired;

  if (noAssignedUsers && !searchClear) {
    return (
      <NoResultsDiv>
        <NoResults
          darkImage={CompletionsEmptyState}
          heading={t('no_access', { subject: curriculumSingular })}
          iconWidth='75%'
          lightImage={CompletionsEmptyState}
          minHeight='unset'
          showBorder={false}
          subHeaderCta={{
            action: () => setShowSuperShareModal(true),
            text: t('add_teammates'),
          }}
        />
        {showSuperShareModal && (
          <SuperShareModal
            curriculumId={curriculumId}
            setShowSuperShareModal={setShowSuperShareModal}
            showSuperShareModal={showSuperShareModal}
          />
        )}
      </NoResultsDiv>
    );
  }

  return (
    <CurriculumCompletionWrapper className='curriculum-completions'>
      <TableWrapper id='curriculum-completions-table'>
        <StyledTable>
          <thead>
            <HeaderRow>
              <NameHeader
                bulkChecked={allUsersSelected}
                handleBulkCheckboxChange={() => dispatch(selectAllUsers(!allUsersSelected))}
              />
              <SharedViaHeaderCell>{t('table.shared_via_header')}</SharedViaHeaderCell>
              <CompletionScoreHeaderCell>
                {t('table.completion_score_header')}
              </CompletionScoreHeaderCell>
              {showESignatureColumn && (
                <ESignatureDataCellHeaderCell>
                  {t('table.e_signatures_header')}
                </ESignatureDataCellHeaderCell>
              )}
              <CompletionActionHeaderCell></CompletionActionHeaderCell>
            </HeaderRow>
          </thead>
          <tbody>
            {users.map((user) => (
              <DataRow id={`manage-completions-row-${user.id}`} key={user.id}>
                <NameDataCell>
                  <NameDataWrapper>
                    <CheckboxWrapper id={`manage-completions-cell-checkbox-${user.id}`}>
                      <Checkbox
                        checked={selectedUsers.includes(user.id)}
                        disabled={signaturesRequired}
                        id={`manage-completions-checkbox-${user.id}`}
                        name='name-header-checkbox'
                        onCheck={() => {
                          dispatch(toggleUserSelect(user.id));
                        }}
                        tooltipHoverCheckbox={signaturesRequired}
                        tooltipId={`manage-completions-checkbox-${user.id}`}
                        tooltipText={t('table.disabled_by_e_signature')}
                      />
                    </CheckboxWrapper>
                    <AvatarWrapper breadcrumb={routes.manageUsers({ slug })} id={user.id}>
                      <Avatar image={user.avatar} name={user.name} shape='circle' size='sm' />
                    </AvatarWrapper>
                    <NameTitleWrapper id={`manage-completions-name-title-${user.id}`}>
                      <NameText>{user.name}</NameText>
                    </NameTitleWrapper>
                  </NameDataWrapper>
                </NameDataCell>
                <SharedViaDataCell>
                  <Tooltip
                    content={[
                      individualOrGroup(user.assignments[0]),
                      ...user.assignments
                        .slice(1)
                        .map((assignment) => individualOrGroup(assignment)),
                    ].join(', ')}
                    id={`shared-via-tooltip-${user.id}`}
                  />
                  {user.assignments[0]?.type === 'User' ? (
                    <SourceBadge
                      id={`shared-via-pill-${user.id}`}
                      readOnly
                      sourceImageUrl={user.avatar}
                      sourceName='users'
                      sourceText={setupSharedViaSourceBadge({ assignments: user.assignments })}
                      tooltipId={`shared-via-tooltip-${user.id}`}
                      userName={user.name}
                    />
                  ) : (
                    <SourceBadge
                      id={`shared-via-pill-${user.id}`}
                      readOnly
                      sourceName='group'
                      sourceText={setupSharedViaSourceBadge({ assignments: user.assignments })}
                      tooltipId={`shared-via-tooltip-${user.id}`}
                    />
                  )}
                </SharedViaDataCell>
                <CompletionScoreDataCell id={`completions-score-data-cell-${user.id}`}>
                  <ProgressBarContainer className='progress-bar-container'>
                    <ProgressBarWrapper>
                      <ProgressBar percent={user.completion.score} thickness='lg' />
                    </ProgressBarWrapper>
                    <ProgressPercentage>{user.completion.score}%</ProgressPercentage>
                  </ProgressBarContainer>
                </CompletionScoreDataCell>
                {showESignatureColumn && (
                  <ESignatureDataCell id={`e-signature-data-cell-${user.id}`}>
                    <ESignatureStatus
                      curriculumId={curriculumId}
                      eSignature={user.eSignature}
                      kind='by_user'
                      userId={user.id}
                    />
                  </ESignatureDataCell>
                )}
                <CompletionActionDataCell id={`completions-action-data-cell-${user.id}`}>
                  {signaturesRequired && (
                    <Tooltip
                      id={`manage-completions-actions-tooltip-${user.id}`}
                      text={t('table.disabled_by_e_signature')}
                    />
                  )}
                  <div
                    data-for={`manage-completions-actions-tooltip-${user.id}`}
                    data-tip={signaturesRequired}
                  >
                    <DefaultButton
                      buttonType='tertiary'
                      disabled={signaturesRequired}
                      iconName='check'
                      id={`manage-completions-page-complete-button-${user.id}`}
                      onClick={() => handleSingleActionSelection('complete', user.id)}
                      text={t('action_menu.complete')}
                    />
                  </div>
                  <div
                    data-for={`manage-completions-actions-tooltip-${user.id}`}
                    data-tip={signaturesRequired}
                  >
                    <DefaultButton
                      buttonType='tertiary'
                      disabled={signaturesRequired}
                      iconName='circle-xmark'
                      id={`manage-completions-page-clear-button-${user.id}`}
                      onClick={() => handleSingleActionSelection('clear', user.id)}
                      text={t('action_menu.clear')}
                    />
                  </div>
                </CompletionActionDataCell>
              </DataRow>
            ))}
          </tbody>
        </StyledTable>
        {emptySearchResults && (
          <NoResults
            darkImage={CompletionsEmptyState}
            heading={t('no_results')}
            iconWidth='25%'
            lightImage={FiltersEmptyStateLight}
            minHeight='unset'
            showBorder={false}
            subHeaderCta={{
              action: () => dispatch(setSearchValue('')),
              text: t('clear_search'),
            }}
          />
        )}
      </TableWrapper>
      {totalPages > 1 && (
        <Pagination
          activePage={page}
          itemsCountPerPage={limitValue}
          onChange={(page) => {
            dispatch(changePage(page));
          }}
          showPaginationDetails
          totalItemsCount={totalUserCount}
          totalPages={totalPages}
        />
      )}
    </CurriculumCompletionWrapper>
  );
};

export default CurriculumCompletions;
