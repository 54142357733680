import { AccountEditorState } from './AccountState';
import { Completion } from './Completion';
import { Course, CourseWithSteps } from './Course';
import { CurriculumElement } from './CurriculumElement';
import { Emoji } from './Emoji';
import { ESignatureInfo } from './ESignatureStatus';
import { Sector } from './Sector';
import { AccessControl, accessLevels } from './SuperShare';
import { SimpleUser } from './User';

export const CURRICULUM_DESCRIPTION_MAX_CHARACTERS = 500;

type AdvancedSettingsResponse = {
  completion_certificates: boolean;
  completion_required: boolean;
  disable_translation: boolean;
  display_owner: boolean;
  expiring_completion: boolean;
  forced_order: boolean;
  verify_content: boolean;
};

export type PublicCurriculum = {
  active: boolean;
  copy_curriculum_enabled: boolean;
  id: string;
  curriculum_id: number;
};

export type Curriculum = {
  accessControl: AccessControl;
  completion_required?: boolean;
  element_kind?: 'curriculum';
  id: number;
  owner: string | null;
  position?: number;
  public_curriculum: PublicCurriculum | null;
  published: boolean;
  locked: boolean;
  sector: Sector;
  title: string;
};

export type DetailedCurriculum = {
  access_control: AccessControl;
  advanced_settings: AdvancedSettingsResponse;
  archived: boolean;
  completion_expiration?: string;
  completion_percentage: number;
  completion_required?: boolean;
  curriculum_elements: CurriculumElement[];
  description?: string;
  element_kind?: 'curriculum';
  emoji: Emoji;
  expiring_completion: boolean;
  favorited?: boolean;
  id: number;
  locked: boolean;
  minutes_to_read: number;
  minutes_to_read_formatted: string;
  move_to_modal_data?: string;
  overdue: boolean;
  owner: CurriculumOwner | null;
  position?: number;
  published: boolean;
  sector: Sector;
  title: string;
  updated_at: string;
};

export type CardViewCurriculum = {
  id: number;
  title: string;
  sector: Sector;
  locked: boolean;
  archived: boolean;
  favorite: boolean;
  published: boolean;
  description: string;
  emoji: Emoji;
  assignedUsers?: SimpleUser[];
  assignedUsersCount: number;
  completionPercentage: number;
  expiringCompletion: boolean;
  completionRequired: boolean;
  updatedAt: string;
  expiresAt: number | null;
  dueDate: number | null;
  minutesToRead: string;
  contentAccess: 'edit' | 'manage' | 'view' | 'owner' | null;
};

export type LibraryCurriculum = {
  id: number;
  title: string;
  sector: Sector;
  description: string;
  emoji: string;
  disableRequest: boolean;
  minutesToRead: string;
  completionRequired: boolean;
};

export type SharedCurriculum = {
  account: AccountEditorState;
  copy_curriculum_enabled: boolean;
  courses: CourseWithSteps[];
  description: string | null;
  emoji: Emoji;
  public_curriculum_id: string;
} & Curriculum;

export type SimpleCurriculum = {
  id: number;
  title: string;
  disableTranslation: boolean;
};

export type CurriculumsAndTopics = SimpleCurriculum & {
  courses: Pick<Course, 'id' | 'title'>[];
};

type CurriculumExtendedResponse = {
  advanced_settings: AdvancedSettingsResponse;
  signatures_required: boolean;
  description: string | null;
  due_date: number | null;
  expiring_completion_in_days_after_completed: number | null;
  owner_id: number | null;
  user_permission: string;
  verify_content_frequency_in_days: number | null;
};

export type CurriculumShowResponse = Curriculum & CurriculumExtendedResponse;

export type CurriculumOwner = {
  id: number;
  initials: string;
  name: string;
  title: string;
  archived?: boolean;
  avatar?: string;
};

const accessArray = [
  ...accessLevels.filter((access) => access !== 'remove'),
  'owner',
  'assigned',
  'discoverable',
  'pending',
  null,
] as const;
export type MetaUserAccess = (typeof accessArray)[number];

export type ContentLibraryCurriculum = {
  id: number;
  title: string;
  sector: Sector;
  published: boolean;
  groupsCount: number;
  owner: CurriculumOwner | null;
  updatedAt: string;
  archived: boolean;
  signaturable: boolean;
  description: string | undefined;
  emoji: Emoji;
  locked: boolean;
  favorited: boolean;
  assignmentId?: number;
  userAccess?: MetaUserAccess;
  userCanEdit: boolean;
  canBeMoved: boolean;
  canAssignUsers: boolean;
  disableTranslation?: boolean;
};

export type TrainingOrderCurriculum = Pick<
  ContentLibraryCurriculum,
  'id' | 'title' | 'sector' | 'owner' | 'description' | 'emoji'
> & {
  groupsCount: number;
};

export type BulkCurriculumActionParams = {
  ids: number[];
};

export type SimpleUserWithCompletion = SimpleUser & {
  completion: Completion;
  eSignature: ESignatureInfo;
};

export type TCurriculumAssignee = 'User' | 'Group';

export type Assignment = {
  id: number;
  type: TCurriculumAssignee;
  name: string;
};

type SimpleUserWithCompletionAndAssignments = SimpleUserWithCompletion & {
  assignments: Assignment[];
};

export type ManageCompletionResponse = {
  id: number;
  title: string;
  signaturesRequired: boolean;
  users: SimpleUserWithCompletionAndAssignments[];
  allUserIds: number[];
  limitValue: number;
  totalPages: number;
  totalUserCount: number;
};
