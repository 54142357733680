import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../../lib/initTranslations';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import { fontMd5, fontSm4 } from '../../../../../../../styled/TypeSystem';

const t = initTranslations('curriculums.ai_outliner.slideout.cta');

const Text = styled.p`
  margin-block: 0;
  margin-inline: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerSm3}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-align: left;
  ${fontSm4};
  line-height: 1.188rem;
`;

const Emoji = styled.div`
  background: transparent;
  ${fontMd5};
`;

const CTABanner = styled.button<{ hasCustomizations: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd1}`};
  border: ${({ theme: { constants } }) => `${constants.borderWidthSm} solid transparent`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ hasCustomizations, theme: { vars } }) =>
    hasCustomizations ? vars.trainualBrandBlueSurfaceLight : vars.trainualBrandGreenSurfaceLight};
  &:hover {
    cursor: pointer;
    border-color: ${({ theme: { vars }, hasCustomizations }) =>
      hasCustomizations ? vars.trainualBrandBlueSurface : vars.trainualBrandGreenSurface};
    svg {
      color: ${({ theme: { vars }, hasCustomizations }) =>
        hasCustomizations ? vars.trainualBrandBlueMedium : vars.trainualBrandGreenStrong};
    }
  }
`;

export type Props = {
  hasCustomizations: boolean;
  onClick: () => void;
};

const ContextCTA = ({ hasCustomizations, onClick }: Props) => {
  return (
    <CTABanner hasCustomizations={hasCustomizations} onClick={onClick}>
      <Emoji id='context-cta-emoji'>{hasCustomizations ? '⚡️' : '🤔'}</Emoji>
      <Text id='context-cta-text'>
        {hasCustomizations ? t('customized_text') : t('default_text')}
      </Text>
      <Icon className='context-cta-arrow' name='arrow-right' />
    </CTABanner>
  );
};

export default ContextCTA;
