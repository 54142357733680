import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { FC, useCallback, useEffect, useState } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { EditorInputWrapper, Title, TranslatedText } from './styles';
import { EditorInputProps } from './types';

const EditorInput: FC<EditorInputProps> = ({
  $isError,
  value,
  onBeforeInput,
  onBlur,
  onChange,
  spellCheck = false,
  className,
  disabled = false,
}) => {
  const { hasMultiLanguageFeature } = useCurrentAccount();
  const [isTranslatable, setIsTranslatable] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
    onUpdate: ({ editor }) => {
      onChange(editor.getText());
    },
    editorProps: {
      attributes: {
        class: 'editor-input',
        translate: isTranslatable ? 'true' : 'no',
      },
      handleKeyDown(view, event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          return true;
        }
        return false;
      },
    },
  });

  const handleInputClick = useCallback(() => {
    setIsTranslatable(false);
    editor?.commands.focus();
  }, [editor]);

  useEffect(() => {
    if (editor && editor.getText() !== value) {
      editor.commands.setContent(value, false);
    }
  }, [value, editor]);

  useEffect(() => {
    if (hasMultiLanguageFeature) setIsTranslatable(!isFocused);
  }, [isFocused, hasMultiLanguageFeature]);

  return (
    <>
      {disabled ? (
        <Title>{value}</Title>
      ) : hasMultiLanguageFeature && isTranslatable ? (
        <EditorInputWrapper
          $isError={$isError}
          inputMode='text'
          isFocused={isFocused}
          onClick={handleInputClick}
        >
          <TranslatedText>{value}</TranslatedText>
        </EditorInputWrapper>
      ) : (
        <EditorInputWrapper
          $isError={$isError}
          inputMode='text'
          isFocused={isFocused}
          onBeforeInput={onBeforeInput}
          onBlur={onBlur}
          spellCheck={spellCheck}
        >
          <EditorContent className={className} editor={editor} />
        </EditorInputWrapper>
      )}
    </>
  );
};

export default EditorInput;
