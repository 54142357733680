import React, { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useCreateDocumentMutation } from '../../../../redux/services/resourceApis/documents/documentsApi';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import StepContentSkeleton from '../components/StepContentSkeleton/StepContentSkeleton';
import { TiptapCollaborationProvider } from '../contexts/TiptapCollaboration';
import { convertContentJson } from '../shared/helpers';
import { BaseContentWrapper } from '../shared/styles';
import ToolbarSkeleton from './components/EditModeSkeleton/ToolbarSkeleton/ToolbarSkeleton';
import EditorContent from './EditorContent';

type Props = {
  stepData: GetStepResponse;
};

const DocumentFactory = ({ stepData }: Props) => {
  const { palettes } = useTheme();

  const { id: stepId, content, documentMigratedAt } = stepData;
  const documentName = `stepDocument.${stepId}`;

  const convertedContent = useMemo(
    () => convertContentJson(content, palettes),
    [content, palettes]
  );

  const [createDocumentTrigger, { isLoading }] = useCreateDocumentMutation();

  useEffect(() => {
    if (!documentMigratedAt) {
      createDocumentTrigger({ id: documentName, content: convertedContent });
    }
  }, [convertedContent, createDocumentTrigger, documentMigratedAt, documentName]);

  if (isLoading) {
    return (
      <BaseContentWrapper>
        <ToolbarSkeleton />
        <StepContentSkeleton editMode />;
      </BaseContentWrapper>
    );
  }

  return (
    <TiptapCollaborationProvider stepId={stepId}>
      <EditorContent stepData={stepData} />
    </TiptapCollaborationProvider>
  );
};

export default DocumentFactory;
