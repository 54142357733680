import React, { useEffect } from 'react';

import { useCloseFullScreenOverlay } from '../../../../../../hooks/billing/useCloseFullScreenOverlay';
import { usePlanBrandColors } from '../../../../../../hooks/billing/usePlanBrandColors';
import { usePlanTitle } from '../../../../../../hooks/billing/usePlanTitle';
import { useSimplePricingFullScreenOverlay } from '../../../../../../hooks/billing/useSimplePricingFullScreenOverlay';
import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import { checkCreditCardData } from '../../../../../../lib/billing/checkCreditCardData';
import { setQueryParamsFromResponse } from '../../../../../../lib/billing/setQueryParamsFromResponse';
import { GoYearlyAndSaveImage } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import { FetchUpcomingSimplePricingInvoiceDataResponse } from '../../../../../../redux/services/resourceApis/billing/simplePricingTypes';
import { useUpdateSubscriptionMutation } from '../../../../../../redux/services/resourceApis/subscriptions/subscriptionsApi';
import { FullScreenOverlayStorybookProps } from '../../../../../../types/FullScreenOverlay';
import { SimplePricingFullScreenOverlayRoute } from '../../../../../../types/SimplePricing';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { useFlashNotification } from '../../../../../FlashNotificationContext';
import { routes } from '../../../../publicApplication/applicationRouter';
import routeTo from '../../../../publicApplication/routeTo';
import DiscountsSection from '../../../../shared/DiscountsSection/DiscountsSection';
import {
  MainSectionContent,
  MainSectionWrapper,
  OverlayContentHeaderTitle,
  OverlayContentWrapper,
} from '../../../../shared/FullScreenOverlay/styles';
import CardDetailsSection from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSection';
import CardDetailsSkeleton from '../../../shared/BillingFullScreenOverlay/CardDetailsSection/CardDetailsSectionSkeleton/CardDetailsSectionSkeleton';
import NextPayment from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPayment';
import NextPaymentSkeleton from '../../../shared/BillingFullScreenOverlay/NextPayment/NextPaymentSkeleton/NextPaymentSkeleton';
import PlanCardsSkeleton from '../../../shared/BillingFullScreenOverlay/PlanCards/PlanCardsSkeleton/PlanCardsSkeleton';
import {
  ButtonsGroup,
  PaymentSummaryFooterWrapper,
  PaymentSummaryWrapper,
} from '../../../shared/BillingFullScreenOverlay/styles';
import SubmitButton from '../../../shared/BillingFullScreenOverlay/SubmitButton/SubmitButton';
import SummarySkeleton from '../../../shared/BillingFullScreenOverlay/Summary/SummarySkeleton/SummarySkeleton';
import BillingIntervalToggle from '../../../shared/BillingIntervalToggler/BillingIntervalToggle';
import PlanCards from './PlanCards/PlanCards';
import { BillingIntervalWrapper, NotificationPlan, NotificationTitle, SavingsImg } from './styles';
import Summary from './Summary/Summary';

export type UpgradePlanOverlayContentProps = {
  route: SimplePricingFullScreenOverlayRoute;
  currentDate?: Date;
} & FullScreenOverlayStorybookProps<FetchUpcomingSimplePricingInvoiceDataResponse>;

const t = initTranslations('simple_pricing.upgrade_plan_overlay_content');

const UpgradePlanOverlayContent = ({
  route,
  currentDate = new Date(),
  isStorybookEnvironment,
  mockData,
}: UpgradePlanOverlayContentProps) => {
  const fullScreenOverlayState = useSimplePricingFullScreenOverlay({
    route,
    isStorybookEnvironment,
    mockData,
  });

  const closeFullScreenOverlay = useCloseFullScreenOverlay();
  const {
    upcomingInvoiceData,
    isLoadingOrFetching,
    isAnnualInterval,
    showDiscounts,
    setSelectedBillingInterval,
    selectedBillingInterval,
    selectedPlanName,
    slug,
  } = fullScreenOverlayState;
  const { flash } = useFlashNotification();
  const { color: planBrandColor } = usePlanBrandColors({ name: selectedPlanName });
  const planTitle = usePlanTitle({ name: selectedPlanName });
  const isAvailableOverlayData = !isLoadingOrFetching && !!upcomingInvoiceData;
  const hasCardDetails = checkCreditCardData(upcomingInvoiceData?.billingDetails.cardDetails);
  const isDifferentPlans =
    isAvailableOverlayData &&
    upcomingInvoiceData.currentPlan.stripePlanId !== upcomingInvoiceData.newPlan.stripePlanId;
  const [updateSubscription, updateSubscriptionResult] = useUpdateSubscriptionMutation();
  const updateSubscriptionIsLoading = updateSubscriptionResult.isLoading;
  const isLoadingSubmitButton = isLoadingOrFetching || updateSubscriptionIsLoading;
  const isDisabledSubmitButton =
    !isDifferentPlans || updateSubscriptionIsLoading || !hasCardDetails;
  const handleSubmit = () => {
    !isDisabledSubmitButton &&
      isAvailableOverlayData &&
      updateSubscription({
        plan: upcomingInvoiceData.newPlan.name,
        interval: selectedBillingInterval,
        totalAmount: upcomingInvoiceData.newPlan.dueToday,
      });
  };

  useEffect(() => {
    setQueryParamsFromResponse({ selectedBillingInterval });
  }, [selectedBillingInterval]);

  useDisplayFlashOnResponse({
    result: updateSubscriptionResult,
    successFunction: () => {
      flash('info', undefined, {
        customTitle: (
          <NotificationTitle>
            {t('flash.success')}
            <NotificationPlan color={planBrandColor}>{planTitle}</NotificationPlan>
          </NotificationTitle>
        ),
      });
      routeTo(routes.billing({ slug }));
    },
    errorMessage: t('flash.error'),
  });

  // return if user doesn't have access to this overlay
  if (!fullScreenOverlayState) return <></>;

  return (
    <OverlayContentWrapper>
      <MainSectionWrapper>
        <MainSectionContent>
          <OverlayContentHeaderTitle>{t('header.title')}</OverlayContentHeaderTitle>
          <BillingIntervalWrapper>
            <SavingsImg
              alt={t('billing_interval_toggle.alt_annual_savings')}
              src={GoYearlyAndSaveImage}
            />
            <BillingIntervalToggle
              handleChange={setSelectedBillingInterval}
              isAnnualChecked={isAnnualInterval}
              isAnnualValueFirst={false}
              isLoading={isLoadingOrFetching}
            />
          </BillingIntervalWrapper>
          {isAvailableOverlayData ? (
            <PlanCards
              coupon={upcomingInvoiceData.coupon}
              currentPlan={upcomingInvoiceData.currentPlan}
              newPlan={upcomingInvoiceData.newPlan}
            />
          ) : (
            <PlanCardsSkeleton />
          )}
        </MainSectionContent>
      </MainSectionWrapper>
      <PaymentSummaryWrapper>
        {isAvailableOverlayData ? (
          <Summary
            addonItems={upcomingInvoiceData.addonItems}
            coupon={upcomingInvoiceData.coupon}
            currentDate={currentDate}
            currentPlan={upcomingInvoiceData.currentPlan}
            newPlanData={upcomingInvoiceData.newPlan}
          />
        ) : (
          <SummarySkeleton />
        )}
        <PaymentSummaryFooterWrapper>
          {showDiscounts && isAvailableOverlayData && (
            <DiscountsSection
              coupon={upcomingInvoiceData.coupon}
              isManagePlanOverlayContent
              productName={upcomingInvoiceData.newPlan.name}
              showYearlyBillingPerk={isAnnualInterval}
            />
          )}
          {isAvailableOverlayData ? (
            <CardDetailsSection
              cardDetails={upcomingInvoiceData.billingDetails.cardDetails}
              isMaxSeatsCount={false}
            />
          ) : (
            <CardDetailsSkeleton />
          )}
          {isAvailableOverlayData ? (
            <>{isDifferentPlans && <NextPayment newPlanData={upcomingInvoiceData.newPlan} />}</>
          ) : (
            <NextPaymentSkeleton />
          )}
          <ButtonsGroup>
            <DefaultButton
              buttonType='secondary'
              id='upgrade-plan-cancel-button'
              onClick={closeFullScreenOverlay}
              text={t('cta.cancel')}
            />
            <SubmitButton
              handleSubmit={handleSubmit}
              hasCardDetails={hasCardDetails}
              id='upgrade-plan-primary-button'
              isDisabled={isDisabledSubmitButton}
              isLoading={isLoadingSubmitButton}
              text={t('cta.upgrade')}
            />
          </ButtonsGroup>
        </PaymentSummaryFooterWrapper>
      </PaymentSummaryWrapper>
    </OverlayContentWrapper>
  );
};

export default UpgradePlanOverlayContent;
