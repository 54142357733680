import React, { FC } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import ThreeDotMenu from '../../../../design_system/Triage/menus/ThreeDotMenu';
import { routes } from '../../../publicApplication/applicationRouter';
import { SpecializationsThreeDotProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal');

const SpecializationsThreeDot: FC<SpecializationsThreeDotProps> = ({
  boardColumnResponsibilityId,
}) => {
  const {
    slug,
    splitFeatures: { accountSettingsTabsEnabled },
  } = useCurrentAccount();

  const menuId: RegisteredMenuId = `specializations-three-dot-${boardColumnResponsibilityId}`;

  const handleMenuOptionClick = () => {
    const url = accountSettingsTabsEnabled
      ? routes.accountSettings({
          slug,
          tab: 'delegation',
        }).href
      : routes.accountSettings({
          slug,
          section: 'responsibility-specializations-surface',
        }).href;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <ThreeDotMenu
      id={menuId}
      menuOptions={[
        {
          title: t('rating_settings'),
          onClick: handleMenuOptionClick,
          iconName: 'gear',
          id: 'td-details-responsibility-modal-edit-terminology',
          visible: true,
        },
      ]}
      menuPlacement='bottom-end'
    />
  );
};

export default SpecializationsThreeDot;
