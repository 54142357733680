import React, { useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import {
  ReportsEmptyState as EmptyState,
  ReportsEmptyStateDark as EmptyStateDark,
} from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import useDebounce from '../../../../../lib/useDebounce';
import { setUserAssignmentReportPage } from '../../../../../redux/domains/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useGetUsersAssignmentReportQuery } from '../../../../../redux/services/resourceApis/reports/reportsApi';
import NoResults from '../../../../design_system/Triage/NoResults';
import Pagination from '../../../../design_system/Triage/Paginate';
import LoadingTable from '../../../people/UsersOutline/Table/LoadingTable';
import { TableStyled, TableWrapper } from '../../AllUsersReportPage/AllUsersReportTable/styles';
import { sortIcon } from '../../SortIcon';
import TableActionsHeader from './ActionsHeader/TableActionsHeader';
import TableRow from './TableRow';
import { Action, TableHeaderState } from './types';
import UserAssignmentReportTableHeader from './UserAssignmentReportTableHeader';

const UsersAssignmentReportTable = ({
  curriculumId,
  curriculumTitle,
}: {
  curriculumId: number;
  curriculumTitle: string;
}) => {
  const t = initTranslations('reports');
  const { page, filters, searchTerm } = useAppSelector(
    (state) => state.reports.userAssignmentReport
  );
  const dispatch = useAppDispatch();
  const { slug } = useCurrentAccount();
  const cookiePath = `/${slug}/curriculum_report`;
  const [cookies, setCookie] = useCookies(['users_assignment_selected_reports_sort']);
  const isCookies = cookies.users_assignment_selected_reports_sort;
  const sortColumn = isCookies && cookies.users_assignment_selected_reports_sort['column'];
  const sortDirection = isCookies && cookies.users_assignment_selected_reports_sort['dir'];
  const debouncedSearchValue = useDebounce<string>(searchTerm.trim(), 500);

  const initialHeaderState: TableHeaderState = {
    sortCol: sortColumn ?? 'name',
    sortColDir: sortDirection ?? 'asc',
    sortIcon: sortIcon(sortDirection),
  };

  const tableHeaderReducer = (state: TableHeaderState, action: Action): TableHeaderState => {
    switch (action.type) {
      case 'sortColumnChange': {
        const sortDirection =
          state.sortCol === action.sortColumn && state.sortColDir === 'asc' ? 'desc' : 'asc';
        return {
          ...state,
          sortCol: action.sortColumn,
          sortColDir: sortDirection,
          sortIcon: sortIcon(sortDirection),
        };
      }
      case 'restoreUsersAssignmentReportDefault': {
        if (!action.sortColumn || !action.sortDirection) return state;
        return {
          sortCol: action.sortColumn,
          sortColDir: action.sortDirection,
          sortIcon: sortIcon(action.sortDirection),
        };
      }
    }
  };

  const [tableHeaderState, dispatchTableHeader] = useReducer(
    tableHeaderReducer,
    initialHeaderState
  );

  useEffect(() => {
    setCookie(
      'users_assignment_selected_reports_sort',
      JSON.stringify({ column: tableHeaderState.sortCol, dir: tableHeaderState.sortColDir }),
      { path: cookiePath }
    );
  }, [cookiePath, setCookie, tableHeaderState.sortColDir, tableHeaderState.sortCol]);

  const { data, isFetching, isError } = useGetUsersAssignmentReportQuery({
    ...filters,
    sortCol: tableHeaderState.sortCol,
    sortColDir: tableHeaderState.sortColDir,
    id: curriculumId,
    searchTerm: debouncedSearchValue,
    page,
  });

  if (isError) return <div>{t('error')}</div>;
  if (!data) return <LoadingTable />;

  const {
    users: users,
    limit_value: limitValue,
    total_pages: totalPages,
    total_users_count: totalUsersCount,
    signatures_required: signaturesRequired,
  } = data;

  return (
    <>
      <TableActionsHeader
        curriculumId={curriculumId}
        isFetching={isFetching}
        totalAssignmentsCount={totalUsersCount}
      />
      {isFetching ? (
        <LoadingTable />
      ) : (
        <>
          {users.length ? (
            <>
              <TableWrapper>
                <TableStyled className='users-assignment-table'>
                  <UserAssignmentReportTableHeader
                    dispatch={dispatchTableHeader}
                    signaturesRequired={signaturesRequired}
                    tableHeaderState={tableHeaderState}
                  />
                  <tbody>
                    {users.map((user) => {
                      return (
                        <TableRow
                          curriculumId={curriculumId}
                          curriculumName={curriculumTitle}
                          key={`users-assignment-report-tr-${user.id}`}
                          signaturesRequired={signaturesRequired}
                          user={user}
                        />
                      );
                    })}
                  </tbody>
                </TableStyled>
              </TableWrapper>
              <Pagination
                activePage={page}
                itemsCountPerPage={limitValue}
                onChange={(page) => {
                  dispatch(setUserAssignmentReportPage(page));
                }}
                showPaginationDetails
                totalItemsCount={totalUsersCount}
                totalPages={totalPages}
              />
            </>
          ) : (
            <NoResults
              darkImage={EmptyStateDark}
              heading={t('no_results_found')}
              iconWidth='45%'
              lightImage={EmptyState}
            />
          )}
        </>
      )}
    </>
  );
};

export default UsersAssignmentReportTable;
