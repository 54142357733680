import React from 'react';
import styled, { css } from 'styled-components';

import UploadWidget from './UploadWidget';

const SideWidget = styled.div(
  () => css`
    max-width: 384px;
  `
);

type Props = {
  widgetType: 'transcript' | 'upload';
};

const VideoSideWidget = ({ widgetType }: Props) => {
  return <SideWidget>{widgetType === 'upload' && <UploadWidget />}</SideWidget>;
};

export default VideoSideWidget;
