import { TiptapCollabProvider } from '@hocuspocus/provider';
import CollaborationHistory from '@tiptap-pro/extension-collaboration-history';
import { Collaboration } from '@tiptap/extension-collaboration';
import { CollaborationCursor } from '@tiptap/extension-collaboration-cursor';
import { AnyExtension, Editor, useEditor } from '@tiptap/react';
import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import useAnalyticsUserEvents from '../../../../../hooks/useAnalyticsUserEvents';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import usePrivateConfigs from '../../../../../hooks/usePrivateConfigs';
import { userInitials } from '../../../../../lib/userNaming';
import { EditorUser } from '../../../../../types/Editor';
import { paletteColorDecoder } from '../../../../design_system/helpers';
import { COLLABORATION_USER_COLORS } from '../../shared/constants/editor';
import { EditorExtensions } from '../../shared/constants/extensions';

type EditableContent = {
  collabUsers: EditorUser[];
  editor: Editor | null;
  isAILoading: boolean;
};

type Props = {
  provider: TiptapCollabProvider | null;
  writeVersionHistoryEnabled: boolean;
};

const randomPaletteColors = () => {
  return COLLABORATION_USER_COLORS[Math.floor(Math.random() * COLLABORATION_USER_COLORS.length)];
};

export const useEditableContent = ({
  provider,
  writeVersionHistoryEnabled,
}: Props): EditableContent => {
  const [isAILoading, setIsAILoading] = useState(false);
  const { configs: privateConfigs } = usePrivateConfigs();
  const { cdpTiptapStepEditorContentFailed } = useAnalyticsUserEvents();
  const { name, avatarUrl } = useCurrentUser();
  const { palettes } = useTheme();
  const decodedUserColor = useMemo(
    () => paletteColorDecoder(palettes, randomPaletteColors()),
    [palettes]
  );

  const editor = useEditor(
    {
      editorProps: {
        attributes: {
          translate: 'no',
        },
      },
      extensions: [
        ...EditorExtensions({
          collaborationEnabled: true,
          isEditable: true,
          aiAppId: privateConfigs['TIPTAP_AI_APP'],
          aiToken: privateConfigs['TIPTAP_AI_SECRET'],
          setIsAILoading,
          palettes,
        }),
        provider
          ? Collaboration.configure({
              document: provider.document,
            })
          : undefined,
        provider
          ? CollaborationCursor.configure({
              provider,
              user: {
                avatarUrl,
                name,
                color: decodedUserColor,
              },
            })
          : undefined,
        ...(writeVersionHistoryEnabled ? [CollaborationHistory.configure({ provider })] : []),
      ].filter((e): e is AnyExtension => e !== undefined),
      autofocus: true,
      editable: true,
      enableContentCheck: false, // TODO: Enable once https://trainual.atlassian.net/browse/GS5-622 is complete
      onContentError: ({ editor, disableCollaboration }) => {
        // See TipTap information here: https://tiptap.dev/docs/guides/invalid-schema#for-collaborative-editing
        // TODO: https://trainual.atlassian.net/browse/GS5-588
        // Disable collaboration to prevent syncing invalid content
        disableCollaboration();

        const emitUpdate = false;
        editor.setEditable(false, emitUpdate);

        provider &&
          cdpTiptapStepEditorContentFailed({ document_name: provider.configuration.name });
      },
    },
    [provider]
  );

  const collabUsers = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
      return { ...user, initials: userInitials(user.name) };
    });
  }, [editor?.storage.collaborationCursor?.users]);

  return { collabUsers, editor, isAILoading };
};
