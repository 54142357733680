import { animated, useSpring } from '@react-spring/web';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import React, { useEffect, useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import { useIsAdmin } from '../../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../../lib/initTranslations';
import { useAdminUpdateCurriculumMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { CurriculumOwner } from '../../../../../types';
import { MetaUserAccess } from '../../../../../types/Curriculum';
import { Emoji } from '../../../../../types/Emoji';
import { Sector } from '../../../../../types/Sector';
import ChevronControl from '../../../shared/ChevronControl/ChevronControl';
import LibraryCurriculumElementsTable from '../LibraryCurriculumElementsTable/LibraryCurriculumElementsTable';
import LibraryRowTitle from './LibararyRowTitle';
import LibraryRowBulkActions from './LibraryRowBulkActions';
import LibraryRowRightSide from './LibraryRowRightSide';

const LibraryRowContainer = styled.div<{
  isExpanded: boolean;
  isChecked: boolean;
  isContentBulkActionsEnabled: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  min-width: 45.5rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  padding-block: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ isChecked, isContentBulkActionsEnabled, theme: { vars } }) =>
    (() => {
      if (isContentBulkActionsEnabled && isChecked) {
        return vars.foundationBase1;
      } else {
        return vars.foundationSurface1;
      }
    })()};

  ${({ theme: { vars, constants }, isExpanded }) =>
    isExpanded && {
      backgroundColor: vars.foundationBase1,
      border: vars.foundationHover,
      borderBottom: `${constants.borderWidthSm} solid ${vars.borderSurface2}`,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    }};
`;

const ChildContainerRow = styled(animated.div)`
  overflow: hidden;
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
`;

const LeftSide = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 90%; /* TODO: Verify with the design team the preferred truncation point for the title and update accordingly */
`;

const ChevronWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 4rem;
  flex-shrink: 0;
`;

const Backdrop = styled.div<{
  isExpanded: boolean;
  isChecked: boolean;
  isContentBulkActionsEnabled: boolean;
}>`
  ${({ theme: { vars }, isExpanded }) =>
    isExpanded && {
      backgroundColor: vars.foundationBase1,
    }};

  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  border-color: ${({ isChecked, isContentBulkActionsEnabled, theme: { vars } }) =>
    isContentBulkActionsEnabled && (isChecked ? vars.borderSurface2 : vars.borderSurface1)};
`;

export type Props = {
  id: number;
  published: boolean;
  sector: Sector;
  title: string;
  groupsCount: number;
  owner: CurriculumOwner | null;
  updatedAt: string;
  archived: boolean;
  description: string | undefined;
  emoji: Emoji;
  isLocked: boolean;
  signaturable: boolean;
  favorited: boolean;
  assignmentId?: number;
  userAccess?: MetaUserAccess;
  canEdit: boolean;
  isChecked: boolean;
  onCheckboxChange?: (id: number, checked: boolean) => void;
  isBulkActionsMenuOpen?: boolean;
  setIsBulkActionsOpen?: (isOpen: boolean) => void;
};

const t = initTranslations('curriculums.content.library_row');

const LibraryRow = ({
  id,
  owner,
  groupsCount,
  published,
  sector,
  favorited,
  title,
  updatedAt,
  archived,
  description,
  emoji,
  isLocked,
  assignmentId,
  userAccess,
  signaturable,
  canEdit,
  onCheckboxChange,
  isBulkActionsMenuOpen,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdminHovered, setIsAdminHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [ref, bounds] = useMeasure();
  const containerStyle = useSpring({
    height: isOpen ? bounds.height + 8 : 0,
    config: { tension: 400, friction: 40 },
  });
  const noAccess = useMemo(() => !userAccess || userAccess === 'pending', [userAccess]);

  const anAdmin = useIsAdmin();
  const {
    slug,
    splitFeatures: { contentBulkActionsEnabled },
  } = useCurrentAccount();
  const [updateCurriculum, updateCurriculumResult] = useAdminUpdateCurriculumMutation();

  useEffect(() => {
    if (!isBulkActionsMenuOpen) {
      setIsChecked(false);
    }
  }, [isBulkActionsMenuOpen]);

  useDisplayFlashOnResponse({
    result: updateCurriculumResult,
    errorMessage: t('emoji_select.emoji_error'),
  });

  return (
    <Backdrop
      className='library-row'
      id={`curriculum-${id}-library-row-backdrop`}
      isChecked={isChecked}
      isContentBulkActionsEnabled={contentBulkActionsEnabled}
      isExpanded={isOpen}
    >
      <LibraryRowContainer
        className={`subject-library-row ${isChecked ? 'checked' : ''}`}
        id={`curriculum-${id}-library-row`}
        isChecked={isChecked}
        isContentBulkActionsEnabled={contentBulkActionsEnabled}
        isExpanded={isOpen}
        onMouseEnter={() => setIsAdminHovered(anAdmin)}
        onMouseLeave={() => setIsAdminHovered(false)}
      >
        <LeftSide className='library-row-left-side' data-testid={`library-row-${id}-left-side`}>
          {anAdmin && <span data-testid='bulk-action-checkbox-wrapper'></span>}
          <ChevronWrapper>
            <ChevronControl
              id={id}
              isOpen={isOpen}
              noAccess={noAccess}
              onClick={() => setIsOpen(!isOpen)}
              title={title}
            />
          </ChevronWrapper>
          <LibraryRowBulkActions
            anAdmin={anAdmin}
            contentBulkActionsEnabled={contentBulkActionsEnabled}
            emoji={emoji}
            handleEmojiClear={() => updateCurriculum({ id, emoji: null })}
            handleEmojiSelect={(baseEmoji: BaseEmoji) =>
              updateCurriculum({ emoji: baseEmoji.native, id })
            }
            id={id}
            isAdminHovered={isAdminHovered}
            isChecked={isChecked}
            onCheckboxChange={onCheckboxChange}
            setIsChecked={setIsChecked}
          />
          <LibraryRowTitle
            assignmentId={assignmentId}
            canEdit={canEdit}
            emoji={emoji}
            favorited={favorited}
            id={id}
            isAdminHovered={isAdminHovered}
            isEditing={isEditing}
            isOpen={isOpen}
            published={published}
            setIsEditing={setIsEditing}
            slug={slug}
            title={title}
            updatedAt={updatedAt}
            userAccess={userAccess}
          />
        </LeftSide>
        <LibraryRowRightSide
          archived={archived}
          canEdit={canEdit}
          description={description}
          groupsCount={groupsCount}
          id={id}
          isLocked={isLocked}
          owner={owner}
          renameClickHandler={() => setIsEditing(true)}
          sector={sector}
          title={title}
        />
      </LibraryRowContainer>
      <ChildContainerRow style={containerStyle}>
        <div ref={ref}>
          {isOpen && (
            <LibraryCurriculumElementsTable
              blockEditPrivileges={!canEdit}
              curriculumId={id}
              isContentLibrary
              isLocked={isLocked}
              signaturable={signaturable}
              userAccess={userAccess}
            />
          )}
        </div>
      </ChildContainerRow>
    </Backdrop>
  );
};

export default LibraryRow;
