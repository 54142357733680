import React from 'react';
import styled from 'styled-components';

import { CurriculumElementsProvider } from '../../../../../contexts/CurriculumElementsContext';
import { useGetCurriculumForEditQuery } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import CurriculumElementsTable from './CurriculumElementsTable/CurriculumElementsTable';

const OutlineWrapper = styled.div`
  padding-inline: ${({ theme: { constants } }) => constants.spacerMd1};
  padding-bottom: 8rem;
`;

export interface Props {
  curriculumId: number;
}

const CurriculumOutline = ({ curriculumId }: Props) => {
  const { data } = useGetCurriculumForEditQuery(curriculumId);

  if (!data) return <></>;

  const { locked, signaturable } = data;

  return (
    <OutlineWrapper>
      <CurriculumElementsProvider>
        <CurriculumElementsTable
          curriculumId={curriculumId}
          isLocked={locked}
          signaturable={signaturable}
          userAccess='edit'
        />
      </CurriculumElementsProvider>
    </OutlineWrapper>
  );
};

export default CurriculumOutline;
