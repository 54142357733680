import React, { ReactNode, createContext, useContext } from 'react';

import { GetVideoResponse } from '../redux/services/resourceApis/videos/types';

type BaseVideoData = {
  data: GetVideoResponse | undefined;
  isLoading: boolean;
  isError: boolean;
};

type VideoData = BaseVideoData;

type Props = {
  children: ReactNode;
} & BaseVideoData;

const VideoDataContext = createContext({} as VideoData);
export const useVideoData = () => useContext(VideoDataContext);

const VideoDataContextProvider = ({ children, data, isError, isLoading }: Props) => {
  return (
    <VideoDataContext.Provider
      value={{
        data,
        isError,
        isLoading,
      }}
    >
      {children}
    </VideoDataContext.Provider>
  );
};

export { VideoDataContextProvider };
