import { number } from 'prop-types';
import { useEffect, useState } from 'react';

const localStorageStorageKeys = [
  `aiBotCurrentScreen_${number}`,
  'aiBotInterceptorMetadata',
  'aiBotSeeMomentItems',
  `clearbitData_${number}`,
  `currentDelegationBoardId_${number}`,
  `isMoveResponsibilityDontShowAgainEnabled_${number}`,
  `isPlusButtonPulseAnimationRun_${number}`,
  `invalidateDPSpecializationsTags`,
  `isShowTrainualUCard_${number}`,
  'isESignaturePaywallBannerDismissed',
  `isTrainualPlusWidget_${number}`,
  `lastSeenAiBotMessageKey_${number}`,
  `aiFollowUpQuestions_${number}`,
] as const;

export type LocalStorageKey = (typeof localStorageStorageKeys)[number];

type UseLocalStorageStateArgs<T> = {
  key: LocalStorageKey;
  initialValue?: T;
};

type UseLocalStorageStateReturn<T> = [T, (value: T) => void, () => void];

const localStorageUpdateEvent = 'localStorageUpdate';

const dispatchLocalStorageUpdate = <T>(key: LocalStorageKey, value: T): void => {
  const event = new CustomEvent<LocalStorageUpdateEventDetail<T>>(localStorageUpdateEvent, {
    detail: { key, value },
  });
  window.dispatchEvent(event);
};

interface LocalStorageUpdateEventDetail<T> {
  key: LocalStorageKey;
  value: T;
}

const useLocalStorageState = <T>({
  key,
  initialValue,
}: UseLocalStorageStateArgs<T>): UseLocalStorageStateReturn<T> => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = localStorage.getItem(key);
    return item !== null ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    const handleStorageChange = (event: CustomEvent<LocalStorageUpdateEventDetail<T>>) => {
      if (event.detail.key === key) {
        setStoredValue(event.detail.value);
      }
    };

    window.addEventListener(localStorageUpdateEvent, handleStorageChange);

    return () => {
      window.removeEventListener(localStorageUpdateEvent, handleStorageChange);
    };
  }, [key]);

  const setValue = (newValue: T) => {
    setStoredValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
    dispatchLocalStorageUpdate(key, newValue);
  };

  const removeValue = () => {
    setStoredValue(initialValue as T);
    localStorage.removeItem(key);
    dispatchLocalStorageUpdate(key, initialValue as T);
  };

  return [storedValue, setValue, removeValue];
};

export default useLocalStorageState;
