import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import useWindowResize from '../../../../../../../hooks/useWindowResize';
import { isCourse } from '../../../../../../../types/Course';
import { MetaUserAccess } from '../../../../../../../types/Curriculum';
import { EditableCurriculumElement } from '../../../../../../../types/CurriculumElement';
import { Placeholder } from '../../../../../shared/DragAndDrop/types';
import OutlineCourseTableRow from './OutlineCourseTableRow/OutlineCourseTableRow';
import OutlineElementTableRow from './OutlineElementTableRow/OutlineElementTableRow';
import OutlineFlowchartTableRow from './OutlineFlowchartTableRow/OutlineFlowchartTableRow';
import OutlineSurveyTableRow from './OutlineSurveyTableRow/OutlineSurveyTableRow';

type CurriculumOutlineElementRowProps = {
  curriculumElement: EditableCurriculumElement;
  isLocked?: boolean;
  curriculumId: number;
  signaturable: boolean;
  placeholder: Placeholder;
  index: number;
  blockEditPrivileges: boolean;
  userAccess?: MetaUserAccess;
  isContentLibrary?: boolean;
};

const CurriculumOutlineElementRow = ({
  curriculumElement,
  curriculumId,
  isLocked,
  signaturable,
  index,
  placeholder,
  blockEditPrivileges,
  userAccess,
  isContentLibrary,
}: CurriculumOutlineElementRowProps) => {
  const { elementKind, id, canBeModified } = curriculumElement;
  const { isMobile } = useWindowResize();

  return (
    <Draggable
      draggableId={`curriculumElement-${curriculumElement.id}`}
      index={index}
      isDragDisabled={
        isMobile ||
        isLocked ||
        blockEditPrivileges ||
        (isCourse(curriculumElement) && !canBeModified)
      }
      key={id}
    >
      {(provided) => {
        if (elementKind === 'course') {
          return (
            <OutlineCourseTableRow
              blockEditPrivileges={blockEditPrivileges}
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              emoji={curriculumElement.element.emoji}
              isContentLibrary={isContentLibrary}
              isLocked={isLocked}
              key={`course-element-${id}`}
              placeholder={placeholder}
              provided={provided}
              signaturable={signaturable}
              userAccess={userAccess}
            />
          );
        } else if (elementKind === 'survey') {
          return (
            <OutlineSurveyTableRow
              blockEditPrivileges={blockEditPrivileges}
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              isCurriculumLocked={isLocked}
              key={`survey-element-${id}`}
              provided={provided}
              userAccess={userAccess}
            />
          );
        } else if (elementKind === 'flowchart') {
          return (
            <OutlineFlowchartTableRow
              blockEditPrivileges={blockEditPrivileges}
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              emoji={curriculumElement.element.emoji}
              isLocked={isLocked}
              key={`flowchart-element-${id}`}
              provided={provided}
              userAccess={userAccess}
            />
          );
        } else if (elementKind === 'video') {
          return (
            <OutlineElementTableRow
              blockEditPrivileges={blockEditPrivileges}
              curriculumElement={curriculumElement}
              curriculumId={curriculumId}
              isLocked={isLocked}
              key={`${elementKind}-element-${id}`}
              provided={provided}
              userAccess={userAccess}
            />
          );
        } else return <></>;
      }}
    </Draggable>
  );
};

export default CurriculumOutlineElementRow;
