import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import { Option } from '../../../../../design_system/core/SelectOption/types';
import SingleSelect from '../../../../../design_system/Triage/fields/SingleSelectField/SingleSelectField';
import InputField from '../../../../../design_system/Triage/InputField';
import { InputRow, StyledFormGroup } from '../../styles';
import { SettingsData } from '../../types';
import { CompanyInfoProps } from './types';

const t = initTranslations('account_settings.general_settings');

const CompanyInfo = ({ availableIndustries, availableOrganizationSizes }: CompanyInfoProps) => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<SettingsData>();

  const industryOption: Option[] = formatOptions(
    availableIndustries.map((option) => ({
      label: option,
      value: option,
    }))
  );
  const employeesOption: Option[] = formatOptions(
    availableOrganizationSizes.map((option) => ({
      label: option,
      value: option,
    }))
  );

  return (
    <>
      <InputRow>
        <StyledFormGroup>
          <InputField
            errorText={touched.name && errors.name}
            id='organization-name'
            label={t('organization_name')}
            name='name'
            onChange={handleChange}
            type='text'
            value={values.name}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <SingleSelect
            className='employee-size'
            defaultValue={values.employeeSize}
            fieldLabelText={t('of_employees')}
            name='employeeSize'
            onNonDefaultSelected={(employee: string) => setFieldValue('employeeSize', employee)}
            options={employeesOption}
            size='md'
            value={values.employeeSize}
          />
        </StyledFormGroup>
      </InputRow>
      <InputRow>
        <StyledFormGroup>
          <SingleSelect
            className='industry'
            defaultValue={values.industry}
            fieldLabelText={t('industry')}
            name='industry'
            onNonDefaultSelected={(industry: string) => setFieldValue('industry', industry)}
            options={industryOption}
            size='md'
            value={values.industry}
          />
        </StyledFormGroup>
        <StyledFormGroup>
          <InputField
            errorText={touched.phone && errors.phone}
            id='phone-number'
            label={t('phone_number')}
            name='phone'
            onChange={handleChange}
            placeholder='(000) 000-0000'
            type='text'
            value={values.phone || ''}
          />
        </StyledFormGroup>
      </InputRow>
    </>
  );
};

export default CompanyInfo;
