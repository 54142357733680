import { onStatelessParameters } from '@hocuspocus/provider';
import { CollabHistoryVersion } from '@tiptap-pro/extension-collaboration-history';
import { Editor } from '@tiptap/react';
import React, { useState } from 'react';

import { useStepId } from '../../../../contexts/StepIdContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { renderFormattedDate } from '../../../../lib/dateUtils';
import { routes } from '../../publicApplication/applicationRouter';
import { useTiptapCollaboration } from '../contexts/TiptapCollaboration';
import Modal from './Modal';
import { Button } from './styles';

interface Props {
  editor: Editor | null;
  selectedVersion: CollabHistoryVersion;
}

const Submit = ({ editor, selectedVersion }: Props) => {
  const { provider } = useTiptapCollaboration();

  const stepId = useStepId();
  const { slug } = useCurrentAccount();

  const [showModal, setShowModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleRevert = () => {
    setProcessing(true);

    provider?.on('stateless', (data: onStatelessParameters) => {
      const payload = JSON.parse(data.payload);
      // check that it is the right THistoryDocumentRevertedEvent
      const { event, version } = payload;
      event === 'document.reverted' &&
        version === selectedVersion.version &&
        routes.editor({ id: stepId, slug }).push();
    });

    const date = renderFormattedDate(selectedVersion.date, 'short');
    // The server will reply with a stateless message (THistoryDocumentRevertedEvent)
    editor?.commands.revertToVersion(
      selectedVersion.version,
      // the title of the new version
      `Restored from ${date}`,
      // the title of an extra version in case there are un-versioned changes in progress
      `Unsaved changes before restoration to ${date}`
    );
  };

  return (
    <>
      {showModal && (
        <Modal
          onCloseRequest={() => setShowModal(false)}
          primaryButtonTask={handleRevert}
          processing={processing}
          selectedVersionDate={renderFormattedDate(selectedVersion.date)}
        />
      )}

      <Button
        behavesAs='a'
        buttonType='secondary'
        href={routes.editor({ id: stepId, slug }).href}
        id='step-version-history-cancel'
        text='Secondary'
      />
      <Button
        buttonType='primary'
        id='step-version-history-submit'
        onClick={() => setShowModal(true)}
        text='Primary'
      />
    </>
  );
};

export default Submit;
