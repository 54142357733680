import styled from 'styled-components';

import { CardBackgroundTopicTitle } from '../../EditorPage/TopicHeader/styles';
import { CardBackgroundShadowed } from '../../shared/styles';

export const StepContentSkeletonWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  height: 100%;
`;

export const TopicTitleWrapper = styled(CardBackgroundTopicTitle)`
  height: 6.725rem;
  display: flex;
  align-items: center;
`;

export const StepContentWrapper = styled(CardBackgroundShadowed)`
  height: 100%;
`;
