import React from 'react';
import styled from 'styled-components';

import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import { ContentType } from '../../../../../types/ContentType';
import { ElementKind } from '../../../../../types/CurriculumElement';
import { Emoji } from '../../../../../types/Emoji';
import Badge from '../../../../design_system/display/badge';
import Icon from '../../../../design_system/display/icons/Icon';
import Link from '../../../../design_system/Link';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxMd, mediaBreakpointPxXl } from '../../../../styled/Breakpoint';
import { fontSm4 } from '../../../../styled/TypeSystem';

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  overflow: hidden;
`;

const EmojiWrapper = styled.div`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-inline: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Separator = styled.p`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

// DS Override: Use the color as described in the designs
const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

// DS Override: Prevent truncation
const StyledBadge = styled(Badge)`
  flex-shrink: 0;
`;

const TruncateFlowchartTitle = styled.div`
  max-width: 11.687rem;
  ${TruncatedText({})};
  @media (min-width: ${mediaBreakpointPxMd}) {
    max-width: 30rem;
  }
  ${fontSm4};
`;

const SubjectTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  max-width: 5rem;
  ${TruncatedText({})};
  @media (min-width: ${mediaBreakpointPxXl}) {
    max-width: 10rem;
  }
  ${fontSm4};
`;

type Props = {
  contentType: ContentType;
  contentTypeTitle: string;
  emoji: Emoji;
  elementId: number;
  subjectEmoji: Emoji;
  subjectHref: string;
  subjectTitle: string;
};

const TopNavBreadcrumb = ({
  contentType,
  contentTypeTitle,
  elementId,
  subjectTitle,
  subjectEmoji,
  subjectHref,
  emoji,
}: Props) => {
  const elementAttributes = useCurriculumElement();
  const { termSingular, iconName } = elementAttributes({
    elementId,
    elementKind: contentType as ElementKind,
  });
  const emojiOrIcon = emoji || (iconName ? <Icon name={iconName} /> : null);

  return (
    <FlexWrapper id='top-nav-breadcrumb'>
      <Link
        href={subjectHref}
        text={
          <FlexWrapper as='span'>
            <StyledIcon name='arrow-left' />
            <EmojiWrapper>{subjectEmoji || <Icon name='file-lines' />}</EmojiWrapper>
            <SubjectTitle>{subjectTitle}</SubjectTitle>
          </FlexWrapper>
        }
        underlineBehavior='dynamic'
      />
      <Separator>/</Separator>
      <FlexWrapper as='span'>
        <StyledBadge text={termSingular} type='info' />
        <EmojiWrapper>{emojiOrIcon}</EmojiWrapper>
        <TruncateFlowchartTitle>{contentTypeTitle}</TruncateFlowchartTitle>
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default TopNavBreadcrumb;
