import { Form, Formik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Route } from 'type-route';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { toSnakeCase } from '../../../../../lib/keyFormatConverter';
import { sanitizeTags } from '../../../../../lib/sanitize';
import { setIsCurrentResponsibilityPositionChanged } from '../../../../../redux/domains/delegationPlanner/delegationPlannerSlice';
import {
  useCheckModalState,
  useDispatchSetShowModal,
} from '../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useUpdateBoardColumnResponsibilityMutation } from '../../../../../redux/services/resourceApis/boardColumns/boardColumnsApi';
import { heapModalNameActionIds } from '../../../../../types/TaskTracking';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import CoreModal from '../../../../design_system/core/CoreModal';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import { mediaBreakpointSm } from '../../../../styled/Breakpoint';
import { routes, useRoute } from '../../../publicApplication/applicationRouter';
import useAutoSaveStatusUpdater from '../../../shared/AutoSaveStatusUpdater/useAutoSaveStatusUpdater';
import { DEFAULT_FREQUENCY, DEFAULT_WORKING_TIME } from '../../shared/constants/modals';
import { responsibilityDetailsModalValidationSchema } from '../../shared/utils/validators';
import { BoardResponseError } from '../../types';
import { extractDomainName } from '../utils';
import ModalHeader from './ModalHeader/ModalHeader';
import ResponsibilityDetails from './ResponsibilityDetails/ResponsibilityDetails';
import { ResponsibilityDetailsFormData } from './ResponsibilityDetails/types';
import {
  ControlButtonsGroup,
  ModalContentWrapper,
  ModalHeaderContainer,
  PrimaryButtonWrapper,
} from './styles';
import { ModalHeaderData, ResponsibilityDetailsModalProps } from './types';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal');

const ResponsibilityDetailsModal: FC<ResponsibilityDetailsModalProps> = ({
  boardColumn,
  boardColumnResponsibility,
}) => {
  const {
    params: { id: boardId },
  } = useRoute() as Route<typeof routes.delegationPlannerBoard>;
  const [modalHeadedData, setModalHeadedData] = useState<ModalHeaderData>({ name: '', error: '' });
  const connectedLinkAttribute = boardColumnResponsibility.contentUrls?.[0];
  const contentUrl = connectedLinkAttribute?.url || '';
  const contentTitle = connectedLinkAttribute?.title || extractDomainName(contentUrl);
  const heapActionIds = heapModalNameActionIds('responsibility-details-modal');
  const { isMenuOpen: isMoveResponsibilityMenuOpen } = useActiveMenuHandler({
    menuId: 'delegation-planner-move-responsibility-popup',
  });
  const { width } = useWindowResize();
  const dispatch = useAppDispatch();
  const dispatchShowModal = useDispatchSetShowModal();
  const isCurriculumModalOpen = useCheckModalState('delegationCreateAndConnectCurriculumModal');
  const displayControlButtonsFullWidth = width < mediaBreakpointSm;
  const [updateBoardColumnResponsibility, result] = useUpdateBoardColumnResponsibilityMutation();
  const { isLoading, isSuccess, error } = result;
  const updateBoardColumnError = error as BoardResponseError;
  const processing = isLoading,
    disabled = isLoading || isMoveResponsibilityMenuOpen;

  useAutoSaveStatusUpdater([{ isSaving: isLoading, isSavedSuccessfully: isSuccess }]);

  const handleCloseRequest = () => {
    dispatchShowModal('responsibilityDetailsModal', false);
  };

  const preprocessedContentUrls = useMemo(() => {
    return boardColumnResponsibility.contentUrls.map((contentUrl) => ({
      ...contentUrl,
      title: contentUrl.title || extractDomainName(contentUrl.url),
    }));
  }, [boardColumnResponsibility.contentUrls]);

  const initialFormValues: ResponsibilityDetailsFormData = {
    name: boardColumnResponsibility.name,
    description: boardColumnResponsibility.description || '',
    contentUrl,
    contentTitle,
    destroyContentUrl: connectedLinkAttribute?._destroy || false,
    lastSavedContentUrl: contentUrl,
    lastSavedContentTitle: contentTitle,
    time: boardColumnResponsibility.workingHours?.time || DEFAULT_WORKING_TIME,
    frequency: boardColumnResponsibility.workingHours?.frequency || DEFAULT_FREQUENCY,
    targetColumnId: String(boardColumn.id),
    targetPosition: 1,
    specializations: boardColumnResponsibility.specializations || [],
    contents_urls: preprocessedContentUrls || [],
  };

  const transformFormValuesForSubmission = (values: ResponsibilityDetailsFormData) => {
    const { description, name, time, frequency, specializations } = values;

    // TODO: Add content_urls to the form values w/o enabling the feature flag

    return {
      name: sanitizeTags(name),
      description,
      modified_content_urls: {
        content_urls: toSnakeCase(values.contents_urls, { exclude: ['_destroy'] }),
      },
      workingHours: [
        { time, frequency, responsibilityId: boardColumnResponsibility.responsibilityId },
      ],
      specializations: specializations.map(({ id, score }) => ({
        id,
        score,
        responsibilityId: boardColumnResponsibility.responsibilityId,
      })),
    };
  };

  const handleSubmit = async (values: ResponsibilityDetailsFormData) => {
    const submissionValues = transformFormValuesForSubmission(values);
    const isResponsibilityMoved = +values.targetColumnId !== boardColumn.id;

    await updateBoardColumnResponsibility({
      boardId,
      columnId: boardColumn.id,
      boardColumnResponsibilityId: boardColumnResponsibility.id,
      targetColumnId: isResponsibilityMoved ? +values.targetColumnId : null,
      position: values.targetPosition,
      responsibilityId: boardColumnResponsibility.responsibilityId,
      ...submissionValues,
    });
    setModalHeadedData((prevState) => ({ ...prevState, name: values.name }));

    isResponsibilityMoved && dispatch(setIsCurrentResponsibilityPositionChanged(true));
  };

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_message'),
    successFunction: handleCloseRequest,
  });

  useEffect(() => {
    if (updateBoardColumnError) {
      setModalHeadedData((prevState) => ({
        ...prevState,
        error: updateBoardColumnError.data.baseErrors[0],
      }));
    } else {
      setModalHeadedData({ name: '', error: '' });
    }
  }, [result, updateBoardColumnError, isSuccess]);

  useEffect(() => {
    const activeElementBlurTimeout = setTimeout(() => {
      const activeElement = document.activeElement as HTMLElement;

      activeElement && activeElement.blur();
    }, 0);

    return () => clearTimeout(activeElementBlurTimeout);
  }, []);

  return (
    <CoreModal
      closeIconAriaLabel={t('aria_label_cancel')}
      desktopSize='xl'
      disabledPadding={{ horizontal: { all: true }, vertical: { all: true } }}
      heapModalName='responsibility-details-modal'
      onCloseRequest={handleCloseRequest}
      visible={!isCurriculumModalOpen}
    >
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={responsibilityDetailsModalValidationSchema}
      >
        {({ handleSubmit }) => (
          <Form autoComplete='off'>
            <ModalHeaderContainer isError={!!modalHeadedData.error}>
              <ModalHeader column={boardColumn} headerData={modalHeadedData} />
            </ModalHeaderContainer>
            <ModalContentWrapper>
              <ResponsibilityDetails boardColumn={boardColumn} />
            </ModalContentWrapper>
            <ControlButtonsGroup>
              <DefaultButton
                buttonType='secondary'
                className={heapActionIds.cancel}
                disabled={disabled}
                fullWidth={displayControlButtonsFullWidth}
                id={heapActionIds.cancel}
                onClick={() => !processing && handleCloseRequest()}
                size='md'
                text={t('cancel')}
              />
              <PrimaryButtonWrapper>
                <DefaultButton
                  buttonType='primary'
                  className={heapActionIds.submit}
                  disabled={disabled}
                  fullWidth={displayControlButtonsFullWidth}
                  id={heapActionIds.submit}
                  loading={processing}
                  onClick={() => handleSubmit()}
                  size='md'
                  text={t('save_changes')}
                  type='button'
                />
              </PrimaryButtonWrapper>
            </ControlButtonsGroup>
          </Form>
        )}
      </Formik>
    </CoreModal>
  );
};

export default ResponsibilityDetailsModal;
