import React from 'react';
import styled from 'styled-components';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useGetComingUpTrainingPathQuery } from '../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { TrainingPathTag } from '../../../../redux/services/resourceApis/trainingPaths/types';
import { useGetUserPrimaryDetailsQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { routes } from '../../publicApplication/applicationRouter';
import GappedAccordion from '../../shared/GappedAccordion/GappedAccordion';
import OverviewSet from '../PathOverview/OverviewSets/OverviewSet/OverviewSet';

const Container = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerLg1};
`;

const UpcomingTrainingPathWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  flex-direction: column;
`;

const TrainingPathButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const t = initTranslations('training_path.shared.coming_up_next');

const ComingUpSet = ({ userId, trainingPathTag }: ComingUpNextProps) => {
  const { slug } = useCurrentAccount();
  const { data: user } = useGetUserPrimaryDetailsQuery(userId);
  const { data: trainingPathData } = useGetComingUpTrainingPathQuery({
    userId,
    tag: trainingPathTag,
  });

  if (!trainingPathData || !user) return null;

  const { trainingPathSets } = trainingPathData;

  if (trainingPathSets.length === 0) return null;

  return (
    <Container>
      <GappedAccordion
        id='home-page-training-path-up-next-accordion'
        initialCollapsedState={false}
        title={t('accordion_label')}
      >
        <UpcomingTrainingPathWrapper>
          <OverviewSet
            set={trainingPathData.trainingPathSets[0]}
            setStatus='not started'
            showFavorite
            user={{ name: user.name, id: userId }}
          />
          <TrainingPathButtonWrapper>
            <DefaultButton
              buttonType='tertiary'
              id='training-path-see-entire-tp-button'
              onClick={() => {
                routes.userTrainingPath({ slug, id: userId }).push();
              }}
              text={t('see_entire_training_path')}
            />
          </TrainingPathButtonWrapper>
        </UpcomingTrainingPathWrapper>
      </GappedAccordion>
    </Container>
  );
};

type ComingUpNextProps = {
  userId: number;
  trainingPathTag?: TrainingPathTag;
};

const ComingUpNext = (props: ComingUpNextProps) => {
  const { trainingPathsEnabled } = useCurrentAccount();

  if (!trainingPathsEnabled) return null;

  return <ComingUpSet trainingPathTag={props.trainingPathTag} userId={props.userId} />;
};

export default ComingUpNext;
