import React from 'react';
import styled from 'styled-components';

import { TrainingPathCaughtUpForNow } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { Title } from '../../shared/DetailedTitle/Title';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
  justify-content: center;
`;

const t = initTranslations('training_path.caught_up');

const CaughtUpForNow = () => {
  return (
    <Wrapper id='tp-caught-up-for-now'>
      <img alt={t('img_alt')} src={TrainingPathCaughtUpForNow} />
      <TextWrapper>
        <Title fontSize='md1' fontWeight='bold' title={t('header')} />
        <Title fontWeight='regular' title={t('subtext')} />
      </TextWrapper>
    </Wrapper>
  );
};

export default CaughtUpForNow;
