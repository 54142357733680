import React from 'react';

import { useStepId } from '../../../../contexts/StepIdContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { routes } from '../../publicApplication/applicationRouter';

const Breadcrumb = () => {
  const { slug } = useCurrentAccount();
  const stepId = useStepId();
  // query the course and step name by stepId

  const navigateToStep = () => routes.editor({ id: stepId, slug }).push();

  return <h4 onClick={navigateToStep}>TODO: GS5-503</h4>;
};

export default Breadcrumb;
