import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { Option } from '../../../../../design_system/core/SelectOption/types';
import SingleSelectField from '../../../../../design_system/Triage/fields/SingleSelectField';
import { GROUP_VIEW_BY_OPTIONS } from '../../../../ContentLibrary/ContentLibraryPage/libraryReducer';
import {
  FormSectionDescription,
  FormSectionTitle,
  FormSectionWrapper,
  MultiSelectInputRow,
  ToggleInfoWrapper,
} from '../../styles';
import { SettingsData, ViewByOption } from '../../types';

const t = initTranslations('account_settings.content_settings.content_page.default_view_settings');

const ContentPageDefaultView = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const options: Option[] = GROUP_VIEW_BY_OPTIONS.map((viewByOption): Option => {
    return {
      label: viewByOption.label,
      searchableTerm: viewByOption.label,
      value: viewByOption.value,
    };
  });

  return (
    <FormSectionWrapper id='content-view-by-section'>
      <ToggleInfoWrapper>
        <FormSectionTitle>{t('title')}</FormSectionTitle>
        <FormSectionDescription>{t('description')}</FormSectionDescription>
      </ToggleInfoWrapper>
      <MultiSelectInputRow>
        <SingleSelectField
          className='account-settings-default-view-by-dropdown'
          defaultValue={values.contentPageDefaultViewBy}
          hideSelectedOptions={false}
          isClearable
          isSearchable={false}
          menuPosition='fixed'
          onNonDefaultSelected={(value: ViewByOption) =>
            setFieldValue('contentPageDefaultViewBy', value)
          }
          options={options}
          placeholder={t('placeholder')}
          value={values.contentPageDefaultViewBy}
        />
      </MultiSelectInputRow>
    </FormSectionWrapper>
  );
};

export default ContentPageDefaultView;
