import React, { useContext, useReducer, useState } from 'react';

import { PaywallContext } from '../../../../contexts/PaywallContext';
import initTranslations from '../../../../lib/initTranslations';
import { useGetAccountSettingsQuery } from '../../../../redux/services/resourceApis/accountSettings/accountsApi';
import Icon from '../../../design_system/display/icons/Icon';
import Hoverable from '../../../design_system/Hoverable';
import CollapsibleSurface from '../../../design_system/Triage/layout/CollapsibleSurface';
import {
  collapseReducer,
  initialCollapsedState,
} from '../../../design_system/Triage/layout/CollapsibleSurface/reducers/collapse';
import PaywallTooltip from '../../shared/tooltips/PaywallTooltip';
import { TitleInner } from '../OrganizationProfile/styles';
import BrandStylesInner from './BrandStylesInner';

const t = initTranslations('account_settings.brand_styles');

const BrandStyles = () => {
  const [isToggleHovered, setIsToggleHovered] = useState(false);
  const paywallCtx = useContext(PaywallContext);
  const brandStylesLocked = paywallCtx.includes('brand_styles');
  const { data, isLoading: isLoadingData } = useGetAccountSettingsQuery();
  const [{ collapsed }, collapseDispatch] = useReducer(collapseReducer, initialCollapsedState);

  if (isLoadingData) return <></>;
  if (!data) return <></>;

  const {
    accentColor,
    accentPalette,
    defaultLogoUrl,
    brandStyles,
    defaultBrandStyles,
    logoUrl,
    logoBackgroundColor,
  } = data;

  const title = brandStylesLocked ? (
    <>
      <PaywallTooltip
        description={t('lock_brand_styles_paywall.description')}
        modifiers={[{ name: 'offset', options: { offset: [140, 0] } }]}
        shouldBeVisible={isToggleHovered && brandStylesLocked}
        title={t('lock_brand_styles_paywall.title')}
      />
      <Hoverable setIsHovered={setIsToggleHovered}>
        <TitleInner>
          {t('title')} {brandStylesLocked && <Icon name='lock' />}
        </TitleInner>
      </Hoverable>
    </>
  ) : (
    t('title')
  );

  return (
    <CollapsibleSurface
      collapseDispatch={collapseDispatch}
      id='brand-styles-surface'
      isCollapsed={collapsed}
      title={title}
    >
      <BrandStylesInner
        accentColor={accentColor}
        accentPalette={accentPalette}
        brandStyles={brandStyles}
        defaultBrandStyles={defaultBrandStyles}
        defaultLogoUrl={defaultLogoUrl}
        logoBackgroundColor={logoBackgroundColor}
        logoUrl={logoUrl}
      />
    </CollapsibleSurface>
  );
};

export default BrandStyles;
