import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import MenuOption from '../../../design_system/core/MenuOption/MenuOption';
import { SharedOptionProps } from './types';

const t = initTranslations('three_dot_menu');

const Edit = ({ onClick, isDisabled, id, tooltipText }: SharedOptionProps) => {
  return (
    <MenuOption
      iconName='edit'
      iconWeight='regular'
      id={id}
      isDisabled={isDisabled}
      onClick={onClick}
      title={t('edit')}
      tooltipText={tooltipText}
    />
  );
};

export default Edit;
