import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react';

import { TeammateModalProvider } from '../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { getSavedOnboardingState } from '../../../../lib/home/getSavedOnboardingState';
import { getTaskDrivenOnboardingState } from '../../../../lib/home/getTaskDrivenOnboardingState';
import initTranslations from '../../../../lib/initTranslations';
import { useGetTaskListQuery } from '../../../../redux/services/resourceApis/taskLists/taskListsApi';
import { TaskDrivenOnboardingState } from '../../../../types/TaskDrivenOnboarding';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import AddTeammateWithConfirmation from '../../people/TeammateModal/AddTeammateWithConfirmation';
import ComingUpNext from '../../TrainingPath/shared/ComingUpNext';
import TaskDrivenOnboardingCard from '../Onboarding/./task_driven/TaskDrivenOnboardingCard/TaskDrivenOnboardingCard';
import CurriculumsAssignments from '../CurriculumsAssignments/CurriculumsAssignments';
import FavoriteCurriculums from '../FavoriteCurriculums/FavoriteCurriculums';
import FollowingWidget from '../FollowingWidget/FollowingWidget';
import { CurrentTaskName } from '../Onboarding/task_driven/TaskDrivenOnboardingCard/types';
import TasksList from '../Onboarding/task_driven/TasksList/TasksList';
import { TaskData } from '../Onboarding/task_driven/TasksList/types';
import ProgressBlock from '../ProgressBlock/ProgressBlock';
import TrainualPlusWidget from '../TrainualPlusWidget/TrainualPlusWidget';
import TrainualUCard from '../TrainualUCard/TrainualUCard';
import { HeaderWrapper, LeftContainer, PageContent, RightContainer, StyledLoader } from './styles';

const t = initTranslations('home');

const HomePageContent = () => {
  const { accountPlan, backdropSetupStatus } = useCurrentAccount();
  const ability = useCurrentUserAbilities();
  const canSeeFollowingWidget = ability.can('read', 'FollowingWidget');
  const { documenting, invite, organize } = getSavedOnboardingState();
  const initialShowOnboardingTasks = !documenting && !invite && !organize;
  const [showOwnerOnboardingTasks, setShowOwnerOnboardingTasks] = useState(
    initialShowOnboardingTasks
  );
  const { permission, name, id: userId } = useCurrentUser();
  const isBillingAdmin = permission === 'billing_admin';
  const isBackdropSetupFinished = backdropSetupStatus === 'backdrop_setup_finished';
  const displayOnboarding = isBackdropSetupFinished && isBillingAdmin;
  const { data, isLoading } = useGetTaskListQuery(displayOnboarding ? null : skipToken);
  const [onboardingState, setOnboardingState] = useState<TaskDrivenOnboardingState>(
    getTaskDrivenOnboardingState(data?.tasks, data?.onboardingVariation)
  );
  const [currentTaskNameState, setCurrentTaskNameState] = useState<CurrentTaskName>();
  const showOnboardingTitle = displayOnboarding && showOwnerOnboardingTasks;
  const showTaskDrivenOnboarding = displayOnboarding && data;
  const { productTerm } = useAccountTerminology();

  useEffect(() => {
    setShowOwnerOnboardingTasks(!!currentTaskNameState);
  }, [currentTaskNameState, setShowOwnerOnboardingTasks]);

  useEffect(() => {
    if (data?.tasks && data?.onboardingVariation) {
      const { tasks, onboardingVariation } = data;
      setOnboardingState(getTaskDrivenOnboardingState(tasks, onboardingVariation));
    }
  }, [data]);

  const queryParams = new URLSearchParams(window.location.search);
  const kind = queryParams.get('kind');
  const showTpComingUpNext = kind === null || kind === 'incomplete';

  return (
    <TeammateModalProvider>
      <LeftContainer>
        <PageContent>
          {!isLoading && (
            <>
              <HeaderWrapper>
                <PageHeader
                  title={
                    showOnboardingTitle
                      ? t('onboarding.title', { user: name, productTerm })
                      : t('home')
                  }
                />
              </HeaderWrapper>
              {showTaskDrivenOnboarding && (
                <TaskDrivenOnboardingCard
                  currentTaskNameState={currentTaskNameState}
                  onboardingState={onboardingState}
                  setCurrentTaskNameState={setCurrentTaskNameState}
                  setOnboardingState={setOnboardingState}
                />
              )}
            </>
          )}
          <CurriculumsAssignments userId={userId} />
          {showTpComingUpNext && <ComingUpNext userId={userId} />}
        </PageContent>
      </LeftContainer>
      <RightContainer>
        {isLoading ? (
          <StyledLoader />
        ) : (
          <>
            <TrainualUCard />
            <TrainualPlusWidget />
            {showTaskDrivenOnboarding && (
              <TasksList
                onboardingState={onboardingState}
                setCurrentTaskNameState={setCurrentTaskNameState}
                tasks={data.tasks as TaskData[]}
              />
            )}
            {accountPlan !== 'build' && <ProgressBlock userId={userId} />}
            {canSeeFollowingWidget && <FollowingWidget />}
            <FavoriteCurriculums />
          </>
        )}
      </RightContainer>
      <AddTeammateWithConfirmation showAddConfirmation />
    </TeammateModalProvider>
  );
};

export default HomePageContent;
