import styled from 'styled-components';

import { fontSm5 } from '../../../../../styled/TypeSystem';
import { LogoBackgroundColor } from '../../../BrandStyles/types';

export const CurrentIpInputSection = styled.div`
  input,
  input:focus {
    width: 100%;
    color: ${({ theme: { vars } }) => vars.textPlaceholder};
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
    padding-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const Title = styled.h3`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const LogoWrapper = styled.div<LogoBackgroundColor>`
  display: flex;
  justify-content: center;
  background-color: ${({ logoBackgroundColor }) => logoBackgroundColor};
  text-align: center;
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  max-width: 24rem;
  max-height: 12rem;
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
