import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { TrainingPathDelayState } from '../../../../../../redux/services/resourceApis/trainingPaths/types';
import Badge from '../../../../../design_system/display/badge/Badge';
import Icon from '../../../../../design_system/display/icons/Icon';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';

const t = initTranslations('training_path.path_overview.overview_sets.overview_set');

const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
`;

const DelayRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const DelaySection = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const Container = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    justify-content: center;
    padding: ${constants.spacerMd1} ${constants.spacerMd3};
    gap: ${constants.spacerMd2};
    color: ${vars.textSubdued};
    border-bottom: ${constants.borderWidthSm} solid ${vars.borderSurface2};
  `
);

const LeftContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${constants.spacerMd2};
  `
);

const Title1 = styled.div(
  ({ theme: { constants, vars } }) => css`
    color: ${vars.textSubdued};
    font-weight: ${constants.fontMedium};
    ${fontMd1};
  `
);

const Label = styled.div(
  ({ theme: { vars } }) => css`
    color: ${vars.textSubdued};
    ${fontSm4};
  `
);

const ForceOrderDelayContainer = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    gap: ${constants.spacerMd2};
  `
);

const VerticalLine = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 1px;
    height: ${constants.height2xs};
    background-color: ${vars.foundationBase4};
  `
);

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type DelayHeaderProps = {
  delayType: string | null;
  delayValue: number | null;
  delayState: TrainingPathDelayState;
  title: string | null;
  titleVisible: boolean;
  minutesToReadFormatted: string;
  forceOrder: boolean;
};

const OverviewSetHeader = ({
  delayState,
  delayType,
  delayValue,
  title,
  titleVisible,
  minutesToReadFormatted,
  forceOrder,
}: DelayHeaderProps) => {
  const displayDelay = !(delayState.status === 'no_delay' || delayState.status === 'complete');
  const delayInProgress = delayState.status === 'in_progress';
  const titleIsVisible = titleVisible && !!title;

  const delayMessage = delayInProgress
    ? t('delay_in_progress')
    : t(`training_break.${delayType}`, { delayValue });

  const icon = delayInProgress ? 'rotate' : 'calendar';

  return (
    <Container>
      <LeftContainer>
        {titleIsVisible && <Title1>{title}</Title1>}
        {(displayDelay || forceOrder) && (
          <ForceOrderDelayContainer>
            {displayDelay && (
              <DelayRow>
                <StyledIcon name={icon} size='sm' />
                <DelaySection>
                  <Label>{delayMessage}</Label>
                  {delayInProgress && (
                    <Badge
                      text={t('badge_message', {
                        availableAtInWords: delayState.availableAtInWords,
                      })}
                      type='trainual-purple'
                    />
                  )}
                </DelaySection>
              </DelayRow>
            )}
            {displayDelay && forceOrder && <VerticalLine />}
            {forceOrder && <Label>{t('force_order')}</Label>}
          </ForceOrderDelayContainer>
        )}
      </LeftContainer>
      <RightContainer>
        <Label>{t('read_time', { minutesToReadFormatted })}</Label>
      </RightContainer>
    </Container>
  );
};

export default OverviewSetHeader;
