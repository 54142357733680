import React, { MouseEvent, useCallback, useMemo } from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../lib/initTranslations';
import { useToggleFavoriteCurriculumMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import ProgressBar from '../../../../design_system/Triage/ProgressBar';
import CompletionCertLink from '../../../curriculums/CompletionCert/CompletionCertLink';
import { routes } from '../../../publicApplication/applicationRouter';
import CurriculumAssignmentsBadges from './CurriculumAssignmentsBadges/CurriculumAssignmentsBadges';
import {
  CurriculumActionWrapper,
  CurriculumAssignedActionIcon,
  CurriculumAssignedActionInner,
  CurriculumAssignmentType,
  CurriculumCompletion,
  CurriculumEmoji,
  CurriculumInfo,
  CurriculumInfoItem,
  CurriculumInner,
  CurriculumItem,
  CurriculumProgressBar,
  CurriculumReference,
  CurriculumReferenceIcon,
  CurriculumTitle,
  CurriculumTitleWrapper,
  FavoriteIcon,
  FavoriteIconWrapper,
  StyledButton,
} from './styles';
import { CurriculumsAssignmentsItemKind, CurriculumsAssignmentsItemProps } from './types';

const ELEMENT_TYPE_TO_CONSUME_ROUTE = {
  Course: routes.courseConsume,
  'Survey::Survey': routes.surveyConsume,
  Video: routes.videoConsume,
  Flowchart: routes.flowchartConsume,
};

const t = initTranslations('home.curriculum_assignments');

const CurriculumsAssignmentsItem = ({
  title,
  curriculumEmoji,
  favorite,
  justUpdated,
  overdue,
  curriculumReadingTime,
  curriculumSector,
  curriculumStatus,
  curriculumId,
  completionScore,
  completionId,
  showCertificate,
  kind,
  nextStepId,
  elementType,
  elementId,
  isOwner,
  isReference,
  eSignatureRequired,
  requiredForUser,
  requiredForGroups,
}: CurriculumsAssignmentsItemProps) => {
  const terminology = useAccountTerminology();
  const { slug, hasESignatureFeature } = useCurrentAccount();

  const [toggleFavoriteAssignment] = useToggleFavoriteCurriculumMutation();
  const showTimeEstimates = curriculumReadingTime !== '0 min';
  const isPublished = curriculumStatus === 'published';

  const toggleFavorite = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    toggleFavoriteAssignment(curriculumId);
  };

  const handleButtonText = (kind: CurriculumsAssignmentsItemKind) => {
    switch (kind) {
      case 'incomplete':
        return completionScore === 0 ? t('actions.start') : t('actions.continue');
      case 'owned_by_you':
        return t('actions.edit');
      case 'completed':
      case 'reference':
        return t('actions.read');
      case 'recent':
        if (isOwner) {
          return t('actions.edit');
        } else if (isReference) {
          return t('actions.read');
        } else if (isPublished && completionScore === 0) {
          return t('actions.start');
        } else if (isPublished && completionScore >= 1 && completionScore < 100) {
          return t('actions.continue');
        } else {
          return t('actions.read');
        }
    }
  };

  const ownerActionText = () => {
    return (
      <>
        <CurriculumAssignedActionIcon
          name={isPublished ? 'file-lines' : 'pen-to-square'}
          size='xs'
        />
        <div>{t(`status.${curriculumStatus}`)}</div>
      </>
    );
  };

  const referenceActionText = () => {
    return (
      <CurriculumReference>
        <CurriculumReferenceIcon name='file-lines' size='xs' />
        {t('reference')}
      </CurriculumReference>
    );
  };

  const otherActionText = () => {
    const assignmentType = requiredForUser
      ? t('required_for.you')
      : t('required_for.group', { group: requiredForGroups });
    return (
      <>
        <CurriculumAssignedActionIcon name='user' size='2xs' weight='solid' />
        <CurriculumAssignmentType>{assignmentType}</CurriculumAssignmentType>
      </>
    );
  };

  const handleActionText = (kind: CurriculumsAssignmentsItemKind) => {
    switch (kind) {
      case 'owned_by_you':
        return ownerActionText();
      case 'reference':
        return referenceActionText();
      case 'recent':
        if (isOwner) {
          return ownerActionText();
        } else if (isReference) {
          return referenceActionText();
        } else {
          return otherActionText();
        }
      default:
        return otherActionText();
    }
  };

  const ownerNavigationLink = useCallback(() => {
    return { href: routes.curriculumEdit({ slug, id: curriculumId }).href };
  }, [curriculumId, slug]);

  const showCurriculumNavigationLink = useCallback(() => {
    return { href: routes.curriculumShow({ slug, id: curriculumId }).href };
  }, [curriculumId, slug]);

  const navigationLinkForCompletionRequiredWithoutNextStepId = useCallback(() => {
    if (!elementId) return showCurriculumNavigationLink();

    return (
      ELEMENT_TYPE_TO_CONSUME_ROUTE[elementType]({ slug, id: elementId }).link ||
      showCurriculumNavigationLink()
    );
  }, [elementId, elementType, showCurriculumNavigationLink, slug]);

  const navigationLinkForCompletionRequired = useCallback(() => {
    if (nextStepId) {
      return routes.consume({ slug, id: nextStepId }).link;
    } else {
      return navigationLinkForCompletionRequiredWithoutNextStepId();
    }
  }, [navigationLinkForCompletionRequiredWithoutNextStepId, nextStepId, slug]);

  const navigationLink = useMemo(() => {
    switch (kind) {
      case 'reference':
      case 'incomplete':
        return navigationLinkForCompletionRequired();

      case 'recent':
        if (isOwner) return ownerNavigationLink();
        if (isReference) {
          return isPublished && elementId
            ? navigationLinkForCompletionRequiredWithoutNextStepId()
            : showCurriculumNavigationLink();
        }
        return isPublished && (nextStepId || elementId)
          ? navigationLinkForCompletionRequired()
          : showCurriculumNavigationLink();

      case 'owned_by_you':
        return ownerNavigationLink();

      default:
        return showCurriculumNavigationLink();
    }
  }, [
    elementId,
    isOwner,
    isPublished,
    isReference,
    kind,
    navigationLinkForCompletionRequired,
    navigationLinkForCompletionRequiredWithoutNextStepId,
    nextStepId,
    ownerNavigationLink,
    showCurriculumNavigationLink,
  ]);

  const incompleteOrReference = ['incomplete', 'reference'].includes(kind);
  const showESignatureBadge = hasESignatureFeature && eSignatureRequired;
  const showOverdueBadge = overdue && incompleteOrReference;
  const showJustUpdatedBadge = justUpdated && incompleteOrReference && !overdue;
  const hasNoBadges = !showESignatureBadge && !showOverdueBadge && !showJustUpdatedBadge;

  return (
    <CurriculumItem
      className='curriculum-assignments-item'
      hideOnHover={showCertificate}
      {...navigationLink}
    >
      <CurriculumEmoji>
        {curriculumEmoji ? (
          curriculumEmoji
        ) : (
          <Icon className='default-emoji' name='file-alt' size='sm' weight='regular' />
        )}
      </CurriculumEmoji>
      <CurriculumInner kind={kind}>
        <CurriculumTitleWrapper className='curriculum-assignments-title'>
          <CurriculumTitle>{title}</CurriculumTitle>
          {isPublished && (
            <FavoriteIconWrapper onClick={toggleFavorite}>
              <FavoriteIcon
                className={`favorite-icon${favorite ? ' is-favorite' : ''}`}
                isFavorite={favorite}
                name='star'
                weight={favorite ? 'solid' : 'regular'}
              />
            </FavoriteIconWrapper>
          )}
        </CurriculumTitleWrapper>
        <CurriculumInfo hasNoBadges={hasNoBadges}>
          <CurriculumAssignmentsBadges
            showESignatureBadge={showESignatureBadge}
            showJustUpdatedBadge={showJustUpdatedBadge}
            showOverdueBadge={showOverdueBadge}
          />
          {showTimeEstimates && (
            <CurriculumInfoItem className='hidden-on-parent-hover'>
              {t('read', { time: curriculumReadingTime })}
            </CurriculumInfoItem>
          )}
          {showCertificate && <CompletionCertLink completionId={completionId} />}
          <CurriculumInfoItem followedByHidden={showCertificate}>
            {terminology[`${curriculumSector}`].singular}
          </CurriculumInfoItem>
        </CurriculumInfo>
      </CurriculumInner>
      {!['owned_by_you', 'reference', 'recent'].includes(kind) && (
        <CurriculumProgressBar>
          <ProgressBar percent={completionScore} thickness='lg' />
          <CurriculumCompletion className='notranslate'>{completionScore}%</CurriculumCompletion>
        </CurriculumProgressBar>
      )}
      <CurriculumActionWrapper>
        <CurriculumAssignedActionInner className='curriculum-assigned-action'>
          {handleActionText(kind)}
        </CurriculumAssignedActionInner>
        <StyledButton
          className='curriculum-assignments-button'
          fullWidth
          id={`curriculum-assignments-button-${curriculumId}`}
          text={handleButtonText(kind)}
        />
      </CurriculumActionWrapper>
    </CurriculumItem>
  );
};

export default CurriculumsAssignmentsItem;
