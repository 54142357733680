import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import translationClassname from '../../../../../lib/translationClassname';
import { useAdminUpdateCurriculumMutation } from '../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import ClickInlineTextarea from '../../../../design_system/Triage/ClickInlineTextarea/ClickInlineTextarea';
import { SharedFontCss } from '../../../../design_system/Triage/ResizableInput/ResizableInput';
import { MultilineTruncatedText } from '../../../../design_system/Triage/TruncatedText';

const StyledP = styled.p`
  margin-bottom: 0;
  ${MultilineTruncatedText({ lineClamp: 2 })};
  ${SharedFontCss};
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  width: fit-content;
`;

// DS Override: Pointer events none was required to get tooltip to show up
const StyledTooltip = styled(Tooltip)`
  pointer-events: none;
`;

type Props = {
  title: string;
  id: number;
  isLocked?: boolean;
  containsSignature?: boolean;
  disableTranslation?: boolean;
};

const t = initTranslations('curriculums.title');

const Title = ({ id, title, isLocked, containsSignature, disableTranslation }: Props) => {
  const [update, result] = useAdminUpdateCurriculumMutation();
  const { isSuccess, reset } = result;
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_message', { curriculum: subjectTermSingular }),
  });

  const setTitle = useCallback(
    (newTitle: string) => {
      update({ title: newTitle, id });
    },
    [id, update]
  );

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);

  return (
    <>
      {isLocked ? (
        <Wrapper id='locked-title'>
          <StyledTooltip id='title-locked-tooltip' text={t('locked_tooltip')} />
          <StyledP className={disableTranslation ? 'notranslate' : ''}>{title}</StyledP>
          <div data-for='title-locked-tooltip' data-tip>
            <Icon name='lock' size='sm' />
          </div>
        </Wrapper>
      ) : containsSignature ? (
        <Wrapper id='locked-title'>
          <StyledP className={translationClassname(disableTranslation)}>{title}</StyledP>
        </Wrapper>
      ) : (
        <Wrapper id='editable-title'>
          <ClickInlineTextarea
            attributeName='title'
            disableTranslation={disableTranslation}
            initialValue={title}
            inputFontSize='lg'
            maxCharacters={100}
            placeholder=''
            resourceName='curriculum'
            submitChanges={setTitle}
          />
        </Wrapper>
      )}
    </>
  );
};

export default Title;
