import React, { useContext } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../lib/initTranslations';
import { useGetUserProgressQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import { AccountTerminologyContext } from '../../../AccountTerminologyProvider';
import Avatar from '../../../design_system/display/avatar';
import { CompletionStatusCircle } from '../../../design_system/display/avatar/Avatar';
import Icon from '../../../design_system/display/icons/Icon';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import { routes } from '../../publicApplication/applicationRouter';
import RouteTo from '../../publicApplication/routeTo';
import { WidgetContainer } from '../shared/styles';
import {
  CompanyProgressBarPercentage,
  CompanyProgressBarWrapper,
  HomeBlockBody,
  HomeBlockHeader,
  InfoIcon,
  ProgressBarWrapper,
  ProgressContent,
  ProgressItem,
  ProgressRow,
  ProgressSubtitle,
} from './styles';

const t = initTranslations('home.progress_block');

export type ProgressBlockProps = {
  userId: number;
};

const ProgressBlock = ({ userId }: ProgressBlockProps) => {
  const accountTerminology = useContext(AccountTerminologyContext);
  const { curriculum, topic } = accountTerminology;
  const { slug } = useCurrentAccount();
  const { data, isLoading } = useGetUserProgressQuery(userId);

  if (isLoading) return <></>;
  if (!data) return <></>;

  const {
    accountCompletionPercentage,
    avatar,
    coursesRemainingCount,
    completionPercentage,
    curriculumsRemainingCount,
    name,
    surveysRemainingCount,
    readingTimeRemaining,
    id,
  } = data;

  return (
    <WidgetContainer id='home-progress'>
      <HomeBlockHeader>{t('title')}</HomeBlockHeader>
      <HomeBlockBody>
        <ProgressRow>
          <ProgressBarWrapper>
            <Avatar
              completionPercent={completionPercentage}
              image={avatar}
              name={name}
              onClick={() => RouteTo(routes.userProfile({ slug, id }))}
              shape='circle'
              size='md'
            />
          </ProgressBarWrapper>
          <ProgressContent>
            <ProgressSubtitle className='notranslate'>{name}</ProgressSubtitle>
            <div>
              <ProgressItem>
                <strong>{completionPercentage}%</strong> {t('completed')}
              </ProgressItem>
              <ProgressItem>
                <strong>{curriculumsRemainingCount}</strong>{' '}
                {t('items_remaining', {
                  count: curriculumsRemainingCount,
                  plural: curriculum.plural.toLowerCase(),
                  singular: curriculum.singular.toLowerCase(),
                })}
              </ProgressItem>
              <ProgressItem>
                <strong>{coursesRemainingCount}</strong>{' '}
                {t('items_remaining', {
                  count: coursesRemainingCount,
                  plural: topic.plural.toLowerCase(),
                  singular: topic.singular.toLowerCase(),
                })}
              </ProgressItem>
              <ProgressItem>
                <strong>{surveysRemainingCount}</strong>{' '}
                {t('tests_remaining', { count: surveysRemainingCount })}
              </ProgressItem>
              <ProgressItem>
                <strong>{readingTimeRemaining}</strong> {t('reading_time_remaining')}
                <InfoIcon data-for='reading-time-remaining' data-tip>
                  <Icon name='info-circle' size='2xs' weight='regular' />
                </InfoIcon>
                <Tooltip id='reading-time-remaining' text={t('reading_time_remaining_tooltip')} />
              </ProgressItem>
            </div>
          </ProgressContent>
        </ProgressRow>
        <ProgressRow>
          <ProgressBarWrapper>
            <CompanyProgressBarWrapper>
              <CompanyProgressBarPercentage>
                {accountCompletionPercentage}%
              </CompanyProgressBarPercentage>
              <CompletionStatusCircle
                barWidth={6}
                circleSize={52}
                completionPercent={accountCompletionPercentage}
              />
            </CompanyProgressBarWrapper>
          </ProgressBarWrapper>
          <ProgressContent>
            <ProgressSubtitle>{t('company_progress')}</ProgressSubtitle>
            <ProgressItem>
              <strong>{accountCompletionPercentage}%</strong> {t('average_completion_score')}
            </ProgressItem>
          </ProgressContent>
        </ProgressRow>
      </HomeBlockBody>
    </WidgetContainer>
  );
};

export default ProgressBlock;
