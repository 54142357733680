import React, { Dispatch, SetStateAction } from 'react';

import { ThreeDotLoaderGif } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { EditableCurriculumElement } from '../../../../../types/CurriculumElement';
import StatusBadgeActions from '../../../shared/CurriculumElements/CurriculumElementActionRow/StatusBadgeActions';
import CurriculumElementThreeDot from '../../../shared/CurriculumElementThreeDot/CurriculumElementThreeDot';
import { RowActionsContainer } from '../CurriculumRowStyles';
import { LoadingImage, LoadingText, TestGeneratingLoadingContainer } from './styles';

type Props = {
  blockEditPrivileges: boolean;
  curriculumId: number;
  curriculumElement: EditableCurriculumElement;
  isLocked?: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
};

const t = initTranslations('curriculums_view');

const ElementRowActions = ({
  blockEditPrivileges,
  curriculumId,
  curriculumElement,
  isLocked,
  setIsEditing,
}: Props) => {
  const { element, elementKind } = curriculumElement;
  const { status } = element;

  const isProcessingAIComposeSurvey = elementKind === 'survey' && element.inProcessingQueue;

  return (
    <RowActionsContainer>
      {isProcessingAIComposeSurvey && (
        <TestGeneratingLoadingContainer>
          <LoadingText>{t('generating_test')}</LoadingText>
          <LoadingImage alt='three dot loader' src={ThreeDotLoaderGif} />
        </TestGeneratingLoadingContainer>
      )}
      {!isProcessingAIComposeSurvey && (
        <StatusBadgeActions
          curriculumElement={{ id: curriculumElement.id, status }}
          curriculumId={curriculumId}
          infoText={
            elementKind === 'survey'
              ? t('questions', {
                  count: element.questionCount,
                })
              : undefined
          }
        />
      )}
      {!blockEditPrivileges && (
        <CurriculumElementThreeDot
          canBeModified
          curriculumElement={curriculumElement}
          curriculumId={curriculumId}
          eSignatureDisplayedAndRequired={false}
          isCurriculumLocked={isLocked}
          renameClickHandler={() => setIsEditing(true)}
        />
      )}
    </RowActionsContainer>
  );
};

export default ElementRowActions;
