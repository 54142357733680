import React from 'react';
import styled from 'styled-components';

import { NoGroupResultsGraphic, NoGroupResultsGraphicDark } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../../redux/baseQueries/types';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { ResponsibilitiesResponse } from '../../../../../redux/services/resourceApis/responsibilities/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import LoadingContainer from '../../../../design_system/LoadingContainer';
import { StyledNoResults } from '../UserGroupResponsibilities/GroupResponsibilitiesList';
import ResponsibilityNameList from '../UserGroupResponsibilities/ResponsibilityNameList/ResponsibilityNameList';

const StyledUl = styled.ul`
  list-style-type: none;
`;

const t = initTranslations('users_profile');

interface Props {
  result: UseQueryResult<ResponsibilitiesResponse>;
}

const NonAdminUserResponsibilitesList = ({ result }: Props) => {
  const {
    responsibility: { plural: responsibilityPlural },
  } = useAccountTerminology();

  if (result.isLoading) return <LoadingContainer />;
  if (result.error) return <BasicErrorDisplay error={result.error} />;
  if (!result.data) return <></>;

  if (result.data.assigned_responsibilities.length === 0) {
    return (
      <StyledNoResults
        className='no-results-user-responsibilities'
        darkImage={NoGroupResultsGraphicDark}
        heading={t('no_other_responsibilities_heading', {
          responsibilities: responsibilityPlural.toLowerCase(),
        })}
        lightImage={NoGroupResultsGraphic}
        minHeight='18.5rem'
      />
    );
  }

  const responsibilities = result.data.assigned_responsibilities.map(
    (assignedResponsibility) => assignedResponsibility.responsibility
  );

  return (
    <StyledUl>
      <ResponsibilityNameList isGroup={false} responsibilities={responsibilities} />
    </StyledUl>
  );
};

export default NonAdminUserResponsibilitesList;
