import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import styled from 'styled-components';

import useDisplayFlashOnResponse from '../../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../../redux/errors/BasicErrorDisplay';
import { useGetCurriculumElementsForAdminQuery } from '../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { useUpdateCurriculumServiceMutation } from '../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { User } from '../../../../../../types/User';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import Loader from '../../../../../design_system/Triage/Loader';
import { mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../../styled/TypeSystem';
import { StatusIcon } from '../../../../editor/components/StatusDropdown/StatusDropdown';
import SuperShareModal from '../../../../people/SuperShare/SuperShareModal/SuperShareModal';
import { OptionType } from '../../../../shared/ActionDropdown/ActionDropdownMenuOption/ActionDropdownMenuOption';
import DropdownWithPoppableMenu from '../../../../shared/DropdownWithPoppableMenu';
import AssignOnPublishModal from './AssignOnPublishModal/AssignOnPublishModal';
import { initialState, modalReducer } from './PublishModal/modalReducer';
import PublishModal from './PublishModal/PublishModal';
import PublishOption from './PublishOption';

const TextWrapper = styled.div`
  display: flex;
  margin-right: ${({ theme: { constants } }) => constants.spacerSm2};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;

  @media (min-width: ${mediaBreakpointPxXl}) {
    margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

const Title = styled.p`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm5};
`;

const t = initTranslations('curriculums.publish_dropdown');

type OptionData = {
  label: string;
  description: string;
  badgeType: OptionType.Finished | OptionType.PendingReview;
};

export type PublishDropdownProps = {
  allUsersAssigned: User[];
  id: number;
  published: boolean;
  subject: { title: string; emoji: string | null };
};

const PublishDropdown = ({ allUsersAssigned, id, published, subject }: PublishDropdownProps) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const [update, result] = useUpdateCurriculumServiceMutation();
  const { data, error, isLoading } = useGetCurriculumElementsForAdminQuery(id);
  const {
    curriculum: { singular: subjectTermSingular },
    topic: { singular: topicTermSingular },
  } = useAccountTerminology();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const onboardingTask = queryParams.get('onboarding_task');
    if (onboardingTask === 'publish_subject') {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);

  const canNotPublishAsIs = useMemo(() => {
    return (
      (data?.publishState === 'elements-incomplete' && !published) ||
      (data?.publishState === 'force-finish-to-publish' && !published)
    );
  }, [data?.publishState, published]);

  const setToPublished = useCallback(
    ({ finishCourses }: { finishCourses: boolean }) => {
      if (allUsersAssigned.length === 0) {
        dispatch({ type: 'SHOW_ASSIGN_MODAL' });
      }
      update({ id, published: true, finishCourses });
      dispatch({ type: 'HIDE_FINISH_MODAL' });
    },
    [allUsersAssigned.length, update, id]
  );

  const onPublishSelection = useCallback(
    (selectedIndex: number) => {
      const isPublishing = selectedIndex === 0;
      if (published === isPublishing) return;

      if (isPublishing && canNotPublishAsIs) {
        dispatch({ type: 'SHOW_FINISH_MODAL' });
      } else if (isPublishing) {
        setToPublished({ finishCourses: false });
      } else {
        update({ id, published: false, finishCourses: false });
      }
    },
    [canNotPublishAsIs, id, published, setToPublished, update]
  );

  const optionData: OptionData[] = useMemo(
    () => [
      {
        label: t('publish'),
        description: t('publish_description', { subject: subjectTermSingular }),
        badgeType: OptionType.Finished,
      },
      {
        label: t('unpublish'),
        description: t('unpublish_description'),
        badgeType: OptionType.PendingReview,
      },
    ],
    [subjectTermSingular]
  );

  const options = useMemo(
    () =>
      optionData.map((optionData) => {
        return (
          <PublishOption
            badgeType={optionData.badgeType}
            description={optionData.description}
            key={optionData.label}
            title={optionData.label}
          />
        );
      }),
    [optionData]
  );

  const SelectedOption = useMemo(() => {
    const selectedOptionIndex = published ? 0 : 1;
    return (
      <TextWrapper>
        <StatusIcon
          name='circle'
          size='xs'
          status={optionData[selectedOptionIndex].badgeType}
          weight='solid'
        />
        <Title>{optionData[selectedOptionIndex].label}</Title>
      </TextWrapper>
    );
  }, [optionData, published]);

  useDisplayFlashOnResponse({
    result,
    errorMessage: t('error_message', { subject: subjectTermSingular }),
    successFunction: () => result.reset(),
  });

  if (isLoading) return <Loader />;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!data) return <></>;

  const { publishState } = data;
  const isUnpublishable = publishState === 'unpublishable' && !published;

  return (
    <>
      {isUnpublishable ? (
        <>
          <Tooltip
            id='disabled-toggle'
            text={t('disabled_publish_button_tooltip', {
              topic: topicTermSingular.toLowerCase(),
              subject: subjectTermSingular.toLowerCase(),
            })}
          />
          <div data-for='disabled-toggle' data-tip>
            <DropdownWithPoppableMenu
              id='publish-dropdown'
              lockDropdown
              menuId='publish-dropdown-menu'
              menuPlacement='bottom-start'
              options={options}
              selectedOption={SelectedOption}
              setSelectedOption={onPublishSelection}
            />
          </div>
        </>
      ) : (
        <DropdownWithPoppableMenu
          id='publish-dropdown'
          lockDropdown={result.isLoading}
          menuId='publish-dropdown-menu'
          menuPlacement='bottom-start'
          options={options}
          selectedOption={SelectedOption}
          setSelectedOption={onPublishSelection}
        />
      )}
      {state.showFinishModal && (
        <PublishModal
          forceFinishToPublish={publishState === 'force-finish-to-publish'}
          id={id}
          onClose={() => dispatch({ type: 'HIDE_FINISH_MODAL' })}
          primaryButtonTask={() => setToPublished({ finishCourses: true })}
          secondaryButtonAction={() => setToPublished({ finishCourses: false })}
          subject={subject}
        />
      )}
      {state.showAssignModal && (
        <AssignOnPublishModal
          actionFunction={() => dispatch({ type: 'SHOW_MANAGE_USERS' })}
          onCloseRequest={() => dispatch({ type: 'HIDE_ASSIGN_MODAL' })}
        />
      )}
      {state.showManageUsers && (
        <SuperShareModal
          curriculumId={id}
          setShowSuperShareModal={() => dispatch({ type: 'HIDE_MANAGE_USERS' })}
          showSuperShareModal
        />
      )}
    </>
  );
};

export default PublishDropdown;
