import React, { memo } from 'react';

import { EditableCurriculumElement } from '../../../../../../types/CurriculumElement';
import { Placeholder } from '../../../../shared/DragAndDrop/types';
import { CurriculumElementsTableProps } from '../CurriculumElementsTable/CurriculumElementsTable';
import CurriculumOutlineElementRow from '../CurriculumOutlineElementRow/CurriculumOutlineElementRow';

type CurriculumElementsListProps = {
  curriculumElements: EditableCurriculumElement[];
  isLocked: boolean;
  signaturable: boolean;
  placeholder: Placeholder;
  blockEditPrivileges: boolean;
} & CurriculumElementsTableProps;

const CurriculumElementsList = memo((props: CurriculumElementsListProps) => {
  const {
    curriculumId,
    curriculumElements,
    isLocked,
    signaturable,
    placeholder,
    blockEditPrivileges,
    userAccess,
  } = props;

  return (
    <>
      {curriculumElements.map((curriculumElement, index) => {
        return (
          <CurriculumOutlineElementRow
            blockEditPrivileges={blockEditPrivileges}
            curriculumElement={curriculumElement}
            curriculumId={curriculumId}
            index={index}
            isLocked={isLocked}
            key={`curriculum-element-${curriculumElement.id}`}
            placeholder={placeholder}
            signaturable={signaturable}
            userAccess={userAccess}
          />
        );
      })}
    </>
  );
});
CurriculumElementsList.displayName = 'CurriculumElementsList';

export default CurriculumElementsList;
