import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import IconButton from '../../../design_system/buttons/IconButton';
import Icon from '../../../design_system/display/icons/Icon';
import { getChevronIconName } from '../helpers';

const t = initTranslations('curriculums.content.library_row');

type Props = {
  id: number;
  isOpen: boolean;
  noAccess: boolean;
  onClick: () => void;
  title: string;
};

const ChevronControl = ({ id, isOpen, noAccess, onClick, title }: Props) => {
  return (
    <>
      {noAccess ? (
        <Icon aria-label={t('locked')} name='lock' size='sm' weight='light' />
      ) : (
        <IconButton
          ariaLabel={t('aria_label', {
            curriculumTitle: title,
            options: isOpen ? t('collapse') : t('expand'),
          })}
          buttonSize='sm'
          id={`toggle-expand-row-${id}`}
          name={getChevronIconName({ isActive: !isOpen, initialDirection: 'right' })}
          onClick={onClick}
          weight='light'
        />
      )}
    </>
  );
};

export default ChevronControl;
