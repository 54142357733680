import React from 'react';

import getLetterByPosition from '../../../../../../../../lib/surveys/getLetterByPosition';
import {
  ChoiceLetter,
  ChoiceTextWrapper,
  ChoiceWrapper,
  ElementSelectionFieldsWrapper,
  SelectionField,
  SelectionFieldWrapper,
  StyledIcon,
  StyledInput,
  StyledLabel,
} from '../styles';
import { ElementSelectionFieldsProps } from '../types';

const ElementSelectionFields = ({
  inputType,
  name,
  onChange,
  options,
  selectedValue,
}: ElementSelectionFieldsProps) => {
  return (
    <ElementSelectionFieldsWrapper className={`choices-${inputType}-field`}>
      {options.map((option) => {
        const { text, value, position } = option;
        const isChecked = Array.isArray(selectedValue)
          ? selectedValue.includes(value)
          : selectedValue === value;
        const elementId = `${inputType}-option-${value}`;
        const iconName =
          inputType === 'checkbox'
            ? isChecked
              ? 'square-check'
              : 'square'
            : isChecked
            ? 'circle-dot'
            : 'circle';

        return (
          <SelectionFieldWrapper key={value}>
            <SelectionField>
              <StyledInput
                checked={isChecked}
                id={elementId}
                name={name}
                onChange={onChange}
                type={inputType}
                value={value}
              />
              <StyledIcon isChecked={isChecked} name={iconName} size='sm' />
            </SelectionField>
            <StyledLabel htmlFor={elementId} key={value}>
              <ChoiceWrapper isChecked={isChecked}>
                <ChoiceLetter className='notranslate' isChecked={isChecked}>
                  {getLetterByPosition(position)}
                </ChoiceLetter>
                <ChoiceTextWrapper>{text}</ChoiceTextWrapper>
              </ChoiceWrapper>
            </StyledLabel>
          </SelectionFieldWrapper>
        );
      })}
    </ElementSelectionFieldsWrapper>
  );
};

export default ElementSelectionFields;
