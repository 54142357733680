import React from 'react';

import { DelegationBoardEmptyStateImage } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import {
  BoardEmptyStateContainer,
  BoardEmptyStateImageContainer,
  BoardEmptyStateText,
  BoardEmptyStateTitle,
} from './styles';

const t = initTranslations('delegation_planner.board_empty_state');

const BoardEmptyState = () => {
  return (
    <BoardEmptyStateContainer>
      <BoardEmptyStateImageContainer src={DelegationBoardEmptyStateImage} />

      <BoardEmptyStateTitle>{t('title')}</BoardEmptyStateTitle>

      <BoardEmptyStateText>{t('description')}</BoardEmptyStateText>
    </BoardEmptyStateContainer>
  );
};

export default BoardEmptyState;
