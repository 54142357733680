import React, { useEffect, useMemo } from 'react';
import { AccountState } from 'types/AccountState';
import { CurrentUser } from 'types/CurrentUser';

import { GroupsIndexProvider } from '../../../contexts/GroupsIndexContext';
import useIntegrations from '../../../hooks/useIntegrations';
import { fetchQueryParameters } from '../../../lib/getQueryParams';
import initTranslations from '../../../lib/initTranslations';
import { collapseSidebar } from '../../../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { mediaBreakpointXl } from '../../styled/Breakpoint';
import AllCurriculumsReport from '../reports/././AllCurriculumsReport';
import AccountSettingsPage from '../account_settings/AccountSettingsPage';
import AiBotPage from '../ai/aiBotPOC/AiBotPage';
import DeprecatedUrlBanner from '../banners/DeprecatedUrlBanner';
import BillingFullScreenOverlayFactory from '../billing/per_user_pricing/BillingFullScreenOverlayFactory/BillingFullScreenOverlayFactory';
import { BillingPage } from '../billing/shared/BillingPage/BillingPage';
import AddonFullScreenOverlayFactory from '../billing/simple_pricing/AddonFullScreenOverlays/AddonFullScreenOverlayFactory';
import CheckoutFullScreenOverlay from '../checkout/CheckoutFullScreenOverlay/CheckoutFullScreenOverlay';
import ContentLibrary from '../ContentLibrary/ContentLibraryPage/ContentLibrary';
import ContentLibrarySetOrder from '../ContentLibrary/SetOrderPage/SetOrderPage';
import CourseConsume from '../courses/Course/CourseConsume';
import CourseEdit from '../courses/CourseEdit/CourseEdit';
import BulkDocumentImportFullScreenOverlay from '../curriculums/BulkDocumentImportFullScreenOverlay/BulkDocumentImportFullScreenOverlay';
import CreateCurriculumFullScreenOverlay from '../curriculums/CreateCurriculumFullScreenOverlay/CreateCurriculumFullScreenOverlay';
import CurriculumAdmin from '../curriculums/CurriculumAdmin/CurriculumAdmin';
import ManageCompletions from '../curriculums/CurriculumEdit/ManageCompletions/ManageCompletions';
import CurriculumEdit from '../curriculums/CurriculumEdit2/CurriculumEdit';
import CurriculumPrint from '../curriculums/CurriculumPrint/CurriculumPrint';
import CurriculumShow from '../curriculums/CurriculumShow/CurriculumShow';
import RequestAccessPage from '../curriculums/RequestAccessPage/RequestAccessPage';
import BoardPage from '../DelegationPlanner/Board/BoardPage';
import DelegationPlannerPage from '../DelegationPlanner/DelegationPlannerPage';
import ConsumptionPage from '../editor/ConsumptionPage/ConsumptionPage';
import EditorPage from '../editor/EditorPage/EditorPage';
import PDFExportPage from '../editor/PDFExportPage/PDFExportPage';
import StepVersionHistory from '../editor/StepVersionHistory/Page';
import FlowchartConsume from '../flowcharts/FlowchartConsume/FlowchartConsume';
import FlowchartEditor from '../flowcharts/FlowchartEditor/FlowchartEditor';
import GroupsEditView from '../groups/GroupsEditView/GroupsEditView';
import GroupsIndexView from '../groups/GroupsIndexView/GroupsIndexView';
import HomePage from '../home/HomePage/HomePage';
import IntegrationShowPage from '../integrations/IntegrationShowPage';
import IntegrationsPage from '../integrations/IntegrationsPage';
import CoursesAndTemplates from '../marketplaces/CoursesAndTemplates/CoursesAndTemplates';
import NotificationsPage from '../notifications/NotificationsPage';
import CardView from '../people/CardView';
import GroupChart from '../people/GroupChart';
import OrgChart from '../people/OrgChart';
import Profile from '../people/Profile/Profile';
import ProfileSubjectsPage from '../people/Profile/SubjectsAssigned/ProfileSubjectsPage';
import Settings from '../people/Settings';
import UsersOutline from '../people/UsersOutline';
import LatestActivityReport from '../reports/ActivityLogPage';
import AllUsersReport from '../reports/AllUsersReportPage/AllUsersReportPage';
import CourseReport from '../reports/CourseReportPage';
import ElementReport from '../reports/ElementReportPage';
import IndividualUserReport from '../reports/IndividualUserReportPage/IndividualUserReportPage';
import PaywallReportsPage from '../reports/PaywallReportsPage/PaywallReportsPage';
import SurveyByUserReport from '../reports/SurveyReportByUserPage/SurveyReportByUserPage';
import SurveyReport from '../reports/SurveyReportPage';
import UserAssignmentReport from '../reports/UserAssignmentReportPage';
import RequestsPage from '../requests/RequestsPage';
import SearchResultsPage from '../searches/SearchResultsPage/SearchResultsPage';
import StripeElementsProvider from '../shared/UpdatePaymentMethodForm/StripeElementsProvider/StripeElementsProvider';
import SingUpLinkExpired from '../SingUpLinkExpired/SingUpLinkExpired';
import SurveyConsumption from '../surveys/SurveyConsumption/SurveyConsumption';
import SurveyEdit from '../surveys/SurveyEdit/SurveyEdit';
import SurveyEditor from '../surveys/SurveyEdit/SurveyEditor';
import SurveyResults from '../surveys/SurveyResults/SurveyResults';
import TemplatePreviewPage from '../templates/TemplatePreviewPage/TemplatePreviewPage';
import TemplatesOutlineIndexPage from '../templates/TemplatesOutlinePage/TemplatesOutlineIndexPage/TemplatesOutlineIndexPage';
import PathConfig from '../TrainingPath/PathConfig/PathConfig';
import PathOverview from '../TrainingPath/PathOverview/PathOverview';
import VideoConsume from '../videos/VideoConsume';
import VideoEdit from '../videos/VideoEdit';
import { session, useRoute } from './applicationRouter';
import AcceptInvitation from './invitations/AcceptInvitation';
import AccountSelector from './login/AccountSelector/AccountSelector';
import Login from './login/Login/Login';
import UniversalLogin from './login/UniversalLogin/UniversalLogin';
import ForgotPassword from './passwords/ForgotPassword/ForgotPassword';
import ResetPassword from './passwords/ResetPassword/ResetPassword';
import { InternalIndexFeature } from './route_types/internalIndex';
import SignUpPage from './SignUpPage/SignUpPage';

const t = initTranslations('router');

type Props = {
  user?: CurrentUser;
  account?: AccountState;
};

const ApplicationRoutes = ({ user, account }: Props) => {
  const route = useRoute();
  const dispatch = useAppDispatch();

  useIntegrations(route, user, account);

  useEffect(() => {
    let timeout: number;
    const removeListener = session.listen((nextRoute) => {
      // If we are navigating to a react route, we want to collapse the sidebar on mobile
      if (nextRoute.name) {
        if (window.document.documentElement.clientWidth < mediaBreakpointXl) {
          dispatch(collapseSidebar());
        }
      } else {
        // Until fully on react-router we will reload page to switch back to legacy routes
        // without this it would yield a blank screen on back button to a legacy route.
        // setTimeout is used due to issue here: https://github.com/zilch/type-route/issues/123
        timeout = setTimeout(() => {
          window.location.reload();
        });
      }
    });

    return () => {
      clearTimeout(timeout);
      removeListener();
    };
  }, [dispatch]);

  const page = useMemo(() => {
    switch (route.name) {
      /* un-authenticated routes */
      case 'root':
        return <SignUpPage route={route} />;
      case 'signUpLinkExpired':
        return <SingUpLinkExpired titleKey='this_link_has_expired' />;
      case 'login':
        return <Login route={route} />;
      case 'universalLogin':
        return <UniversalLogin route={route} />;
      case 'accountSelection':
        return <AccountSelector route={route} />;
      case 'forgotPassword':
        return <ForgotPassword route={route} />;
      case 'resetPassword':
        return <ResetPassword route={route} />;
      case 'acceptInvitation':
        return <AcceptInvitation route={route} />;
      case 'internalAuthIndex': {
        switch (route.params.feature) {
          case InternalIndexFeature.PDFExport:
            return <PDFExportPage route={route} />;
        }
        return <>{t('internal_index.unsupported_feature')}</>;
      }

      /* authenticated routes */
      case 'billing':
        return <BillingPage />;
      case 'consume':
        return <ConsumptionPage route={route} />;
      case 'editor':
        return <EditorPage route={route} />;
      case 'courseConsume':
        return <CourseConsume route={route} />;
      case 'courseEditor':
        return <CourseEdit route={route} />;
      case 'flowchartConsume':
        return <FlowchartConsume route={route} />;
      case 'flowchartEditor':
        return <FlowchartEditor route={route} />;
      case 'videoEdit':
        return <VideoEdit route={route} />;
      case 'videoConsume':
        return <VideoConsume route={route} />;
      case 'content':
        return <ContentLibrary route={route} />;
      case 'contentOrder':
        return <ContentLibrarySetOrder />;
      case 'curriculumV2Edit':
        return <CurriculumEdit route={route} />;
      case 'curriculumAdmin':
        return <CurriculumAdmin route={route} />;
      case 'curriculumEdit':
        if (account?.splitFeatures?.subjectEdit2Point0Enabled) {
          return <CurriculumEdit route={route} />;
        }
        return <CurriculumAdmin route={route} />;
      case 'curriculumShow':
        return <CurriculumShow route={route} />;
      case 'curriculumPrint':
        return <CurriculumPrint route={route} />;
      case 'curriculumRequestAccess':
        return <RequestAccessPage route={route} />;
      case 'directory':
        return <CardView />;
      case 'manageUsers':
        return <UsersOutline />;
      case 'userProfile':
        return <Profile route={route} />;
      case 'userTrainingPath':
        return <PathOverview route={route} />;
      case 'userTrainingPathConfig':
        return <PathConfig route={route} />;
      case 'userAssignedCurriculums':
        return <ProfileSubjectsPage route={route} />;
      case 'mySettings':
        return <Settings />;
      case 'upgradeSimplePricingPlan':
        return <BillingFullScreenOverlayFactory id='upgrade-simple-pricing-plan-overlay' />;
      case 'pickASimplePricingPlan':
        return <BillingFullScreenOverlayFactory id='pick-a-simple-pricing-plan-overlay' />;
      case 'configurePlan':
        return <BillingFullScreenOverlayFactory id='configure-plan-overlay' />;
      case 'comparePlans':
        return <BillingFullScreenOverlayFactory id='compare-plans-overlay' />;
      case 'downgradePlan':
        return <BillingFullScreenOverlayFactory id='downgrade-plan-overlay' />;
      case 'manageSeats':
        return <BillingFullScreenOverlayFactory id='manage-seats-overlay' />;
      case 'upgradePlan':
        return <BillingFullScreenOverlayFactory id='upgrade-plan-overlay' />;
      case 'orgChart':
        return <OrgChart />;
      case 'roleChart':
        return <GroupChart />;
      case 'groups':
        return (
          <GroupsIndexProvider route={route}>
            <GroupsIndexView />
          </GroupsIndexProvider>
        );
      case 'group':
        return <GroupsEditView route={route} />;
      case 'allCurriculumsReport':
        return <AllCurriculumsReport />;
      case 'allUsersReport':
        return <AllUsersReport />;
      case 'latestActivityReport':
        return <LatestActivityReport />;
      case 'individualUserReport':
        return <IndividualUserReport route={route} />;
      case 'curriculumByUserReport':
        return <UserAssignmentReport route={route} />;
      case 'curriculumByElementReport':
        return <ElementReport route={route} />;
      case 'courseReport':
        return <CourseReport route={route} />;
      case 'surveyReport':
        return <SurveyReport route={route} />;
      case 'surveyByUserReport':
        return <SurveyByUserReport route={route} />;
      case 'reportsUpgrade':
        return <PaywallReportsPage />;
      case 'templates':
        return <TemplatesOutlineIndexPage />;
      case 'template':
        return <TemplatePreviewPage route={route} />;
      case 'templatesSearch':
        return <TemplatesOutlineIndexPage />;
      case 'home':
        return <HomePage />;
      case 'integrations':
        return <IntegrationsPage />;
      case 'integration':
        return <IntegrationShowPage route={route} />;
      case 'search':
        return <SearchResultsPage route={route} />;
      case 'createCurriculumFullscreenOverlay':
        return <CreateCurriculumFullScreenOverlay />;
      case 'accountSettings':
        return <AccountSettingsPage route={route} />;
      case 'notifications':
        return <NotificationsPage />;
      case 'requests':
      case 'archivedRequests':
        return <RequestsPage />;
      case 'stepVersionHistory':
        return <StepVersionHistory route={route} />;
      case 'surveyEditor':
        return <SurveyEdit route={route} />;
      case 'surveyQuestionEditor':
        return <SurveyEditor route={route} />;
      case 'surveyConsume':
        return <SurveyConsumption route={route} />;
      case 'surveyResults':
        return <SurveyResults route={route} />;
      case 'manageCompletions':
        return <ManageCompletions route={route} />;
      case 'bulkDocumentImport':
        return <BulkDocumentImportFullScreenOverlay />;
      case 'checkout':
        return (
          <StripeElementsProvider>
            <CheckoutFullScreenOverlay />
          </StripeElementsProvider>
        );
      case 'delegationPlanner':
        return <DelegationPlannerPage />;
      case 'delegationPlannerBoard':
        return <BoardPage />;
      case 'signUpLinkUsed':
        return <SingUpLinkExpired titleKey='this_link_has_been_used' />;
      case 'addonOverlay':
        return <AddonFullScreenOverlayFactory route={route} />;
      case 'aiBotPoc': {
        if (account?.splitFeatures?.aiBotPocEnabled) {
          return <AiBotPage />;
        }
        return <></>;
      }
      case 'coursesAndTemplates':
        return <CoursesAndTemplates />;
      case false:
        // Once fully on router we will replace this with a 404 page
        return <></>;
    }
  }, [
    account?.splitFeatures?.aiBotPocEnabled,
    account?.splitFeatures?.subjectEdit2Point0Enabled,
    route,
  ]);

  const queryParams = fetchQueryParameters();
  let deprecatedUrlBanner: JSX.Element | null = null;

  if (queryParams.get('redirected_from_deprecated_url') === 'true') {
    const url = new URL(window.location.href).href.replace(
      /[&?]redirected_from_deprecated_url=true/,
      ''
    );
    deprecatedUrlBanner = <DeprecatedUrlBanner copyablePath={url} />;
  }

  return (
    <>
      {deprecatedUrlBanner}
      {page}
    </>
  );
};

export default ApplicationRoutes;
