import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Route } from 'type-route';

import useScrollTopOnChange from '../../../../hooks/useScrollTopOnChange';
import useSlideout from '../../../../hooks/useSlideout';
import initTranslations from '../../../../lib/initTranslations';
import { useAutosaveStatus } from '../../../../redux/domains/autoSave/autosaveSlice';
import { useCheckModalState } from '../../../../redux/domains/modalsSlice/modalsSlice';
import { useGetEditSurveyQuery } from '../../../../redux/services/resourceApis/surveys/surveysApi';
import ComposeButton from '../../ai/ComposeButton/ComposeButton';
import GoBackIconButton from '../../editor/components/GoBackIconButton/GoBackIconButton';
import { routes } from '../../publicApplication/applicationRouter';
import AutoSaveStatusIndicator from '../../shared/AutoSaveStatusIndicator/AutoSaveStatusIndicator';
import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import PreviewCurriculumElementHeader from '../../shared/preview/PreviewCurriculumElementHeader/PreviewCurriculumElementHeader';
import {
  MainContentWrapper,
  MainWrapper,
  QuestionCardWrapper,
  SurveyDataWrapper,
  SurveyEditDataPreviewWrapper,
  Toolbar,
  ToolbarWrapper,
} from '../shared/styles';
import EditModeSurveySkeleton from './EditModeSurveySkeleton/EditModeSurveySkeleton';
import ErrorsPreview from './ErrorsPreview/ErrorsPreview';
import LoadingModal from './modals/LoadingModal/LoadingModal';
import SmartTestModal from './modals/SmartTestModal/SmartTestModal';
import OutlineContent from './OutlineContent';
import {
  AutoComposeDescriptionButton,
  ButtonWrapper,
  SlideoutWrapper,
  SmartTestButton,
  StyledButton,
} from './styles';
import SurveyQuestion from './SurveyQuestion/SurveyQuestion';
import SurveySlideout from './SurveySlideout';
import SurveySlideoutOptions from './SurveySlideoutOptions';

const t = initTranslations('editor.create_survey');

export type SurveyEditorProps = {
  route: Route<typeof routes.surveyQuestionEditor>;
};

const SurveyEditor = ({ route }: SurveyEditorProps) => {
  const {
    params: { id: surveyQuestionId, slug, surveyId },
  } = route;
  const [isSurveyStatusChangeError, setIsSurveyStatusChangeError] = useState(false);
  const { close, open, isOpen } = useSlideout('survey-options-slideout');
  const [isSmartTestModalOpen, setIsSmartTestModalOpen] = useState(false);
  const isShowSurveyLoadingModal = useCheckModalState('surveyLoadingModal');
  const autosaveStatus = useAutosaveStatus();

  const { data: survey, isFetching } = useGetEditSurveyQuery({ id: surveyId });
  const currentQuestion = survey?.questions.find((question) => question.id === surveyQuestionId);
  const currentQuestionRef = useScrollTopOnChange(surveyQuestionId);

  useEffect(() => {
    if (!survey?.errors.length) {
      setIsSurveyStatusChangeError(false);
    }
  }, [survey?.errors.length]);

  const handleSmartTestButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setIsSmartTestModalOpen(true);

    e.target && (e.target as HTMLElement).blur();
  };

  if (!survey) return <EditModeSurveySkeleton />;

  const { lastEditedBy, lastEditedOn, updatedAtInWords } = survey.updatedAtData;

  const toggleSlideout = () => {
    const closeSlideoutBtn = document.getElementById('slideout-close-button');
    closeSlideoutBtn && closeSlideoutBtn.focus();
    isOpen ? close() : open();
  };

  const composeButton = (onClick: () => void) => {
    return (
      <AutoComposeDescriptionButton
        buttonType='secondary'
        iconName='wand-magic-sparkles'
        id='compose-survey-ai-description'
        onClick={onClick}
        text={t('auto_compose')}
      />
    );
  };

  const { cover, description, name, questions, curriculum, errors } = survey;
  const displayErrorsPreview = isSurveyStatusChangeError && !!errors.length;

  return (
    <FullScreenOverlay id='survey-edit-page-overlay' isSlideoutOpen={isOpen} withoutCloseIcon>
      <MainWrapper>
        <OutlineContent
          setIsSurveyStatusChangeError={setIsSurveyStatusChangeError}
          survey={survey}
        />

        <MainContentWrapper ref={currentQuestionRef}>
          <ToolbarWrapper>
            <Toolbar>
              <SmartTestButton
                buttonType='brand'
                iconName='wand-magic-sparkles'
                id='ai-smart-test-button'
                onClick={handleSmartTestButtonClick}
                text={t('auto_generate_test')}
              />
              {currentQuestion && (
                <AutoSaveStatusIndicator
                  autosaveStatus={autosaveStatus}
                  isFetching={isFetching}
                  lastEditedBy={lastEditedBy}
                  lastEditedOn={lastEditedOn}
                  updatedAtInWords={updatedAtInWords}
                />
              )}
              <GoBackIconButton
                onClick={() => routes.curriculumEdit({ slug, id: curriculum.id }).push()}
              />
            </Toolbar>
          </ToolbarWrapper>
          <SurveyDataWrapper>
            <SurveyEditDataPreviewWrapper>
              <PreviewCurriculumElementHeader
                cover={cover}
                description={description}
                disableTranslation={curriculum.disableTranslation}
                editable
                elementType='survey'
                id={surveyId}
                title={name}
              >
                <>
                  <ComposeButton
                    background='gray'
                    composeModalType='SurveyDescriptionModal'
                    customButton={composeButton}
                    metadata={{ surveyName: name, surveyId }}
                    text={t('auto_compose')}
                  />
                  {displayErrorsPreview ? <ErrorsPreview errors={errors} /> : undefined}
                </>
              </PreviewCurriculumElementHeader>
            </SurveyEditDataPreviewWrapper>
            <ButtonWrapper>
              <StyledButton
                buttonType='secondary'
                iconName='gear'
                iconWeight='regular'
                id='slideout-toggle-survey-options-button'
                onClick={toggleSlideout}
                size='sm'
                text={t('options')}
                width='100%'
              />
            </ButtonWrapper>
          </SurveyDataWrapper>
          <QuestionCardWrapper>
            <SurveyQuestion
              adjacentCurriculumElements={survey.adjacentCurriculumElements}
              questions={questions}
              route={route}
            />
          </QuestionCardWrapper>
        </MainContentWrapper>

        <SlideoutWrapper isOpen={isOpen}>
          <SurveySlideout slideoutType='survey-options-slideout' title={t('test_options')}>
            <SurveySlideoutOptions survey={survey} />
          </SurveySlideout>
        </SlideoutWrapper>

        {isSmartTestModalOpen && <SmartTestModal setShowModal={setIsSmartTestModalOpen} />}
        {isShowSurveyLoadingModal && <LoadingModal curriculumId={curriculum.id} />}
      </MainWrapper>
    </FullScreenOverlay>
  );
};

export default SurveyEditor;
