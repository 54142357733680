import React, { useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import { MetaUserAccess } from '../../../../../types/Curriculum';
import {
  CourseCurriculumElement,
  CurriculumElement,
  EditableCurriculumElementBase,
} from '../../../../../types/CurriculumElement';
import { RowCardBody, RowContainer } from '../CurriculumRowStyles';
import ElementEmojiAndTitle from './ElementEmojiAndTitle';
import ElementRowActions from './ElementRowActions';

export type Props = {
  blockEditPrivileges: boolean;
  curriculumId: number;
  curriculumElement: EditableNonCourseCurriculumElement;
  isLocked: boolean | undefined;
  provided: DraggableProvided;
  userAccess?: MetaUserAccess;
};

type EditableNonCourseCurriculumElement = EditableCurriculumElementBase &
  Exclude<CurriculumElement, CourseCurriculumElement>;

const ElementTableRow = ({
  blockEditPrivileges,
  curriculumId,
  curriculumElement,
  isLocked,
  provided,
  userAccess,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { elementId, elementKind } = curriculumElement;

  return (
    <RowContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      id={`curriculum-element-${elementKind}-${elementId}`}
    >
      <RowCardBody>
        <ElementEmojiAndTitle
          blockEditPrivileges={blockEditPrivileges}
          curriculumElement={curriculumElement}
          curriculumId={curriculumId}
          isEditing={isEditing}
          isLocked={isLocked}
          setIsEditing={setIsEditing}
          userAccess={userAccess}
        />
        <ElementRowActions
          blockEditPrivileges={blockEditPrivileges}
          curriculumElement={curriculumElement}
          curriculumId={curriculumId}
          isLocked={isLocked}
          setIsEditing={setIsEditing}
        />
      </RowCardBody>
    </RowContainer>
  );
};

export default ElementTableRow;
