import React from 'react';

import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import ConfirmationModal from '../../../../../../design_system/overlays/modals/ConfirmationModal';

export type Props = {
  onCloseRequest: () => void;
  actionFunction: () => void;
};

const t = initTranslations('curriculums.assign_users_modal');

const AssignOnPublishModal = ({ onCloseRequest, actionFunction }: Props) => {
  const {
    curriculum: { plural: subjectTermPlural },
  } = useAccountTerminology();

  return (
    <>
      <ConfirmationModal
        actionFunction={actionFunction}
        actionText={t('assign')}
        heapModalName='assign-on-publish-modal'
        message={t('message', { subject: subjectTermPlural })}
        onCloseRequest={onCloseRequest}
        processing={false}
        secondaryButtonText={t('not_now')}
        title={t('assign_users')}
      />
    </>
  );
};

export default AssignOnPublishModal;
