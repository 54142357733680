import React from 'react';
import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ButtonWrapper = styled.div`
  width: 30%;
`;

const RightButtonWrapper = styled.div`
  width: 30%;
  text-align: right;
`;

type Props = {
  previousButton: JSX.Element | null;
  nextButton: JSX.Element | null;
};

const BaseNavigationButtons = ({ previousButton, nextButton }: Props) => {
  return (
    <ButtonsWrapper>
      <ButtonWrapper>{previousButton}</ButtonWrapper>
      <RightButtonWrapper>{nextButton}</RightButtonWrapper>
    </ButtonsWrapper>
  );
};

export default BaseNavigationButtons;
