import React, { useState } from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import OutsideClickHandler from '../../../../design_system/OutsideClickHandler';
import Poppable from '../../../../Poppable';
import { getChevronIconName } from '../../../shared/helpers';
import { InsertDropdownMenu, InsertDropdownWrapper } from './styles';

export type InsertDropdownProps = {
  menuItems: React.ReactNode;
};

const t = initTranslations('editor.insert_dropdown');

const InsertDropdown = ({ menuItems }: InsertDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const buttonIcon = getChevronIconName({ isActive: dropdownOpen, initialDirection: 'up' });

  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
      <InsertDropdownWrapper>
        <Poppable
          id='insert-toolbar'
          isOpen={dropdownOpen}
          item={
            <InsertDropdownMenu onClick={() => setDropdownOpen(false)}>
              {menuItems}
            </InsertDropdownMenu>
          }
          onClick={toggleDropdown}
          placement='bottom-start'
          trigger={
            <DefaultButton
              buttonType='tertiary'
              iconName={buttonIcon}
              iconPosition='right'
              iconWeight='solid'
              id='insert-editor-toolbar-button'
              size='sm'
              text={t('insert')}
            />
          }
        />
      </InsertDropdownWrapper>
    </OutsideClickHandler>
  );
};

export default InsertDropdown;
