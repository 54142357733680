import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { CurriculumAssignmentsEmptyState } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { useGetComingUpTrainingPathQuery } from '../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { TrainingPathTag } from '../../../../redux/services/resourceApis/trainingPaths/types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import NoResults from '../../../design_system/Triage/NoResults';
import CaughtUpForNow from '../CaughtUpForNow/CaughtUpForNow';
import { SortingKind } from './CurriculumsAssignmentstItem/types';

type CurriculumsAssignmentsProps = {
  currentAssignmentsKind: SortingKind;
  userId: number;
  trainingPathTag: TrainingPathTag;
};

const t = initTranslations('home.curriculum_assignments');

const CurriculumsAssignmentsEmptyState = ({
  userId,
  trainingPathTag,
  currentAssignmentsKind,
}: CurriculumsAssignmentsProps) => {
  const {
    curriculum: { plural: curriculums },
  } = useAccountTerminology();
  const { accountPlan, trainingPathsEnabled } = useCurrentAccount();
  const isBuildPlan = accountPlan === 'build';

  const { data: trainingPathData } = useGetComingUpTrainingPathQuery({
    userId,
    tag: trainingPathTag,
  });

  if (!trainingPathData) return <></>;

  const { trainingPathSets } = trainingPathData;
  const trainingPathHasUpcomingSet = trainingPathsEnabled && !!trainingPathSets.length;

  if (trainingPathHasUpcomingSet && currentAssignmentsKind === 'incomplete') {
    return <CaughtUpForNow />;
  }

  return (
    <NoResults
      darkImage={CurriculumAssignmentsEmptyState}
      heading={t(`empty_state.${currentAssignmentsKind}.title`, {
        curriculums: curriculums.toLowerCase(),
      })}
      iconWidth='210px'
      lightImage={CurriculumAssignmentsEmptyState}
      subHeaderText={
        isBuildPlan
          ? t(`empty_state.build_plan.subtitle`, {
              curriculums: curriculums.toLowerCase(),
            })
          : t(`empty_state.${currentAssignmentsKind}.subtitle`, {
              curriculums: curriculums.toLowerCase(),
            })
      }
    />
  );
};

export default CurriculumsAssignmentsEmptyState;
