import React, { useCallback } from 'react';
import styled from 'styled-components';

import useSlideout from '../../../../../../../hooks/useSlideout';
import initTranslations from '../../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../../AccountTerminologyProvider';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import IconButton from '../../../../../../design_system/buttons/IconButton';
import { fontSm4, fontSm5 } from '../../../../../../styled/TypeSystem';
import { GlowingIconButton } from '../GlowingIconButton/GlowingIconButton';

const StyledCTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleMedium};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
  border: ${({ theme: { constants, vars } }) => `${constants.borderWidthLg} solid
    ${vars.trainualBrandBlueStrong}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0`};
`;

const IconWithHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTextWrapper = styled.div`
  color: ${({ theme: { vars } }) => vars.textSurface};
  margin-left: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const FirstLineHeader = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm5};
  /* Override type system line-height to match designs */
  line-height: 1.333rem;
`;

const SecondLineHeader = styled.div`
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm4};
  /* Override type system line-height to match designs */
  line-height: 1.185rem;
`;

const StyledDefaultButton = styled(DefaultButton)`
  box-shadow: 0 0 16px 0 ${({ theme: { vars } }) => vars.trainualBrandGreenMedium};
`;

const RightAnchoredButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCloseIcon = styled(IconButton)`
  color: ${({ theme: { vars } }) => vars.textSurface};
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.textSurface};
  }
`;

export type Props = {
  setShowAICTABanner: React.Dispatch<React.SetStateAction<boolean>>;
};

const t = initTranslations('curriculums.ai_outliner.cta_banner');

export const CTABanner = ({ setShowAICTABanner }: Props) => {
  const {
    curriculum: { singular: subjectTermSingular },
    topic: { plural: topicTermPlural },
  } = useAccountTerminology();
  const { open } = useSlideout('ai-outliner');

  const handleOutlineClick = useCallback(() => {
    open();
    setShowAICTABanner(false);
  }, [open, setShowAICTABanner]);

  return (
    <StyledCTAContainer id='ai-cta-banner'>
      <IconWithHeaderWrapper>
        <GlowingIconButton onClick={handleOutlineClick} />
        <HeaderTextWrapper>
          <FirstLineHeader>
            {t('header_title', { topics: topicTermPlural.toLowerCase() })}
          </FirstLineHeader>
          <SecondLineHeader>
            {t('header_subtitle', {
              subject: subjectTermSingular.toLowerCase(),
              topics: topicTermPlural.toLowerCase(),
            })}
          </SecondLineHeader>
        </HeaderTextWrapper>
      </IconWithHeaderWrapper>
      <RightAnchoredButtonWrapper>
        <StyledDefaultButton
          buttonType='brand'
          id='ai-start-outlining-button'
          onClick={handleOutlineClick}
          text={t('start_outlining')}
        />
        <StyledCloseIcon
          ariaLabel={t('close_icon_aria_label')}
          name='times'
          onClick={() => setShowAICTABanner(false)}
        />
      </RightAnchoredButtonWrapper>
    </StyledCTAContainer>
  );
};
