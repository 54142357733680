import styled from 'styled-components';

import { SharedToolbarStyles } from '../../../../shared/styles';

export const ToolbarSkeletonWrapper = styled.div`
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  height: 7.875rem;
  justify-content: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd3} 0`};

  ${SharedToolbarStyles};
`;
