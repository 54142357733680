import React, { useEffect } from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../redux/errors/helpers';
import { useUpdateSurveyMutation } from '../../../../../../redux/services/resourceApis/surveys/surveysApi';
import InlineTextarea from '../../../../../design_system/ToBeDeprecated/InlineTextarea';
import InlineEditInput from '../../../../../design_system/Triage/InlineEditInput';
import { InlineEditDescriptionWrapper, InlineEditTitleWrapper } from './styles';
import { EditableSurveyHeaderElementsProps } from './types';

const t = initTranslations('curriculum_element_header.editable_header_elements');

const MAX_TITLE_CHARACTERS_COUNT = 250;
const MAX_DESCRIPTION_CHARACTERS_COUNT = 2500;

const EditableSurveyHeaderElements = ({
  id,
  title,
  description,
}: EditableSurveyHeaderElementsProps) => {
  const [updateSurvey, { isSuccess, isLoading, reset, isError, error }] = useUpdateSurveyMutation();

  const setName = (name: string) => {
    updateSurvey({ name, id });
  };

  const setDescription = (description: string) => {
    updateSurvey({ description, id });
  };

  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);

  return (
    <>
      <InlineEditTitleWrapper className='notranslate'>
        <InlineEditInput
          attributeName='title'
          errorMessage={t('title_error_message')}
          focusText
          infoText=''
          initialValue={title}
          inputFor='Title'
          isSuccess={isSuccess}
          isTextClickable
          label=''
          maxCharacters={MAX_TITLE_CHARACTERS_COUNT}
          responseError={messageFromError(error)?.join(', ')}
          submitChanges={setName}
          truncate
        />
      </InlineEditTitleWrapper>
      <InlineEditDescriptionWrapper className='notranslate'>
        <InlineTextarea
          attributeName='description'
          infoText=''
          initialValue={description}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isTextClickable
          maxCharacters={MAX_DESCRIPTION_CHARACTERS_COUNT}
          placeholder={t('description_placeholder')}
          resourceName='test'
          submitChanges={setDescription}
        />
      </InlineEditDescriptionWrapper>
    </>
  );
};

export default EditableSurveyHeaderElements;
