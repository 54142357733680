import React from 'react';
import styled from 'styled-components';

import useCurrentUser from '../../../../../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../../../../hooks/useDisplayFlashOnResponse';
import { NoResultsGraphic, NoResultsGraphicDark } from '../../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../../../../redux/errors/helpers';
import { useUpdateCurriculumMutation } from '../../../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { User } from '../../../../../../../../types/User';
import Avatar from '../../../../../../../design_system/display/avatar';
import Icon from '../../../../../../../design_system/display/icons/Icon';
import NoResults from '../../../../../../../design_system/Triage/NoResults';
import { TruncatedText } from '../../../../../../../design_system/Triage/TruncatedText';
import FlexContainer from '../../../../../../../styled/FlexContainer';
import AddTeammateMenuListButton from '../../../../../../people/AddTeammateMenuListButton';

const MenuWrapper = styled.div`
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const UserRow = styled(FlexContainer)`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};

  &:hover {
    background: ${({ theme: { vars } }) => vars.foundationHover};
  }
`;

const UserButton = styled.button`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  align-items: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerSm2}`};
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:focus {
    border: ${({ theme: { vars, constants } }) =>
      `${constants.borderRadiusXs} solid ${vars.accentPrimaryDefault}`};
  }
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding-right: ${({ theme: { constants } }) => constants.spacerMd1};

  &:hover,
  &:focus {
    color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  }
`;

const StyledNoResults = styled(NoResults)`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 18rem;
`;

const UserAvatar = styled(Avatar)`
  flex-shrink: 0;
`;

const UserName = styled.span`
  ${TruncatedText({})};
`;

const AssignToMeWrapper = styled.span`
  flex-shrink: 0;
`;

type Props = {
  close: () => void;
  curriculumId: number;
  ownerId?: number;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  users?: User[];
};

const t = initTranslations('curriculums.owner');

const OwnerMenu = ({ curriculumId, close, ownerId, setSearchValue, users }: Props) => {
  const { id: currentUserId } = useCurrentUser();
  const ability = useCurrentUserAbilities();
  const canInviteUser = ability.can('create', 'User');

  const [updateCurriculum, updateResult] = useUpdateCurriculumMutation();

  useDisplayFlashOnResponse({
    result: updateResult,
    errorMessage: messageFromError(updateResult.error)?.join(', '),
  });

  console.log(canInviteUser);
  return (
    <MenuWrapper>
      {users?.length ? (
        <>
          {users.map((user) => {
            const { id, name, avatar } = user;
            return (
              <UserRow key={id}>
                <UserButton
                  onClick={() => {
                    updateCurriculum({
                      id: curriculumId,
                      owner_assignment: id,
                    });
                    close();
                  }}
                >
                  <UserAvatar
                    className='notranslate'
                    image={avatar}
                    name={name}
                    shape='circle'
                    size='md'
                  />
                  <UserName className='notranslate'>{name}</UserName>{' '}
                  {user.id === currentUserId && (
                    <AssignToMeWrapper>{`(${t('assign_to_me')})`}</AssignToMeWrapper>
                  )}
                </UserButton>
                {ownerId === user.id && (
                  <RemoveButton
                    id='remove-owner-button'
                    onClick={(event) => {
                      event.preventDefault();
                      updateCurriculum({
                        id: curriculumId,
                        owner_assignment: null,
                      });
                      close();
                    }}
                  >
                    <Icon name='circle-x' size='xs' />
                  </RemoveButton>
                )}
              </UserRow>
            );
          })}
          {canInviteUser && <AddTeammateMenuListButton large />}
        </>
      ) : (
        <StyledNoResults
          darkImage={NoResultsGraphicDark}
          heading={t('no_result_found')}
          lightImage={NoResultsGraphic}
          minHeight='18.5rem'
          showBorder={false}
          subHeaderCta={{
            action: () => setSearchValue(''),
            text: t('clear'),
          }}
        />
      )}
    </MenuWrapper>
  );
};

export default OwnerMenu;
