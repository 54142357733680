import React from 'react';
import { useTheme } from 'styled-components';

import { useGroupsModals } from '../../../../contexts/GroupsModalContext';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import { GroupsPageImage } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import DismissableBanner from '../../../design_system/banners/DismissableBanner/DismissableBanner';
import { ContentBannerWrapper } from './styles';

const t = initTranslations('dismissable_banner');

export const GroupsIndexBanners = () => {
  const abilities = useCurrentUserAbilities();
  const { dispatch } = useGroupsModals();
  const { vars } = useTheme();

  if (!abilities.can('create', 'Group')) return <></>;

  return (
    <ContentBannerWrapper>
      <DismissableBanner
        backgroundColor={vars.trainualBrandPurpleSurfaceLight}
        body={t('groups_index.body')}
        image={GroupsPageImage}
        primaryButtonProps={{
          id: 'open-create-groups-modal-button',
          text: t('action_buttons.try_it_out'),
          onClick: () => {
            dispatch({ type: 'openGroupKindModal' });
          },
        }}
        showCloseIcon
        title={t('groups_index.title')}
        type='groups-index'
      />
    </ContentBannerWrapper>
  );
};
