import React from 'react';

import { useConsumptionCourseContext } from '../../../../../contexts/ConsumptionCourseContext';
import { useEditorContext } from '../../../../../contexts/EditorContext';
import useContentStyles from '../../../../../hooks/useContentStyles';
import useScrollTopOnChange from '../../../../../hooks/useScrollTopOnChange';
import initTranslations from '../../../../../lib/initTranslations';
import translationClassname from '../../../../../lib/translationClassname';
import { GetStepResponse } from '../../../../../redux/services/resourceApis/steps/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import {
  CardBackgroundShadowed,
  ContentLayout,
  EditorContentWrapper,
  StyledEditorContent,
  StyledHr,
} from '../../shared/styles';
import TopicHeader from '../../shared/TopicHeader/TopicHeader';
import CompletionDelighter from '../components/CompletionDelighter/CompletionDelighter';
import ESignatureContainer from '../components/ESignatureContainer/ESignatureContainer';
import NavigationButtons from '../components/NavigationButtons/NavigationButtons';
import ReadOnlyTitle from '../components/ReadOnlyTitle/ReadOnlyTitle';
import { StepTitleWrapper } from '../Editor/Editor';
import { CONSUMPTION_TOOLBAR_HEIGHT } from '../ToolbarContainer';

const t = initTranslations('editor');

interface Props {
  step: GetStepResponse;
}

const DeprecatedEditor = ({ step }: Props) => {
  const contentStyles = useContentStyles();
  const { editor } = useEditorContext();
  const {
    course: { emoji: courseEmoji, title: courseTitle, cover: courseCover },
    isCourseCompleteShowing,
    isESignatureShowing,
    curriculum: { disableTranslation },
  } = useConsumptionCourseContext();
  const { id: stepId, emoji: stepEmoji, title: stepTitle } = step;
  const {
    topic: { singular: topicTerm },
  } = useAccountTerminology();

  const editorContentRef = useScrollTopOnChange(stepId);

  return (
    <EditorContentWrapper
      data-testid='editor'
      ref={editorContentRef}
      toolbarAdjustment={CONSUMPTION_TOOLBAR_HEIGHT}
    >
      <ContentLayout>
        {isESignatureShowing ? (
          <ESignatureContainer stepId={stepId} />
        ) : isCourseCompleteShowing ? (
          <CardBackgroundShadowed>
            <CompletionDelighter subtitle={t('success.success_subtitle', { topic: courseTitle })} />
            <NavigationButtons stepId={stepId} />
          </CardBackgroundShadowed>
        ) : (
          <>
            <TopicHeader
              cover={courseCover}
              disableTranslation={disableTranslation}
              emoji={courseEmoji}
              title={courseTitle}
              topicTerm={topicTerm}
            />
            <CardBackgroundShadowed>
              <div className='step-content'>
                <StepTitleWrapper className={translationClassname(disableTranslation)}>
                  <ReadOnlyTitle emoji={stepEmoji} resource='step' title={stepTitle} />
                </StepTitleWrapper>
                <StyledHr />
                <StyledEditorContent
                  $contentStyles={contentStyles}
                  className={disableTranslation ? 'notranslate editor-content' : 'editor-content'}
                  editor={editor}
                />
                <NavigationButtons stepId={stepId} />
              </div>
            </CardBackgroundShadowed>
          </>
        )}
      </ContentLayout>
    </EditorContentWrapper>
  );
};

export default DeprecatedEditor;
