import { TiptapCollabProvider, WebSocketStatus, onStatusParameters } from '@hocuspocus/provider';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Doc } from 'yjs';

import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import { useGetEditorCollabTokenQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';

interface ContextProps {
  collabState: WebSocketStatus;
  provider: TiptapCollabProvider | null;
}

const Context = createContext<ContextProps>({
  collabState: WebSocketStatus.Connecting,
  provider: null,
});

interface Props {
  children: ReactNode;
  stepId: number;
}

/**
 * Initializes a TiptapCollabProvider with the necessary configuration and manages its lifecycle.
 * Dependent on the stepId and can be used to wrap an Editor component.
 * @param children ReactNode - The children components to be wrapped by the provider.
 * @param stepId number - The ID of the step to be edited. Used in the provider configuration.
 * @returns JSX.Element - A context provider component with the WebSocketStatus and TiptapCollabProvider instance as values.
 */
export const TiptapCollaborationProvider = ({ children, stepId }: Props) => {
  const name = `stepDocument.${stepId}`;
  const { configs } = usePrivateConfigs();
  const baseUrl = configs['TIPTAP_BASE_URL'];
  const { data: token } = useGetEditorCollabTokenQuery(stepId);

  const [provider, setProvider] = useState<TiptapCollabProvider | null>(null);
  const [collabState, setCollabState] = useState<WebSocketStatus>(WebSocketStatus.Connecting);

  useEffect(() => {
    if (baseUrl && token) {
      const document = new Doc();

      const provider = new TiptapCollabProvider({
        baseUrl,
        document,
        name,
        token,
        onStatus({ status }: onStatusParameters) {
          setCollabState(status);
        },
      });

      setProvider(provider);

      return () => {
        provider.destroy();
      };
    }
  }, [baseUrl, token, name]);

  return <Context.Provider value={{ collabState, provider }}>{children}</Context.Provider>;
};

export const useTiptapCollaboration = (): ContextProps => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useTiptapCollaboration must be used within a TiptapCollaborationProvider');
  }

  const { collabState, provider } = context;

  return { collabState, provider };
};
