import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo } from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { useRedirectToAddonOverlay } from '../../../../hooks/useRedirectToAddonOverlay';
import { ESignaturePaywallBannerLogo } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import { useCheckSidebarState } from '../../../../redux/domains/collapsibleSidebar/collapsibleSidebarSlice';
import { Addon } from '../../../../types/Addon';
import DefaultButton from '../../../design_system/buttons/DefaultButton';
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '../../navigation/PlaybookNavigation/styles';
import useLocalStorageState from '../../publicApplication/utils/useLocalStorageState';
import DropdownWithPoppableMenu from '../../shared/DropdownWithPoppableMenu';
import {
  ESignaturePaywallBannerActionsWrapper,
  ESignaturePaywallBannerImage,
  ESignaturePaywallBannerMessage,
  ESignaturePaywallBannerWrapper,
} from './styles';

const t = initTranslations('curriculums.e_signature_paywall_banner');

export type ESignaturePaywallBannerProps = {
  isStorybookEnvironment?: boolean;
};

const ESignaturePaywallBanner = ({ isStorybookEnvironment }: ESignaturePaywallBannerProps) => {
  const {
    hasESignatureFeature,
    splitFeatures: { eSignatureAddonPaywallBannerEnabled },
    status,
  } = useCurrentAccount();
  const { permission } = useCurrentUser();
  const navigateToAddonOverlay = useRedirectToAddonOverlay();
  const ableToSeeESignaturePaywallBanner =
    status === 'active' && permission === 'billing_admin' && eSignatureAddonPaywallBannerEnabled;
  const [isESignaturePaywallBannerDismissed, setIsESignaturePaywallBannerDismissed] =
    useLocalStorageState({
      key: 'isESignaturePaywallBannerDismissed',
      initialValue: {
        value: !ableToSeeESignaturePaywallBanner,
        expire: 'never',
      },
    });
  const options = useMemo(
    () =>
      [t('dropdown_menu.dismiss'), t('dropdown_menu.dont_show_again')].map((item) => {
        return <div key={item}>{item}</div>;
      }),
    []
  );
  const setSelectedOption = useCallback(
    (index: number) => {
      const expireValue = index === 0 ? dayjs().add(30, 'days').toString() : 'never';

      setIsESignaturePaywallBannerDismissed({
        value: true,
        expire: expireValue,
      });
    },
    [setIsESignaturePaywallBannerDismissed]
  );
  const selectedOption = options[0];
  const isExpandedSidebar = useCheckSidebarState('isExpanded') as boolean;
  const bannerLeftPosition = isStorybookEnvironment
    ? 'auto'
    : isExpandedSidebar
    ? SIDEBAR_EXPANDED_WIDTH
    : SIDEBAR_COLLAPSED_WIDTH;

  useEffect(() => {
    const isLocalStorageKeyExpired =
      isESignaturePaywallBannerDismissed.expire !== 'never' &&
      dayjs().isAfter(dayjs(isESignaturePaywallBannerDismissed.expire));

    if (isLocalStorageKeyExpired) {
      localStorage.removeItem('isESignaturePaywallBannerDismissed');
    }
  }, [isESignaturePaywallBannerDismissed.expire]);

  if (hasESignatureFeature || isESignaturePaywallBannerDismissed.value) return null;

  return (
    <ESignaturePaywallBannerWrapper
      className='fixed-banner'
      id='e-signature-paywall-banner'
      leftPosition={bannerLeftPosition}
    >
      <ESignaturePaywallBannerImage alt={t('logo_alt')} src={ESignaturePaywallBannerLogo} />
      <ESignaturePaywallBannerMessage dangerouslySetInnerHTML={{ __html: t('message_html') }} />
      <ESignaturePaywallBannerActionsWrapper>
        <DropdownWithPoppableMenu
          id='e-signature-paywall-banner-dismiss-dropdown-button'
          menuId='e-signature-paywall-banner-dismiss-dropdown-menu'
          menuPlacement='top-end'
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          style='eSignaturePaywallBanner'
        />
        <DefaultButton
          buttonType='brand'
          id='e-signature-paywall-banner-buy-now-button'
          onClick={() => navigateToAddonOverlay({ name: Addon.ESignature })}
          size='md'
          text={t('button_text')}
        />
      </ESignaturePaywallBannerActionsWrapper>
    </ESignaturePaywallBannerWrapper>
  );
};

export default ESignaturePaywallBanner;
