import { WebSocketStatus } from '@hocuspocus/provider';
import React from 'react';

import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useScrollTopOnChange from '../../../../../hooks/useScrollTopOnChange';
import { GetStepResponse } from '../../../../../redux/services/resourceApis/steps/types';
import { EditorUser } from '../../../../../types/Editor';
import { ContentLayout, EditorContentWrapper } from '../../shared/styles';
import { CREATE_TOOLBAR_HEIGHT } from '../../toolbar/Toolbar';
import TopicHeader from '../TopicHeader/TopicHeader';
import StepContent from './StepContent';

export type EditorProps = {
  collabState: WebSocketStatus;
  collabUsers: EditorUser[];
  step: GetStepResponse;
};

const Editor = ({ collabState, collabUsers, step }: EditorProps) => {
  const {
    course: { title, emoji, steps, cover, id },
  } = useEditorCourseContext();
  const editorContentRef = useScrollTopOnChange(step.id);

  return (
    <EditorContentWrapper ref={editorContentRef} toolbarAdjustment={CREATE_TOOLBAR_HEIGHT}>
      <ContentLayout>
        <TopicHeader
          coverImage={cover}
          disableTranslation={step.disableTranslation}
          topicEmoji={emoji}
          topicId={id}
          topicTitle={title}
        />
        <StepContent
          collabState={collabState}
          collabUsers={collabUsers}
          courseSteps={steps}
          step={step}
        />
      </ContentLayout>
    </EditorContentWrapper>
  );
};

export default Editor;
