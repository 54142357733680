import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { BillingInterval } from '../../../../../types/BillingInterval';
import { CheckoutPriceBillingInterval, StyledCheckoutPriceDetails } from './styles';

type CheckoutAddonPriceProps = {
  strike?: boolean;
  price: string;
  isAddon?: boolean;
  billingInterval?: BillingInterval;
  isTotal?: boolean;
};

const t = initTranslations('checkout_overlay');

const CheckoutAddonPrice = ({
  billingInterval,
  price,
  isAddon = false,
  strike = false,
  isTotal = false,
}: CheckoutAddonPriceProps) => {
  const billingIntervalTranslationKey = t(billingInterval === 'year' ? 'per_year' : 'per_month');

  return (
    <StyledCheckoutPriceDetails isAddon={isAddon} isTotal={isTotal} strike={strike}>
      {price}
      {!strike && (
        <CheckoutPriceBillingInterval isTotal={isTotal}>
          {billingIntervalTranslationKey}
        </CheckoutPriceBillingInterval>
      )}
    </StyledCheckoutPriceDetails>
  );
};
export default CheckoutAddonPrice;
