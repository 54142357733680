import React, { useEffect, useState } from 'react';

import EnvelopeIcon from '../../../../../images/public_application/envelope.svg';
import initTranslations from '../../../../../lib/initTranslations';
import {
  Animation,
  CheckEmailWrapper,
  Envelope,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
} from '../../styles/shared';

const t = initTranslations('public_application.check_email');

type CheckEmailProps = {
  setVisibleCheckEmail: (value: boolean) => void;
  email: string;
};

const CheckEmail = ({ setVisibleCheckEmail, email }: CheckEmailProps) => {
  const [animationStage, setAnimationStage] = useState(0);
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    const showEnvelopeWithStars = setTimeout(() => {
      setAnimationStage(1);
    }, 600);

    const showForm = setTimeout(() => {
      setAnimationStage(2);
    }, 1200);

    const formSlideUp = setTimeout(() => {
      setAnimated(true);
    }, 1300);

    return () => {
      clearTimeout(showEnvelopeWithStars);
      clearTimeout(showForm);
      clearTimeout(formSlideUp);
    };
  }, []);

  const goBack = () => {
    setVisibleCheckEmail(false);
  };

  switch (animationStage) {
    case 0:
      return null;
    case 1:
      return (
        <CheckEmailWrapper>
          <Envelope src={EnvelopeIcon} />
        </CheckEmailWrapper>
      );
    case 2:
      return (
        <Animation animated={animated}>
          <CheckEmailWrapper>
            <Envelope src={EnvelopeIcon} />
            <StyledTitle>{t('title')}</StyledTitle>
            <StyledSubtitle dangerouslySetInnerHTML={{ __html: t('subtitle', { email }) }} />
            <StyledSubtitle>
              <span>{t('receive_email_prompt')}</span>
              <StyledLink behavesAs='button' onClick={goBack} text={t('go_back')} />
            </StyledSubtitle>
          </CheckEmailWrapper>
        </Animation>
      );
    default:
      return null;
  }
};

export default CheckEmail;
