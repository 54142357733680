import React, { useCallback } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import {
  setSuperShareModalCurriculumId,
  setSuperShareModalVisible,
} from '../../../../../redux/domains/contentLibrary/contentLibrarySlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import Badge from '../../../../design_system/display/badge';
import Link from '../../../../design_system/Link';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';

const GroupsWrapper = styled.div`
  width: 8.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${mediaBreakpointPxMd}) {
    display: none;
  }
`;

const GroupsLink = styled(Link)`
  margin: 0 ${({ theme: { constants } }) => constants.spacerLg1};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  white-space: nowrap;
`;

// DS Override: Updated cursor to pointer
const StyledBadge = styled(Badge)`
  cursor: pointer;
`;

export type Props = {
  canAssignUsers: boolean;
  id: number;
  groupsCount: number;
};

const t = initTranslations('curriculums.content.group_badges');

const GroupBadge = ({ canAssignUsers, groupsCount, id }: Props) => {
  const dispatch = useAppDispatch();

  const openSuperShareModal = useCallback(() => {
    dispatch(setSuperShareModalCurriculumId(id));
    dispatch(setSuperShareModalVisible(true));
  }, [dispatch, id]);

  return (
    <GroupsWrapper>
      {canAssignUsers ? (
        <>
          {groupsCount > 0 ? (
            <a id={`group-badge-curriculum-${id}`} onClick={openSuperShareModal}>
              <StyledBadge text={t('group', { count: groupsCount })} type='general' />
            </a>
          ) : (
            <GroupsLink
              behavesAs='button'
              onClick={openSuperShareModal}
              text={t('share_with_groups')}
            />
          )}
        </>
      ) : (
        <Badge text={t('group', { count: groupsCount })} type='general' />
      )}
    </GroupsWrapper>
  );
};

export default GroupBadge;
