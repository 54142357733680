import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import usePublicConfigs from '../../../hooks/usePublicConfigs';
import { StarsLeftSide, StarsRightSide } from '../../../lib/gcsImages';
import Surface from '../../design_system/Triage/layout/Surface';
import { StarWrapper, Stars } from './styles/shared';

type SurfacePosition = 'top' | 'center';

const PageContainer = styled.div<{ surfacePosition: SurfacePosition }>`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: ${({ surfacePosition }) => (surfacePosition === 'center' ? 'center' : 'start')};
  overflow: hidden;
  top: 0;
  left: 0;

  * {
    box-sizing: border-box;
  }
`;

const FlexColumnWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

//DS Override - Needed box-shadow on Surface
const StyledSurface = styled(Surface)<{
  boxShadow?: boolean;
  maxWidth: string;
  nullifyBorder?: boolean;
  nullifyPadding?: boolean;
  surfacePosition: SurfacePosition;
  withPaddingTop?: boolean;
}>`
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd2};

  ${({ nullifyBorder }) =>
    nullifyBorder &&
    css`
      border: 0;
    `};

  ${({ nullifyPadding }) =>
    nullifyPadding &&
    css`
      padding: 0;
    `};

  ${({ withPaddingTop }) =>
    !withPaddingTop &&
    css`
      padding-top: 0;
    `};

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    `};

  ${({ surfacePosition }) =>
    surfacePosition === 'top' &&
    css`
      margin: ${({
        theme: {
          constants: { spacerLg3, spacerMd2, spacerMd3 },
        },
      }) => `${spacerLg3} ${spacerMd2} ${spacerMd3}`};
    `};
`;

type Props = {
  children: ReactNode;
  className?: string;
  boxShadow?: boolean;
  id: string;
  surfacePosition?: SurfacePosition;
  reversedStars?: boolean;
};

const PAGES_WITH_PADDING_TOP = ['sign-up-page', 'accept-invitation-page'];

const PublicPage = ({
  boxShadow = false,
  children,
  className,
  id,
  surfacePosition = 'top',
  reversedStars,
}: Props) => {
  const { configs } = usePublicConfigs();
  const universalLoginWithOtpEnabled = !!configs['UNIVERSAL_LOGIN_WITH_OTP'];
  const getSurfaceMaxWidth = () => {
    if (universalLoginWithOtpEnabled) {
      return '27rem';
    }

    switch (id) {
      case 'account-selector-page':
        return '46rem';
      case 'accept-invitation-page':
        return '30rem';
      case 'sign-up-page':
        return '24rem';
      default:
        return '26rem';
    }
  };

  return universalLoginWithOtpEnabled ? (
    <PageContainer className={className} id={id} surfacePosition={surfacePosition}>
      <FlexColumnWrapper>
        <StarWrapper align={reversedStars ? 'left' : 'right'}>
          <Stars
            src={reversedStars ? StarsLeftSide : StarsRightSide}
            {...(reversedStars && { rotate: 165 })}
          />
        </StarWrapper>
        <StyledSurface
          maxWidth={getSurfaceMaxWidth()}
          nullifyBorder
          surfacePosition={surfacePosition}
        >
          {children}
        </StyledSurface>
        <StarWrapper align={reversedStars ? 'right' : 'left'}>
          <Stars
            src={reversedStars ? StarsRightSide : StarsLeftSide}
            {...(reversedStars && { rotate: 150 })}
          />
        </StarWrapper>
      </FlexColumnWrapper>
    </PageContainer>
  ) : (
    <PageContainer className={className} id={id} surfacePosition={surfacePosition}>
      <StyledSurface
        boxShadow={boxShadow}
        maxWidth={getSurfaceMaxWidth()}
        surfacePosition={surfacePosition}
        withPaddingTop={PAGES_WITH_PADDING_TOP.includes(id)}
      >
        {children}
      </StyledSurface>
    </PageContainer>
  );
};

export default PublicPage;
