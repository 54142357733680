import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useCurrentUserAbilities from '../../../../../hooks/useCurrentUserAbilities';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { messageFromError } from '../../../../../redux/errors/helpers';
import { useUpdateElementMutation } from '../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import { CurriculumElementStatus } from '../../../../../types/CurriculumElement';
import Icon from '../../../../design_system/display/icons/Icon';
import ActionDropdown from '../../../shared/ActionDropdown';
import ActionDropdownMenuOption, {
  ActionDropdownMenuOptionData,
} from '../../../shared/ActionDropdown/ActionDropdownMenuOption';
import { OptionType } from '../../../shared/ActionDropdown/ActionDropdownMenuOption/ActionDropdownMenuOption';

// DS Override: Need to match design color
export const StatusIcon = styled(Icon)<{
  status: OptionType.Draft | OptionType.PendingReview | OptionType.Finished;
}>`
  color: ${({ status, theme: { vars } }) =>
    (status === OptionType.Finished && vars.stateSuccess) ||
    (status === OptionType.PendingReview && vars.stateCaution) ||
    vars.foundationBase4};
`;

const t = initTranslations('editor_toolbar.status_dropdown');

export interface StatusDropdownMenuOptionData extends ActionDropdownMenuOptionData {
  value: CurriculumElementStatus;
}

export const statusOptionsData: Array<StatusDropdownMenuOptionData> = [
  {
    icon: <StatusIcon name='circle' size='xs' status={OptionType.Draft} weight='solid' />,
    title: t('options.draft.title'),
    description: t('options.draft.description'),
    value: 'draft',
    optionType: OptionType.Draft,
  },
  {
    icon: <StatusIcon name='circle' size='xs' status={OptionType.PendingReview} weight='solid' />,
    title: t('options.pending.title'),
    description: t('options.pending.description'),
    value: 'pending_review',
    optionType: OptionType.PendingReview,
  },
  {
    icon: <StatusIcon name='circle' size='xs' status={OptionType.Finished} weight='solid' />,
    title: t('options.finished.title'),
    description: t('options.finished.description'),
    value: 'finished',
    optionType: OptionType.Finished,
  },
];

type StatusDropdownProps = {
  element: { curriculumElementId: number; status: CurriculumElementStatus };
  errorFunction?: () => void;
  curriculumId: number;
};

const StatusDropdown = ({ element, curriculumId, errorFunction }: StatusDropdownProps) => {
  const [updateElement, updateElementResult] = useUpdateElementMutation();
  const ability = useCurrentUserAbilities();
  const selectedOptionIndex = statusOptionsData.findIndex(
    (option) => option.value === element.status
  );

  const permittedStatusOptions = statusOptionsData.filter(({ optionType }) => {
    if (optionType === OptionType.Finished) {
      return ability.can('update', `ManageCurriculum-${curriculumId}`);
    } else {
      return true;
    }
  });

  const options = useMemo(
    () =>
      permittedStatusOptions.map((optionData, index) => {
        return (
          <ActionDropdownMenuOption
            description={optionData.description}
            icon={optionData.icon}
            isSelected={index === selectedOptionIndex}
            key={optionData.title}
            optionType={optionData.optionType}
            title={optionData.title}
          />
        );
      }),
    [permittedStatusOptions, selectedOptionIndex]
  );

  const statusChangeErrorFunction = useCallback(() => {
    if (errorFunction) errorFunction();
  }, [errorFunction]);

  useDisplayFlashOnResponse({
    result: updateElementResult,
    errorMessage: messageFromError(updateElementResult.error)?.join(', '),
    errorFunction: statusChangeErrorFunction,
  });

  const handleStatusChange = useCallback(
    (index) => {
      const selectedStatus = statusOptionsData[index].value;
      if (selectedStatus === element.status) return;

      const statusUpdateParams = {
        curriculumElementId: element.curriculumElementId,
        status: selectedStatus,
      };

      updateElement(statusUpdateParams);
    },
    [element, updateElement]
  );

  if (ability.can('update', `EditCurriculum-${curriculumId}`)) {
    return (
      <ActionDropdown
        id='change-subject-status-dropdown'
        name='status'
        options={options}
        selectedOptionData={statusOptionsData[selectedOptionIndex]}
        setSelectedOption={handleStatusChange}
      />
    );
  }

  return <></>;
};

export default StatusDropdown;
