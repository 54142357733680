import { FormikErrors, useFormik } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';

import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { toSnakeCase } from '../../../../../lib/keyFormatConverter';
import { useUpdateAccountDataMutation } from '../../../../../redux/services/resourceApis/accountSettings/accountsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import InputFieldWithCharacterCounter from '../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { ButtonRightPositionWrapper } from '../../OrganizationProfile/styles';
import { CUSTOM_TERMINOLOGY_MAX_CHARACTERS } from '../../shared/constants/accountSettings';
import { TerminologyInputRow, TerminologySettingWrapper } from '../styles';
import TerminologyPluralsSettingToggle from './TerminologyPluralsSettingToggle';
import { CustomTerminologiesFormValues, CustomTerminologyFormProps } from './types';

const t = initTranslations('account_settings.advanced_settings.custom_terminology_form');

const CustomTerminologyForm = ({
  customTerminologies,
  customTerminologySettings,
}: CustomTerminologyFormProps) => {
  const [updateAccountData, result] = useUpdateAccountDataMutation();
  const { isLoading } = result;
  const {
    companyTerm,
    policyTerm,
    processTerm,
    stepTerm,
    subjectTerm,
    topicTerm,
    responsibilityTerm,
  } = customTerminologies;
  const {
    pluralizeCompanyTerm,
    pluralizeProcessTerm,
    pluralizeResponsibilityTerm,
    pluralizeStepTerm,
    pluralizePolicyTerm,
    pluralizeSubjectTerm,
    pluralizeTopicTerm,
  } = customTerminologySettings;

  useDisplayFlashOnResponse({
    result,
    successMessage: t('success_message'),
  });

  const formik = useFormik({
    initialValues: {
      companyTerm,
      policyTerm,
      processTerm,
      stepTerm,
      subjectTerm,
      topicTerm,
      responsibilityTerm,
    },
    validate: (values: CustomTerminologiesFormValues) => validateForm(values),
    onSubmit: (values: CustomTerminologiesFormValues) => submitForm(toSnakeCase(values)),
  });

  const validateForm = useCallback((values: CustomTerminologiesFormValues) => {
    const {
      companyTerm,
      policyTerm,
      processTerm,
      stepTerm,
      subjectTerm,
      topicTerm,
      responsibilityTerm,
    } = values;
    const errors: FormikErrors<CustomTerminologiesFormValues> = {};
    if (companyTerm && companyTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.companyTerm = t('errors.max_characters_exceeded');
    }
    if (processTerm && processTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.processTerm = t('errors.max_characters_exceeded');
    }
    if (stepTerm && stepTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.stepTerm = t('errors.max_characters_exceeded');
    }
    if (subjectTerm && subjectTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.subjectTerm = t('errors.max_characters_exceeded');
    }
    if (topicTerm && topicTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.topicTerm = t('errors.max_characters_exceeded');
    }
    if (policyTerm && policyTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.policyTerm = t('errors.max_characters_exceeded');
    }
    if (responsibilityTerm && responsibilityTerm.length > CUSTOM_TERMINOLOGY_MAX_CHARACTERS) {
      errors.responsibilityTerm = t('errors.max_characters_exceeded');
    }
    return errors;
  }, []);

  const submitForm = useCallback(
    (formValues) => {
      updateAccountData(formValues);
    },
    [updateAccountData]
  );

  const { values, errors, handleChange, initialValues } = formik;

  const isValuesUnchanged = isEqual(values, initialValues);

  return (
    <>
      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.subjectTerm}
            id='subject-term'
            inputFor='subjectTerm'
            label={t('curriculum.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='subjectTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.subjectTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeSubjectTerm}
          className='subject-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_subject_term: e.target.checked })}
          id='enable-subject-term-pluralize'
          name='pluralizeSubjectTerm'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.topicTerm}
            id='topic-term'
            inputFor='topicTerm'
            label={t('course.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='topicTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.topicTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeTopicTerm}
          className='topic-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_topic_term: e.target.checked })}
          id='enable-topic-term-pluralize'
          name='pluralizeTopicTerm'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.stepTerm}
            id='step-term'
            inputFor='stepTerm'
            label={t('step.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='stepTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.stepTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeStepTerm}
          className='step-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_step_term: e.target.checked })}
          id='enable-step-term-pluralize'
          name='pluralizeStepTerm'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.companyTerm}
            id='company-term'
            inputFor='companyTerm'
            label={t('company.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='companyTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.companyTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeCompanyTerm}
          className='company-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_company_term: e.target.checked })}
          id='enable-company-term-pluralize'
          name='pluralizeCompanyTerm'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.policyTerm}
            id='policy-term'
            inputFor='policyTerm'
            label={t('policy.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='policyTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.policyTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizePolicyTerm}
          className='policy-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_policy_term: e.target.checked })}
          id='enable-policy-term-pluralize'
          name='pluralizePolicyTerm'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.processTerm}
            id='process-term'
            inputFor='processTerm'
            label={t('process.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='processTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.processTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeProcessTerm}
          className='process-term-pluralize'
          handleToggle={(e) => updateAccountData({ pluralize_process_term: e.target.checked })}
          id='enable-process-term-pluralize'
          name='pluralizeProcessTerm'
        />
      </TerminologySettingWrapper>

      <TerminologySettingWrapper>
        <TerminologyInputRow>
          <InputFieldWithCharacterCounter
            errorText={errors.responsibilityTerm}
            id='responsibility-term'
            inputFor='responsibilityTerm'
            label={t('responsibility.label')}
            maxCharacters={CUSTOM_TERMINOLOGY_MAX_CHARACTERS}
            name='responsibilityTerm'
            onChange={handleChange}
            placeholder={t('placeholder')}
            value={values.responsibilityTerm}
          />
        </TerminologyInputRow>
        <TerminologyPluralsSettingToggle
          checked={pluralizeResponsibilityTerm}
          className='responsibility-term-pluralize'
          handleToggle={(e) =>
            updateAccountData({ pluralize_responsibility_term: e.target.checked })
          }
          id='enable-responsibility-term-pluralize'
          name='pluralizeResponsibilityTerm'
        />
      </TerminologySettingWrapper>

      <ButtonRightPositionWrapper>
        <DefaultButton
          buttonType='primary'
          disabled={isValuesUnchanged}
          id='save-custom-terminology'
          loading={isLoading}
          onClick={() => formik.handleSubmit()}
          text={t('save_changes')}
        />
      </ButtonRightPositionWrapper>
    </>
  );
};

export default CustomTerminologyForm;
