import React from 'react';
import { Route } from 'type-route';

import { useGetSearchResultData } from '../../../../hooks/search/useGetSearchResultData';
import useAnalyticsUserEvents from '../../../../hooks/useAnalyticsUserEvents';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import initTranslations from '../../../../lib/initTranslations';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetSearchHelpCenterResultsQuery } from '../../../../redux/services/resourceApis/searches/searchesApi';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import { routes } from '../../publicApplication/applicationRouter';
import routeTo from '../../publicApplication/routeTo';
import PageContent from '../../shared/page_content';
import ExperimentalSearchResults from '../ExperimentalSearchResults/ExperimentalSearchResults';
import SearchResultsChips from './SearchResultsChips/SearchResultsChips';
import { ChipsCount } from './SearchResultsChips/types';
import SearchResultsContent from './SearchResultsContent/SearchResultsContent';
import SearchResultsFilter from './SearchResultsFilter/SearchResultsFilter';
import { FilterName } from './SearchResultsFilter/types';
import SearchResultsLoader from './SearchResultsLoader/SearchResultsLoader';
import {
  SearchResultsFoundBox,
  SearchResultsLoaderWrapper,
  SearchResultsPageHeader,
  SearchResultsRow,
  SearchResultsSubTitle,
  SearchResultsTitle,
} from './styles';
import { SearchKind } from './types';

const t = initTranslations('search_results_page');

export type SearchResultsPageProps = {
  route: Route<typeof routes.search>;
  isStorybookEnvironment?: boolean;
};

const SearchResultsPage = ({ route, isStorybookEnvironment }: SearchResultsPageProps) => {
  const {
    slug,
    textEmbeddingModels,
    splitFeatures: { helpCenterSearchEnabled, newSearchUiEnabled },
  } = useCurrentAccount();
  const { id: userId } = useCurrentUser();
  const { cdpSearchListPaginationClicked } = useAnalyticsUserEvents();
  const { semanticSteps, isSemanticStepsFetching } = useAppSelector((state) => state.searchResults);

  const {
    data,
    filters,
    isFetching,
    isLoading,
    isCompanyKind,
    currentPage,
    querySearchKind,
    searchTerm,
  } = useGetSearchResultData(route);

  const isNotHelpCenterKind = querySearchKind !== 'help_center';

  const { data: helpCenterData, isFetching: isFetchingHelpCenter } =
    useGetSearchHelpCenterResultsQuery(
      {
        page: currentPage,
        search: searchTerm,
      },
      { skip: isNotHelpCenterKind }
    );

  const handleFilters = (filters: FilterName[]) => {
    routeTo(routes.search({ slug, page: 1, filters, search: searchTerm }));
  };

  const handleChips = (kind: SearchKind) => {
    routeTo(routes.search({ slug, page: 1, kind, search: searchTerm, filters }));
  };

  if (isLoading && !isStorybookEnvironment)
    return (
      <SearchResultsLoaderWrapper>
        <SearchResultsLoader />
      </SearchResultsLoaderWrapper>
    );

  if (!data) return <></>;

  const {
    searchHistory,
    totalCount,
    templatesResultCount,
    companyResultCount,
    filters: companyFilters,
  } = data;

  const handlePaginate = (page: number) => {
    cdpSearchListPaginationClicked({
      userId,
      pageNumber: page,
      searchHistoryId: isNotHelpCenterKind ? null : searchHistory.id,
      searchKind: querySearchKind,
    });

    routeTo(routes.search({ slug, page, filters, search: searchTerm, kind: querySearchKind }));
  };

  const companyCount = companyResultCount === undefined ? totalCount : companyResultCount;
  const templatesCount = templatesResultCount === undefined ? totalCount : templatesResultCount;
  const companyOrSemanticCount = companyCount || semanticSteps.length;

  const chipsCount: ChipsCount = {
    company: companyOrSemanticCount,
    templates: templatesCount,
    help_center: null,
  };

  const isTextEmbeddingModelAvailable = textEmbeddingModels.length > 0;
  const isSearchByPhrase = searchTerm.trim().split(' ').length > 2;

  const shouldUseSemanticSearch =
    isTextEmbeddingModelAvailable && (!newSearchUiEnabled || isSearchByPhrase);

  const showCompanyFilter = companyFilters && isCompanyKind && companyFilters.all !== 0;
  const totalResultCount = companyOrSemanticCount + templatesCount;
  const subtitle = t('header.items_found', { count: totalResultCount });
  const showSearchResultLoader = isFetching || isFetchingHelpCenter || isSemanticStepsFetching;

  const renderPageHeader = () => {
    if (newSearchUiEnabled) {
      return (
        <SearchResultsPageHeader>
          <PageHeader className='search-results-header' title={`“${searchTerm}“`} />
        </SearchResultsPageHeader>
      );
    }

    return (
      <PageHeader
        {...(!helpCenterSearchEnabled && { subtitle })}
        className='notranslate'
        title={`“${searchTerm}“`}
      />
    );
  };

  return (
    <PageContent id='search-results-page'>
      {data && (
        <>
          {shouldUseSemanticSearch ? (
            <ExperimentalSearchResults
              searchTerm={searchTerm}
              totalResultCount={totalResultCount}
            />
          ) : (
            renderPageHeader()
          )}
          <SearchResultsChips
            chipsCount={chipsCount}
            selectedChip={querySearchKind}
            setSelectedChip={handleChips}
            showLoader={isFetching}
          />
          <SearchResultsRow>
            {newSearchUiEnabled ? (
              <SearchResultsFoundBox>
                <SearchResultsTitle>{t('search_results')}</SearchResultsTitle>
                <SearchResultsSubTitle>{subtitle}</SearchResultsSubTitle>
              </SearchResultsFoundBox>
            ) : (
              <SearchResultsTitle>{t('search_results')}</SearchResultsTitle>
            )}
            {showCompanyFilter && (
              <SearchResultsFilter
                appliedFilters={filters}
                filtersCount={companyFilters}
                setAppliedFilters={handleFilters}
              />
            )}
          </SearchResultsRow>
        </>
      )}
      {showSearchResultLoader ? (
        <SearchResultsLoader />
      ) : (
        <>
          {data && (
            <SearchResultsContent
              data={data}
              handlePaginate={handlePaginate}
              helpCenterData={helpCenterData}
              page={currentPage}
              searchKind={querySearchKind}
              searchTerm={searchTerm}
            />
          )}
        </>
      )}
    </PageContent>
  );
};

export default SearchResultsPage;
