import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import Hoverable from '../../../../../design_system/Hoverable';
import PaywallTooltip from '../../../../shared/tooltips/PaywallTooltip';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledIcon,
  StyledToggle,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.signature_settings');

const SignatureToggle = () => {
  const {
    onFree3SeatsPlan,
    splitFeatures: { simplePricingEnabled },
  } = useCurrentAccount();
  const [isCheckboxHovered, setCheckboxHovered] = useState(false);
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const paywallCtx = useContext(PaywallContext);
  const signaturesLocked = paywallCtx.includes('e_signature');
  const {
    policy: { plural: policyTermPlural },
    topic: { singular: TopicTermSingular },
  } = useAccountTerminology();

  const getPaywallDescriptionKey = () => {
    if (onFree3SeatsPlan) {
      return 'free_plan_description';
    } else if (simplePricingEnabled) {
      return 'simple_pricing_description';
    } else {
      return 'description';
    }
  };

  return (
    <>
      <PaywallTooltip
        description={t(`signatures_paywall.${getPaywallDescriptionKey()}`)}
        modifiers={[{ name: 'offset', options: { offset: [25, 0] } }]}
        shouldBeVisible={isCheckboxHovered && signaturesLocked}
        title={t('signatures_paywall.title')}
      />
      <Hoverable setIsHovered={setCheckboxHovered}>
        <ToggleSettingContainer id='e-signatures-form'>
          <StyledToggle
            checked={values.allowSignatures}
            className='signatures-setting'
            disabled={signaturesLocked}
            handleToggle={() => setFieldValue('allowSignatures', !values.allowSignatures)}
            id='allow-signatures'
            name='allow_signatures'
          />
          <ToggleInfoWrapper>
            <FormSectionTitle>
              {t('signatures_title')}
              {signaturesLocked && <StyledIcon name='lock' weight='regular' />}
            </FormSectionTitle>
            <FormSectionDescription>
              {t('signatures_description', {
                policy: policyTermPlural,
                topic: TopicTermSingular.toLowerCase(),
              })}
            </FormSectionDescription>
          </ToggleInfoWrapper>
        </ToggleSettingContainer>
      </Hoverable>
    </>
  );
};

export default SignatureToggle;
