import React from 'react';

import { FinchProvider } from '../../../../../types/IntegrationProvider';
import { Activity } from '../../activities/types';
import FinchTemplate, { UserFinchTemplateProps } from './FinchTemplate';
import OverdueCurriculumsTemplate, {
  UserOverdueCurriculumsProps,
} from './OverdueCurriculumsTemplate';
import UserNotificationTemplate, {
  UserNotificationTemplateProps,
} from './UserNotificationTemplate';

interface UserActivitiesProps {
  'user.bulk_upload.failed': React.FC<UserNotificationTemplateProps>;
  'user.due_date.overdue': React.FC<UserOverdueCurriculumsProps>;
  'user.due_date.upcoming': React.FC<UserOverdueCurriculumsProps>;
  'user.finch.need_to_reauthorize': React.FC<UserFinchTemplateProps>;
  'user.finch.new_users_found': React.FC<UserFinchTemplateProps>;
  'user.finch.new_users_to_deactivate': React.FC<UserFinchTemplateProps>;
  'user.finch.provider_connected': React.FC<UserFinchTemplateProps>;
}

const ACTIVITY_KEYS: UserActivitiesProps = {
  'user.bulk_upload.failed': UserNotificationTemplate,
  'user.due_date.overdue': OverdueCurriculumsTemplate,
  'user.due_date.upcoming': OverdueCurriculumsTemplate,
  'user.finch.need_to_reauthorize': FinchTemplate,
  'user.finch.new_users_found': FinchTemplate,
  'user.finch.new_users_to_deactivate': FinchTemplate,
  'user.finch.provider_connected': FinchTemplate,
};

interface UsersActivity extends Omit<Activity, 'parameters'> {
  parameters: {
    assignment_ids: number[];
    source: FinchProvider;
    curriculum_id: number;
  };
}

interface UsersCardProps {
  activity: UsersActivity;
}

const UserNotificationsDetails = ({ activity }: UsersCardProps) => {
  const { key, owner, parameters } = activity;
  const NotificationMessage = ACTIVITY_KEYS[activity.key as keyof UserActivitiesProps];

  return <NotificationMessage activityKey={key} owner={owner} parameters={parameters} />;
};

export default UserNotificationsDetails;
