import React, { useEffect, useState } from 'react';
import AccountTerminology from 'types/AccountTerminology';

import initTranslations from '../../../../lib/initTranslations';
import { DetailedCurriculum } from '../../../../types';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { useFlashNotification } from '../../../FlashNotificationContext';
import ContentModal from '../../curriculums/modals/CurriculumModal/ContentModal';
import { LockedOverlay } from './styles';

type Props = {
  curriculum: Pick<DetailedCurriculum, 'id' | 'locked' | 'sector'>;
  elementType: 'Flowchart' | 'Step';
};

const t = initTranslations('editor');

const CurriculumLockedOverlay = ({ curriculum, elementType }: Props) => {
  const { flash } = useFlashNotification();
  const terminology = useAccountTerminology();

  const term = elementType.toLowerCase() as keyof Omit<AccountTerminology, 'productTerm'>;
  const elementTerm = terminology[term].singular.toLowerCase();
  const curriculumTerm = terminology['curriculum'].singular.toLowerCase();
  const [showCurriculumModal, setShowCurriculumModal] = useState(curriculum.locked);
  useEffect(() => {
    if (curriculum.locked) {
      flash(
        'warning',
        t('unlock_curriculum', {
          curriculum: curriculumTerm.toLowerCase(),
          element: elementTerm.toLowerCase(),
        }),
        {
          autoClose: 5000,
        }
      );
    }
  }, [curriculum.locked, curriculumTerm, flash, elementTerm]);

  return (
    <LockedOverlay
      className='locked-curriculum-overlay'
      onClick={() => setShowCurriculumModal(true)}
    >
      {showCurriculumModal && curriculum.locked && (
        <ContentModal
          closeRequest={() => setShowCurriculumModal(false)}
          curriculumId={curriculum.id}
          sector={curriculum.sector}
        />
      )}
    </LockedOverlay>
  );
};

export default CurriculumLockedOverlay;
