import React, { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import {
  useDeleteTrainingPathMutation,
  usePublishTrainingPathMutation,
  useUpdateTrainingPathContentMutation,
  useUpdateTrainingPathSetMutation,
} from '../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { TrainingPath } from '../redux/services/resourceApis/trainingPaths/types';
import { ProfileUser } from '../redux/services/resourceApis/users/types';

type TrainingPathUser = Pick<ProfileUser, 'id' | 'name' | 'avatar'>;

type TrainingPathContext = {
  trainingPath: TrainingPath;
  user: TrainingPathUser;
  updateInProgress: boolean;
  setUpdateInProgress: (value: boolean) => void;
  previewing: boolean;
  setPreviewing: (value: boolean) => void;
};

type TrainingPathProps = {
  children: ReactNode;
  trainingPath: TrainingPath;
  user: Pick<ProfileUser, 'id' | 'name' | 'avatar'>;
};

const TrainingPathContext = React.createContext({} as TrainingPathContext);
const useTrainingPathContext = () => useContext(TrainingPathContext);

const TrainingPathProvider = ({
  children,
  trainingPath,
  user,
}: TrainingPathProps): ReactElement => {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  // TODO figure out how to tap into preview Loading state since queries are nested

  const [, trainingPathSetUpdateResult] = useUpdateTrainingPathSetMutation({
    fixedCacheKey: 'shared-update-training-path-set',
  });
  const [, trainingPathContentUpdateResult] = useUpdateTrainingPathContentMutation({
    fixedCacheKey: 'shared-update-training-path-content',
  });
  const [, trainingPathPublishResult] = usePublishTrainingPathMutation({
    fixedCacheKey: 'shared-publish-training-path',
  });
  const [, trainingPathCancelResult] = useDeleteTrainingPathMutation({
    fixedCacheKey: 'shared-cancel-training-path',
  });

  const anyMutationLoading = useMemo(() => {
    return (
      trainingPathSetUpdateResult.isLoading ||
      trainingPathContentUpdateResult.isLoading ||
      trainingPathPublishResult.isLoading ||
      trainingPathCancelResult.isLoading
    );
  }, [
    trainingPathCancelResult.isLoading,
    trainingPathContentUpdateResult.isLoading,
    trainingPathPublishResult.isLoading,
    trainingPathSetUpdateResult.isLoading,
  ]);

  useEffect(() => {
    setUpdateInProgress(anyMutationLoading);
  }, [anyMutationLoading]);

  return (
    <TrainingPathContext.Provider
      value={{
        previewing,
        trainingPath,
        user,
        updateInProgress,
        setPreviewing,
        setUpdateInProgress,
      }}
    >
      {children}
    </TrainingPathContext.Provider>
  );
};

export { TrainingPathProvider, useTrainingPathContext };
