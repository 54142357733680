import React, { Dispatch, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import initTranslations from '../../../../../lib/initTranslations';
import { fontSm4 } from '../../../../styled/TypeSystem';
import { ToolbarActionProps } from '../../lib/toolbarReducer';

type ToolbarAction = 'format' | 'insert';

const Tabs = styled.div`
  display: flex;
  position: relative;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  z-index: 2;
  width: 10rem;
  justify-content: center;
  flex-direction: row;
  margin: ${({ theme: { constants } }) =>
    `0 ${constants.spacerSm3} ${constants.spacerSm3} ${constants.spacerSm3}`};
`;

const Label = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { constants } }) => constants.heightMd};
  z-index: 2;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ checked, theme: { constants } }) =>
    checked ? constants.fontBold : constants.fontMedium};
  width: 5rem;
  margin-bottom: 0;
  ${fontSm4};

  :hover {
    cursor: pointer;
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

const Radio = styled.input`
  display: none;
`;

const Glider = styled.span<{ checkedOption: ToolbarAction }>`
  position: absolute;
  display: flex;
  height: ${({ theme: { constants } }) => constants.heightMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDefault}`};
  z-index: 1;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  transition: left 0.35s ease-out;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  left: ${({ checkedOption }) => (checkedOption === 'insert' ? 5 : 0)}rem;
  width: 5rem;
`;

const t = initTranslations('editor_toolbar');

export interface Props {
  toolbarDispatch: Dispatch<ToolbarActionProps>;
  checkedOption: ToolbarAction;
}

const ToolbarTabs = ({ toolbarDispatch, checkedOption }: Props) => {
  const { editor } = useEditorContext();
  const { $from, $to } = editor.view.state.selection;

  useEffect(() => {
    const isTextHighlighted = $from.pos !== $to.pos && $from.parent.type.name !== 'doc';

    if (isTextHighlighted) {
      toolbarDispatch({ type: 'format' });
    }
  }, [$from, $to, toolbarDispatch]);

  const handleRadioClick = useCallback(
    (type) => {
      toolbarDispatch({ type });
    },
    [toolbarDispatch]
  );

  return (
    <Tabs>
      <Label checked={checkedOption === 'format'} htmlFor='format'>
        {t('format')}
      </Label>
      <Radio
        id='format'
        name='tabs'
        onClick={() => handleRadioClick('format')}
        type='radio'
        value='format'
      />

      <Label checked={checkedOption === 'insert'} htmlFor='insert'>
        {t('insert')}
      </Label>
      <Radio
        id='insert'
        name='tabs'
        onClick={() => handleRadioClick('insert')}
        type='radio'
        value='insert'
      />
      <Glider checkedOption={checkedOption} />
    </Tabs>
  );
};

export default ToolbarTabs;
