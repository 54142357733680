import React, { useState } from 'react';

import OutsideClickHandler from '../../../../design_system/OutsideClickHandler';
import Poppable from '../../../../Poppable';
import PoppableDropdownMenu from '../../../shared/DropdownWithPoppableMenu/PoppableDropdownMenu';
import { StyledIconButton } from './styles';
import { SortDropdownProps } from './types';

const SortingDropdown = ({ id, options, setSelectedOption }: SortDropdownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
      <Poppable
        isOpen={dropdownOpen}
        item={
          <PoppableDropdownMenu
            activeOptionIndex={undefined}
            dropdownOpen={dropdownOpen}
            menuId={`dropdown-menu-${id}`}
            menuItemDirection='column'
            menuItemType='default'
            options={options}
            setSelectedOption={setSelectedOption}
            toggleDropdown={toggleDropdown}
          />
        }
        onClick={toggleDropdown}
        placement='bottom-start'
        trigger={<StyledIconButton ariaLabel='sort' buttonSize='sm' name='sort' weight='solid' />}
      />
    </OutsideClickHandler>
  );
};

export default SortingDropdown;
