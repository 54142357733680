import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { TextFragment } from '../styles';

export type UserNotificationTemplateProps = {
  activityKey: string;
};

const t = initTranslations('notifications');

/**
 * A generic user notification component that displays a message
 *
 * @param {UserNotificationTemplate} props - The props for the component.
 */
const UserNotificationTemplate = ({ activityKey }: UserNotificationTemplateProps) => {
  return <TextFragment>{t(activityKey)}</TextFragment>;
};

export default UserNotificationTemplate;
