import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import initTranslations from '../../../../../lib/initTranslations';
import { sanitizeInput } from '../../../../../lib/sanitize';
import translationClassname from '../../../../../lib/translationClassname';
import { useUpdateStepMutation } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { Emoji } from '../../../../../types/Emoji';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import Icon from '../../../../design_system/display/icons/Icon';
import TextareaWithCharacterCount from '../../../../design_system/input/fields/TextareaWithCharacterCount';
import TextDisplay from '../../../../design_system/Triage/ClickInlineTextarea/TextDisplay';
import { ErrorText as BaseErrorText } from '../../../../design_system/Triage/InputField';
import { fontMd3 } from '../../../../styled/TypeSystem';
import EmojiPicker from '../../../shared/EmojiPicker';
import { EmojiWrapper } from '../../../shared/styles';
import { getValidationSchema } from '../../shared/validators';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const ErrorText = styled(BaseErrorText)`
  margin-top: -${({ theme: { constants } }) => constants.spacerSm2};
`;

const StyledEmojiWrapper = styled(EmojiWrapper)`
  margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  border-radius: ${({ theme }) => theme.constants.borderRadiusCircle};
  ${fontMd3};
`;

const t = initTranslations('editor.step');

type FormValues = {
  id: number;
  courseId: number;
  emoji: Emoji;
  title: string;
  disableTranslation?: boolean;
};

export type Props = FormValues;

const StepTitle = ({ emoji, id, title, courseId, disableTranslation }: Props) => {
  // setIsEditingTitle is for adding the overlay on the toolbar when editing.
  // setIsInputShowing is for displaying the text area.
  // They need to be different otherwise the TopicTitle can not be edited.
  const { setSaveState, setIsEditingTitle } = useEditorContext();
  const [isInputShowing, setIsInputShowing] = useState(false);
  const handleStateChange = useCallback(
    (state: boolean) => {
      setIsInputShowing(state);
      setIsEditingTitle(state);
    },
    [setIsEditingTitle]
  );

  const {
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const [updateStep, result] = useUpdateStepMutation();
  const { isLoading, isSuccess } = result;
  const [showPicker, setShowPicker] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);

  useEffect(() => {
    if (isLoading) {
      setSaveState('saving');
    } else if (isSuccess) {
      setSaveState('saved');
    }
  }, [setSaveState, isLoading, isSuccess]);

  useEffect(() => {
    handleStateChange(false);
    setCurrentTitle(title);
  }, [handleStateChange, id, title]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ emoji, id, title, courseId }}
      onSubmit={(values) => {
        const newTitle = sanitizeInput(values.title);
        setCurrentTitle(newTitle);
        return updateStep({ ...values, title: newTitle });
      }}
      validationSchema={getValidationSchema()}
    >
      {({
        isSubmitting,
        isValidating,
        isValid,
        errors,
        setFieldValue,
        handleSubmit,
        values,
      }: FormikProps<FormValues>) => {
        if (isSubmitting && !isValidating && !isValid) {
          setFieldValue('title', currentTitle);
        }

        return (
          <div>
            <TitleWrapper>
              <EmojiPicker
                emojiPresent={values.emoji}
                isOpen={showPicker}
                onClick={() => setShowPicker(!showPicker)}
                onClickOutside={() => setShowPicker(false)}
                onEmojiSelect={(emoji: BaseEmoji) => {
                  setFieldValue('emoji', emoji.native);
                  handleSubmit();
                  setShowPicker(false);
                }}
                removeButtonAction={() => {
                  setFieldValue('emoji', null);
                  handleSubmit();
                }}
                trigger={
                  <StyledEmojiWrapper id='emoji-picker-trigger'>
                    {values.emoji || <Icon name='list-ol' size='sm' weight='regular' />}
                  </StyledEmojiWrapper>
                }
              />
              <FullWidthContainer className={translationClassname(disableTranslation)}>
                {isInputShowing ? (
                  <TextareaWithCharacterCount
                    attributeName='title'
                    id='editable-title-field'
                    inputFontSize='md'
                    onBlur={() => handleStateChange(false)}
                    onChange={(e) => {
                      setFieldValue('title', e.target.value);
                    }}
                    onFocus={() => setIsEditingTitle(true)}
                    placeholder={t('placeholder', { step: stepTerm.toLowerCase() })}
                    resourceName={stepTerm}
                    rows={1}
                    submitChanges={() => {
                      handleStateChange(false);
                      if (values.title !== currentTitle) {
                        if (isValid) {
                          handleSubmit();
                        } else {
                          setFieldValue('title', currentTitle);
                        }
                      }
                    }}
                    value={values.title}
                  />
                ) : (
                  <TextDisplay
                    attributeName='title'
                    inputFontSize='md'
                    isPlaceholder={values.title === ''}
                    setEditState={() => setIsInputShowing(true)}
                    text={values.title}
                  />
                )}
                {errors && <ErrorText>{errors.title}</ErrorText>}
              </FullWidthContainer>
            </TitleWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default StepTitle;
