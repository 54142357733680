import { Sector } from '../../../../types/Sector';

export type TrainingPathCurriculum = {
  __typename: string;
  emoji: string;
  id: number;
  minutesToReadFormatted: string;
  published: boolean;
  sector: Sector;
  title: string;
};

export type TrainingPathContent = {
  completionPercentage: number;
  curriculum: TrainingPathCurriculum;
  dueDateAt: string | null;
  favorite: boolean;
  position: number;
  requiredForUser: boolean;
  requiredGroups: string[];
  __typename: string;
};

export const delayTypes = ['days', 'weeks', 'months'] as const;

export type DelayType = (typeof delayTypes)[number];

type DelayStatus = 'complete' | 'not_started' | 'no_delay' | 'in_progress';

type BaseDelayState = {
  status: DelayStatus;
};

type CompletedDelayState = BaseDelayState & {
  status: 'complete';
};

type NotStartedDelayState = BaseDelayState & {
  status: 'not_started';
};

type NotDelayState = BaseDelayState & {
  status: 'no_delay';
};

type InProgressDelayState = BaseDelayState & {
  availableAtInWords: string;
  status: 'in_progress';
};

export type TrainingPathDelayState =
  | CompletedDelayState
  | InProgressDelayState
  | NotDelayState
  | NotStartedDelayState;

export type TrainingPathSet = {
  availableAt: string | null;
  completionPercentage: number;
  delayStartedAt: string | null;
  delayState: TrainingPathDelayState;
  delayType: DelayType | null;
  delayValue: number | null;
  forceOrder: boolean;
  minutesToReadFormatted: string;
  position: number;
  title: string | null;
  trainingPathContents: TrainingPathContent[];
  trainingPathSetUuid: string;
  titleVisible: boolean;
  __typename: string;
};

export type TrainingPath = {
  isDraft: boolean;
  isPublished: boolean;
  trainingPathSets: TrainingPathSet[];
  updatedAtInWords: string;
  __typename: string;
};

export type UpdatableTrainingPathSetData = Pick<
  TrainingPathSet,
  'delayType' | 'delayValue' | 'forceOrder' | 'position' | 'title' | 'titleVisible'
> & { resetDelay?: boolean };

type UpdatableTrainingPathContentData = Pick<TrainingPathContent, 'position'>;

export type UpdateTrainingPathSetParams = Omit<UpdatableTrainingPathSetData, 'resetDelay'> & {
  trainingPathSetUuid: string;
  userId: number;
  resetDelay: boolean;
};

export type UpdateTrainingPathContentParams = UpdatableTrainingPathContentData & {
  curriculumId: number;
  trainingPathSetUuid: string;
  oldSetUuid: string;
  userId: number;
};

export type SplitTrainingPathSetParams = {
  trainingPathSetUuid: string;
  userId: number;
  curriculumId: number;
};

export type DeleteTrainingPathSetParams = {
  trainingPathSetUuid: string;
  userId: number;
};

const trainingPathTagTypes = ['draft', 'published'] as const;
export type TrainingPathTag = (typeof trainingPathTagTypes)[number];
