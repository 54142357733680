import React from 'react';

import { useAccountTerminology } from '../../../../components/AccountTerminologyProvider';
import usePageTitle from '../../../../hooks/usePageTitle';
import { useRedirectToBillingOverlay } from '../../../../hooks/useRedirectToBillingOverlay';
import useWindowResize from '../../../../hooks/useWindowResize';
import {
  ReportsPaywallEmptyStateDark as EmptyStateDark,
  ReportsPaywallEmptyState as EmptyStateLight,
} from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import NoResults from '../../../design_system/Triage/NoResults';
import PageContent from '../../shared/page_content';

const t = initTranslations('reports.paywall_report');

const PaywallReportsPage = () => {
  const { productTerm } = useAccountTerminology();
  usePageTitle({ resourceType: 'reports', productTerm });

  const { isDesktop } = useWindowResize();
  const navigateToBillingOverlay = useRedirectToBillingOverlay();

  return (
    <PageContent>
      <NoResults
        darkImage={EmptyStateDark}
        heading={t('title')}
        iconWidth={isDesktop ? '50%' : '100%'}
        lightImage={EmptyStateLight}
        subHeaderCta={{
          action: () => navigateToBillingOverlay(),
          text: t('action_text'),
        }}
        subHeaderText={t('text')}
        subheaderMargin='1.5rem'
      />
    </PageContent>
  );
};

export default PaywallReportsPage;
