import React from 'react';

import { useStepId } from '../../../../contexts/StepIdContext';
import usePrivateConfigs from '../../../../hooks/usePrivateConfigs';
import initTranslations from '../../../../lib/initTranslations';
import { useGetEditorTopicQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Link from '../../../design_system/Link/Link';
import TaskModal from '../../../design_system/overlays/modals/TaskModal';
import { LinkWrapper, ModalBody, ModalCTA } from './styles';

interface Props {
  onCloseRequest: () => void;
  primaryButtonTask: () => void;
  processing: boolean;
  selectedVersionDate: string;
}

const t = initTranslations('editor.version_history.modal');

const Modal = ({ onCloseRequest, primaryButtonTask, processing, selectedVersionDate }: Props) => {
  const stepId = useStepId();
  const { configs } = usePrivateConfigs();
  const {
    curriculum: { singular: subjectTermSingular },
  } = useAccountTerminology();
  const { data: stepData } = useGetStepQuery(stepId);
  const courseId = stepData?.courseId;
  const { data: courseData } = useGetEditorTopicQuery(
    { courseId: courseId as number },
    {
      skip: !courseId,
    }
  );

  // TODO: GS5-506 - Handle error state
  if (!stepData || !courseData) {
    return null;
  }

  // These need to be set to variables to be passed as options in translations
  const subjectName = courseData.curriculum.title;
  const courseStatus = courseData.status;

  return (
    <TaskModal
      heapModalName='step-version-history-modal'
      isDisabled={false}
      onCloseRequest={onCloseRequest}
      primaryButtonTask={primaryButtonTask}
      primaryButtonText={t('primary_button')}
      processing={processing}
      title={t('title')}
    >
      <ModalBody>
        <ModalCTA dangerouslySetInnerHTML={{ __html: t('cta', { selectedVersionDate }) }} />
        {t('body', { subjectName, subjectTerm: subjectTermSingular.toLowerCase(), courseStatus })}
        <LinkWrapper>
          {t('clear_completions')}
          <Link
            behavesAs='a'
            external
            href={configs['UPDATE_COMPLETION_PERCENTAGES_HELP_PAGE']}
            text={t('help_doc')}
          />
        </LinkWrapper>
      </ModalBody>
    </TaskModal>
  );
};

export default Modal;
