import React from 'react';

import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import { EmptyStateCardSvg } from '../../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../../lib/initTranslations';
import { EmptyStateCardBody, EmptyStateCardImage, EmptyStateCardWrapper } from './styles';

const t = initTranslations('billing.full_screen_overlay_content.empty_state_card');

const EmptyStateCard = () => {
  const {
    splitFeatures: { simplePricingEnabled },
  } = useCurrentAccount();
  const bodyText = simplePricingEnabled ? t('simple_pricing_body') : t('per_user_pricing_body');

  return (
    <EmptyStateCardWrapper>
      <EmptyStateCardImage src={EmptyStateCardSvg} />
      <EmptyStateCardBody text={bodyText} />
    </EmptyStateCardWrapper>
  );
};

export default EmptyStateCard;
