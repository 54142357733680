import styled, { css } from 'styled-components';

import P from '../../../../../../design_system/text/P';
import { fontLg2 } from '../../../../../../styled/TypeSystem';
import { getTrainualPlusBgImg } from '../../shared/styles';
import { sharedBeforeStyles, sharedInlineBlockStyles } from '../FeatureSection/styles';

export const TrainualPlusHeaderWrapper = styled.div`
  ${({ theme: { constants } }) => css`
    max-width: 29.5rem;
    text-align: center;
    margin: 0 auto ${constants.spacerMd3};
  `}
`;

export const TrainualPlusHeaderTitle = styled.div<{ primaryColor: string; secondaryColor: string }>`
  ${({ theme: { constants, vars }, primaryColor, secondaryColor }) => css`
    margin-top: 0;
    margin-bottom: ${constants.spacerLg1};
    color: ${vars.trainualBrandBlueStrong};
    font-weight: ${constants.fontBold};
    ${fontLg2};

    .trainual-plus {
      ${sharedInlineBlockStyles};

      color: ${primaryColor};
      margin-top: ${constants.spacerSm3};

      &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -3px;
        width: 165px;
        height: 58px;
        z-index: -1;
        background: ${getTrainualPlusBgImg(secondaryColor, 165, 59)};
      }
    }

    .playbook {
      ${sharedInlineBlockStyles};

      &::before {
        top: 16px;
        left: 0;
        right: -4px;
        height: 23px;
        z-index: -1;
        background-color: ${vars.previewPurpleSecondary};
        ${sharedBeforeStyles};
      }
    }
  `}
`;

export const TrainualPlusHeaderDescription = styled(P)`
  ${({ theme: { constants } }) => css`
    margin: 0 auto;
    font-weight: ${constants.fontMedium};
    line-height: normal;
  `}
`;
