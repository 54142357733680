import { CollabHistoryVersion } from '@tiptap-pro/extension-collaboration-history';

export const groupVersions = (
  versions: CollabHistoryVersion[],
  todayLabel: string,
  yesterdayLabel: string
) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const groups: { [key: string]: CollabHistoryVersion[] } = {};

  versions.forEach((version) => {
    const versionDate = new Date(version.date);
    const isToday = versionDate.toDateString() === today.toDateString();
    const isYesterday = versionDate.toDateString() === yesterday.toDateString();

    let groupKey;

    if (isToday) {
      groupKey = todayLabel;
    } else if (isYesterday) {
      groupKey = yesterdayLabel;
    } else {
      groupKey = versionDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      });
    }

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }

    groups[groupKey].push(version);
  });

  return groups;
};

export const sortedGroupKeys = (
  groupedVersions: { [key: string]: CollabHistoryVersion[] },
  todayLabel: string,
  yesterdayLabel: string
) => {
  return Object.keys(groupedVersions).sort((a, b) => {
    if (a === todayLabel) return -1;
    if (b === todayLabel) return 1;
    if (a === yesterdayLabel) return -1;
    if (b === yesterdayLabel) return 1;

    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateB.getTime() - dateA.getTime();
  });
};
