import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';

const t = initTranslations('account_settings.content_settings.brand_styles');

const LockContentStyles = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.lockBrandStyles}
        className='lock-brand-styles-setting'
        handleToggle={() => setFieldValue('lockBrandStyles', !values.lockBrandStyles)}
        id='lock-brand-styles'
        name='lockBrandStyles'
      />
      <ToggleInfoSection>
        <ToggleInfoWrapper>
          <FormSectionTitle>{t('lock_content_styles_title')}</FormSectionTitle>
          <FormSectionDescription>{t('lock_content_styles_description')}</FormSectionDescription>
        </ToggleInfoWrapper>
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default LockContentStyles;
