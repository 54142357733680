import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import MenuOption from '../../../design_system/core/MenuOption/MenuOption';
import { SharedOptionProps } from './types';

const t = initTranslations('three_dot_menu');

const Rename = ({ isDisabled, onClick, id, tooltipText }: SharedOptionProps) => {
  return (
    <MenuOption
      iconName='pen'
      iconWeight='regular'
      id={id}
      isDisabled={isDisabled}
      onClick={onClick}
      title={t('rename')}
      tooltipText={tooltipText}
    />
  );
};

export default Rename;
