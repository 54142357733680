interface ToolbarState {
  action: ToolbarAction;
}

type ToolbarAction = 'format' | 'insert';

export interface ToolbarActionProps {
  type: ToolbarAction;
}

export const initialToolbarState: ToolbarState = {
  action: 'format',
};

export const toolbarReducer = (_state: ToolbarState, action: ToolbarActionProps): ToolbarState => {
  const { type } = action;

  switch (type) {
    case 'format':
      return { action: 'format' };
    case 'insert':
      return { action: 'insert' };
  }
};
