import {
  customDomainFeatureIconStyles,
  delegationPlannerFeatureIconStyles,
  eSignatureFeatureIconStyles,
  multiLanguageFeatureIconStyles,
  trainingPathFeatureIconStyles,
} from '../../components/application/billing/simple_pricing/AddonFullScreenOverlays/AddonTrainualPlusFullScreenOverlay/FeatureSection/styles';
import { Addon } from '../../types/Addon';

export function getAddonTrainualPlusFeatureIconStyles(name: Addon) {
  switch (name) {
    case 'delegation_planner':
      return delegationPlannerFeatureIconStyles;
    case 'e_signature':
      return eSignatureFeatureIconStyles;
    case 'training_path':
      return trainingPathFeatureIconStyles;
    case 'multi_language':
      return multiLanguageFeatureIconStyles;
    case 'custom_domain':
      return customDomainFeatureIconStyles;
  }
}
