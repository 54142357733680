import React from 'react';

import initTranslations from '../../../../../lib/initTranslations';
import { useAddEditorTranslationPopupAcceptedMutation } from '../../../../../redux/services/resourceApis/users/usersApi';
import ConfirmationModal from '../../../../design_system/overlays/modals/ConfirmationModal';

const t = initTranslations('editor');

interface Props {
  closeModal: () => void;
}

const NoTranslationModal = ({ closeModal }: Props) => {
  const [addEditorTranslationPopupAccepted] = useAddEditorTranslationPopupAcceptedMutation();

  return (
    <ConfirmationModal
      actionFunction={() => {
        addEditorTranslationPopupAccepted();
        closeModal();
      }}
      actionText={t('translation_modal.action')}
      desktopSize='lg'
      heapModalName='editor-translation-modal'
      message={t('translation_modal.message')}
      onCloseRequest={() => {
        closeModal();
      }}
      processing={false}
      secondaryButtonText={t('translation_modal.secondary_action')}
      title={t('translation_modal.title')}
    />
  );
};

export default NoTranslationModal;
