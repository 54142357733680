import React from 'react';
import styled from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import Toggle from '../../../../design_system/input/controls/Toggle';

const t = initTranslations('training_path.path_config.config_header');

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const PreviewToggle = () => {
  const { previewing, setPreviewing } = useTrainingPathContext();
  const { updateInProgress } = useTrainingPathContext();

  return (
    <Wrapper>
      <Icon name='eye' />
      <Toggle
        checked={previewing}
        disabled={updateInProgress}
        handleToggle={() => setPreviewing(!previewing)}
        id='preview-toggle'
        name='preview'
        primaryLabel={t('preview')}
      />
    </Wrapper>
  );
};

export default PreviewToggle;
