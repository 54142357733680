import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import useActionCableChannel, {
  ChannelProps,
} from '../../../../../../../hooks/useActionCableChannel';
import useCurrentAccount from '../../../../../../../hooks/useCurrentAccount';
import useSlideout from '../../../../../../../hooks/useSlideout';
import {
  useCreateElementMutation,
  useGetCurriculumElementsForAdminQuery,
} from '../../../../../../../redux/services/resourceApis/curriculumElements/curriculumElementsApi';
import {
  useAdminUpdateCurriculumMutation,
  useGetCurriculumForEditQuery,
} from '../../../../../../../redux/services/resourceApis/curriculums/curriculumsApi';
import { ContextQuestions } from '../../../../../../../redux/services/resourceApis/curriculums/types';
import { useCreateChatCompletionMutation } from '../../../../../../../redux/services/resourceApis/openAI/openAiAPI';
import { CompletionResponse } from '../../../../../../../redux/services/resourceApis/openAI/types';
import { isCourse } from '../../../../../../../types/Course';
import {
  contentOutlinePromptMessages,
  courseStepsPromptMessages,
  refreshCoursePromptMessages,
} from '../../../../../ai/prompts/prompts';
import SlideoutPanel from '../../../../../shared/slideout';
import ContextQuestionsPanel, {
  ContextQuestionsValues,
} from '../ContextQuestionsPanel/ContextQuestionsPanel';
import CourseSuggestions from '../CourseSuggestions/CourseSuggestions';
import { CourseSuggestion } from '../CourseSuggestions/CourseSuggestionsTable/CourseSuggestionsTable';
import ErrorState from '../ErrorState/ErrorState';
import SlideoutHeader from '../SlideoutHeader/SlideoutHeader';
import StepSuggestions from '../StepSuggestions/StepSuggestions';
import SuccessState from '../SuccessState/SuccessState';
import { reducer } from './reducer';

const FEATURE_NAME = 'subject-outliner';

const channelProps: ChannelProps = {
  channel: 'AiCompletionsChannel',
  compose_feature: FEATURE_NAME,
};

export type StepSuggestion = {
  emoji: string;
  title: string;
  contentJson: { htmlText: string };
};

const initialState = {
  hasContentError: false,
  courseCompletions: [],
  courseSuggestions: [],
  selectedCourse: null,
  stepCompletionByCourse: {},
};

export type BodyProps = {
  contextQuestions: ContextQuestions | null;
  curriculumId: number;
  curriculumTitle: string;
  currentTopicTitles: string[];
  isEditingContext: boolean;
  setIsEditingContext: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SlideoutBody = ({
  contextQuestions,
  curriculumId,
  curriculumTitle,
  currentTopicTitles,
  isEditingContext,
  setIsEditingContext,
}: BodyProps) => {
  const { isOpen } = useSlideout('ai-outliner');
  const [hasApiError, setHasApiError] = useState(false);
  const { name: accountName, industry, employeeSize } = useCurrentAccount();
  const [completion, setCompletion] = useState<CompletionResponse>();
  const [showSuccessState, setShowSuccessState] = useState(false);
  const [hasTriggeredInitialCompletionRequest, setHasTriggeredInitialCompletionRequest] =
    useState(false);
  const [
    {
      courseCompletions,
      courseSuggestions,
      selectedCourse,
      stepCompletionByCourse,
      hasContentError,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [createChatCompletion] = useCreateChatCompletionMutation();
  const [createElement] = useCreateElementMutation();
  const [updateCurriculum] = useAdminUpdateCurriculumMutation();
  const hasContextQuestions = useMemo(() => {
    if (contextQuestions === null) return false;

    return Object.values(contextQuestions).some((value) => value !== '');
  }, [contextQuestions]);

  const isLoading = useMemo(() => completion?.status === 'pending', [completion]);

  const triggerChatCompletion = useCallback(
    (promptData: { contextQuestions?: ContextQuestions } = {}) => {
      const messages = selectedCourse
        ? courseStepsPromptMessages({
            accountName,
            industry,
            employeeSize,
            curriculumTitle,
            courseTitle: selectedCourse.title,
          })
        : contentOutlinePromptMessages({
            accountName,
            industry,
            employeeSize,
            curriculumTitle,
            currentTopicTitles,
            contextQuestions,
            ...promptData,
          }).concat(refreshCoursePromptMessages({ previousCourseCompletions: courseCompletions }));
      createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages,
        feature_name: FEATURE_NAME,
        temperature: 0.8,
        presence_penalty: 0.8,
        max_tokens: 2000,
      });
    },
    [
      selectedCourse,
      accountName,
      industry,
      employeeSize,
      curriculumTitle,
      currentTopicTitles,
      contextQuestions,
      courseCompletions,
      createChatCompletion,
    ]
  );

  useEffect(() => {
    if (!isOpen) return; // TODO: In GS2-4677, add checks for hasApiError || hasContentError here
    if (selectedCourse) {
      // Don't trigger again if we've already retrieved steps for this course
      const stepCompletionPresent = Object.prototype.hasOwnProperty.call(
        stepCompletionByCourse,
        selectedCourse.title
      );
      if (stepCompletionPresent) return;
    } else {
      if (hasTriggeredInitialCompletionRequest) return;
    }

    setHasTriggeredInitialCompletionRequest(true);
    triggerChatCompletion();
  }, [
    isOpen,
    selectedCourse,
    stepCompletionByCourse,
    triggerChatCompletion,
    hasTriggeredInitialCompletionRequest,
  ]);

  useEffect(() => {
    if (completion?.status === 'completed') {
      dispatch({ type: 'storeCompletion', completion: completion.completion });
    } else if (completion?.status === 'failed') {
      setHasApiError(true);
    }
  }, [completion]);

  const received = useCallback((completionResponse: CompletionResponse) => {
    setCompletion(completionResponse);
  }, []);

  useActionCableChannel<CompletionResponse>(channelProps, received);

  const setSelectedCourse = useCallback((selectedCourse: CourseSuggestion | null) => {
    dispatch({ type: 'setSelectedCourse', selectedCourse });
  }, []);

  const stepSuggestions: StepSuggestion[] | undefined = useMemo(() => {
    if (selectedCourse && stepCompletionByCourse[selectedCourse.title]) {
      return stepCompletionByCourse[selectedCourse.title].split('\n').map((stepData) => {
        const [emoji, title, content] = stepData.split('|');
        return { emoji, title, contentJson: { htmlText: content } };
      });
    }
  }, [selectedCourse, stepCompletionByCourse]);

  const addCourseToCurriculum = useCallback(() => {
    if (!selectedCourse) return;
    createElement({
      element: {
        ...selectedCourse,
        steps: stepSuggestions,
      },
      aiCompletionId: completion?.id,
      elementKind: 'course',
      curriculumId,
    });
    dispatch({ type: 'removeCourseSuggestion', courseSuggestion: selectedCourse });
    setSelectedCourse(null);
    setShowSuccessState(true);
  }, [
    selectedCourse,
    createElement,
    stepSuggestions,
    completion?.id,
    curriculumId,
    setSelectedCourse,
  ]);

  const submitContextQuestions = useCallback(
    (values: ContextQuestionsValues) => {
      updateCurriculum({ id: curriculumId, contextQuestions: values });
      setIsEditingContext(false);
      dispatch({ type: 'clearCompletions' });
      triggerChatCompletion({
        contextQuestions: {
          key_learning_objectives: values.keyLearningObjectives,
          info_to_cover: values.infoToCover,
          who_is_it_for: values.whoIsItFor,
        },
      });
    },
    [updateCurriculum, curriculumId, setIsEditingContext, triggerChatCompletion]
  );

  if (isEditingContext) {
    return (
      <ContextQuestionsPanel
        contextQuestions={contextQuestions}
        onCancel={() => setIsEditingContext(false)}
        onSubmit={submitContextQuestions}
      />
    );
  }

  if (showSuccessState) {
    return <SuccessState onClick={() => setShowSuccessState(false)} />;
  }

  return (
    <>
      {hasApiError ? (
        <ErrorState />
      ) : hasContentError ? (
        <ErrorState
          hasContentError={hasContentError}
          onClick={() => {
            setSelectedCourse(null);
            dispatch({ type: 'resetContentError' });
          }}
        />
      ) : (
        <>
          {selectedCourse ? (
            <StepSuggestions
              addCourseToCurriculum={addCourseToCurriculum}
              isLoading={isLoading}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              stepSuggestions={stepSuggestions}
            />
          ) : (
            <CourseSuggestions
              courseSuggestions={courseSuggestions}
              hasContextQuestions={hasContextQuestions}
              isLoading={isLoading}
              refreshCourseCompletions={triggerChatCompletion}
              setIsEditingContext={setIsEditingContext}
              setSelectedCourse={setSelectedCourse}
            />
          )}
        </>
      )}
    </>
  );
};

type Props = {
  curriculumId: number;
};

const SuggestedTopicsSlideout = ({ curriculumId }: Props) => {
  const [isEditingContext, setIsEditingContext] = useState<boolean>(false);
  const { data: curriculum } = useGetCurriculumForEditQuery(curriculumId);
  const { data: curriculumElements } = useGetCurriculumElementsForAdminQuery(curriculumId);
  const courseTitles = useMemo(() => {
    if (curriculumElements) {
      return curriculumElements.curriculumElements
        .filter((ce) => isCourse(ce))
        .map(({ element }) => element.title);
    }
  }, [curriculumElements]);

  if (!curriculum || !courseTitles) return null;

  return (
    <SlideoutPanel
      id='ai-outliner-slideout'
      scrollable={false}
      showClose={false}
      slideoutType='ai-outliner'
      slideoutWidth='20rem'
    >
      <>
        <SlideoutHeader type={isEditingContext ? 'custom' : 'topic'} />
        <SlideoutBody
          contextQuestions={curriculum.context_questions}
          currentTopicTitles={courseTitles}
          curriculumId={curriculumId}
          curriculumTitle={curriculum.title}
          isEditingContext={isEditingContext}
          setIsEditingContext={setIsEditingContext}
        />
      </>
    </SlideoutPanel>
  );
};

export default SuggestedTopicsSlideout;
