import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { PaywallContext } from '../../../../../../contexts/PaywallContext';
import initTranslations from '../../../../../../lib/initTranslations';
import { BrandStyles } from '../../../../../../redux/services/resourceApis/accountSettings/types';
import Icon from '../../../../../design_system/display/icons/Icon';
import { paletteColorDecoder } from '../../../../../design_system/helpers';
import Hoverable from '../../../../../design_system/Hoverable';
import Link from '../../../../../design_system/Link';
import PaywallTooltip from '../../../../shared/tooltips/PaywallTooltip';
import { Title } from '../../../BrandStyles/styles';
import HorizontalLine from '../../../HorizontalLine/HorizontalLine';
import TextStyleBar from '../../../TextStyleBar';
import {
  FormSectionDescription,
  FormSectionTitle,
  PageHeader,
  ToggleInfoWrapper,
} from '../../styles';
import { SettingsData } from '../../types';
import LockContentStyles from './LockContentStyles';

const FontWrapper = styled.div<{ isLocked: boolean }>`
  ${({ isLocked }) =>
    isLocked &&
    `
        pointer-events: none;
    `}
`;

const BrandStylesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 100%;
`;

const TitleInner = styled.div`
  width: 18rem;
`;

const TextStyleBarWrapper = styled.div`
  margin: ${({ theme: { constants } }) => constants.spacerMd3} 0;
`;

const t = initTranslations('account_settings.content_settings.brand_styles');

type BrandProps = {
  brandStyles: BrandStyles;
  defaultBrandStyles: BrandStyles;
};

const BrandStylesSection = ({ brandStyles, defaultBrandStyles }: BrandProps) => {
  const [isToggleHovered, setIsToggleHovered] = useState(false);
  const [isResetStyle, setIsResetStyle] = useState(false);
  const paywallCtx = useContext(PaywallContext);
  const { palettes } = useTheme();
  const brandStylesLocked = paywallCtx.includes('brand_styles');
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  const setH1 = useCallback((data) => setFieldValue('brandStyles.h1', data), [setFieldValue]);
  const setH2 = useCallback((data) => setFieldValue('brandStyles.h2', data), [setFieldValue]);
  const setH3 = useCallback((data) => setFieldValue('brandStyles.h3', data), [setFieldValue]);
  const setH4 = useCallback((data) => setFieldValue('brandStyles.h4', data), [setFieldValue]);
  const setP = useCallback((data) => setFieldValue('brandStyles.p', data), [setFieldValue]);
  const setA = useCallback((data) => setFieldValue('brandStyles.a', data), [setFieldValue]);
  const setHrWidth = useCallback(
    (data) => setFieldValue('brandStyles.hr.border-width', data),
    [setFieldValue]
  );
  const setHrColor = useCallback(
    (data) => setFieldValue('brandStyles.hr.border-color', data),
    [setFieldValue]
  );

  const resetStyles = () => {
    setFieldValue('brandStyles', defaultBrandStyles);
    setFieldValue('previousStyles', brandStyles);
    setIsResetStyle(true);
  };

  const undoStyles = () => {
    setFieldValue('brandStyles', values.previousStyles);
    setIsResetStyle(false);
  };

  const constructFontData = useCallback(
    (fontData: { [key: string]: string }) => {
      return {
        ...fontData,
        color: paletteColorDecoder(palettes, fontData.color),
      };
    },
    [palettes]
  );

  const title = brandStylesLocked ? (
    <Hoverable setIsHovered={setIsToggleHovered}>
      <FormSectionTitle>
        {t('title')} <Icon name='lock' />
      </FormSectionTitle>
    </Hoverable>
  ) : (
    <FormSectionTitle>{t('title')}</FormSectionTitle>
  );

  return (
    <BrandStylesContainer>
      <PaywallTooltip
        description={t('lock_brand_styles_paywall.description')}
        shouldBeVisible={isToggleHovered && brandStylesLocked}
        title={t('lock_brand_styles_paywall.title')}
      />
      <TitleInner>
        <PageHeader>
          <PageHeader>{t('header')}</PageHeader>
        </PageHeader>
      </TitleInner>
      <ToggleInfoWrapper>
        {title}
        <FormSectionDescription>
          {t('description')}
          {t('space')}
          {!brandStylesLocked && (
            <Link
              behavesAs='button'
              onClick={isResetStyle ? undoStyles : resetStyles}
              text={isResetStyle ? t('reset_styles.undo') : t('reset_styles.reset')}
            />
          )}
        </FormSectionDescription>
      </ToggleInfoWrapper>
      <Hoverable setIsHovered={setIsToggleHovered}>
        <FontWrapper data-testid='content-styles' isLocked={brandStylesLocked}>
          <TextStyleBarWrapper>
            <TextStyleBar
              defaultFontColor={values.defaultBrandStyles.h1.color}
              disabled={brandStylesLocked}
              fontData={constructFontData(values.brandStyles.h1)}
              setFontData={setH1}
              triggerElement={<h1>{t('heading', { size: 1 })}</h1>}
            />
          </TextStyleBarWrapper>
          <TextStyleBarWrapper>
            <TextStyleBar
              defaultFontColor={values.defaultBrandStyles.h2.color}
              disabled={brandStylesLocked}
              fontData={constructFontData(values.brandStyles.h2)}
              setFontData={setH2}
              triggerElement={<h2>{t('heading', { size: 2 })}</h2>}
            />
          </TextStyleBarWrapper>
          <TextStyleBarWrapper>
            <TextStyleBar
              defaultFontColor={values.defaultBrandStyles.h3.color}
              disabled={brandStylesLocked}
              fontData={constructFontData(values.brandStyles.h3)}
              setFontData={setH3}
              triggerElement={<h3>{t('heading', { size: 3 })}</h3>}
            />
          </TextStyleBarWrapper>
          <TextStyleBarWrapper>
            <TextStyleBar
              defaultFontColor={values.defaultBrandStyles.h4.color}
              disabled={brandStylesLocked}
              fontData={constructFontData(values.brandStyles.h4)}
              setFontData={setH4}
              triggerElement={<h4>{t('heading', { size: 4 })}</h4>}
            />
          </TextStyleBarWrapper>
          <TextStyleBarWrapper>
            <TextStyleBar
              defaultFontColor={values.defaultBrandStyles.p.color}
              disabled={brandStylesLocked}
              fontData={constructFontData(values.brandStyles.p)}
              setFontData={setP}
              triggerElement={<p>{t('normal')}</p>}
            />
          </TextStyleBarWrapper>
          <TextStyleBarWrapper>
            <TextStyleBar
              defaultFontColor={values.defaultBrandStyles.a.color}
              disabled={brandStylesLocked}
              fontData={constructFontData(values.brandStyles.a)}
              setFontData={setA}
              triggerElement={<a href='#'>{t('link_text')}</a>}
            />
          </TextStyleBarWrapper>
          <Title>{t('horizontal_line')}</Title>
          <HorizontalLine
            brandStylesHr={values.brandStyles.hr}
            setHrColor={setHrColor}
            setHrWidth={setHrWidth}
          />
        </FontWrapper>
      </Hoverable>
      {!brandStylesLocked && <LockContentStyles />}
    </BrandStylesContainer>
  );
};

export default BrandStylesSection;
