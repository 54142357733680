import React from 'react';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { useDeleteTrainingPathMutation } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import TaskModal from '../../../../design_system/overlays/modals/TaskModal';
import useActiveModalHandler from '../../../../design_system/useActiveModalHandler';

const t = initTranslations('training_path.path_config.config_header.cancel_confirmation_modal');

const CancelConfirmationModal = () => {
  const {
    user: { id: userId },
  } = useTrainingPathContext();
  const { closeActiveModal } = useActiveModalHandler();
  const [deleteDraft, cancelDraftResult] = useDeleteTrainingPathMutation({
    fixedCacheKey: 'shared-cancel-training-path',
  });

  useDisplayFlashOnResponse({
    result: cancelDraftResult,
    errorMessage: t('undo_failed'),
    successFunction: () => {
      cancelDraftResult.reset();
      closeActiveModal();
    },
    errorFunction: () => {
      cancelDraftResult.reset();
    },
  });

  return (
    <TaskModal
      desktopSize='lg'
      heapModalName='delete-draft-confirmation-modal'
      isDisabled={cancelDraftResult.isLoading}
      onCloseRequest={closeActiveModal}
      primaryButtonTask={() => deleteDraft({ userId })}
      primaryButtonText={t('submit_button')}
      processing={cancelDraftResult.isLoading}
      secondaryButtonText={t('cancel_button')}
      subtitle={t('subheader')}
      title={t('header')}
    />
  );
};

export default CancelConfirmationModal;
