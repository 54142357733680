import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import { TeammateModalProvider } from '../../../../contexts/TeammateContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useCurrentUserAbilities from '../../../../hooks/useCurrentUserAbilities';
import usePageTitle from '../../../../hooks/usePageTitle';
import {
  useAbilityToEditUser,
  useAbilityToManageProfile,
} from '../../../../hooks/users/ProfileSharedAbilities';
import { useIsAdmin } from '../../../../hooks/users/useIsPermission';
import initTranslations from '../../../../lib/initTranslations';
import { UseQueryResult } from '../../../../redux/baseQueries/types';
import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useAppSelector } from '../../../../redux/hooks';
import {
  useGetResponsibilitiesByUserQuery,
  useGetUserGroupResponsibilitiesQuery,
} from '../../../../redux/services/resourceApis/responsibilities/responsibilitiesApi';
import {
  ResponsibilitiesResponse,
  UserGroupResponsibilitiesResponse,
} from '../../../../redux/services/resourceApis/responsibilities/types';
import { useGetUserPrimaryDetailsQuery } from '../../../../redux/services/resourceApis/users/usersApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import Breadcrumbs from '../../../design_system/navigation/Breadcrumbs';
import Loader from '../../../design_system/Triage/Loader';
import { mediaBreakpointPxLg, mediaBreakpointPxMd } from '../../../styled/Breakpoint';
import { routes } from '../../publicApplication/applicationRouter';
import ResponsibilityDetailsModal from '../../responsibilities/modals/ResponsibilityDetailsModal/ResponsibilityDetailsModal';
import ViewOnlyResponsibilityDetailsModal from '../../responsibilities/modals/ViewOnlyResponsibilityDetailsModal/ViewOnlyResponsibilityDetailsModal';
import ResponsibilitiesUserProfile from '../../responsibilities/SurfaceResponsibilities/ResponsibilitiesUserProfile';
import PageContent from '../../shared/page_content';
import NonAdminUserResponsibilities from '../UserShowView/NonAdminUserResponsibilities';
import SurfaceUserGroupResponsibilities from '../UserShowView/UserGroupResponsibilities/SurfaceUserGroupResponsibilities';
import Bio from './Bio';
import LatestActivities from './LatestActivities';
import ProfileSubjects from './SubjectsAssigned/ProfileSubjects';
import TrainingPath from './TrainingPath/TrainingPath';
import UserProfileCard from './UserProfileCard/UserProfileCard';

const BreadcrumbWrapper = styled.div`
  margin-left: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxMd}) {
    margin-left: 0;
  }
`;

const StyledUserProfileCard = styled(UserProfileCard)`
  /* flex-start is used to prevent this card growing in height when extra content is added to the page */
  align-self: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    flex-direction: row;
    margin: 0;
  }
`;

const ProfileRightBlockWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 100%;

  & > div {
    background: ${({ theme: { vars } }) => vars.foundationSurface1};
    border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
      ${({ theme: { vars } }) => vars.foundationBase1};
    box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  }

  @media (min-width: ${mediaBreakpointPxLg}) {
    max-width: calc(100% - 23rem);
    box-sizing: border-box;
  }
`;

const t = initTranslations('user_profile');

type Props = {
  route: Route<typeof routes.userProfile>;
};

const Profile = ({
  route: {
    params: { id, breadcrumb },
  },
}: Props) => {
  const ability = useCurrentUserAbilities();
  const ableToEditUser = useAbilityToEditUser(id);
  const ableToManageProfile = useAbilityToManageProfile(id);
  const { id: currentUserId } = useCurrentUser();
  const { onHoldPlan, slug, status: accountStatus, trainingPathsEnabled } = useCurrentAccount();
  const isAdmin = useIsAdmin();
  const {
    responsibility: { plural: responsibilityPlural },
    productTerm,
  } = useAccountTerminology();
  const canReadUser = ability.can('read', 'User');
  const canReadOrgChart = ability.can('read', 'OrgChart');
  const canReadDirectory = ability.can('read', 'Directory');

  const defaultBreadCrumb = useMemo(() => {
    if (canReadUser) return routes.manageUsers({ slug });
    if (canReadDirectory) return routes.directory({ slug });
    if (canReadOrgChart) return routes.orgChart({ slug });

    return routes.home({ slug });
  }, [canReadDirectory, canReadOrgChart, canReadUser, slug]);

  const shownBreadcrumb = useMemo(() => {
    if (!breadcrumb) return defaultBreadCrumb;

    switch (breadcrumb.name) {
      case 'manageUsers':
        return canReadUser ? breadcrumb : defaultBreadCrumb;
      case 'directory':
        return canReadDirectory ? breadcrumb : defaultBreadCrumb;
      case 'orgChart':
        return canReadOrgChart ? breadcrumb : defaultBreadCrumb;
      default:
        return breadcrumb;
    }
  }, [breadcrumb, canReadDirectory, canReadOrgChart, canReadUser, defaultBreadCrumb]);
  const breadcrumbText =
    shownBreadcrumb.name === 'home' ? t('breadcrumbs.home') : shownBreadcrumb.name;
  const userProfileResult = useGetUserPrimaryDetailsQuery(id);
  const responsibilitiesByUserResult: UseQueryResult<ResponsibilitiesResponse> =
    useGetResponsibilitiesByUserQuery(id);
  const groupResponsibilitiesResult: UseQueryResult<UserGroupResponsibilitiesResponse> =
    useGetUserGroupResponsibilitiesQuery(id);
  const { data: user, isLoading, error } = userProfileResult;
  usePageTitle({ resourceName: user?.name, resourceType: 'user', productTerm });
  const isTrialEndedOrOnHold = accountStatus === 'trial_ended' || onHoldPlan;
  const { isResponsibilityDetailsModalOpen, selectedResponsibilityId, mode } = useAppSelector(
    (state) => state.detailedResponsibilityModal
  );
  const showResponsibilityModal = isResponsibilityDetailsModalOpen && selectedResponsibilityId;

  const ResponsibilityModal =
    mode === 'edit' && ableToEditUser && isAdmin
      ? ResponsibilityDetailsModal
      : ViewOnlyResponsibilityDetailsModal;

  if (isLoading) return <Loader />;
  if (error) return <BasicErrorDisplay error={error} />;
  if (!user) return <></>;

  return (
    <PageContent id='user-profile'>
      <BreadcrumbWrapper>
        <Breadcrumbs
          currentPageText={user.name}
          redirectPath={shownBreadcrumb.href}
          redirectText={breadcrumbText}
          secondClassName='notranslate'
        />
      </BreadcrumbWrapper>
      <ProfileWrapper>
        <TeammateModalProvider userId={id}>
          <StyledUserProfileCard user={user} />
        </TeammateModalProvider>
        <ProfileRightBlockWrapper>
          <Bio isTrialEndedOrOnHold={isTrialEndedOrOnHold} name={user.name} userId={id} />
          {trainingPathsEnabled && ableToManageProfile && <TrainingPath userId={id} />}
          <SurfaceUserGroupResponsibilities
            adminView={ableToEditUser}
            groupResponsibilitiesResult={groupResponsibilitiesResult}
            isAdmin={ability.can('create', 'Group')}
            title={t('user_group_responsibilities_title', {
              responsibilities: responsibilityPlural.toLowerCase(),
            })}
          />
          {ableToEditUser ? (
            <ResponsibilitiesUserProfile
              assignedResponsibilityResult={responsibilitiesByUserResult}
              description={t('user_responsibilities.description')}
              resource={{ userId: id }}
              title={t('user_responsibilities.title', {
                responsibilities: responsibilityPlural.toLowerCase(),
              })}
            />
          ) : (
            <NonAdminUserResponsibilities result={responsibilitiesByUserResult} />
          )}
          {(ableToEditUser || currentUserId === id) && (
            <ProfileSubjects id={id} limit={3} userProfilePage />
          )}
          {ableToEditUser && !isTrialEndedOrOnHold && <LatestActivities userId={id} />}
        </ProfileRightBlockWrapper>
      </ProfileWrapper>
      {showResponsibilityModal && (
        <ResponsibilityModal id={selectedResponsibilityId} responsibiliableId={id} type='User' />
      )}
    </PageContent>
  );
};

export default Profile;
