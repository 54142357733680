import { useFormikContext } from 'formik';
import React from 'react';

import initTranslations from '../../../../../../lib/initTranslations';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import InputField from '../../../../../design_system/Triage/InputField';
import CreatableSelectField from '../../../AdvancedSettings/RestrictByIpSection/CreatableSelectField';
import {
  FormSectionDescription,
  FormSectionTitle,
  InputRow,
  SectionDivider,
  StyledFormGroup,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';
import { CurrentIpInputSection } from './styles';

const t = initTranslations('account_settings.general_settings.whitelisted_ip_addresses_form');

const LoginRestrictions = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();
  const { productTerm } = useAccountTerminology();

  return (
    <>
      <SectionDivider />
      <ToggleSettingContainer>
        <StyledToggle
          checked={values.restrictByIp}
          className='restrict-by-ip-setting'
          handleToggle={() => setFieldValue('restrictByIp', !values.restrictByIp)}
          id='restrict-by-ip'
          name='restrictByIp'
        />
        <ToggleInfoSection>
          <ToggleInfoWrapper>
            <FormSectionTitle>{t('authorize_by_ip_title')}</FormSectionTitle>
            <FormSectionDescription>
              {t('authorize_by_ip_description', { productTerm })}
            </FormSectionDescription>
          </ToggleInfoWrapper>
          {values.restrictByIp && (
            <InputRow id='restrict-by-ip-form'>
              <StyledFormGroup>
                <CreatableSelectField
                  fieldLabelText={t('label')}
                  name='ipWhitelist'
                  onValueChanged={(value) => setFieldValue('ipWhitelist', value)}
                  placeholder=''
                  selectedValue={values.ipWhitelist}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <CurrentIpInputSection>
                  <InputField
                    id='current-ip-input'
                    label={t('current_ip_label')}
                    readOnly
                    value={values.remoteIp}
                  />
                </CurrentIpInputSection>
              </StyledFormGroup>
            </InputRow>
          )}
        </ToggleInfoSection>
      </ToggleSettingContainer>
    </>
  );
};

export default LoginRestrictions;
