import React from 'react';
import { useCookies } from 'react-cookie';

import MarketingSidebar from '../MarketingSidebar/MarketingSidebar';

const videoMarketingSidebarCookie = 'dismissed-video-marketing-sidebar';
const UploadWidget = () => {
  const [dismissed, setDismissed] = React.useState(false);
  const [cookies, setCookie] = useCookies();
  const inspireVideoDismissed = cookies[videoMarketingSidebarCookie];

  const handleDismiss = (dontShowAgain: boolean) => {
    if (dontShowAgain) {
      setCookie(videoMarketingSidebarCookie, JSON.stringify({ value: true }));
    }
    setDismissed(true);
  };

  const showSidebar = !dismissed && !inspireVideoDismissed;

  if (!showSidebar) return null;
  return <MarketingSidebar onDismiss={handleDismiss} />;
};

export default UploadWidget;
