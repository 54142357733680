import React from 'react';

import BasicErrorDisplay from '../../../../redux/errors/BasicErrorDisplay';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import EditModeSkeleton from './components/EditModeSkeleton/EditModeSkeleton';
import EditorPageBase from './EditorPageBase';

type Props = {
  stepIdParam: number;
};

const CollaborationEditor = ({ stepIdParam }: Props) => {
  const { data: stepData, error, isLoading } = useGetStepQuery(stepIdParam);

  if (isLoading || !stepData) return <EditModeSkeleton />;
  if (error) return <BasicErrorDisplay error={error} />;

  return <EditorPageBase stepData={stepData} />;
};

export default CollaborationEditor;
