import React from 'react';
import styled from 'styled-components';

import { useConsumptionCourseContext } from '../../../../../../contexts/ConsumptionCourseContext';
import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import useCurrentUserAbilities from '../../../../../../hooks/useCurrentUserAbilities';
import initTranslations from '../../../../../../lib/initTranslations';
import translationClassname from '../../../../../../lib/translationClassname';
import { ContentType } from '../../../../../../types/ContentType';
import Icon from '../../../../../design_system/display/icons/Icon';
import ViewModeDropdown from '../../../../shared/ViewModeDropdown/ViewModeDropdown';
import { viewOptions } from '../../../../shared/ViewModeDropdown/ViewOptions';
import CurriculumBreadcrumb from '../../../EditorPage/components/Breadcrumbs/CurriculumBreadcrumb';
import {
  BreadcrumbWrapper,
  TimeToRead,
  TopicEmojiWrapper,
  TopicTitle,
} from '../../../shared/styles';
import ConsumptionProgressBar from '../ProgressBar/ProgressBar';

const DropdownWrapper = styled.div`
  width: 50%;
  align-self: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('editor');

const Breadcrumbs = () => {
  const ability = useCurrentUserAbilities();

  const {
    curriculum: { id: curriculumId, completionRequired, disableTranslation },
    curriculum,
    course: {
      emoji,
      title: courseTitle,
      completionPercentage,
      assignedToUser,
      minutesToReadFormatted,
    },
    stepId,
  } = useConsumptionCourseContext();
  const { slug } = useCurrentAccount();
  const canEditCurriculum = ability.can('update', `EditCurriculum-${curriculumId}`);

  return (
    <BreadcrumbWrapper className='breadcrumb-wrapper'>
      <CurriculumBreadcrumb curriculum={curriculum} mode='view' />
      <TopicEmojiWrapper className='icon'>
        {emoji ? <span>{emoji}</span> : <Icon name='file-lines' size='md' weight='regular' />}
      </TopicEmojiWrapper>
      <TopicTitle className={translationClassname(disableTranslation)}>{courseTitle}</TopicTitle>
      <TimeToRead textAlign='center'>{t('time_to_read', { minutesToReadFormatted })}</TimeToRead>
      {canEditCurriculum && (
        <DropdownWrapper id='view-mode-wrapper'>
          <ViewModeDropdown
            initialSelectedOptionIndex={1}
            options={viewOptions({ id: stepId, slug, contentType: ContentType.Step })}
            titleBold
          />
        </DropdownWrapper>
      )}
      {completionRequired && assignedToUser && (
        <ConsumptionProgressBar
          id='consumption-outline-progress-bar'
          percentage={completionPercentage}
        />
      )}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumbs;
