import React, { useState } from 'react';
import styled from 'styled-components';

import { TrainualLogoIcon } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import Hoverable from '../../../../../design_system/Hoverable';
import {
  SearchResultsCardBadge,
  SearchResultsCardDescription,
  SearchResultsCardDetailsWrapper,
  SearchResultsCardEmojiWrapper,
  SearchResultsCardTitle,
  SearchResultsCardTitleWrapper,
  SearchResultsRightArrowIcon,
  searchResultsCardLink,
} from '../shared/styles';
import { SearchHelpCenterResultsCardProps } from './types';

const SearchResultsCardLink = styled.a`
  ${searchResultsCardLink};
`;

const t = initTranslations('search_results_page');

const SearchHelpCenterResultsCard = ({
  titleHighlighted,
  content,
  url,
}: SearchHelpCenterResultsCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Hoverable className='search-result-card' setIsHovered={setIsHovered}>
      <SearchResultsCardLink href={url} target='_blank'>
        <SearchResultsCardEmojiWrapper isCircle={false} isHovered={isHovered}>
          <img alt={t('trainual_logo')} src={TrainualLogoIcon} />
        </SearchResultsCardEmojiWrapper>
        <SearchResultsCardDetailsWrapper isHovered={isHovered}>
          <SearchResultsCardTitleWrapper>
            <SearchResultsCardTitle
              dangerouslySetInnerHTML={{ __html: titleHighlighted }}
              isSurveyQuestion={false}
            />
            <SearchResultsCardBadge
              isHovered={isHovered}
              size='md'
              text={t('badges.article')}
              type='general'
            />
          </SearchResultsCardTitleWrapper>
          <SearchResultsCardDescription html={content} />
        </SearchResultsCardDetailsWrapper>
        <SearchResultsRightArrowIcon
          isHovered={isHovered}
          name='arrow-right'
          size='sm'
          weight='regular'
        />
      </SearchResultsCardLink>
    </Hoverable>
  );
};

export default SearchHelpCenterResultsCard;
