import { useSortable } from '@dnd-kit/sortable';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { useEditorCourseContext } from '../../../../../contexts/EditorCourseContext';
import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import Step from '../../../../../types/Step';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import { routes } from '../../../publicApplication/applicationRouter';
import { DragStyle } from '../../../shared/DragAndDrop/styles';
import StepThreeDot from '../../../shared/StepThreeDot/StepThreeDot';
import { StepLink } from '../../shared/styles';
import EditableStepTitle from './EditableStepTitle';
import StepEmojiPicker from './StepEmojiPicker';

const ThreeDotWrapper = styled.div<{ isMenuOpen: boolean }>`
  .fa-ellipsis-vertical {
    display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
    height: ${({ theme: { constants } }) => constants.height2xs};

    ${StepLink}:hover & {
      display: block;
    }
  }
`;

const StepWrapper = styled.div<{ isEditing: boolean }>`
  max-width: 90%;
  display: flex;
  align-items: ${({ isEditing }) => (isEditing ? 'center' : 'flex-start')};
`;

interface Props {
  step: Step;
  eSignatureDisplayedAndRequired: boolean;
  canBeModified: boolean;
  isLastStep: boolean;
}

const StepOption = ({ step, eSignatureDisplayedAndRequired, canBeModified, isLastStep }: Props) => {
  const { stepId: currentStepId } = useEditorCourseContext();
  const { slug } = useCurrentAccount();
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: step.id,
    disabled: eSignatureDisplayedAndRequired || isEditing,
  });

  const isActive = currentStepId === step.id;
  const { isMenuOpen, closeMenu } = useActiveMenuHandler({ menuId: `step-three-dot-${step.id}` });

  const handleClick = useCallback(
    (event) => {
      // Do not close the menu if the click is inside the StepEmojiPicker
      if (emojiPickerRef.current && emojiPickerRef.current.contains(event.target)) {
        return;
      }

      // Close any active menu whenever a new step is clicked.
      // This is a safety measure to ensure that no menus remain open unexpectedly,
      // particularly when clicking between various steps.
      closeMenu();
      !isEditing && routes.editor({ id: step.id, slug }).push();
    },
    [closeMenu, isEditing, slug, step.id]
  );

  return (
    <div
      ref={setNodeRef}
      style={DragStyle(transition, transform, isDragging)}
      {...listeners}
      {...attributes}
    >
      <StepLink
        id={`edit-step-${step.id}-title`}
        isActive={isActive}
        isEditable={!eSignatureDisplayedAndRequired}
        onClick={handleClick}
      >
        <StepWrapper isEditing={isEditing}>
          <div ref={emojiPickerRef}>
            <StepEmojiPicker step={step} />
          </div>
          <EditableStepTitle
            courseId={step.courseId}
            disableTranslation={step.disableTranslation}
            id={step.id}
            isActive={isActive}
            isEditing={isEditing}
            maxCharacters={250}
            setIsEditing={setIsEditing}
            title={step.title}
          />
        </StepWrapper>
        <ThreeDotWrapper id={`step-${step.id}-three-dot-menu`} isMenuOpen={isMenuOpen}>
          <StepThreeDot
            canBeModified={canBeModified}
            isLastStep={isLastStep}
            renameClick={() => setIsEditing(true)}
            step={step}
            viewingFrom='editor'
          />
        </ThreeDotWrapper>
      </StepLink>
    </div>
  );
};

export default StepOption;
