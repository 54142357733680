import React from 'react';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxLg } from '../../../../../../styled/Breakpoint';

const WrappedNotifyButton = styled(DefaultButton)`
  display: flex;

  @media (max-width: ${mediaBreakpointPxLg}) {
    display: none;
  }
`;

const t = initTranslations('editor_toolbar.notify');

export type Props = {
  setShowNotifyModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotifyButton = ({ setShowNotifyModal }: Props) => {
  const theme = useTheme();

  return (
    <WrappedNotifyButton
      buttonType='tertiary'
      iconColor={theme.vars.textDefault}
      iconName='bell-on'
      id='notify-team-changes-button'
      onClick={() => {
        setShowNotifyModal(true);
      }}
      text={t('notify_button')}
    />
  );
};

export default NotifyButton;
