import { skipToken } from '@reduxjs/toolkit/query';
import { truncate } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Route } from 'type-route';

import TrainualLogo from '../../../../../../../assets/images/trainual-purple.png';
import initTranslations from '../../../../../lib/initTranslations';
import { useUniversalLoginQuery } from '../../../../../redux/services/resourceApis/publicApplication/loginApi';
import { UniversalLoginResponse } from '../../../../../redux/services/resourceApis/publicApplication/types';
import BaseLine from '../../../../design_system/Triage/layout/Line';
import Loader from '../../../../design_system/Triage/Loader';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { routes } from '../../applicationRouter';
import PublicPageBase from '../../PublicPage';
import { FormWrapper, H1, Logo, LogoWrapper } from '../../styles/shared';

const PublicPage = styled(PublicPageBase)`
  height: auto;
`;

const H2 = styled.h2`
  margin: 0;
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  min-height: ${({ theme: { constants } }) => constants.heightLg};
  ${TruncatedText({})};
`;

const AccountsList = styled.ul`
  display: grid;
  padding: 0;
  list-style: none;
  grid-template-columns: 1fr;
  gap: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};
  margin: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerLg1}`};

  @media (min-width: ${mediaBreakpointPxMd}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const AccountLogoWrapper = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  background: ${({ background }) => background};
`;

const AccountLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const AccountLink = styled.a`
  display: flex;
  flex-direction: column;
  place-items: center;
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
  text-decoration: none;
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:hover {
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  }
`;

const Line = styled(BaseLine)`
  width: 100%;
`;

type AccountProps = UniversalLoginResponse['users'][0] & {
  route: Route<typeof routes.accountSelection>;
};

const Account = ({
  email,
  account: { id: accountId, logo: logoUrl, slug, name: accountName, logoBackgroundColor },
  route,
  otp_token,
}: AccountProps) => {
  const logo = useMemo(() => {
    if (!logoUrl || logoUrl.includes('trainual-purple')) return TrainualLogo;

    return logoUrl;
  }, [logoUrl]);

  return (
    <li>
      {/* Navigation should be with page reload to get the current CSRF token (https://trainual.atlassian.net/browse/GS3-798) */}
      <AccountLink
        href={
          routes.login({
            account_id: accountId,
            email,
            otp_token,
            slug,
            redirect_path: route.params.redirect_path,
          }).href
        }
      >
        <AccountLogoWrapper background={logoBackgroundColor}>
          <AccountLogo src={logo} />
        </AccountLogoWrapper>
        <Line />
        <H2>{truncate(accountName, { length: 20 })}</H2>
      </AccountLink>
    </li>
  );
};

const t = initTranslations('public_application.account_selector');

type Props = {
  route: Route<typeof routes.accountSelection>;
};

const AccountSelector = ({ route }: Props) => {
  const { isLoading, data } = useUniversalLoginQuery(
    route.params.email
      ? route.params.otp_token
        ? { email: route.params.email, otp_token: route.params.otp_token }
        : { email: route.params.email }
      : skipToken
  );

  useEffect(() => {
    if (!route.params.email || (data && data.users.length == 0)) routes.universalLogin().replace();
  }, [route.params.email, data]);

  return (
    <PublicPage id='account-selector-page'>
      <FormWrapper>
        <LogoWrapper>
          <Logo src={TrainualLogo} />
        </LogoWrapper>
        <H1>{t('which_account_would_you_like')}</H1>
        <AccountsList>
          {isLoading ? (
            <Loader />
          ) : (
            data?.users?.map((user) => (
              <Account
                key={user.account.id}
                {...user}
                route={route}
                {...(user.account.otpToken && { otp_token: user.account.otpToken })}
              />
            ))
          )}
        </AccountsList>
      </FormWrapper>
    </PublicPage>
  );
};

export default AccountSelector;
