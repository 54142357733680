import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useConsumptionCourseContext } from '../../../../../contexts/ConsumptionCourseContext';
import useScrollTopOnChange from '../../../../../hooks/useScrollTopOnChange';
import initTranslations from '../../../../../lib/initTranslations';
import { GetStepResponse } from '../../../../../redux/services/resourceApis/steps/types';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import { CardBackgroundShadowed, ContentLayout, EditorContentWrapper } from '../../shared/styles';
import TopicHeader from '../../shared/TopicHeader/TopicHeader';
import CompletionDelighter from '../components/CompletionDelighter/CompletionDelighter';
import ESignatureContainer from '../components/ESignatureContainer/ESignatureContainer';
import NavigationButtons from '../components/NavigationButtons/NavigationButtons';
import { CONSUMPTION_TOOLBAR_HEIGHT } from '../ToolbarContainer';
import StepContent from './StepContent';

export const StepTitleWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('editor');

type Props = {
  step: GetStepResponse;
};

type BaseContentProps = {
  children: ReactNode;
} & Props;

const BaseEditorContent = ({ children, step }: BaseContentProps) => {
  const editorContentRef = useScrollTopOnChange(step.id);

  return (
    <EditorContentWrapper
      data-testid='editor'
      ref={editorContentRef}
      toolbarAdjustment={CONSUMPTION_TOOLBAR_HEIGHT}
    >
      <ContentLayout>{children}</ContentLayout>
    </EditorContentWrapper>
  );
};

const ESignatureContent = ({ step }: Props) => <ESignatureContainer stepId={step.id} />;

const CourseCompleteContent = ({ step }: Props) => (
  <CardBackgroundShadowed>
    <CompletionDelighter subtitle={t('success.success_subtitle', { topic: step.topicTitle })} />
    <NavigationButtons stepId={step.id} />
  </CardBackgroundShadowed>
);

const DefaultContent = ({ step }: Props) => {
  const {
    topic: { singular: topicTerm },
  } = useAccountTerminology();
  const {
    course: { emoji: courseEmoji, title: courseTitle, cover: courseCover, steps },
    curriculum: { disableTranslation },
  } = useConsumptionCourseContext();

  return (
    <>
      <TopicHeader
        cover={courseCover}
        disableTranslation={disableTranslation}
        emoji={courseEmoji}
        title={courseTitle}
        topicTerm={topicTerm}
      />
      <StepContent courseSteps={steps} step={step} />
    </>
  );
};

const Editor = ({ step }: Props) => {
  const { isCourseCompleteShowing, isESignatureShowing } = useConsumptionCourseContext();

  if (isESignatureShowing) {
    return (
      <BaseEditorContent step={step}>
        <ESignatureContent step={step} />
      </BaseEditorContent>
    );
  } else if (isCourseCompleteShowing) {
    return (
      <BaseEditorContent step={step}>
        <CourseCompleteContent step={step} />
      </BaseEditorContent>
    );
  }

  return (
    <BaseEditorContent step={step}>
      <DefaultContent step={step} />
    </BaseEditorContent>
  );
};

export default Editor;
