import styled from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { WidgetContainer } from '../shared/styles';

export const StyledWidgetContainer = styled(WidgetContainer)`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} ${constants.spacerMd2}`};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthXl} solid ${vars.trainualBrandPurpleStrong}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  box-shadow: ${({ theme: { vars } }) => `4px 7px 0 0 ${vars.trainualBrandPurpleStrong}`};
  width: 100%;
`;

export const BackgroundImageWrapper = styled.div`
  position: relative;
  left: ${({ theme: { constants } }) => `-${constants.spacerMd2}`};
  width: ${({ theme: { constants } }) => `calc(100% + ${constants.spacerMd2} * 2)`};
  height: 7.875rem;
  overflow: hidden;
  border-radius: 12px;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: inherit;
  object-fit: cover;
  object-position: bottom;
`;

export const Title = styled.h6`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
  text-align: center;
  ${fontSm5};
`;

export const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerLg1};
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
`;

export const FeatureRow = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const FeatureIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.trainualBrandGreenMedium};
`;

export const FeatureDescription = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin: 0;
  ${fontSm4};

  > b {
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;
