import { Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import translationClassname from '../../../../../lib/translationClassname';
import { useUpdateStepMutation } from '../../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../../AccountTerminologyProvider';
import InputFieldWithCharacterCounter from '../../../../design_system/Triage/InputFieldWithCharacterCounter';
import { StepTitle } from '../../shared/styles';
import { getValidationSchema } from '../../shared/validators';

interface FormValues {
  id: number;
  courseId: number;
  title: string;
}

type Props = {
  id: number;
  courseId: number;
  title: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  maxCharacters?: number;
  isActive: boolean;
  disableTranslation?: boolean;
};

const t = initTranslations('outline_content');

const EditableStepTitle = ({
  id,
  isEditing,
  title,
  maxCharacters,
  isActive,
  setIsEditing,
  courseId,
  disableTranslation,
}: Props) => {
  const {
    vars: { accentSubdued1, accentSubdued4 },
  } = useTheme();
  const { step } = useAccountTerminology();
  const [updateStep, result] = useUpdateStepMutation();
  const { isSuccess } = result;

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess, setIsEditing]);

  return (
    <>
      {isEditing ? (
        <div className='editable-title'>
          <Formik
            enableReinitialize
            initialValues={{ title, id, courseId }}
            onSubmit={(values) => updateStep(values)}
            validationSchema={getValidationSchema(
              t('no_title', { step: step.singular.toLowerCase() })
            )}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              values,
              setFieldValue,
            }: FormikProps<FormValues>) => {
              const handleTitleUpdate = () => {
                if (values.title.trim() === '') {
                  setFieldValue('title', title);
                }

                if (values.title !== title) {
                  handleSubmit();
                }
                setIsEditing(false);
              };

              return (
                <InputFieldWithCharacterCounter
                  autoFocus
                  borderColor={accentSubdued4}
                  errorText={errors.title}
                  fillColor={accentSubdued1}
                  inputFor={step.singular}
                  maxCharacters={maxCharacters}
                  name='title'
                  onBlur={handleTitleUpdate}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleTitleUpdate();
                    }
                  }}
                  value={values.title}
                />
              );
            }}
          </Formik>
        </div>
      ) : (
        <StepTitle className={translationClassname(disableTranslation)} isActive={isActive}>
          {title}
        </StepTitle>
      )}
    </>
  );
};

export default EditableStepTitle;
