import {
  GrowthPlanPriceArrow,
  MediumPlanPriceArrow,
  SmallPlanPriceArrow,
  TrainualCustomLogo,
  TrainualGrowthLogo,
  TrainualMediumLogo,
  TrainualSmallLogo,
  TrainualUnlimitedLogo,
  UnlimitedPlanPriceArrow,
} from '../../lib/gcsImages';
import { SimplePricingBillingPlanName } from '../../types/BillingPlanName';

export function getImagesByPlanName(planName: SimplePricingBillingPlanName) {
  switch (planName) {
    case 'small':
      return {
        trainualLogoSrc: TrainualSmallLogo,
        priceArrowSrc: SmallPlanPriceArrow,
      };
    case 'medium':
      return {
        trainualLogoSrc: TrainualMediumLogo,
        priceArrowSrc: MediumPlanPriceArrow,
      };
    case 'growth':
      return {
        trainualLogoSrc: TrainualGrowthLogo,
        priceArrowSrc: GrowthPlanPriceArrow,
      };
    case 'unlimited':
      return {
        trainualLogoSrc: TrainualUnlimitedLogo,
        priceArrowSrc: UnlimitedPlanPriceArrow,
      };
    case 'custom':
      return {
        trainualLogoSrc: TrainualCustomLogo,
        priceArrowSrc: UnlimitedPlanPriceArrow,
      };
  }
}
