import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  FormSectionDescription,
  FormSectionTitle,
  StyledToggle,
  ToggleInfoSection,
  ToggleInfoWrapper,
  ToggleSettingContainer,
} from '../../styles';
import { SettingsData } from '../../types';
import CustomTerminologyForm from './CustomTerminologyForm';

const DropdownSpacer = styled.div`
  height: 10rem;
`;

const t = initTranslations('account_settings.content_settings.terminology');

const CustomTerminologySection = () => {
  const { values, setFieldValue } = useFormikContext<SettingsData>();

  return (
    <ToggleSettingContainer>
      <StyledToggle
        checked={values.customTerminology}
        className='custom-terminology-setting'
        handleToggle={() => setFieldValue('customTerminology', !values.customTerminology)}
        id='enable-custom-terminology'
        name='customTerminology'
      />
      <ToggleInfoSection>
        <ToggleInfoWrapper>
          <FormSectionTitle>{t('toggle_title')}</FormSectionTitle>
          <FormSectionDescription>{t('toggle_description')}</FormSectionDescription>
        </ToggleInfoWrapper>
        {values.customTerminology ? <CustomTerminologyForm /> : <DropdownSpacer />}
      </ToggleInfoSection>
    </ToggleSettingContainer>
  );
};

export default CustomTerminologySection;
